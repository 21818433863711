import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { InvoiceTypes } from "../../utils";

export const InvoiceTypeDropDown = ({ value, isMulti = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    setList(InvoiceTypes);
    return () => {
      setList();
    };
  }, []);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? null}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
