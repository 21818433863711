import React, { useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  FormInput,
  IsCompliantDropdown,
  LoadingComponent,
  MoreOptions,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../components/Component";
import { carerListApi, carerTableColumns, exportCarerAPI } from "./CarerData";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import InvoiceEmail from "../invoices/invoiceEmail/InvoiveEmail";
import AddNotes from "../notes/AddNote";
import { PER_PAGE_ITEMS, ServerStatusType, showErrorToast } from "../../../utils";
import { NoteUserType } from "../notes/NoteList";
import Sms from "../sms/Sms";
import { ImportExportButton } from "../../../components/button/ActionButton";
import ImportFileModal from "../../../components/ImportFileModal/ImportFileModal";
import SendEmailModal from "../invoices/invoiceEmail/SendEmailModal";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { scrollToTop } from "../../../utils/Functions";

const CarerList = () => {
  const history = useHistory();
  const location = useLocation();
  const statusData = location?.state?.status;
  const [status, setStatus] = useState();
  const [isCompliant, setIsCompliant] = useState();
  const [searchText, setSearchText] = useState();
  const [carerList, setCarerList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [emailModal, setEmailModal] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const isRowSelected = selectedRows?.length > 0;
  const selectedRowIds = selectedRows.map((row) => row);
  const emailList = selectedRows.map((item) => item.email);
  const [loading, setLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  
  const onImport = () => {
    setImportModalOpen(true);
  };

  useEffect(() => {
    callCarerListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      callCarerListApi();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompliant, status, searchText, sortId, sortOrder]);

  useEffect(() => {
    if (statusData && location?.state?.isMoveCarerList) {
      setStatus(statusData);
    }
  }, [statusData, location?.state?.isMoveCarerList]);

  function onAddClick() {
    history.push(`${process.env.PUBLIC_URL}/add-carer`);
  }

  const onEdit = (row) => {
    history.push({ pathname: `${process.env.PUBLIC_URL}/add-carer`, state: { data: row } });
  };

  const onView = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/carer-detail`,
      state: { data: row, id: row?.id },
    });
  };

  const onEmail = (selectedRows) => {
    setEmailModal(true);
  };

  const onSms = () => {
    setSmsModal(true);
  };

  function onNote() {
    setNoteModal(true);
  }

  function onExport() {
    setLoading(true);
    let params = {
      search: searchText,
      status: status?.value ?? statusData?.value ?? ServerStatusType.active,
    };
    if (isCompliant?.value === "YES") {
      params.isCompliant = true;
    } else if (isCompliant?.value === "NO") {
      params.isCompliant = false;
    } else {
      params.isCompliant = "";
    }

    exportCarerAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  }

  // API----------------------------------------------------------->
  const callCarerListApi = () => {
    scrollToTop();
    if (isCheckToList(UserPermissionType.carer)) {
      let params = {
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        sorting: {
          field: sortId ?? null,
          order: sortOrder ?? null,
        },
        search: searchText ?? null,
        status: status?.value ?? statusData?.value ?? ServerStatusType.active,
        // isCompliant: isCompliant === "YES" ? true : false,
      };
      if (isCompliant?.value === "YES") {
        params.isCompliant = true;
      } else if (isCompliant?.value === "NO") {
        params.isCompliant = false;
      } else {
        params.isCompliant = "";
      }
      carerListApi(params, (data, error) => {
        if (error === null) {
          setCarerList(data?.list);
          if (currentPage === 1) {
            setTotalItems(data?.pageInfo?.totalItems ?? 0);
          } else {
            showErrorToast(error.message);
          }
          setInitScreen(false);
        }
      });
    } else {
      setInitScreen(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Carers" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Carer</BlockTitle>
              {/* <p>{data?.auth?.sessionUser?.email}</p> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2 ">
                  <span className="form-label">Carer</span>
                  <FormInput
                    labelGroupClass="m-0"
                    value={searchText ?? ""}
                    placeholder="Search Carer"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>
                <span className="me-2">
                  <span className="form-label">Status</span>
                  <StatusActiveDropDown
                    value={status}
                    className="w-125px"
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                <span className="me-2">
                  <span className="form-label">Is Compliance</span>
                  <IsCompliantDropdown
                    value={isCompliant}
                    className="w-100px"
                    onSelect={(e) => {
                      setIsCompliant(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.carer, UserActionType.import) && (
                  <span className="me-2 mb-n3">
                    <ImportExportButton title={"Import"} iconName={"arrow-down"} onClick={onImport} />
                  </span>
                )}
                {isAllowToAccess(UserPermissionType.carer, UserActionType.export) && (
                  <span className="me-2 mb-n3">
                    <ImportExportButton
                      title={"Export"}
                      onClick={() => onExport()}
                      iconName={"arrow-up"}
                      loading={loading}
                    />
                  </span>
                )}
                {isAllowToAccess(UserPermissionType.carer, UserActionType.add) && (
                  <span className="me-2 mb-n3">
                    <ImportExportButton addButton title={"Add"} onClick={() => onAddClick()} iconName={"plus"} />
                  </span>
                )}
                {isRowSelected && (
                  <span className="mb-n3">
                    <MoreOptions
                      className="text-bg-light"
                      options={[
                        {
                          icon: "emails",
                          text: "Email",
                          action: () => {
                            // onEdit(row);
                            onEmail(selectedRows);
                          },
                        },
                        {
                          icon: "chat",
                          text: "Sms",
                          action: () => {
                            onSms(selectedRows);
                            // onRemove(row);
                          },
                        },
                        {
                          icon: "notes-alt",
                          text: "Note",
                          action: () => {
                            onNote(selectedRowIds);
                          },
                        },
                      ]}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={carerList}
              columns={carerTableColumns(onEdit, onView)}
              isAllowed={isCheckToList(UserPermissionType.carer)}
              className={"carerListTable"}
              totalItemCount={totalItems}
              selectableRowsNoSelectAll
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
              selectableRows
              onRowSelected={(row) => {
                setSelectedRows(row.selectedRows);
              }}
              onRowClicked={(row) => isAllowToAccess(UserPermissionType.carer, UserActionType.detail) && onView(row)}
              onSort={(id) => {
                setSortId(id);
                const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
                setSortOrder(newSortOrder);
              }}
            />
          </PreviewCard>
        </Block>
        <SendEmailModal
          visible={emailModal}
          item={emailList}
          onClosed={() => {
            setEmailModal(false);
            // setSelectedRows();
          }}
          onSaved={() => {
            //  loadBranch();
          }}
        />
        <Sms
          visible={smsModal}
          item={selectedRows}
          onClosed={() => {
            setSmsModal(false);
          }}
          onSaved={() => {
            //  loadBranch();
          }}
        />
        <AddNotes
          visible={noteModal}
          parents={selectedRowIds}
          type={NoteUserType.carer}
          onClosed={() => {
            setNoteModal(false);
            // setSelectedRows();
          }}
          onSaved={() => {
            // loadCarerNote();
            // loadClientNote();
          }}
        />
      </Content>
      <LoadingComponent isLoading={initScreen} />
      <ImportFileModal
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen(!importModalOpen)}
        title="Carers"
        callCarerListApi={callCarerListApi}
      />
    </React.Fragment>
  );
};

export default CarerList;
