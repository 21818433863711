import React, { useState } from "react";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { Icon, LoadingComponent, PaginationComponent, PhoneInput } from "../../../../components/Component";
import { CURRENCY_CODE, PER_PAGE_ITEMS } from "../../../../utils";
import { numberFormatter } from "../../../../utils/Functions";
import { clientAuditTrailAPI } from "./HospitalTableData";
import { carerAuditTrailAPI } from "../../carers/CarerData";
import { jobAuditTrailAPI } from "../../jobs/JobTableData";

const AuditTrailDatatable = ({ clientId, carerId, jobId }) => {
  const location = useSelector((state) => state?.auth?.currentLocation);
  const [initScreen, setInitScreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (clientId) {
      loadClientAuditTrail(clientId);
    } else if (carerId) {
      loadCarerAuditTrail(carerId);
    } else if (jobId) {
      loadJobAuditTrail(jobId);
    } else {
      setCurrentPage(1);
    }
  }, [currentPage, clientId, carerId, jobId]);

  // ************ API ****************** //
  const loadClientAuditTrail = (clientId) => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 20,
      },
      clientId: clientId ?? undefined,
    };
    clientAuditTrailAPI(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
        setTotalItems(data?.pageInfo?.totalItems ?? 0);
      }
      setInitScreen(false);
    });
  };

  const loadCarerAuditTrail = (carerId) => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 20,
      },
      carerId: carerId ?? undefined,
    };
    carerAuditTrailAPI(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
        setTotalItems(data?.pageInfo?.totalItems ?? 0);
      }
      setInitScreen(false);
    });
  };

  const loadJobAuditTrail = (jobId) => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 20,
      },
      jobId: jobId ?? undefined,
      // rotaId: 851,
      // timesheetId: 0,
    };
    jobAuditTrailAPI(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
        setTotalItems(data?.pageInfo?.totalItems ?? 0);
      }
      setInitScreen(false);
    });
  };

  // ************ Functions ****************** //
  const handleModal = (item) => {
    setModalData(item);
    setViewModal(true);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Card className="card-bordered mt-2">
        {data?.length > 0 ? (
          <>
            <div className="px-2">
              <div className="align-items-center">
                <div className="row">
                  <div className="col-sm-4 col-12 audit-trail-seprater py-1">
                    <p className="mb-0">Transaction Date & Time</p>
                  </div>
                  {/* <div className="col-sm-2 col-12 audit-trail-seprater py-1">
                    <p className="mb-0">From Account</p>
                  </div> */}
                  {/* <div className="col-sm-2 col-12 audit-trail-seprater py-1">
                    <p className="mb-0">Transaction Date</p>
                  </div> */}
                  <div className="col-sm-8 col-12 py-1">
                    <p className="mb-0 w-80">Description</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-card-end"></div>
          </>
        ) : null}
        <div className="px-2">
          <div className="align-items-center">
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <div className="row align-center" key={index}>
                  <div className="col-sm-4 col-12 audit-trail-seprater py-2">
                    <p className="mb-0">
                      {moment(item?.atDate).format("DD-MM-YYYY")} at {""}
                      {moment(item?.atDate).format("HH:mm")}
                    </p>
                  </div>
                  {/* <div className="col-sm-2 col-12 audit-trail-seprater py-2 ">
                      <p className="mb-0">{item?.fromAccountType ?? "-"}</p>
                    </div> */}
                  {/* <div className="col-sm-2 col-12 audit-trail-seprater py-2">
                      <p className="mb-0">{moment(item?.transData?.date?.new).format("DD-MM-YYYY")}</p>
                    </div> */}
                  <div className="col-sm-8 col-12 align-center py-2 d-flex justify-content-between">
                    <div>
                      <span>
                        {item?.atType === "ADD"
                          ? "Added"
                          : item?.atType === "NEW_JOB"
                          ? "Created"
                          : item?.atType === "NEW_ROTA_CREATED"
                          ? "Booked"
                          : item?.atType === "ROTA_CANCEL"
                          ? "Rota Cancel"
                          : item?.atType === "UPDATE_TS_STATUS"
                          ? "Timesheet Status Updated"
                          : item?.atType === "NEW_TIMESHEET"
                          ? "Timesheet Created"
                          : item?.atType === "UPDATE_TIMESHEET"
                          ? "Timesheet Updated"
                          : item?.atType === "BACK_TO_TIMESHEET"
                          ? "Moved to Timesheet"
                          : item?.atType === "WAGE_CREATED"
                          ? "Wage Created"
                          : item?.atType === "CARER_ASSIGN"
                          ? "Carer Assign"
                          : item?.atType === "UPDATE"
                          ? "Modified"
                          : item?.atType === "UPDATE_JOB"
                          ? "Modified"
                          : null}
                      </span>
                      <span>{" " + "by" + " " + item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</span>
                    </div>
                    {(item?.atType === "UPDATE" ||
                      item?.atType === "UPDATE_JOB" ||
                      item?.atType === "NEW_ROTA_CREATED" ||
                      item?.atType === "ROTA_CANCEL" ||
                      item?.atType === "UPDATE_TS_STATUS" ||
                      item?.atType === "UPDATE_TIMESHEET" ||
                      item?.atType === "CARER_ASSIGN") && (
                      <div className="text-end">
                        <span
                          className="px-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleModal(item);
                          }}
                        >
                          <Icon
                            name="eye"
                            style={{ fontSize: "17px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModal(item);
                            }}
                          ></Icon>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="border-card-end"></div>
                </div>
              ))
            ) : (
              <div className="">
                <div className="py-2">
                  <p className="text-center">There are no records found</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <LoadingComponent isLoading={initScreen} />
      </Card>

      {data?.length > 0 && (
        <div className="my-2">
          <PaginationComponent itemPerPage={20} totalItems={totalItems} paginate={paginate} currentPage={currentPage} />
        </div>
      )}

      <AuditTrailViewModal
        modalData={modalData}
        visible={viewModal}
        onClosed={() => {
          setViewModal(false);
        }}
      />
    </>
  );
};

export default AuditTrailDatatable;

export const AuditTrailViewModal = ({ visible, modalData, onClosed }) => {
  const close = () => {
    onClosed();
  };
  function getTitle(key) {
    switch (key) {
      case "client":
        return "Client Name";
      case "clientStatus":
        return "Status";
      case "shiftType":
        return "Shift";
      case "abbrCode":
        return "Client Code";
      case "address":
        return "Address";
      case "agencies":
        return "Agencies";
      case "branch":
        return "Area Manager";
      case "city":
        return "City";
      case "clientName":
        return "ClientName";
      case "comCareNo":
        return "Com Care No";
      case "contacts":
        return "Contacts";
      case "postalCode":
        return "Postal Code";
      case "telephone":
        return "Phone Number";
      case "clientType":
        return "ClientType";
      case "trust":
        return "Trust";
      case "latitude":
        return "latitude";
      case "longitude":
        return "longitude";
      case "clientContactName":
        return "Name";
      case "contactType":
        return "ContactType";
      case "email":
        return "Email";
      case "phone":
        return "Phone";
      case "clientContactStatus":
        return "Status";
      case "specialities":
        return "Specialities";
      case "grades":
        return "Grades";
      case "banClients":
        return "Client Block";
      case "homeTel1":
        return "Home Contact-1";
      case "homeTel2":
        return "Home Contact-2";
      case "niNo":
        return "NI No";
      case "nisccNmcPin":
        return "NISCC NO / NMC Pin";
      case "refNo":
        return "Ref No";
      case "title":
        return "Title";
      case "firstName":
        return "Firstname";
      case "lastName":
        return "LastName";
      case "dob":
        return "DOB";
      case "payType":
        return "PayType";
      case "gender":
        return "Gender";
      case "carerType":
        return "Carer Type";
      case "relationship":
        return "Relationship";
      case "middleName":
        return "MiddleName";
      case "days":
        return "Day";
      case "endTime":
        return "End Time";
      case "startTime":
        return "Start Time";
      case "ward":
        return "Ward";
      case "agency":
        return "Agency";
      case "assignedCarers":
        return "Assigned Carer";
      case "fromDate":
        return "Start Date";
      case "toDate":
        return "End Date";
      case "status":
        return "Status";
      case "statusReason":
        return "Cancel Reason";
      case "timesheetDataStatus":
        return "Status";
      case "carerName":
        return "Carer Name";
      case "breakTime":
        return "Break Time";
      case "isIncharge":
        return "Incharge";
      case "poNumber":
        return "Po Number";
      case "sleepoverEnd":
        return "Sleepover End";
      case "sleepoverStart":
        return "Sleepover Start";
      case "totalHours":
        return "Total Hours";
      case "totalMiles":
        return "Total Miles";
      case "files":
        return "Added Files";
      case "removedFiles":
        return "Removed Files";
      default:
        break;
    }
  }
  let keys = Object.keys(modalData?.desc ?? {});

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.file_url, "_blank");
    }
  };

  return (
    <Modal size={"lg"} isOpen={visible} toggle={() => close()}>
      <ModalHeader toggle={() => close()}>{moment(modalData?.atDate).format("DD-MM-YYYY")}</ModalHeader>
      <ModalBody className="mb-1">
        <div className="m-n1">
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Old</th>
                <th scope="col">New</th>
              </tr>
            </thead>
            <tbody>
              {keys
                .filter(
                  (item) =>
                    item !== "contacts" &&
                    item !== "homeTel1CountryCode" &&
                    item !== "homeTel2CountryCode" &&
                    item !== "kins"
                )
                .map((key, index) => {
                  return (
                    <React.Fragment key={index}>
                      {(Object.keys(modalData?.desc?.[key])?.length ?? 0) > 0 ? (
                        <React.Fragment key={index}>
                          <tr>
                            <td scope="row" style={{ width: "25%" }}>
                              {getTitle(key)}
                            </td>
                            <td style={{ width: "37%" }}>
                              {/* Display old date */}
                              {modalData?.desc?.[key] && (
                                <>
                                  {key === "clientStatus" ? (
                                    modalData?.desc?.[key]?.old === "DISABLED" ? (
                                      "INACTIVE"
                                    ) : (
                                      moment(modalData?.desc?.[key]?.old).format("DD-MM-YYYY")
                                    )
                                  ) : key === "agencies" ? (
                                    <>
                                      {modalData?.desc?.agencies?.old.map((agency, index) => (
                                        <tr key={index}>
                                          <td>{modalData?.desc?.[key]?.old?.[index] ?? "-"}</td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : key === "grades" ? (
                                    <>
                                      {modalData?.desc?.grades?.old.map((grade, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.old?.[index]}</span>
                                          {index !== modalData?.desc?.grades?.old.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "specialities" ? (
                                    <>
                                      {modalData?.desc?.specialities?.old.map((spe, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.old?.[index]}</span>
                                          {index !== modalData?.desc?.specialities?.old.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "banClients" ? (
                                    <>
                                      {modalData?.desc?.banClients?.old.map((client, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.old?.[index]}</span>
                                          {index !== modalData?.desc?.banClients?.old.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "homeTel1" ? (
                                    <>
                                      {modalData?.desc?.homeTel1CountryCode?.old && (
                                        <span>{`+${modalData?.desc?.homeTel1CountryCode?.old}-${
                                          modalData?.desc?.homeTel1?.old ?? "-"
                                        }`}</span>
                                      )}
                                    </>
                                  ) : key === "homeTel2" ? (
                                    <>
                                      {modalData?.desc?.homeTel2CountryCode?.old && (
                                        <span>{`+${modalData?.desc?.homeTel2CountryCode?.old}-${
                                          modalData?.desc?.homeTel2?.old ?? "-"
                                        }`}</span>
                                      )}
                                    </>
                                  ) : key === "dob" ? (
                                    moment(modalData?.desc?.dob?.old).format("DD-MM-YYYY")
                                  ) : key === "fromDate" ? (
                                    moment(modalData?.desc?.fromDate?.old).format("DD-MM-YYYY")
                                  ) : key === "toDate" ? (
                                    moment(modalData?.desc?.toDate?.old).format("DD-MM-YYYY")
                                  ) : key === "isIncharge" ? (
                                    <>
                                      {modalData?.desc?.isIncharge?.old === 1 ? (
                                        <span>True</span>
                                      ) : modalData?.desc?.isIncharge?.old === 0 ? (
                                        <span>False</span>
                                      ) : null}
                                    </>
                                  ) : key === "removedFiles" ? (
                                    <>
                                      {modalData?.desc?.removedFiles?.length > 0 &&
                                        modalData?.desc?.removedFiles?.map((file, index) => {
                                          return (
                                            <div key={index}>
                                              <span className="me-1">{index + 1 + "."}</span>
                                              <span
                                                className="text-primary cursor-pointer"
                                                onClick={() => openFileInNewTab(file)} // Handle click event
                                              >
                                                {file?.name ?? "-"}
                                              </span>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    modalData?.desc?.[key]?.old ?? "-"
                                  )}
                                </>
                              )}
                            </td>
                            <td style={{ width: "37%" }}>
                              {/* Display new date */}
                              {modalData?.desc?.[key] && (
                                <>
                                  {key === "date" ? (
                                    moment(modalData?.desc?.[key]?.new).format("DD-MM-YYYY")
                                  ) : key === "clearanceDate" ? (
                                    moment(modalData?.desc?.[key]?.new).format("DD-MM-YYYY")
                                  ) : key === "agencies" ? (
                                    <>
                                      {modalData?.desc?.agencies?.new?.map((agency, index) => (
                                        <tr key={index}>
                                          <td>{modalData?.desc?.[key]?.new?.[index] ?? "-"}</td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : key === "grades" ? (
                                    <>
                                      {modalData?.desc?.grades?.new.map((grade, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.new?.[index]}</span>
                                          {index !== modalData?.desc?.grades?.new.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "specialities" ? (
                                    <>
                                      {modalData?.desc?.specialities?.new.map((spe, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.new?.[index]}</span>
                                          {index !== modalData?.desc?.specialities?.new.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "banClients" ? (
                                    <>
                                      {modalData?.desc?.banClients?.new.map((client, index) => (
                                        <React.Fragment key={index}>
                                          <span>{modalData?.desc?.[key]?.new?.[index]}</span>
                                          {index !== modalData?.desc?.banClients?.new.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                      ))}
                                    </>
                                  ) : key === "homeTel1" ? (
                                    <>
                                      {modalData?.desc?.homeTel1CountryCode?.new && (
                                        <span>{`+${modalData?.desc?.homeTel1CountryCode?.new}-${
                                          modalData?.desc?.homeTel1?.new ?? "-"
                                        }`}</span>
                                      )}
                                    </>
                                  ) : key === "homeTel2" ? (
                                    <>
                                      {modalData?.desc?.homeTel2CountryCode?.new && (
                                        <span>{`+${modalData?.desc?.homeTel2CountryCode?.new}-${
                                          modalData?.desc?.homeTel2?.new ?? "-"
                                        }`}</span>
                                      )}
                                    </>
                                  ) : key === "dob" ? (
                                    moment(modalData?.desc?.dob?.new).format("DD-MM-YYYY")
                                  ) : key === "fromDate" ? (
                                    moment(modalData?.desc?.fromDate?.new).format("DD-MM-YYYY")
                                  ) : key === "toDate" ? (
                                    moment(modalData?.desc?.toDate?.new).format("DD-MM-YYYY")
                                  ) : key === "carerName" ? (
                                    <>
                                      {modalData?.desc?.carerName && (
                                        <span>{modalData?.desc?.carerName?.new ?? "-"}</span>
                                      )}
                                    </>
                                  ) : key === "isIncharge" ? (
                                    <>
                                      {modalData?.desc?.isIncharge?.new === 1 ? (
                                        <span>True</span>
                                      ) : modalData?.desc?.isIncharge?.new === 0 ? (
                                        <span>False</span>
                                      ) : null}
                                    </>
                                  ) : key === "files" ? (
                                    <>
                                      {modalData?.desc?.files?.length > 0 &&
                                        modalData?.desc?.files?.map((file, index) => {
                                          return (
                                            <div key={index}>
                                              <span className="me-1">{index + 1 + "."}</span>
                                              <span
                                                className="text-primary cursor-pointer"
                                                onClick={() => openFileInNewTab(file)} // Handle click event
                                              >
                                                {file?.name ?? "-"}
                                              </span>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    modalData?.desc?.[key]?.new ?? "-"
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        {(modalData?.desc?.contacts?.modified?.length ?? 0) > 0 ? (
          <div className="m-n1 pt-4">
            <span className="fs-6">Contact</span>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Old</th>
                  <th scope="col">New</th>
                </tr>
              </thead>
              <tbody>
                {modalData?.desc?.contacts?.modified?.map((modifiedItem, index) => {
                  let modifiedKeys = modifiedItem
                    ? Object.keys(modifiedItem).filter(
                        (itemkey) => itemkey !== "accountId" && itemkey !== "accountName"
                      )
                    : [];
                  return (
                    <React.Fragment key={index}>
                      {modifiedKeys.map((modifiedKey, innerIndex) => {
                        return (
                          <tr key={innerIndex}>
                            <td scope="row">{getTitle(modifiedKey)}</td>
                            <td>
                              {modifiedKey === "amount"
                                ? numberFormatter(CURRENCY_CODE, modifiedItem?.old?.[modifiedKey])
                                : modifiedItem?.[modifiedKey].old}
                            </td>
                            <td>
                              {modifiedKey === "amount"
                                ? numberFormatter(CURRENCY_CODE, modifiedItem?.new?.[modifiedKey])
                                : modifiedItem?.[modifiedKey].new}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          (modalData?.desc?.kins?.modified?.length ?? 0) > 0 && (
            <div className="m-n1 pt-4">
              <span className="fs-6">Next of Kin</span>
              <table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Old</th>
                    <th scope="col">New</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData?.desc?.kins?.modified?.map((modifiedItem, index) => {
                    let modifiedKeys = modifiedItem
                      ? Object.keys(modifiedItem).filter(
                          (itemkey) => itemkey !== "accountId" && itemkey !== "accountName" && itemkey !== "countryCode"
                        )
                      : [];
                    return (
                      <React.Fragment key={index}>
                        {modifiedKeys.map((modifiedKey, innerIndex) => {
                          return (
                            <tr key={innerIndex}>
                              <td scope="row">{getTitle(modifiedKey)}</td>
                              <td>
                                {modifiedKey === "amount"
                                  ? numberFormatter(CURRENCY_CODE, modifiedItem?.old?.[modifiedKey])
                                  : modifiedItem?.[modifiedKey].old}
                              </td>
                              <td>
                                {modifiedKey === "amount"
                                  ? numberFormatter(CURRENCY_CODE, modifiedItem?.new?.[modifiedKey])
                                  : modifiedItem?.[modifiedKey].new}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        )}

        {modalData?.desc?.contacts?.added?.length > 0 && (
          <div className="m-n1 pt-4">
            {modalData?.desc?.contacts?.added?.map((addedItem, index) => {
              let addedKeys = addedItem
                ? Object.keys(addedItem).filter(
                    (itemkey) =>
                      itemkey !== "clientId" &&
                      itemkey !== "createdBy" &&
                      itemkey !== "clientContactId" &&
                      itemkey !== "countryCode"
                  )
                : [];
              return (
                <React.Fragment key={index}>
                  <span className="fs-6">Contact Add {index + 1}</span>
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Add</th>
                      </tr>
                    </thead>
                    <tbody key={index}>
                      {addedKeys?.map((addedKey, innerIndex) => (
                        <React.Fragment key={innerIndex}>
                          <tr>
                            <td scope="row">{getTitle(addedKey)}</td>
                            <td>
                              {addedKey === "phone"
                                ? `${"+" + addedItem?.countryCode + "-" + addedItem?.phone}`
                                : addedItem?.[addedKey]}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
          </div>
        )}

        {modalData?.desc?.contacts?.removed?.length > 0 && (
          <div className="m-n1 pt-4">
            {modalData?.desc?.contacts?.removed?.map((removedItem, index) => {
              let removedKeys = removedItem
                ? Object.keys(removedItem).filter(
                    (itemkey) =>
                      itemkey !== "clientId" &&
                      itemkey !== "createdBy" &&
                      itemkey !== "clientContactId" &&
                      itemkey !== "countryCode" &&
                      itemkey !== "createdBy" &&
                      itemkey !== "modifiedDate" &&
                      itemkey !== "createdDate"
                  )
                : [];
              return (
                <React.Fragment key={index}>
                  <p className="fs-6 mb-0 mt-1">Contact remove {index + 1}</p>
                  <table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Remove</th>
                      </tr>
                    </thead>
                    <tbody key={index}>
                      {removedKeys?.map((removedKey, innerIndex) => (
                        <React.Fragment key={innerIndex}>
                          <tr>
                            <td scope="row">{getTitle(removedKey)}</td>
                            <td>
                              {removedKey === "phone"
                                ? `${"+" + removedItem?.countryCode + "-" + removedItem?.phone}`
                                : removedKey === "clientContactStatus" && removedItem?.clientContactStatus === "DELETED"
                                ? "REMOVED"
                                : removedItem?.[removedKey]}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
