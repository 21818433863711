import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { gradeTypeDropDownData, gradeTypeDropDownDataWithoutCarerType } from "./DropDownData";

const GradeTypeDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  carerTypeId,
  placeholder,
  disabled,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carerTypeId) {
      loadGradeTypeDropDownData();
    }
  }, [carerTypeId]);

  const loadGradeTypeDropDownData = () => {
    gradeTypeDropDownData(carerTypeId, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([...data]);
        }
      }
    });
  };
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      disable={disabled}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default GradeTypeDropDown;

export const GradeTypeDropDownWithoutCarer = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  carerTypeId,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadGradeTypeDropDownData();
  }, []);

  const loadGradeTypeDropDownData = () => {
    gradeTypeDropDownDataWithoutCarerType((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([...data]);
        }
      }
    });
  };
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
