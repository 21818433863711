import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ConfirmationAlert, LoadingComponent, ReactDataTable } from "../../../components/Component";
import { listWageAPI, wageModerateStatus, wageMoveToTimesheet, wageTableData } from "./WageTableData";
import { PER_PAGE_ITEMS } from "../../../utils";
import { useEffect } from "react";
import { WageStatusOption } from "../../../utils/Utils";
import { scrollToTop, showErrorToast, showSuccessToast } from "../../../utils/Functions";
import { UserPermissionType, isCheckToList } from "../../../services/permission/permission";

const WageList = forwardRef(function WageList(
  { startDate, endDate, status, carerId, clientId, agencyId, payType, onRowSelectionChange, type, rowAction },
  ref
) {
  // const WageList = ({ startDate, endDate, status, carerId, onRowSelectionChange, type, selectedRowIds, rowAction }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [wageList, setWageList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [initScreen, setInitScreen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    loadWageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;

    if (currentPage === 1) {
      loadWageList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, startDate, endDate, carerId, clientId, sortId, sortOrder, agencyId, payType]);

  useImperativeHandle(
    ref,
    () => {
      return {
        moveTimesheet(selectedRowIds) {
          moveToTimesheet(selectedRowIds);
        },
        moveFinalised(selectedRowIds) {
          onStatus(selectedRowIds, WageStatusOption.finalised);
        },
      };
    },
    [startDate, endDate]
  );

  function onStatus(wageIds, status) {
    let newStatus;

    if (status === WageStatusOption.pending) {
      newStatus = WageStatusOption.finalised;
    } else if (status === WageStatusOption.finalised) {
      newStatus = "FINALISED";
    } else {
      newStatus = "";
    }

    wageStatusChange(wageIds, newStatus);
  }

  function moveTimesheet(wageIds) {
    moveToTimesheet(wageIds);
  }

  function wageStatusChange(wageIds, newStatus) {
    let params = {
      ids: wageIds,
      wageAction: newStatus,
    };
    ConfirmationAlert("Are you sure!", "Do you want to change status of wage" + " " + "?", () => {
      wageModerateStatus(params, (_, error) => {
        if (error === null) {
          showSuccessToast("Wage has been moved successfully");
          loadWageList();
        } else {
          showErrorToast(error.message);
        }
      });
    });
  }

  function loadWageList() {
    scrollToTop();

    if (isCheckToList(UserPermissionType.wage)) {
      let params = {
        startDate: startDate,
        endDate: endDate,
        carerId: carerId,
        agencyId: agencyId ?? null,
        clientId: clientId ? clientId : null,
        payType: payType ? payType : "",
        status: status,
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        sorting: {
          field: sortId ?? null,
          order: sortOrder ?? null,
        },
      };
      if (!startDate || !endDate) {
        setInitScreen(false);
        return;
      }
      setInitScreen(true);
      listWageAPI(params, (list, error) => {
        if (error === null) {
          setWageList(list?.list);
          if (currentPage === 1) {
            setTotalItems(list?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      });
    }
  }

  function moveToTimesheet(wageIds) {
    let params = {
      wageIds: wageIds,
    };
    wageMoveToTimesheet(params, (data, error) => {
      if (!error) {
        loadWageList();
      } else {
        // Add error toast
      }
    });
  }

  // function exportWages(ev) {
  //   if (!startDate || !endDate) return;
  //   setLoadingExport(true);
  //   let params = {
  //     startDate: startDate,
  //     endDate: endDate,
  //     clientId: client?.id,
  //     carerId: carer?.id,
  //     status: status?.value ?? WageStatusOption.pending,
  //   };

  //   exportWageList(params, (data, error) => {
  //     if (error === null) {
  //       openLinkInNewTab(data.fileUrl, ev);
  //     }
  //     setLoadingExport(false);
  //   });
  // }

  // const handleDatePickerChange = (startDate, endDate) => {
  //   setStartDate(startDate);
  //   setEndDate(endDate);
  // };

  return (
    <React.Fragment>
      <ReactDataTable
        data={wageList}
        columns={wageTableData(type, status, onStatus, moveTimesheet)}
        isAllowed={isCheckToList(UserPermissionType.wage)}
        totalItemCount={totalItems}
        pagination
        onPageChanged={(page, count) => {
          setCurrentPage(page);
        }}
        selectableRows={status === WageStatusOption.pending ? true : false}
        onRowSelected={(row) => {
          onRowSelectionChange(row?.selectedRows);
        }}
        onSort={(id) => {
          setSortId(id);
          const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
          setSortOrder(newSortOrder);
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
});

export default WageList;
