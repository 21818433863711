import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";

export const noteTypeColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row?.title ?? "",
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.noteType, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addNoteTypeAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.title,
    desc: data.description,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addNoteType, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listNoteTypeAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listNoteType, params)
    .then((data) => {
      let list = toNoteTypeList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toNoteTypeList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toNoteType(element));
  });
  return list;
}

function toNoteType(data) {
  return {
    id: data.id,
    title: data?.name ?? "",
    description: data?.desc ?? "",
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}
