import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { carerTypeDropDownData } from "./DropDownData";

export const CarerTypeDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  selectedId,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadCarerTypeList();
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCarerTypeList = () => {
    carerTypeDropDownData((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId && list.length > 0) { 
      const selectedOption = list.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [selectedId, list]);

  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
