import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCurrentUser } from "../pages/auth/AuthData";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
  },
  reducers: {
    logout(state, action) {
      state.logintime = null
      state.sessionUser = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCureentUser.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(loadCureentUser.fulfilled, (state, action) => {
      state.sessionUser = action.payload?.sessionUser;
      state.logintime = new Date();
      state.loading = false
    });
    builder.addCase(loadCureentUser.rejected, (state, action) => {
      state.loading = false
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

export const loadCureentUser = createAsyncThunk("current-user-api", () => {  
  let data = fetchCurrentUser();
  return data;
});
