import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { carerTypeColumns, listCarerTypeAPI } from "./CarerTypeData";
import AddCarerType from "./AddCarerType";
import { ServerStatusType } from "../../../../utils";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const CarerTypeList = () => {
  const [carerTypeListData, setCarerTypeListData] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCarerType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadCarerType();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  function loadCarerType() {
    if (isCheckToList(UserPermissionType.carerType)) {
      setLoading(true);
      listCarerTypeAPI(
        {
          status: status?.value ?? ServerStatusType.active,
          pageInfo: {
            pageIndex: currentPage,
          },
        },
        (list, error) => {
          if (error === null) {
            setCarerTypeListData(list?.list);
            setLoading(false);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    }
  }
  return (
    <React.Fragment>
      <Head title="Carer Type" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Carer Type</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.carerType, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={carerTypeListData}
              columns={carerTypeColumns(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.carerType)}
              totalItemCount={totalItems}
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>
        </Block>
        <AddCarerType
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadCarerType();
          }}
        />
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default CarerTypeList;
