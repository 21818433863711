import React from "react";
import {
  ActionButton,
  Block,
  Col,
  FormCheckbox,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  Row,
} from "../../../components/Component";
import { useState } from "react";
import { HospitalTableData, listHospitalAPI } from "../clients/hospital/HospitalTableData";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { Form, Spinner } from "reactstrap";
import { useContext } from "react";
import { CarerWizardContext } from "./AddCarer";
import { CarerPreviousButton } from "../../../components/button/ActionButton";

const CarerHomeCare = ({ onNext, onPrev, isValid }) => {
  const { data, updateData } = useContext(CarerWizardContext);
  const [hospitalList, setHospitalList] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [itHasChanges, setItHasChanges] = useState(false);

  const history = useHistory();
  useEffect(() => {
    loadHospital();
  }, []);

  useEffect(() => {
    setSelectAll(!hospitalList.map((item) => item?.id).some((item) => data?.hospital?.includes(item)));
    isValid(false, itHasChanges);
  }, [hospitalList, data]);

  function loadHospital() {
    setLoadingDetail(true);

    listHospitalAPI(
      {
        clientType: "HOMECARE",
        status: ServerStatusType.active,
      },
      (list, error) => {
        if (error === null) {
          setHospitalList(list?.list);
        }
        setLoadingDetail(false);
      }
    );
  }

  return (
    <div>
      <Form>
        {loadingDetail ? (
          <div className="spinner d-flex justify-content-center">
            <Spinner size="sm" color="primary" />
          </div>
        ) : (
          <Row>
            <FormCheckbox
              id={"select"}
              label={"Select All"}
              name={"Select All"}
              checked={selectAll}
              onChange={(e) => {
                if (selectAll !== e.target.checked) {
                  setItHasChanges(true);
                }
                const isChecked = e.target.checked;
                setSelectAll(isChecked);
                let updatedHospitalList = data?.hospital ?? [];
                hospitalList.forEach((item, index) => {
                  if (isChecked) {
                    updatedHospitalList = updatedHospitalList.filter((x) => x !== item.id);
                  } else {
                    if (!updatedHospitalList.some((x) => x === item.id)) {
                      updatedHospitalList.push(item.id);
                    }
                  }
                });
                updateData("hospital", updatedHospitalList);
              }}
            />
            {hospitalList.length > 0 &&
              hospitalList.map((hospitalItem, index) => (
                <React.Fragment key={index}>
                  <Col className="col-12 col-sm-6 col-md-4">
                    <FormCheckbox
                      id={"homecare" + index}
                      required={false}
                      name={hospitalItem?.name}
                      label={hospitalItem?.name}
                      checked={data?.hospital?.some((item) => item === hospitalItem.id) ? false : true}
                      onChange={(e) => {
                        if (data?.hospital?.find((item) => item !== hospitalItem?.id)) {
                          setItHasChanges(true);
                        }
                        let list = data.hospital;
                        if (!e.target.checked) {
                          list.push(hospitalItem?.id);
                          updateData("hospital", list);
                        } else {
                          let INDEX = list.indexOf(hospitalItem?.id);
                          list.splice(INDEX, 1);
                          updateData("hospital", list);
                        }
                      }}
                    />
                  </Col>
                </React.Fragment>
              ))}
          </Row>
        )}
      </Form>
      <Block className="text-end mt-3">
        <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
          <li>
            <CarerPreviousButton
              // type="submit"
              className="w-100px"
              title="Back"
              size="md"
              onClick={() => onPrev()}
            />
          </li>
          <li>
            <ActionButton
              //   disabled={isDisable}
              // type="submit"
              className="w-100px"
              title="Save"
              size="md"
              onClick={() => {
                onNext();
              }}
              //   onClick={() => onFormSubmit()}
            />
          </li>
        </ul>
      </Block>
    </div>
  );
};

export default CarerHomeCare;
