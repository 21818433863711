import React, { useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interaction from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import { Popover, PopoverHeader } from "reactstrap";
import FullCalendar from "@fullcalendar/react";

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div
        className=""
        id={publicId}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader>{title}</PopoverHeader>
      </Popover>
    </React.Fragment>
  );
};

const CalenderApp = ({ events, onEvent, onAdd, onDateChange }) => {
  const handleEventClick = (info) => {
    onEvent(info.event);
  };

  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interaction]}
        events={events}
        eventClick={(info) => handleEventClick(info)}
        datesSet={(dates) => onDateChange(dates.start, dates.end)}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "title prev,next",
          center: null,
          right: "today dayGridMonth,listMonth",
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={false}
        droppable={true}
        dateClick={(info) => onAdd(info)}
        backgroundColor="#1a7dff"
      />
    </React.Fragment>
  );
};

export default CalenderApp;
