import React, { useContext, useEffect, useState } from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  FormDatePicker,
  FormInput,
  FormInputType,
  Icon,
  PreviewAltCard,
  PreviewCard,
  Row,
} from "../Component";
import { AppText, showErrorToast } from "../../utils";
import { bytesToMegaBytes } from "../../utils/Utils";
import { carerDocumentSaveApi, fileUploadApi } from "../../pages/tabs/carers/CarerData";
import { Spinner } from "reactstrap";
import { iconsType } from "../../layout/fileIcon/FileIcon";
import { CarerWizardContext } from "../../pages/tabs/carers/AddCarer";
import moment from "moment";

const UploadDocumentModal = ({ visible, onClosed, carerId, selectedItem, afterDocumentSave }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const fileInputRef = React.createRef(); // Create a reference for the file input
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDocumentData([]);
    setUploadedFiles([]);
  }, [visible, selectedItem]);

  //  ****************** API ****************************** //
  const uploadFile = (file, index) => {
    fileUploadApi(file, "CARER_DOCUMENT", (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const carerDocumentSave = () => {
    setLoading(true);
    let fileIds = uploadedFiles.map((file) => {
      return file?.id;
    });

    let params = {
      carerId: carerId,
      documents: [
        {
          // carerDocId: selectedItem?.carerDocumentId ?? null,
          carerDocId: 0,
          fileIds: fileIds,
          documentId: selectedItem?.id,
          expiryDate: documentData?.expiryDate ? moment(documentData?.expiryDate).format("YYYY-MM-DD") : undefined,
          issueDate: documentData?.issueDate ? moment(documentData?.issueDate).format("YYYY-MM-DD") : undefined,
          refNo: documentData?.refNo ?? null,
        },
      ],
      removeDocumentIds: removeFileIds,
    };
    carerDocumentSaveApi(params, (data, error) => {
      setLoading(false);
      if (error === null) {
        onClosed();
        afterDocumentSave();
      }
    });
  };

  // **************** Functions ************ //
  const handleFileSelect = (file, rootIndex) => {
    if (uploadedFiles.length >= 5) {
      showErrorToast("Only a maximum of 5 files can be attached.");
      return;
    }
    if (bytesToMegaBytes(file.size) > 10) {
      showErrorToast("You can upload max 10 MB file");
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles];
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const openFileInNewTab = (file) => {
    if (file) {
      // window.open(file?.fileUrl, "_blank");
    }
  };

  const close = () => {
    onClosed();
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData
    const removedFileId = updatedfiles[ind].id;

    // Add the removed file ID to removeFileIds
    setRemoveFileIds((prevRemoveFileIds) => [...prevRemoveFileIds, removedFileId]);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile
    setUploadedFiles(updatedfiles);
  };

  return (
    <>
      <AnnsModal size={"sm"} isOpen={visible} toggle={() => onClosed()} title={"Upload Document"} isFooterHide={true}>
        <PreviewAltCard bodyClass={"py-1 px-2"}>
          <div>
            <label className="">
              Upload Documents
              <span className="text-danger fs-6">*</span>
            </label>
            <ActionButton title={"Upload Files"} className={"mt-1"} onClick={() => fileInputRef.current.click()} />
            <div className="text-end">
              <input
                id={"files"}
                type="file"
                accept=".xlsx,.xls,.pdf,.png,.jpef,.jpg"
                className="form-control"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => {
                  const selectedFiles = e.target.files;
                  if (selectedFiles.length > 0) {
                    handleFileSelect(selectedFiles[0]);
                  }
                  e.target.value = '';
                }}
              />
            </div>
          </div>
          {uploadedFiles?.length > 0 &&
            uploadedFiles?.map((file, ind) => {
              return (
                <React.Fragment key={`file-${ind}`}>
                  <div className="nk-upload-item">
                    <div className="nk-upload-icon">
                      {file?.isOldFile ? (
                        <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                      ) : (
                        <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                      )}
                    </div>
                    <div className="nk-upload-info">
                      <div className="nk-upload-title">
                        <span className="title" style={{ cursor: "pointer" }} onClick={() => openFileInNewTab(file)}>
                          {file?.name}
                        </span>
                      </div>
                    </div>
                    <div className="nk-upload-action">
                      <a
                        href="#delete"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                      >
                        {file?.isLoading ? (
                          <Spinner size="sm" color="dark" />
                        ) : (
                          <span onClick={() => handleFileRemove(ind)}>
                            <Icon name="trash"></Icon>
                          </span>
                        )}
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </PreviewAltCard>
        <div className="mt-1">
          <label className="form-label">
            Enter Reference Number
            <span className="text-danger fs-6">*</span>
          </label>
          <FormInput
            // label={"Enter Reference Number"}
            type={FormInputType.default}
            value={documentData?.refNo}
            maxLength={15}
            onTextChange={(text) => {
              setDocumentData((prevData) => ({
                ...prevData,
                refNo: text,
              }));
            }}
          />
        </div>
        <div className="form-group mb-0 mt-1">
          <label className="form-label">
            Enter Issue Date {selectedItem?.isIssue === true && <span className="text-danger fs-6">*</span>}
          </label>
          <div className="form-control-wrap">
            <FormDatePicker
              label={"Issue Date"}
              selected={documentData?.issueDate}
              maxDate={new Date()}
              onChange={(date) => {
                setDocumentData((prevData) => ({
                  ...prevData,
                  issueDate: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="form-group mb-0 mt-1">
          <label className="form-label">
            Enter Expiry Date {selectedItem?.isExpire === true && <span className="text-danger fs-6">*</span>}
          </label>
          <div className="form-control-wrap">
            <FormDatePicker
              label={"Expiry Date"}
              selected={documentData?.expiryDate}
              onChange={(date) => {
                setDocumentData((prevData) => ({
                  ...prevData,
                  expiryDate: date,
                }));
              }}
            />
          </div>
        </div>
        <ActionButton
          title={"Save"}
          className={"mt-3"}
          onClick={() => carerDocumentSave()}
          loading={loading}
          disabled={
            (selectedItem?.isExpire === true && !documentData?.expiryDate) ||
            (selectedItem?.isIssue === true && !documentData?.issueDate) ||
            uploadedFiles.length === 0 ||
            !documentData?.refNo
          }
        />
      </AnnsModal>
    </>
  );
};

export default UploadDocumentModal;
