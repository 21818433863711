import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  CarersDropDown,
  Col,
  ConfirmationAlert,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  FormDatePicker,
  FormInput,
  FormInputType,
  HospitalDropDown,
  Icon,
  IconButton,
  LoadingComponent,
  PreviewAltCard,
  Row,
} from "../../../components/Component";
import { DropdownToggle, Form, Spinner, UncontrolledDropdown } from "reactstrap";
import moment from "moment";
import { AppText, CURRENCY_CODE, SERVER_DATE_FORMAT, covertToNumberOnly, showErrorToast } from "../../../utils";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { fileUploadApi } from "../carers/CarerData";
import { iconsType } from "../../../layout/fileIcon/FileIcon";
import { customInvoiceAPI } from "./InvoiceTableData";

const AddCustomInvoice = () => {
  const location = useLocation();
  const history = useHistory();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const fileInputRef = React.createRef(); // Create a reference for the file input
  const [loading, setLoading] = useState(false);
  const [initScreen, setInitScreen] = useState(false);
  const [formData, setFormData] = useState({
    client: "",
    invoiceDate: "",
    dueDate: "",
  });
  const [rateFields, setRateFields] = useState([
    { carer: "", shiftDate: "", description: "", hours: "", rate: "", net: "" },
  ]);

  useEffect(() => {
    setFormData({});
    setUploadedFiles([]);
    setRateFields([{ carer: "", shiftDate: "", description: "", hours: "", rate: "", net: "" }]);
  }, []);

  //  ****************** API ****************************** //
  function onFormSubmit() {
    setLoading(true);
    setInitScreen(true);
    let newFileIds = uploadedFiles.filter((item) => item.isOldFile === false)?.map((item) => item.id);
    const transformedRateFields = rateFields.map((field) => ({
      ...field,
      carerId: field?.carer?.id,
      hours: parseFloat(covertToNumberOnly(field?.hours)),
      rate: parseFloat(covertToNumberOnly(field?.rate)),
      shiftDate: moment(field?.shiftDate).format(SERVER_DATE_FORMAT),
    }));
    customInvoiceAPI(formData, transformedRateFields, newFileIds, (data, error) => {
      if (error === null) {
        history.goBack();
      }
    });
    setInitScreen(false);
    // setLoading(false);
  }

  const uploadFile = (file, index) => {
    fileUploadApi(file, "CUSTOM_INVOICE", (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  // **************** Functions ************ //
  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      // if (updatedfiles.length > 0) {
      //   updatedfiles[0] = fileDetails; // Replace the existing file with the new one
      // } else {
      //   updatedfiles.push(fileDetails); // If no file is uploaded, simply push the new file
      // }
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles];

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1);

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const handleInvoiceDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      invoiceDate: date,
    }));

    if (date) {
      const dueDateAfter14Days = moment(date).add(14, "days");
      let formattedDueDate = dueDateAfter14Days.toString();
      setFormData((prevFormData) => ({
        ...prevFormData,
        dueDate: dueDateAfter14Days.toDate(), // Convert moment to JavaScript Date
      }));
    }
  };

  const addRateField = () => {
    setRateFields([...rateFields, { carer: "", shiftDate: "", description: "", hours: "", rate: "", net: "" }]);
  };

  const handleRemovePersonField = (index) => {
    ConfirmationAlert("Remove?", "Are you sure, you want to remove this field?", () => {
      setRateFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields.splice(index, 1);
        return updatedFields;
      });
    });
  };

  const calculateTotal = (hours, rate) => {
    if (hours !== "" && rate !== "") {
      return parseFloat((hours * rate).toFixed(2));
    }
    return "";
  };

  const calculateTotalHours = () => {
    return rateFields.reduce((acc, item) => {
      return acc + (parseFloat(item.hours) || 0);
    }, 0);
  };

  const calculateTotalNet = () => {
    return rateFields.reduce((acc, item) => {
      return acc + (calculateTotal(item.hours, item.rate) || 0);
    }, 0);
  };

  const handleRateFieldChange = (index, field, value) => {
    const updatedFields = [...rateFields];
    updatedFields[index][field] = value;

    // Calculate and update the net
    const hours = parseFloat(updatedFields[index].hours) || 0;
    const rate = parseFloat(updatedFields[index].rate) || 0;
    updatedFields[index].net = calculateTotal(hours, rate);

    setRateFields(updatedFields);
  };

  return (
    <React.Fragment>
      <Head title="Add-invoice" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="xl">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Add Invoice
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
            <PreviewAltCard className="mt-4">
              <Form className="form-validation">
                <Row className="border rounded-2 mx-auto py-1">
                  <Col md="4" className={"mb-2"}>
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <label className="form-label">Client</label>
                        <span className="text-danger fs-6">*</span>
                        <span>
                          <HospitalDropDown
                            isClearable
                            value={formData?.client}
                            onSelect={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                client: e,
                              }))
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col sm="4" className={"mb-2"}>
                    <div className="form-group">
                      <label className="form-label">
                        Invoice Date<span className="text-danger fs-6">*</span>
                      </label>
                      <FormDatePicker
                        selected={formData?.invoiceDate}
                        onChange={(date) => handleInvoiceDateChange(date)}
                      />
                    </div>
                  </Col>
                  <Col sm="4" className={"mb-2"}>
                    <div className="form-group">
                      <label className="form-label">
                        Due Date<span className="text-danger fs-6">*</span>
                      </label>
                      <FormDatePicker
                        selected={formData?.dueDate}
                        onChange={(date) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            dueDate: date,
                          }));
                        }}
                        minDate={formData.invoiceDate}
                      />
                    </div>
                  </Col>
                  <Col sm="3" className={"mb-2"}>
                    <div className="form-group">
                      <label className="form-label">
                        From Date<span className="text-danger fs-6">*</span>
                      </label>
                      <FormDatePicker
                        selected={formData?.fromDate}
                        onChange={(date) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            fromDate: date,
                          }));
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm="3" className={"mb-2"}>
                    <div className="form-group">
                      <label className="form-label">
                        To Date<span className="text-danger fs-6">*</span>
                      </label>
                      <FormDatePicker
                        selected={formData?.toDate}
                        onChange={(date) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            toDate: date,
                          }));
                        }}
                        minDate={formData?.fromDate}
                      />
                    </div>
                  </Col>
                  <Col md="3" className={""}>
                    <div className="form-group">
                      <label className="form-label">
                        Invoice number<span className="text-danger fs-6">*</span>
                      </label>
                      <FormInput
                        // label={"Invoice number" }
                        placeholder={"Invoice number"}
                        // required={true}
                        maxLength={255}
                        type={FormInputType.default}
                        value={formData?.invoiceNumber}
                        onTextChange={(text) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            invoiceNumber: text,
                          }));
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="3" className={""}>
                    <div className="form-group">
                      <label className="form-label">
                        PO Number
                      </label>
                      <FormInput
                        // label={"PO Number"}
                        placeholder={"PO Number"}
                        required={true}
                        type={FormInputType.default}
                        value={formData?.poNum}
                        onTextChange={(text) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            poNum: text,
                          }));
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <DataTable className="card-stretch transaction-ui mt-3 text-dark">
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Carer <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Shift Date <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Description <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Hours <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Rate <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text fw-medium fs-14px text-dark">
                          Net <span className="text-danger fs-6">*</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow className="text-center pe-0 ps-0">
                        <div className="form-control-wrap">
                          <IconButton type="button" color="success" icon="plus" size={"sm"} onClick={addRateField} />
                        </div>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    {rateFields?.map((item, index) => (
                      <React.Fragment key={index}>
                        <DataTableItem className={"cursor table-rows"} onClick={() => ""}>
                          <DataTableRow>
                            <div className="form-group w-200px">
                              <CarersDropDown
                                isClearable
                                searchOnInput={true}
                                value={item?.carer}
                                onSelect={(value) => {
                                  const updatedFields = [...rateFields];
                                  updatedFields[index].carer = value;
                                  setRateFields(updatedFields);
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="form-group">
                              <FormDatePicker
                                selected={item?.shiftDate}
                                onChange={(value) => {
                                  const updatedFields = [...rateFields];
                                  updatedFields[index].shiftDate = value;
                                  setRateFields(updatedFields);
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="form-group w-350px">
                              <FormInput
                                placeholder={"Description"}
                                required={true}
                                maxLength={50}
                                type={FormInputType.default}
                                value={item?.description}
                                onTextChange={(value) => {
                                  const updatedFields = [...rateFields];
                                  updatedFields[index].description = value;
                                  setRateFields(updatedFields);
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="form-group">
                              <FormInput
                                placeholder={"Hours"}
                                value={item?.hours}
                                onError={null}
                                type={FormInputType.number}
                                onTextChange={(text) => {
                                  const regex = /^\d*\.?\d{0,2}$/; // Regular expression to match numbers with up to 2 decimal places
                                  if (regex.test(text)) {
                                    handleRateFieldChange(index, "hours", text);
                                  }
                                }}
                              />
                            </div>
                          </DataTableRow>

                          <DataTableRow>
                            <div className="form-group">
                              <FormInput
                                placeholder={"Rate"}
                                required={true}
                                type={FormInputType?.amount}
                                value={item?.rate}
                                onTextChange={(value) => {
                                  const regex = /^\d*\.?\d{0,2}$/;
                                  if (regex.test(value)) {
                                    handleRateFieldChange(index, "rate", value);
                                  } else {
                                    handleRateFieldChange(index, "rate", "");
                                  }
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="sub-text fw-medium fs-14px">
                              <FormInput
                                placeholder={"Net"}
                                required={true}
                                type={FormInputType?.amount}
                                value={item?.net}
                              />
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            {index > 0 && (
                              <UncontrolledDropdown className="text-center">
                                <DropdownToggle
                                  tag="a"
                                  className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
                                >
                                  <span onClick={() => handleRemovePersonField(index)}>
                                    <Icon name="trash"></Icon>
                                  </span>
                                </DropdownToggle>
                              </UncontrolledDropdown>
                            )}
                          </DataTableRow>
                        </DataTableItem>
                      </React.Fragment>
                    ))}
                  </DataTableBody>
                </DataTable>
                <Row className="border rounded-2 mx-auto py-1">
                  <Col md="8" className={"mt-2"}></Col>
                  <Col md="4" className={"mt-2 text-dark"}>
                    <div className="d-flex justify-content-between">
                      <span>Total Hours</span>
                      <span>{calculateTotalHours()}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Value excluding VAT</span>
                      <span>{CURRENCY_CODE + calculateTotalNet().toFixed(2)}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Plus VAT</span>
                      <span>{CURRENCY_CODE + "0.00"}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Total Due</span>
                      <span>{CURRENCY_CODE + calculateTotalNet().toFixed(2)}</span>
                    </div>
                  </Col>
                </Row>
                <Row className="border rounded-2 mx-auto py-3 mt-3">
                  <Col className="col-12">
                    <div className="form-group mb-0">
                      <label htmlFor="files">
                        <Icon name="clip" className="fs-6 text-primary" />
                        <span className="text-primary">
                          Attach document <span className="text-danger fs-6">*</span>
                        </span>
                      </label>
                      <input
                        type="file"
                        name="files"
                        // accept=".pdf,.png,.jpg,.jpeg,.svg,.webp,.heic,.doc,.docx"
                        accept=".pdf,.png,.jpg,.jpeg,.webp,.heic"
                        className="form-control"
                        id={"files"}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          let temp = Array.prototype.slice.call(e.target.files);
                          if (temp.length > 0) {
                            handleFileSelect(temp[0]);
                          }
                          e.target.value = '';
                        }}
                      />
                    </div>

                    {uploadedFiles?.length > 0 &&
                      uploadedFiles?.map((file, ind) => {
                        return (
                          <React.Fragment key={`file-${ind}`}>
                            <div className="nk-upload-item">
                              <div className="nk-upload-icon">
                                {file?.isOldFile ? (
                                  <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                                ) : (
                                  <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                                )}
                              </div>
                              <div className="nk-upload-info">
                                <div className="nk-upload-title">
                                  <span
                                    className="title"
                                    style={{ cursor: "pointer" }} // Add cursor pointer style
                                    onClick={() => openFileInNewTab(file)} // Handle click event
                                  >
                                    {file?.name}
                                  </span>
                                  {/* <span className="title">{file?.name}</span> */}
                                </div>
                              </div>
                              <div className="nk-upload-action">
                                <a
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                  className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                                >
                                  {file?.isLoading ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <span onClick={() => handleFileRemove(ind)}>
                                      <Icon name="trash"></Icon>
                                    </span>
                                  )}
                                </a>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </Col>
                </Row>
                <Col md="12" className={"mt-4"}>
                  <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
                    <li>
                      <BackButton cancel />
                    </li>
                    <li>
                      <ActionButton
                        size={"md"}
                        title="Save"
                        type={"button"}
                        loading={loading}
                        disabled={
                          !formData.client ||
                          !formData.invoiceDate ||
                          !formData.dueDate ||
                          !formData.fromDate ||
                          !formData.toDate ||
                          !formData.invoiceNumber ||
                          // !formData.poNum ||
                          rateFields.some(
                            (field) =>
                              !field.carer || !field.shiftDate || !field.description || !field.hours || !field.rate
                          ) ||
                          uploadedFiles.length === 0
                        }
                        onClick={() => onFormSubmit()}
                      />
                    </li>
                  </ul>
                </Col>
              </Form>
            </PreviewAltCard>
          </BlockHead>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default AddCustomInvoice;
