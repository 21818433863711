import React, { useEffect, useState } from "react";
import { Form, Label, Popover, PopoverBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  FormCheckbox,
  FormInput,
  FormInputType,
  FormTimePicker,
  Icon,
  LoadingComponent,
  PreviewAltCard,
  RadioButtonOptions,
  Row,
} from "../../../../components/Component";
import { AppText, RadioOptionTypes, ServerStatusType, showErrorToast, showSuccessToast } from "../../../../utils";
import { listGradesAPI } from "../grade/GradeTableData";
import { addAgencyAPI, agencyDetailAPI } from "./AgencyData";
import moment from "moment";
import CustomPopOver from "../../carers/CustomPopover";

const AddAgency = () => {
  const location = useLocation();
  const selected = location.state;
  const history = useHistory();
  const [gradeList, setGradeList] = useState([]);

  const [agencyData, setAgencyData] = useState({
    title: "",
    isActive: true,
    gradeTimes: [], // Array to store dayStart and nightStart times
  });
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [initScreen, setInitScreen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const isAllDataFilled = () => {
    const isDataFilled = gradeList.every((item) => {
      const gradeData = agencyData.gradeTimes.find((gradeTime) => gradeTime.gradeId === item.id);
      return gradeData && gradeData.dayStart && gradeData.nightStart && gradeData.invoiceDescFormat;
    });
    return isDataFilled;
  };

  useEffect(() => {
    loadAgencyDetail();
  }, [selected?.data?.id]);

  useEffect(() => {
    if ((agencyData?.title?.length ?? 0) === 0 || agencyData?.isNameError || !isAllDataFilled()) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyData]);

  useEffect(() => {
    loadGrades();
  }, [location.state?.data]);

  const onFormSubmit = () => {
    setLoading(true);
    addAgencyAPI(agencyData, (_, error) => {
      if (error === null) {
        let text =
          "Agency" + " " + agencyData?.title + (selected ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        history.push(`${process.env.PUBLIC_URL}/agency`);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  function loadGrades() {
    setInitScreen(true);
    listGradesAPI({ status: ServerStatusType.active }, (list, error) => {
      if (error === null) {
        setGradeList(list);
      }
      setInitScreen(false);
    });
  }

  function loadAgencyDetail() {
    if (!selected?.data?.id) return;
    agencyDetailAPI({ agencyId: selected?.data?.id }, (data, error) => {
      if (error === null) {
        const preFilledData = data.gradeTimes.map((gradeTime) => {
          return {
            gradeId: gradeTime.gradeId,
            dayStart: gradeTime?.dayStartTime ? moment(gradeTime?.dayStartTime, "HH:mm:ss").utc().toDate() : null,
            nightStart: gradeTime?.nightStartTime ? moment(gradeTime?.nightStartTime, "HH:mm:ss").utc().toDate() : null,
            invoiceDescFormat: gradeTime?.invoiceDescFormat,
          };
        });

        setAgencyData({
          ...agencyData,
          id: data?.id,
          title: data?.title,
          gradeTimes: preFilledData,
          isActive: data?.isActive,
        });
      }
    });
  }

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Add Agency" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead className="w-100 wide-sm">
            <BlockBetween className={"mb-4"}>
              <BlockHeadContent className="">
                <BlockTitle tag="h3" page>
                  {selected ? "Edit Agency" : "Add Agency"}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
            <PreviewAltCard>
              <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="12">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        label={"Title"}
                        type={FormInputType.title}
                        value={agencyData?.title ?? ""}
                        // placeholder={"e.g. Manager"}
                        onError={(isError) => {
                          setAgencyData((prev) => ({
                            ...prev,
                            isNameError: isError,
                          }));
                        }}
                        onTextChange={(title) => {
                          setAgencyData((prev) => ({
                            ...prev,
                            title: title,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col size="12">
                  <DataTable className="card-stretch">
                    <DataTableBody>
                      <DataTableHead>
                        <DataTableRow>
                          <span className="sub-text ">Grade</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Day Start</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Night Start</span>
                        </DataTableRow>
                        <DataTableRow className={"d-flex"}>
                          <span className="sub-text align-center">
                            Invoice Format{" "}
                            <Icon
                              id="info-icon"
                              className={"fs-5 ms-1 cursor-pointer"}
                              name="info"
                              onMouseEnter={togglePopover}
                              onMouseLeave={togglePopover}
                            />
                          </span>
                        </DataTableRow>
                        <Popover target="info-icon" isOpen={popoverOpen} toggle={togglePopover} placement="right">
                          <PopoverBody>
                            Format should be based on below variables:
                            <br />
                            {"{{gradeName}}"}
                            <br />
                            {"{{gradeCode}}"}
                            <br />
                            {"{{weekStartDate}}"}
                            <br />
                            {"{{weekEndDate}}"}
                            <br />
                            {"{{timesheetDate}}"}
                            <br />
                            {"{{dayType}}"}
                          </PopoverBody>
                        </Popover>
                      </DataTableHead>
                      {gradeList?.length > 0 &&
                        gradeList?.map((item, index) => (
                          <React.Fragment key={index}>
                            <DataTableItem className={"cursor-pointer"} onClick={() => ""}>
                              <DataTableRow>
                                <div className="user-info">
                                  <span className="tb-lead">{item?.name}</span>
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-info">
                                  <FormTimePicker
                                    placeholderText={"Select day start time"}
                                    selected={
                                      agencyData.gradeTimes.find((grade) => grade.gradeId === item.id)?.dayStart
                                    }
                                    onChange={(dayStart) => {
                                      let gradeListData = [...agencyData?.gradeTimes];
                                      let gradeObject = {
                                        ...gradeListData.find((grade) => grade.gradeId === item.id),
                                        dayStart: dayStart,
                                        gradeId: item?.id,
                                      };
                                      let index = gradeListData.findIndex((grade) => grade.gradeId === item.id);
                                      if (index !== -1) {
                                        gradeListData[index] = gradeObject;
                                      } else {
                                        gradeListData.push(gradeObject);
                                      }
                                      setAgencyData((prevData) => ({
                                        ...prevData,
                                        gradeTimes: gradeListData,
                                      }));
                                    }}
                                  />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-info">
                                  <FormTimePicker
                                    placeholderText={"Select night start time"}
                                    selected={
                                      agencyData.gradeTimes.find((grade) => grade.gradeId === item.id)?.nightStart
                                    }
                                    onChange={(nightStart) => {
                                      let gradeListData = [...agencyData?.gradeTimes];
                                      let gradeObject = {
                                        ...gradeListData.find((grade) => grade.gradeId === item.id),
                                        nightStart: nightStart,
                                        gradeId: item?.id,
                                      };
                                      let index = gradeListData.findIndex((grade) => grade.gradeId === item.id);
                                      if (index !== -1) {
                                        gradeListData[index] = gradeObject;
                                      } else {
                                        gradeListData.push(gradeObject);
                                      }
                                      setAgencyData((prevData) => ({
                                        ...prevData,
                                        gradeTimes: gradeListData,
                                        // Add other properties if needed
                                      }));
                                    }}
                                  />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-info">
                                  <FormInput
                                    placeholder={"Invoice Format"}
                                    type={FormInputType.default}
                                    value={
                                      agencyData.gradeTimes?.find((grade) => grade.gradeId === item.id)
                                        ?.invoiceDescFormat ?? ""
                                    } // Ensure to get the correct field
                                    onTextChange={(text) => {
                                      const gradeListData = [...agencyData?.gradeTimes]; // Copy the existing list
                                      const gradeObject = {
                                        ...gradeListData?.find((grade) => grade.gradeId === item.id),
                                        invoiceDescFormat: text, // Update the description field
                                        gradeId: item?.id, // Ensure the ID is retained
                                      };

                                      const index = gradeListData.findIndex((grade) => grade.gradeId === item.id);
                                      if (index !== -1) {
                                        gradeListData[index] = gradeObject; // Update existing entry
                                      } else {
                                        gradeListData.push(gradeObject); // Add a new entry if it doesn't exist
                                      }

                                      setAgencyData((prevData) => ({
                                        ...prevData,
                                        gradeTimes: gradeListData, // Update the agency list
                                      }));
                                    }}
                                  />
                                </div>
                              </DataTableRow>
                            </DataTableItem>
                          </React.Fragment>
                        ))}
                    </DataTableBody>
                  </DataTable>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <RadioButtonOptions
                        name="status"
                        optionType={RadioOptionTypes.status}
                        onChange={(index) => {
                          setAgencyData((prev) => ({ ...prev, isActive: index === 0 }));
                        }}
                        activeIndex={agencyData.isActive ? 0 : 1}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <BackButton cancel />
                    </li>
                    <li>
                      <ActionButton
                        title="Save"
                        loading={loading}
                        disabled={isDisable}
                        onClick={handleSubmit(onFormSubmit)}
                      />
                    </li>
                  </ul>
                </Col>
              </Form>
            </PreviewAltCard>
          </BlockHead>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default AddAgency;
