import moment from "moment";
import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { DATE_FORMAT, ServerStatusType } from "../../../../utils";
import {
  convertDropValue,
  covertToNumberOnly,
  removeTimeFromServerDateString,
  toCapitalize,
} from "../../../../utils/Functions";
import { toPageInfo } from "../../../_common/DataConverter";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const userDataTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      id: "name",
      selector: (row) => row.title,
      sortable: true,
      compact: false,
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Email"} />,
      id: "email",
      selector: (row) => row.email,
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Role"} />,
      id: "role",
      selector: (row) => row.role?.label ?? "",
      sortable: false,
    },

    {
      name: <TableTitle title={"Last login"} />,
      id: "lastLogin",
      selector: (row) => moment(row.date).utc().format(DATE_FORMAT),
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.user, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addUserAPI(data, callback) {
  let params = {
    id: data?.id,
    firstName: data.firstName,
    lastName: data.lastName,
    title: toCapitalize(data.firstName) + toCapitalize(data.lastName),
    email: data.email,
    phone: data.phone.phoneNumber,
    countryCode: data?.phone.dialCode,
    userType: "STAFF",
    address: data.address,
    city: data.city,
    postalCode: data.postalCode,
    genderId: data?.gender?.id ?? "",
    birthDate: moment(data?.birthDate).format("YYYY-MM-DD"),
    // birthDate: dateStringToShowFormat(removeTimeFromServerDateString(data.birthDate)).format("YYYY-MM-DD"),
    // roleIds: Array.isArray(data.role) ? data.role.map((item) => item.id) : [], // Make sure role is an array before using map    // [data.role.id]
    // branchIds: [data.branch?.id],
    roleIds: [data?.role?.id],
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addUser, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listUserAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listUser, params)
    .then((data) => {
      let list = toUserList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toUserList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toUser(element));
  });
  return list;
}

function toUser(data) {
  let role = (data.role?.length ?? 0) > 0 ? convertDropValue(data.role[0]) : null;
  let branch = (data.branch?.length ?? 0) > 0 ? convertDropValue(data.branch[0]) : null;
  let gender = data?.staff?.gender ? convertDropValue(data?.staff?.gender, true) : null;
  return {
    id: data.id,
    firstName: data?.staff?.firstName ?? "",
    lastName: data?.staff?.lastName ?? "",
    title: toCapitalize(data?.firstName) + " " + toCapitalize(data?.lastName),
    email: data?.email ?? "",
    phone: data?.staff?.phone ?? "",
    countryCode: data?.staff?.countryCode,
    address: data?.staff?.address ?? "",
    city: data?.staff?.city ?? "",
    postalCode: data?.staff?.postalCode ?? "",
    gender: gender,
    birthDate: data?.staff?.birthDate ? removeTimeFromServerDateString(data?.staff?.birthDate) : null,
    role: role,
    branchIds: branch,
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}
