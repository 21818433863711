import React from "react";
import { Icon } from "../Component";

export const NavigationTabs = ({ tabs, activeTab, setActiveTab }) => {
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const getTabClassName = (activeIndex, tabIndex) => {
    if (activeIndex === tabIndex) {
      return "active-tab-carer";
    }
  };

  return (
    <div>
      <ul className="nav nav-tabs py-2 px-4">
        {tabs?.map((tab, index) => (
          <React.Fragment key={index}>
            <li className={`nav-item ${getTabClassName(activeTab, index)}`}>
              <button
                className={`py-1 nav-link  ${activeTab === index ? "active" : ""} ${tab.disabled ? "disabled" : ""}`}
                onClick={() => handleTabClick(index)}
              >
                <span
                  className={`flex-direction-row d-flex align-items-center font-size-nav ${getTabClassName(
                    activeTab,
                    index
                  )}`}
                >
                  {tab?.icon && <Icon name={tab?.icon} />}
                  <span>{tab.text}</span>
                </span>
              </button>
            </li>
          </React.Fragment>
        ))}
      </ul>
      <div className="px-xxl-4">{tabs && tabs[activeTab]?.content}</div>
    </div>
  );
};
