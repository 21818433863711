import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockTitle,
  FormInput,
  Icon,
  PreviewCard,
  FormInputType,
  Row,
  Col,
  Button,
} from "../../components/Component";
import { Form, Alert, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { signIn } from "./AuthData";
import { windowReload } from "../../utils";
import { useForm } from "react-hook-form";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isDisble, setIsDisble] = useState(true);
  const [errorVal, setErrorVal] = useState("");
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    if (
      (authData?.email?.length ?? 0) === 0 ||
      (authData?.password?.length ?? 0) === 0 ||
      authData?.isEmailError === true
    ) {
      setIsDisble(true);
    } else {
      setIsDisble(false);
    }
  }, [authData]);

  const onFormSubmit = (data) => {
    setLoading(true);
    signIn(authData, (data, error) => {
      if (error === null) {
        windowReload();
      } else {
        setErrorVal(error.message);
      }
      setLoading(false);
    });
  };

  const { handleSubmit, register } = useForm();

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Row className={"g-0 h-100vh_-_64px"}>
          <Col className="col-12 col-lg-6 h-100 d-flex align-items-center justify-content-center">
            <Block className="nk-block-middle nk-auth-body wide-xs">
              <div className="brand-logo text-center">
                <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                  <img className="logo-light logo-img logo-img-lg min-h-120" src={Logo} alt="logo" />
                  <img className="logo-dark logo-img logo-img-lg min-h-120" src={LogoDark} alt="logo-dark" />
                </Link>
              </div>

              <PreviewCard className="" bodyClass="card-inner-lg">
                <BlockContent className={"mb-2"}>
                  <BlockTitle tag="h4">Sign-In</BlockTitle>
                </BlockContent>

                {errorVal && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorVal}
                    </Alert>
                  </div>
                )}
                <Form className="" onSubmit={handleSubmit(onFormSubmit)}>
                  <FormInput
                    label={"Email"}
                    type={FormInputType.email}
                    value={authData?.email}
                    {...register("email")}
                    onError={(isError) => {
                      setAuthData((prev) => ({
                        ...prev,
                        isEmailError: isError,
                      }));
                    }}
                    onTextChange={(text) => {
                      setAuthData((prev) => ({
                        ...prev,
                        email: text,
                      }));
                    }}
                  />
                  <FormInput
                    label={"Password"}
                    type={FormInputType.passwordWithoutValidation}
                    value={authData?.password}
                    {...register("password")}
                    onTextChange={(text) => {
                      setAuthData((prev) => ({
                        ...prev,
                        password: text,
                      }));
                    }}
                  />

                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password"></label>
                      <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div className="form-group">
                    <Button
                      size="lg"
                      className="custom-solid-btn-lg btn-block shadow custom-solid-btn-lg"
                      type="submit"
                      color="primary"
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                    </Button>
                  </div>
                </Form>
              </PreviewCard>
            </Block>
          </Col>
          <Col className="col-lg-6 d-none d-lg-flex align-items-center justify-content-center h-100 bg-lighter">
            <div className="d-flex align-items-center justify-content-center">
              <img className="logo-dark logo-img logo-img-lg min-h-300" src={LogoDark} alt="logo-dark" />
            </div>
          </Col>
        </Row>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
