import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Component";
import { dashboardDocDataTable, dashboardDocListAPI } from "./DashboardData";
import { PER_PAGE_ITEMS, showErrorToast } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ButtonGroup } from "reactstrap";
import { CarerDocumentType } from "../../../utils/Utils";
import { toCapitalize } from "../../../utils/Functions";

const CarerDocList = () => {
  const history = useHistory();
  const location = useLocation();
  const type = location?.state?.status;
  const [initScreen, setInitScreen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [activeButton, setActiveButton] = useState(0); // 0 corresponds to the index of the first button
  const [status, setStatus] = useState({ value: type });

  const val = {
    ...CarerDocumentType,
    all: "ALL",
  };
  const statusValues = Object.values(val);

  useEffect(() => {
    if (type && location?.state?.isMoveDocList) {
      setStatus({ value: type === "ALL" ? "" : type });
      setActiveButton(statusValues.findIndex((valueItem) => valueItem === type));
    }
  }, [type, location?.state?.isMoveDocList]);

  useEffect(() => {
    loadDocumentList();
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadDocumentList();
    } else {
      setCurrentPage(1);
    }
  }, [status]);

  function loadDocumentList() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
      type: status?.value === "EXPIRING SOON" ? "EXPIRING_SOON" : status?.value,
    };
    setInitScreen(true);
    dashboardDocListAPI(params, (data, error) => {
      if (error === null) {
        setDocumentList(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      } else {
        showErrorToast(error.message);
      }
      setInitScreen(false);
    });
  }

  const handleButtonClick = (item) => {
    setCurrentPage(1);
    const newValue = item === "ALL" ? "" : item === "EXPIRING SOON" ? "EXPIRING_SOON" : item;
    setActiveButton(statusValues.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  const onMove = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/carer-detail`,
      state: { data: row, id: row?.carer?.id, isMoveCarerDocuments: true },
    });
  };

  return (
    <React.Fragment>
      <Head title="Expired Documents" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Expired Documents</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totalItems} documents</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ButtonGroup>
                {statusValues.map((item, index) => (
                  <>
                    <Button
                      key={index}
                      // key={item.value}
                      className={`btn-dim btn-white border-right border
                             ${
                               activeButton === statusValues?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                      onClick={() => handleButtonClick(item)}
                    >
                      {toCapitalize(item)}
                    </Button>
                  </>
                ))}
              </ButtonGroup>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={documentList}
              columns={dashboardDocDataTable(onMove)}
              // isAllowed={isCheckToList(UserPermissionType.client)}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
              // onSort={(id) => {
              //   setSortId(id);
              //   const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
              //   setSortOrder(newSortOrder);
              // }}
              onRowClicked={(row) => onMove(row)}
            />
          </PreviewCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default CarerDocList;
