import React, { useEffect, useState } from "react";
import { PreviewAltCard, TooltipComponent } from "../../../components/Component";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DocumentStatistics = ({ documentCount }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!documentCount) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [documentCount]);

  const onMove = (status) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/carer-document-list`,
      state: { status: status, isMoveDocList: true },
    });
  };

  return (
    <React.Fragment>
      <PreviewAltCard className="card-full">
        {loading ? (
          <div className="spinner-container-statistics">
            <Spinner className="spinner-border-md" color="primary" />
          </div>
        ) : (
          <div>
            <div className="card-title-group align-start pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Documents</h6>
              </div>
            </div>
            <div className="analytic-au">
              <div className="analytic-data-group analytic-au-group g-3">
                <div className="analytic-data analytic-au-data cursor-pointer" onClick={() => onMove("EXPIRED")}>
                  <div className="title">Expired</div>
                  <div className="amount">{documentCount?.expired ?? 0}</div>
                </div>
                <div
                  className="analytic-data analytic-au-data cursor-pointer text-center"
                  onClick={() => onMove("EXPIRING SOON")}
                >
                  <div className="title">Expiring soon</div>
                  <div className="amount">{documentCount?.expiringSoon ?? 0}</div>
                </div>
                <div className="analytic-data analytic-au-data cursor-pointer text-end" onClick={() => onMove("ALL")}>
                  <div className="title">Total</div>
                  <div className="amount">{(documentCount?.expired ?? 0) + (documentCount?.expiringSoon ?? 0)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PreviewAltCard>
    </React.Fragment>
  );
};

export default DocumentStatistics;
