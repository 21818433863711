import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  UserAvatar,
} from "../../../components/Component";
import { dashboardRotaAPI } from "./DashboardData";
import moment from "moment";
import { showErrorToast } from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import { convertHexToColor } from "../../../utils/Functions";

const DashboardRota = () => {
  const itemsPerPage = 5;
  const [type, setType] = useState("TODAY");
  const [rotaData, setRotaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    loadRota();
  }, [currentPage]);

  useEffect(() => {
    if (loading) return;
    if (currentPage === 1) {
      loadRota();
    } else {
      setCurrentPage(1);
    }
  }, [type]);

  function loadRota() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemsPerPage,
      },
      type: type,
    };
    setLoading(true);
    dashboardRotaAPI(params, (data, error) => {
      if (error === null) {
        setRotaData(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setLoading(false);
    });
  }

  const onMove = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/job-detail`,
      state: { data: item.jobId }, // Pass the selected item as state
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Rota</h6>
          </CardTitle>
          <div className="card-tools">
            <ul className="card-tools-nav">
              <li className={type === "TODAY" ? "active" : ""} onClick={() => setType("TODAY")}>
                <a
                  href="#today"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Today</span>
                </a>
              </li>
              <li className={type === "TOMORROW" ? "active" : ""} onClick={() => setType("TOMORROW")}>
                <a
                  href="#tomorrow"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Tommorrow</span>
                </a>
              </li>
              <li className={type === "THIS_WEEK" ? "active" : ""} onClick={() => setType("THIS_WEEK")}>
                <a
                  href="#thisweek"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>This week</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : rotaData && rotaData.length > 0 ? (
        <DataTableBody bodyclass="nk-tb-orders">
          <DataTableHead>
            <DataTableRow size="sm">
              <span>Carer</span>
            </DataTableRow>
            <DataTableRow>
              <span>Client</span>
            </DataTableRow>
            <DataTableRow>
              <span>Grade</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Date</span>
            </DataTableRow>
          </DataTableHead>
          {rotaData?.map((item, idx) => (
            <DataTableItem key={idx} className={"cursor-pointer"} onClick={() => onMove(item)}>
              <DataTableRow size="sm">
                <div className="user-card">
                  <UserAvatar
                    size="sm"
                    theme={item?.theme}
                    customHex={item?.carer?.colourHex}
                    text={`${item?.carer?.firstName?.charAt(0) || ""}${
                      item?.carer?.lastName?.charAt(0) || ""
                    }`.toUpperCase()}
                    image={item?.carer?.profileImg?.imageUrl ?? ""}
                    name={item?.carer?.firstName + " " + item?.carer?.lastName}
                    // subName={item?.carer?.email}
                  />
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{item?.client?.name}</span>
              </DataTableRow>
              <DataTableRow>
                <span>
                  <GradeBadge
                    // key={index}
                    label={item?.grade.name}
                    className={"me-1 my-1"}
                    color={`outline-${convertHexToColor(item?.grade?.colourHex)}`}
                  />{" "}
                </span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="tb-sub">{moment(item?.fromDate).format("DD-MM-YYYY")}</span>
                <br />
                <span className="tb-sub">
                  {moment(item?.startTime, "HH:mm:ss").format("HH:mm")} -{" "}
                  {moment(item?.endTime, "HH:mm:ss").format("HH:mm")}
                </span>
              </DataTableRow>
            </DataTableItem>
          ))}
        </DataTableBody>
      ) : (
        <div className="no-data-text align-middle">
          <PreviewAltCard className="m-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </div>
      )}
      {totalItems > 5 && (
        <div className="mx-4 my-1">
          <PaginationComponent
            itemPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default DashboardRota;
