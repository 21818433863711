import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { InvoiceTypes, ServerStatusType } from "../../../../utils";
import { convertDropMultiValue } from "../../../../utils/Functions";
import { toContactList, toPageInfo } from "../../../_common/DataConverter";

//***************************API's*********************************/
export function addTrustAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.name,
    latitude: data.coordinates?.lat,
    longitude: data.coordinates?.lng,
    address: data.address,
    city: data.city,
    postalCode: data.postalCode,
    email: data.email,
    invoiceTypes: data.invoiceTypes.map((item) => item?.value),
    contact: data.personFields.map((person) => {
      return {
        id: person.id,
        type: person?.personType?.value,
        name: person.personName,
        phone: person.phone.phoneNumber,
        countryCode: person.phone.dialCode,
        email: person.personEmail,
      };
    }),
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addTrust, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listTrustAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listTrust, params)
    .then((data) => {
      let list = toTrustList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTrustDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.trustDetail, params)
    .then((data) => {
      let cData = toTrustDetail(data?.data);
      callback(cData, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toTrustList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toTrust(element));
  });
  return list;
}

function toTrust(data) {
  let types = [];
  data?.invoiceTypes?.forEach((item) => {
    let value = InvoiceTypes.find((obj) => obj.value === item.InvoiceType);
    value && types.push(value);
  });

  return {
    id: data.id,
    name: data?.name ?? "",
    address: data?.address ?? "",
    latitude: data?.latitude,
    longitude: data?.longitude,
    city: data?.city ?? "",
    // postalCode: data?.postalCode ?? "",
    email: data?.email ?? "",
    invoiceTypes: types,
    personFields: toContactList(data?.contacts),
    isActive: data?.status === ServerStatusType.active ? true : false,
    lastModifiedBy: data?.lastModifiedBy,
    lastModifiedTime: data?.lastModifiedTime,
    colourHex: data?.colourHex,
  };
}

const toTrustDetail = (item) => {
  let types = [];
  item?.invoiceTypes?.forEach((item) => {
    let value = InvoiceTypes.find((obj) => obj.value === item.InvoiceType);
    value && types.push(value);
  });
  return {
    id: item?.id,
    name: item?.name,
    address: item?.address,
    // latitude: item?.coordinates?.latitude,
    // longitude: item?.coordinates?.longitude,
    city: item?.city,
    postalCode: item?.postalCode,
    email: item?.email,
    invoiceTypes: types ?? [],
    status: item?.status,
  };
};
