/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { rotaDayListAPI } from "./RotaTableData";
import { RotaOptions } from "../../../utils";
import RotaMatrix from "./RotaMatrix";
import { LoadingComponent } from "../../../components/Component";
import { getDates } from "../../../utils/Functions";

export const RotaType = {
  hospital: "hospital",
  carer: "carer",
};

const RotaList = ({
  type,
  selectedId,
  carerId,
  clientId,
  startDate,
  endDate,
  status,
  gradeId,
  agencyId,
  shiftType,
}) => {
  const [rotaList, setRotaList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const datesBetween = getDates(startDate, endDate);

  useEffect(() => {
    loadRotaAPI();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadRotaAPI();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, startDate, endDate, carerId, clientId, shiftType, gradeId, agencyId]);

  function loadRotaAPI() {
    if (!type && (!startDate || !endDate)) return;
    if (loading) return;

    let params = {
      startDate: startDate,
      endDate: endDate,
      gradeId: gradeId ? gradeId : null,
      shiftType: shiftType?.value === "ANY" ? "" : shiftType ? shiftType.value : null,
      status: status === RotaOptions.open ? "ACTIVE" : status,
      carerId: carerId,
      clientId: clientId,
      agencyId: agencyId,
    };
    // setLoading(true);
    rotaDayListAPI(params, (data, error) => {
      if (error === null) {
        if (currentPage === 1) {
          setRotaList(data.list);
        }
      }
      // setLoading(false);
    });
  }

  return (
    <>
      <div className="rota-matrix-div">
        <RotaMatrix
          hList={datesBetween}
          vList={rotaList}
          rotaType={type}
          onCancel={(status) => {
            loadRotaAPI();
          }}
        />
      </div>
      <LoadingComponent isLoading={loading} />
    </>
  );
};

export default RotaList;
