import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { PayType } from "../../utils/Utils";

export const PayTypeDropDown = ({
  value,
  isMulti = false,
  onSelect,
  selectedValue,
  carerType,
  isShowAll = false,
  ...props
}) => {
  const [list, setList] = useState(PayType);

  useEffect(() => {
    if (isShowAll) {
      setList([{ label: "All", value: "" }, ...PayType]);
    } else {
      setList(PayType);
    }
  }, [isShowAll]);

  useEffect(() => {
    return () => {
      setList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const selectedOption = list?.find((option) => option.value === selectedValue);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [selectedValue, value, list, onSelect]);

  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      placeholder={"Search Carer"}
      value={value}
      onChange={onSelect}
      {...props}
    />
  );
};
