import moment from "moment";
import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { DATE_FORMAT, ServerStatusType } from "../../../../utils";
import { toPageInfo } from "../../../_common/DataConverter";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const agencyDataTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row.title,
      compact: false,
      sortable: true,
      id: "agencyName",
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.agency, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addAgencyAPI(data, callback) {
  const params = {
    id: data.id,
    name: data.title,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
    grades: data.gradeTimes.map((gradeTime) => ({
      gradeId: gradeTime.gradeId,
      dayStartTime: moment(gradeTime.dayStart).format("HH:mm"),
      nightStartTime: moment(gradeTime.nightStart).format("HH:mm"),
      invoiceDescFormat: gradeTime?.invoiceDescFormat,
    })),
  };
  apiService
    .postCall(APIServicePath.addAgency, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function agencyListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.agencyList, params)
    .then((data) => {
      let list = toAgencyList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function agencyDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.agencyDetail, params)
    .then((data) => {
      let detail = toAgency(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

// //***************************Converters*********************************/

export function toAgencyList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toAgency(element));
  });
  return list;
}

function toAgency(data) {
  return {
    id: data.id,
    title: data?.name ?? "",
    isActive: data?.status === ServerStatusType.active ? true : false,
    gradeTimes: data?.gradeAgency,
  };
}
