import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { WageStatus } from "../../utils/Utils";

export const WageStatusDropdown = ({
  value,
  isMulti = false,
  onSelect,
  placeholder,
  selectedValue,
  carerType,
  ...props
}) => {
  const [list, setList] = useState(WageStatus);

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? { id: 1, label: "Pending", value: "PENDING" }}
      onChange={onSelect}
      {...props}
    />
  );
};
