import React, { useEffect } from "react";

import { ConfirmationAlert, LoadingComponent, ReactDataTable } from "../../../components/Component";
import { jobTableData, listJobAPI, moveJobToRota } from "./JobTableData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { JobStatusType, PER_PAGE_ITEMS, showErrorToast, showSuccessToast } from "../../../utils";
import { RotaCopyJob } from "../rotas/Rotas";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";
import { scrollToTop } from "../../../utils/Functions";

export const JobType = {
  hospital: "hospital",
  carer: "carer",
};

const JobList = ({
  onRowSelectionChange,
  selectableRows,
  parent,
  type,
  searchText,
  status,
  getTotalItem,
  gradeId,
  clientId,
  clientType,
}) => {
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState();
  const [jobListData, setJobListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  useEffect(() => {
    loadJobList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadJobList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, status, parent, type, gradeId, clientId, sortId, sortOrder, clientType]);

  function loadJobList() {
    scrollToTop();

    if (isCheckToList(UserPermissionType.job)) {
      let params = {
        search: searchText,
        status: status?.value,
        clientType: clientType?.value,
        gradeIds: gradeId ? [gradeId] : null,
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        sorting: {
          field: sortId ?? null,
          order: sortOrder ?? null,
        },
        clientId: clientId ? clientId : null,
      };

      if (parent) {
        if (JobType.hospital === type) {
          params.clientId = parent?.id;
        }
      }
      if (searchText) {
        setInitScreen(false);
      } else {
        setInitScreen(true);
      }
      listJobAPI(params, (list, error) => {
        if (error === null) {
          setJobListData(list?.list);
          if (currentPage === 1) {
            setTotalItems(list?.pageInfo?.totalItems ?? 0);
          }
        }
        if (getTotalItem) {
          getTotalItem(list?.pageInfo?.totalItems);
        }
        setInitScreen(false);
      });
    } else {
      setInitScreen(false);
    }
  }
  const onCopy = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-job`,
      state: { type: RotaCopyJob.isCopy, data: row }, // Include the enum value in the state
    });
  };

  const onEdit = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-job`,
      state: { data: row }, // Pass the selected item as state
    });
  };

  const onView = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/job-detail`,
      state: { data: row.jobId }, // Pass the selected item as state
    });
  };
  return (
    <React.Fragment>
      <ReactDataTable
        data={jobListData}
        columns={jobTableData(type, status, onEdit, onView, onCopy)}
        isAllowed={isCheckToList(UserPermissionType.job)}
        pagination
        totalItemCount={totalItems}
        onPageChanged={(page, count) => {
          setCurrentPage(page);
        }}
        onRowClicked={(row) => isAllowToAccess(UserPermissionType.job, UserActionType.detail) && onView(row)}
        onSort={(id) => {
          setSortId(id);
          const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
          setSortOrder(newSortOrder);
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default JobList;
