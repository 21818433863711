import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import { PaginationComponent, PreviewAltCard, UserAvatar } from "../../../components/Component";
import { topCarersApi } from "./DashboardData";
import { showErrorToast } from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TopClients = () => {
  const history = useHistory();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [carerData, setCarerData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTopCarers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function loadTopCarers() {
    setLoading(true);
    topCarersApi((data, error) => {
      if (error === null) {
        setCarerData(data?.list);
      }
      setLoading(false);
    });
  }

  const onMove = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/carer-detail`,
      state: { data: item, id: item?.id },
    });
  };

  const currentItems = carerData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Top 10 Carers</h6>
          </CardTitle>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : carerData && carerData.length > 0 ? (
        <ul className="nk-activity">
          {currentItems?.map((item, index) => (
            <li className="nk-activity-item dashboard-rota cursor-pointer" key={index} onClick={() => onMove(item)}>
              <UserAvatar
                className="nk-activity-media"
                theme={item.theme}
                image={item?.profileImg?.imageUrl ?? ""}
                text={`${item?.firstName?.charAt(0) || ""}${item?.lastName?.charAt(0) || ""}`.toUpperCase()}
                customHex={item?.colourHex}
                name={item?.firstName + " " + item.lastName}
                subName={item?.email}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-data-text align-middle">
          <PreviewAltCard className="m-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </div>
      )}
      {carerData?.length > itemsPerPage && (
        <div className="mx-4 mt-4">
          <PaginationComponent
            itemPerPage={itemsPerPage}
            totalItems={carerData?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TopClients;
