import React from "react";

const FormCheckbox = ({ checked, required, onChange, label, id, name, disabled }) => {
  return (
    <div className=" ">
      <input type="checkbox" id={id} name={name} disabled={disabled} checked={checked ?? true} onChange={onChange} />
      <label htmlFor={id} className="checkbox-label ms-1">
        {label}
        {required && <span className="text-danger fs-6">*</span>}
      </label>
    </div>
  );
};

export default FormCheckbox;
