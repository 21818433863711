import React from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  FormInput,
  FormInputType,
  RadioButtonOptions,
} from "../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../utils";
import { addWardAPI } from "../clients/hospital/ward/WardData";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const QueryModal = ({ visible, item, onSaved, onClosed, selectedDate, isCancelReason }) => {
  const [queryList, setQueryList] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [charCount, setCharCount] = useState(0);
  // const [wordCount, setWordCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("CARER");

  useEffect(() => {
    if (queryList === "") {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
    // Update character count
    setCharCount(queryList?.length);

    // Update word count
    // setWordCount(queryList.trim().split(/\s+/).filter(Boolean).length);
  }, [queryList]);

  const close = () => {
    onClosed();
    setLoading(false);
    setQueryList("");
  };

  useEffect(() => {
    if (onSaved) {
      setQueryList("");
    }
  }, [onSaved]);

  const onFormSubmit = () => {
    if (isDisable) return;

    // Instead of making an API call, pass the queryList and dayLabel to the parent component.
    onSaved(queryList, selectedOption);

    // Close the modal.
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => close()}
        // title={`Write Reason for ${selectedDate}`}
        title={isCancelReason ? `Write Reason for Cancel` : `Write Reason for In Query`}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        // isShowHeaderRemove={item ? true : null}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <div className="mb-2 d-flex">
                  <span>Cancelled by</span>
                  <span className="ms-3">
                    <RadioButtonOptions
                      // name="status"
                      optionType={RadioOptionTypes.rotaCancel}
                      onChange={(index) => {
                        let cancelBy = index === 0 ? "CARER" : index === 1 ? "CLIENT" : null;
                        setSelectedOption(cancelBy);
                      }}
                      activeIndex={selectedOption === "CLIENT" ? 1 : 0}
                    />
                  </span>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Write your reason"
                  maxLength={200}
                  value={queryList}
                  onChange={(e) => {
                    setQueryList(e.target.value);
                  }}
                />
                <span className="d-flex justify-content-end pt-1">
                  {charCount}/200
                  <br />
                  {/* Word Count: {wordCount}/200 */}
                </span>
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default QueryModal;
