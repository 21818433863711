import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import {
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewAltCard,
  Block,
} from "../../../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import TemplateList from "./TemplateList";
import { useDispatch } from "react-redux";
import { loadFetchMasterList } from "../../../../store/masterListSlice";
import { useSelector } from "react-redux";
import { UserPermissionType, isAllowToAccess, isCheckToList } from "../../../../services/permission/permission";

const Template = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const templateList = useSelector((state) => state?.masterList?.masterList?.templates);

  useEffect(() => {
    dispatch(loadFetchMasterList());
  }, []);

  // ... (other code)

  const hashValue = location.hash || `#${templateList[0].name}`;

  let selectedTemplate = templateList.find((menuItem) => "#" + menuItem.name === hashValue);

  if (!selectedTemplate && templateList.length > 0) {
    selectedTemplate = templateList[0];
  }
  return (
    <React.Fragment>
      <Head title="Template" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Template</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {isCheckToList(UserPermissionType.template) ? (
          <Card className="card-bordered">
            <div className="card-aside-wrap">
              <>
                <div
                  className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg `}
                >
                  <ul className="link-list-menu">
                    {templateList?.map((menuItem, index) => (
                      <li key={index}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/template1#${menuItem.name}`}
                          className={selectedTemplate && selectedTemplate.name === menuItem.name ? "active" : ""}
                        >
                          <span>{menuItem.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
              <div className="card-inner card-inner-lg p-0">
                <TemplateList templateData={selectedTemplate} />
              </div>
            </div>
          </Card>
        ) : (
          <Block className={"pt-1"}>
            <PreviewAltCard>
              <span className="d-flex justify-content-center">You do not have permission</span>
            </PreviewAltCard>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Template;
