import React, { useEffect, useState } from "react";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  ActionButton,
  AgencyDropdown,
  AvailableCarersDropDown,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  CarersDropDown,
  Col,
  ConfirmationAlert,
  ErrorText,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  FormTimePicker,
  HospitalDropDown,
  PreviewAltCard,
  RadioButtonOptions,
  Row,
  ShiftDropDown,
  SpecialityDropDown,
  WardDropdown,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useHistory, useLocation } from "react-router";
import { CarerTypeDropDown } from "../../../components/Component";
import { useSelector } from "react-redux";
import { addJobAPI, checkCarerAvailabilityAPI, getJobDetailAPI, jobCarerAPI } from "./JobTableData";
import { AppText, DATE_FORMAT, showErrorToast } from "../../../utils";
import { JobStatusType, RadioOptionTypes, Weekdays } from "../../../utils/Utils";
import { dateToShow, showSuccessToast, updateTimeFromShift } from "../../../utils/Functions";
import GradeTypeDropDown from "../../../components/dropdowns/GradeTypeDropDown";
import moment from "moment";
import { RotaCopyJob } from "../rotas/Rotas";
import { listRateAPI } from "../rates/RatesData";
import Swal from "sweetalert2";

const AddJob = () => {
  const location = useLocation();
  const selectedJobData = location.state?.data;
  // const isRota = location?.state === RotaCopyJob.isRota;
  const AddFormType = location?.state?.type;
  const isCopyData = AddFormType === RotaCopyJob.isCopy;
  const isRotaData = AddFormType === RotaCopyJob.isRota;

  const selectedJobId = selectedJobData?.jobId;
  const history = useHistory();
  const [jobData, setJobData] = useState({ isMoveToRota: false, dayTypeRates: [] });
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [isSelectedDaysSetInEdit, setIsSelectedDaysSetInEdit] = useState(true);
  const [checkCarerAvailability, setCheckCarerAvailability] = useState();
  const [showEnhancedRate, setShowEnhancedRate] = useState(false); // State to track checkbox status
  const [enhanceRate, setEnhanceRate] = useState();
  const dayTypes = useSelector((state) => state?.masterList?.masterList?.dayTypes);

  const [weekDays, setWeekdays] = useState(() =>
    Weekdays.map((weekday) => ({
      ...weekday,
      checked: false, // Set all checkboxes as unchecked by default
      disabled: false,
    }))
  );

  const areDayTypeRatesFilled = () => {
    const filledRates = jobData?.dayTypeRates?.filter(
      (dayTypeRate) =>
        dayTypeRate?.chargeRate !== null &&
        dayTypeRate?.chargeRate !== undefined &&
        dayTypeRate?.chargeRate !== "" &&
        dayTypeRate?.payRate !== null &&
        dayTypeRate?.payRate !== undefined &&
        dayTypeRate?.payRate !== ""
    );

    return filledRates?.length === jobData?.dayTypeRates?.length;
  };

  useEffect(() => {
    if (selectedJobId) {
      loadJobDetail(selectedJobId);
    } else {
      setLoadingDetail(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobId]);

  const hasInvalidChargeRate = (dayTypeRate) => {
    const chargeRate = parseFloat(String(dayTypeRate?.chargeRate).replace(/,/g, "")) || 0;
    const payRate = parseFloat(String(dayTypeRate?.payRate).replace(/,/g, "")) || 0;
    return chargeRate - payRate < 0;
  };

  useEffect(() => {
    const isAnyWeekdayChecked = weekDays?.some((item) => item.checked === true);
    const hasInvalidRate = jobData?.dayTypeRates?.some((dayType, dayTypeIndex) => {
      return hasInvalidChargeRate(jobData?.dayTypeRates?.[dayTypeIndex]);
    });
    if (
      jobData?.client === "" ||
      !jobData?.ward ||
      !jobData?.carerType ||
      // !jobData?.specialities ||
      !jobData?.grade ||
      !jobData?.shiftType ||
      !jobData?.startDate ||
      jobData?.startTime === "" ||
      jobData?.endTime === "" ||
      jobData?.startTime === undefined ||
      jobData?.endTime === undefined ||
      jobData?.startTime === null ||
      jobData?.endTime === null ||
      (jobData?.carers && jobData?.agency === undefined) ||
      (jobData?.carers && ((jobData?.dayTypeRates?.length ?? 0) === 0 || hasInvalidRate || !areDayTypeRatesFilled())) ||
      (isRotaData && (jobData?.carers === null || jobData?.carers === undefined || jobData?.carers === "")) ||
      !isAnyWeekdayChecked
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [jobData, weekDays]);
  /**
 * 
       hasInvalidRate ||
      !jobData?.dayTypeRates?.length > 0 ||
      !areDayTypeRatesFilled() ||
 */
  useEffect(() => {
    if (jobData?.startDate && jobData?.toDate) {
      if (!isSelectedDaysSetInEdit && jobData?.jobId) {
        setIsSelectedDaysSetInEdit(true);
      } else {
        setDaysEnabled(jobData?.startDate, jobData?.toDate);
      }
    }
  }, [jobData?.startDate, jobData?.toDate]);

  function loadJobDetail(selectedJobId) {
    setLoadingDetail(true);
    setIsSelectedDaysSetInEdit(false);
    getJobDetailAPI({ jobId: selectedJobId }, (data, error) => {
      if (error === null) {
        setJobDetailInfo(data);
        if (!isCopyData) {
          loadJobCarerList(selectedJobId);
        }
      }
      setLoadingDetail(false);
    });
  }

  function loadJobCarerList(selectedJobId) {
    jobCarerAPI({ jobId: selectedJobId }, (data, error) => {
      if (error === null) {
        const carerData = data?.assigned?.length > 0 ? data?.assigned[0] : null;
        setJobData((prev) => ({
          ...prev,
          carers: carerData,
        }));
      }
    });
  }

  useEffect(() => {
    if (jobData?.client?.id && jobData?.carerType?.id && jobData?.grade?.id && jobData?.carers) {
      loadRate();
    }
  }, [jobData?.client?.id, jobData?.carerType?.id, jobData?.grade?.id, jobData?.carers?.id]);

  useEffect(() => {
    if (jobData?.carers?.id) {
      checkCarerAvailabity();
    }
  }, [jobData?.carers?.id]);

  function loadRate() {
    listRateAPI(
      {
        clientId: jobData?.client?.id,
        carerTypeId: jobData?.carerType?.id,
        gradeId: jobData?.grade?.id,
        carerId: jobData?.carers?.id,
      },
      (list, error) => {
        if (error === null) {
          setJobData((prevJobData) => ({
            ...prevJobData,
            dayTypeRates: list?.list.map((dayTypeRate) => ({
              ...dayTypeRate,
              chargeRate: dayTypeRate.chargeRate, // Convert to string
              payRate: dayTypeRate.payRate, // Convert to string
            })),
          }));
        }
      }
    );
  }

  function setDaysEnabled(startDate, toDate) {
    var date = new Date(startDate);
    let index = 0;
    let list = [];
    do {
      let day = moment(date).format("dddd");
      list.push(day);
      date.setDate(date.getDate() + 1);
      index = index + 1;
    } while (date <= toDate && index < 7);

    setWeekdays((prevWeekdays) =>
      prevWeekdays.map((weekday) => ({
        ...weekday,
        checked: list?.some((item) => item === weekday.label),
        disabled: !list?.some((item) => item === weekday.label),
      }))
    );
  }

  function setJobDetailInfo(item) {
    setWeekdays((prevWeekdays) =>
      prevWeekdays.map((weekday) => ({
        ...weekday,
        checked: item?.days?.includes(weekday.label), // Check if the weekday label is in the selectedDays array
        disabled: !item?.days?.includes(weekday.label),
      }))
    );
    setJobData((prevState) => ({
      ...prevState,
      jobId: isCopyData ? "" : item?.jobId,
      client: item?.client ?? "",
      ward: item?.ward,
      carerType: item?.carerType,
      shiftType: item?.shiftType,
      carers: isCopyData ? "" : item?.carers,
      agency: isCopyData ? "" : item?.agency,
      isActive: item?.isActive ?? "",
      specialities: item?.specialities ?? "",
      // poNumber: item?.poNumber ?? "",
      grade: item?.grade,
      startDate: isCopyData ? new Date() : new Date(item?.fromDate),
      toDate: isCopyData ? new Date() : new Date(item?.toDate),
      startTime: item?.startTime ?? "",
      endTime: item?.endTime ?? "",
      description: item?.description ?? "",
      isMoveToRota: item?.isMoveToRota,
    }));
  }

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addJobAPI(jobData, weekDays, enhanceRate, (_, error) => {
      if (error === null) {
        let text = isRotaData ? "Rota" : "Job"; // Choose the text based on the form type
        text += selectedJobId && !isCopyData ? " edited successfully" : " added successfully";
        showSuccessToast(text);
        history.push(`${process.env.PUBLIC_URL}/jobs`);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const checkCarerAvailabity = () => {
    let params = {
      carerId: jobData?.carers?.id,
      fromDate: jobData?.startDate,
      toDate: jobData?.toDate,
    };
    checkCarerAvailabilityAPI(params, (data, error) => {
      if (error === null) {
        setCheckCarerAvailability(data?.list);
      } else {
        showErrorToast(error.message);
      }
    });
  };

  useEffect(() => {
    if (checkCarerAvailability?.some((item) => item.rotaDate)) {
      if (jobData?.startDate === jobData?.toDate) {
        // If start date and end date are the same
        Swal.fire({
          title: "Alert!",
          text: `Carer is already assigned to another job on ${dateToShow(jobData?.startDate)}`,
          confirmButtonText: "OK",
        });
      } else {
        // If start date and end date are different
        Swal.fire({
          title: "Alert!",
          text: "Carer is already assigned to another job on selected date range",
          confirmButtonText: "OK",
        });
      }
    }
  }, [checkCarerAvailability]);

  const { handleSubmit } = useForm();

  const enhanceRatesave = (rate) => {
    setEnhanceRate(rate);
  };

  return (
    <React.Fragment>
      <Head title={isRotaData ? "Add Rota" : selectedJobData && !isCopyData ? "Edit Job" : "Add Job"} />

      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="md" wide="md">
            {loadingDetail ? (
              <div className="spinner">
                <Spinner size="sm" color="primary" />
              </div>
            ) : (
              <>
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                      {isRotaData ? "Add Rota" : selectedJobData && !isCopyData ? "Edit Job" : "Add Job"}
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <BackButton />
                  </BlockHeadContent>
                </BlockBetween>
                <PreviewAltCard className="mt-2">
                  <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Client/Homecare<span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <HospitalDropDown
                            value={jobData?.client}
                            onSelect={(item) => {
                              if (item.id !== jobData?.client?.id) {
                                setJobData({ ...jobData, client: item, ward: null });
                              }
                            }}
                            placeholder="Search Client"
                            disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Ward<span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <WardDropdown
                            hospitalId={jobData?.client?.id}
                            value={jobData?.ward}
                            onSelect={(item) => setJobData({ ...jobData, ward: item })}
                            placeholder="Search Ward"
                            selectedId={jobData?.ward?.id}
                            disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Carer Type<span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <CarerTypeDropDown
                            value={jobData?.carerType}
                            onSelect={(item) => {
                              if (item.id !== jobData?.carerType?.id) {
                                setJobData({
                                  ...jobData,
                                  carerType: item,
                                  carers: null,
                                  // specialities: null,
                                  // grade: null,
                                });
                              }
                            }}
                            selectedId={jobData?.carerType?.id}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Grade <span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <GradeTypeDropDown
                            value={jobData?.grade}
                            carerTypeId={jobData?.carerType?.id}
                            onSelect={(gradeType) => {
                              setJobData((prev) => ({ ...prev, grade: gradeType }));
                              // if (jobData?.shiftType) {
                              //   let start =
                              //     jobData.shiftType.value === "DAY" ? gradeType?.dayStart : gradeType?.nightStart;
                              //   let end =
                              //     jobData.shiftType.value === "DAY" ? gradeType?.nightStart : gradeType?.dayStart;
                              //   setJobData((prev) => ({
                              //     ...prev,
                              //     grade: gradeType,
                              //     startTime: start ? moment(start, "HH:mm:ss").toDate() : null,
                              //     endTime: end ? moment(end, "HH:mm:ss").toDate() : null,
                              //   }));
                              // } else {
                              //   setJobData((prev) => ({ ...prev, grade: gradeType }));
                              // }
                            }}
                            disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Grade <span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <GradeTypeDropDown
                            value={jobData?.grade}
                            carerTypeId={jobData?.carerType?.id}
                            onSelect={(gradeType) => {
                              setJobData((prev) => ({ ...prev, grade: gradeType }));
                            }}
                          />
                        </div>
                      </div>
                    </Col> */}

                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Specialities<span className="text-danger fs-6"></span>
                        </label>
                        <div className="form-control-wrap">
                          <SpecialityDropDown
                            value={jobData?.specialities ?? ""}
                            carerTypeId={jobData?.carerType?.id}
                            isMulti={true}
                            onSelect={(items) => {
                              setJobData((prev) => ({ ...prev, specialities: items }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Shift<span className="text-danger fs-6">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <ShiftDropDown
                            isAny={false}
                            placeholder="Day or Night"
                            value={jobData?.shiftType ?? ""}
                            onSelect={(shiftType) => {
                              setJobData((prev) => ({ ...prev, shiftType: shiftType }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="6">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"PO number"}
                            type={FormInputType.default}
                            value={jobData.poNumber ?? ""}
                            placeholder={"PO number"}
                            onTextChange={(poNumber) => {
                              setJobData((prev) => ({
                                ...prev,
                                poNumber: poNumber,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col> */}
                    <Col sm="6" md="3">
                      <div className="form-group">
                        <label className="form-label">
                          Start Date<span className="text-danger fs-6">*</span>
                        </label>
                        <FormDatePicker
                          selected={jobData?.startDate}
                          onChange={(startDate) => {
                            setJobData((prev) => ({
                              ...prev,
                              startDate: startDate,
                              toDate: startDate,
                            }));
                          }}
                          disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                        />
                      </div>
                    </Col>
                    <Col sm="6" md="3">
                      <div className="form-group">
                        <label className="form-label">
                          End Date <span className="text-danger fs-6"></span>
                        </label>
                        <FormDatePicker
                          selected={jobData?.toDate}
                          onChange={(toDate) => {
                            setJobData((prev) => ({
                              ...prev,
                              toDate: toDate,
                            }));
                          }}
                          minDate={jobData?.startDate}
                          disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                        />
                      </div>
                    </Col>
                    <Col sm="6" md="3">
                      <div className="form-group">
                        <label className="form-label">
                          Start Time<span className="text-danger fs-6">*</span>
                        </label>
                        <FormTimePicker
                          placeholderText={"Start Time"}
                          selected={jobData?.startTime}
                          onChange={(startTime) => {
                            setJobData((prev) => ({
                              ...prev,
                              startTime: startTime,
                            }));
                          }}
                          disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                        />
                      </div>
                    </Col>
                    <Col sm="6" md="3">
                      <div className="form-group">
                        <label className="form-label">
                          End Time<span className="text-danger fs-6">*</span>
                        </label>
                        <FormTimePicker
                          placeholderText={"End Time"}
                          selected={jobData?.endTime}
                          onChange={(endTime) => {
                            setJobData((prev) => ({
                              ...prev,
                              endTime: endTime,
                            }));
                          }}
                          disabled={selectedJobData ? selectedJobData?.status !== JobStatusType.open : false}
                        />
                      </div>
                    </Col>

                    {/* <Col md="12">
                      <div className="form-group">
                        <label className="form-label">Description</label>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control"
                            placeholder="Description"
                            value={jobData.description}
                            onChange={(e) => {
                              setJobData((prev) => ({
                                ...prev,
                                description: e.target.value,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col> */}

                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label">Weekday</label>
                        <ul className="align-center flex-wrap gx-4 gy-2">
                          {weekDays.map((weekday, index) => (
                            <li key={index}>
                              <FormCheckbox
                                label={weekday.label}
                                id={weekday.value}
                                name={weekday.value}
                                checked={weekday.checked}
                                disabled={
                                  weekday.disabled || selectedJobData
                                    ? selectedJobData?.status !== JobStatusType.open
                                    : false
                                }
                                onChange={(e) => {
                                  const updatedWeekdays = weekDays.map((day) =>
                                    day.value === weekday.value ? { ...day, checked: e.target.checked } : day
                                  );
                                  setWeekdays(updatedWeekdays);
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Carer<span className="text-danger fs-6">{isRotaData ? "*" : ""}</span>
                        </label>
                        <div className="form-control-wrap">
                          <AvailableCarersDropDown
                            startDate={jobData?.startDate}
                            isClearable={true}
                            endDate={jobData?.toDate}
                            gradeId={jobData?.grade?.id}
                            clientId={jobData?.client?.id}
                            daysFor={weekDays
                              .filter((item) => item.checked === true && item.disabled === false)
                              ?.map((item) => item.value)}
                            value={jobData?.carers}
                            disabled={!(jobData?.startDate && jobData?.toDate && jobData?.grade?.id)}
                            onSelect={(carers) => {
                              setJobData((prev) => ({ ...prev, carers: carers }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Agency <span className="text-danger">{jobData?.carers ? "*" : ""}</span>
                        </label>
                        <div className="form-control-wrap">
                          <AgencyDropdown
                            value={jobData?.agency ?? ""}
                            disable={!jobData?.carers}
                            // isMulti={true}
                            onSelect={(agency) => {
                              setJobData((prev) => ({
                                ...prev,
                                agency: agency,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* {jobData?.client && jobData?.carers && jobData?.carerType && jobData?.grade && (
                      <>
                        <Col md="6">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <FormInput
                                label={"Pay Rate"}
                                type={FormInputType.default}
                                value={jobData.payRate ?? ""}
                                placeholder={"Pay Rate"}
                                onTextChange={(payRate) => {
                                  setJobData((prev) => ({
                                    ...prev,
                                    payRate: payRate,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <FormInput
                                label={"Change Rate"}
                                type={FormInputType.default}
                                value={jobData.changeRate ?? ""}
                                placeholder={"Change Rate"}
                                onTextChange={(changeRate) => {
                                  setJobData((prev) => ({
                                    ...prev,
                                    changeRate: changeRate,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </>
                    )} */}
                    {jobData?.client?.id &&
                    jobData?.carerType?.id &&
                    jobData?.grade?.id &&
                    jobData?.carers?.id &&
                    (jobData?.dayTypeRates?.length ?? 0) > 0 ? (
                      <Col md={12}>
                        <Row>
                          <Col size="4">
                            <label className="form-label">Day Type</label>
                          </Col>
                          <Col size="4">
                            <label className="form-label">Pay Rate</label>
                          </Col>
                          <Col size="4">
                            <label className="form-label">Charge Rate</label>
                          </Col>
                        </Row>

                        {jobData?.dayTypeRates?.map((dayType, dayTypeIndex) => {
                          // let rate = jobData?.dayTypeRates?.find((item) => item?.dayTypeId === dayType?.id);
                          // let index = jobData?.dayTypeRates?.findIndex((item) => item?.dayTypeId === dayType?.id) ?? -1;

                          return (
                            <Row className="align-items-baseline" key={dayTypeIndex}>
                              <Col size="4">
                                <div className=" my-3">
                                  <label>{dayType?.dayType?.name}</label>
                                </div>
                              </Col>
                              <Col size="4">
                                <FormInput
                                  baseClass={"m-0"}
                                  placeholder={"Pay Rate"}
                                  type={FormInputType.amount}
                                  value={dayType?.payRate ?? null}
                                  onTextChange={(payRate) => {
                                    // payRate = parseFloat(payRate) || null;
                                    let list = jobData?.dayTypeRates ? [...jobData.dayTypeRates] : []; // Initialize list as an empty array
                                    let dayTypeData = dayTypeIndex !== -1 ? list[dayTypeIndex] : {};
                                    dayTypeData.payRate = payRate;
                                    dayTypeData.dayTypeId = dayType.id;
                                    dayTypeIndex !== -1 ? (list[dayTypeIndex] = dayTypeData) : list.push(dayTypeData);
                                    setJobData((prev) => ({
                                      ...prev,
                                      dayTypeRates: list,
                                    }));
                                  }}
                                />
                              </Col>
                              <Col size="4">
                                <FormInput
                                  baseClass={"m-0"}
                                  placeholder={"Charge Rate"}
                                  type={FormInputType.amount}
                                  value={dayType?.chargeRate ?? null}
                                  onTextChange={(chargeRate) => {
                                    // chargeRate = parseFloat(chargeRate) || null;
                                    let list = jobData?.dayTypeRates ? [...jobData.dayTypeRates] : []; // Initialize list as an empty array
                                    let dayTypeData = dayTypeIndex !== -1 ? list[dayTypeIndex] : {};
                                    dayTypeData.chargeRate = chargeRate;
                                    dayTypeData.dayTypeId = dayType.id;
                                    dayTypeIndex !== -1 ? (list[dayTypeIndex] = dayTypeData) : list.push(dayTypeData);
                                    setJobData((prev) => ({
                                      ...prev,
                                      dayTypeRates: list,
                                    }));
                                  }}
                                />
                                <span>
                                  {hasInvalidChargeRate(jobData?.dayTypeRates?.[dayTypeIndex]) && (
                                    <span className="invalid">Charge rate should not be less than pay rate</span>
                                  )}
                                </span>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    ) : jobData?.carers?.id ? (
                      <ErrorText>{"Client does not has rate list for selected info."}</ErrorText>
                    ) : null}

                    {jobData?.client?.id &&
                      jobData?.carerType?.id &&
                      jobData?.grade?.id &&
                      jobData?.carers?.id &&
                      (jobData?.dayTypeRates?.length ?? 0) > 0 && (
                        <div>
                          <Row>
                            <Col size="8" className={"align-center"}>
                              <div className="mt-3 d-flex">
                                <label>Do you want to enhance the rate?</label>
                                <span className="ms-3">
                                  <FormCheckbox
                                    checked={showEnhancedRate} // Pass checked state to the checkbox
                                    onChange={(e) => setShowEnhancedRate(e.target.checked)} // Update state when checkbox changes
                                  />
                                </span>
                              </div>
                            </Col>
                            <Col size="4" className={"height-44"}>
                              {showEnhancedRate && (
                                <div className="mt-2">
                                  <FormInput
                                    baseClass={"m-0"}
                                    placeholder={"Enhance Rate"}
                                    type={FormInputType.amount}
                                    value={enhanceRate ?? ""}
                                    onTextChange={(rate) => enhanceRatesave(rate)}
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      )}

                    <Col md="12">
                      <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <BackButton cancel />
                        </li>
                        <li>
                          <ActionButton
                            title="Save"
                            type="submit"
                            loading={loading}
                            disabled={isDisable}
                            // onClick={() => onFormSubmit()}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </PreviewAltCard>
              </>
            )}
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddJob;
