import React, { useState } from "react";
import { Icon } from "../Component";

const Rating = ({ type, initVal, className, disable }) => {
  const handleClick = (newRating) => {
    // if (!disable) {
    //   setRating(newRating);
    // }
  };

  const cursorStyle = disable ? "not-allowed" : "pointer";

  return (
    <ul className={`rating ${className ? className : ""}`}>
      <li onClick={() => handleClick(1)} style={{ cursor: cursorStyle }}>
        <Icon name={initVal >= 1 ? `${type}-fill` : type}></Icon>
      </li>
      <li onClick={() => handleClick(2)} style={{ cursor: cursorStyle }}>
        <Icon name={initVal >= 2 ? `${type}-fill` : type}></Icon>
      </li>
      <li onClick={() => handleClick(3)} style={{ cursor: cursorStyle }}>
        <Icon name={initVal >= 3 ? `${type}-fill` : type}></Icon>
      </li>
      <li onClick={() => handleClick(4)} style={{ cursor: cursorStyle }}>
        <Icon name={initVal >= 4 ? `${type}-fill` : type}></Icon>
      </li>
      <li onClick={() => handleClick(5)} style={{ cursor: cursorStyle }}>
        <Icon name={initVal > 4 ? `${type}-fill` : type}></Icon>
      </li>
    </ul>
  );
};

export default Rating;
