import React from "react";
import ReactDatePicker from "react-datepicker";
import { Icon } from "../../Component";

const FormTimePicker = ({ selected, onChange, placeholderText, removeIcon, disabled }) => {
  return (
    <div className="form-control-wrap">
      <div className="input-group">
        <div className={`input-group-prepend w-100 `}>
          {!removeIcon && <Icon className="input-group-text" name="clock" />}
          <ReactDatePicker
            selected={selected}
            onChange={onChange}
            placeholderText={placeholderText}
            showTimeSelect
            showTimeSelectOnly
            disabled={disabled}
            timeIntervals={5}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            className={`form-control rounded-0  ${removeIcon ? " rounded-1" : "border-start-0 rounded-end"}`}
            popperPlacement="top-end"
          />
        </div>
      </div>
    </div>
  );
};

export default FormTimePicker;
