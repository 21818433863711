import { MoreOptions, StatusBadge, TableTitle } from "../../../../../components/Component";
import { apiService } from "../../../../../services/api";
import { APIServicePath } from "../../../../../services/api/api-service-impl";
import { ServerStatusType } from "../../../../../utils";

export const wardTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Name"} />,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: <TableTitle title={"Code"} />,
      selector: (row) => row?.code,
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            {
              icon: "trash",
              text: "Remove",
              action: () => {
                onRemove(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addWardAPI(data, hospitalId, callback) {
  let params = {
    id: data.id,
    name: data.name,
    abbrCode: data.code,
    clientId: hospitalId,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addWard, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listWardAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listWard, params)
    .then((data) => {
      let list = toWardList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileImportWardApi(fileId, clientId, callback) {
  let params = {
    fileId: fileId,
    clientId: clientId,
  };
  apiService
    .postCall(APIServicePath.importWardClient, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function clientWardRemove(params, callback) {
  apiService
    .postCall(APIServicePath.clientWardRemove, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toWardList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toWard(element));
  });
  return list;
}

function toWard(data) {
  return {
    id: data.wardId,
    name: data.wardName,
    code: data.abbrCode,
    isActive: data.status === ServerStatusType.active ? true : false,
  };
}
