import React from "react";
import { ConfirmationAlert, LoadingComponent, ReactDataTable } from "../../../../components/Component";
import {
  defaultTemplateAPI,
  getTemplateDetailAPI,
  listTemplateAPI,
  removeTemplate,
  templateDataTable,
} from "./TemplateData";
import { useEffect } from "react";
import { useState } from "react";
import AddTemplate from "./AddTemplate";
import { TemplateType } from "./TemplateList";
import { showErrorToast, showSuccessToast } from "../../../../utils";
import { Spinner } from "reactstrap";

const TemplateContent = ({ data, columns, type, templateData, isOpen, onClosed }) => {
  const [templateList, setTemplateList] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //   loadTemplateApi();
  //   return () => {
  //     setTemplateList();
  //     setIsEditOpen();
  //     setSelectedItem();
  //   };
  // }, []);

  useEffect(() => {
    loadTemplateApi();
  }, [templateData, type]);

  function loadTemplateApi() {
    setloading(true);
    setLoadingDetail(true);

    listTemplateAPI(
      {
        templateTypeId: templateData?.id,
        status: "ACTIVE",
        templateFor: type === TemplateType.email ? "EMAIL" : "SMS",
      },
      (data, error) => {
        if (error === null) {
          setTemplateList(data.list);
          setloading(false);
        }
        setLoadingDetail(false);
      }
    );
  }

  function onRemove(row) {
    ConfirmationAlert("Removing Template!", "Do you want to remove Template " + row.title + "?", () => {
      // This code block will be executed when the user confirms the removal

      removeTemplate({ templateId: row?.id }, (data, error) => {
        if (error === null) {
          showSuccessToast("Template is removed successfully");
          // history.goBack();
          loadTemplateApi();
        } else {
          showErrorToast(error.message);
        }
      });
    });
  }

  function onEdit(row) {
    setIsEditOpen(true);
    getTemplateDetailAPI({ templateId: row.id }, (data, error) => {
      if (error === null) {
        setSelectedItem(data);
      }
    });
  }
  function onDefault(row) {
    ConfirmationAlert("Are you sure!", "Do you want to add default template " + row?.title + "?", () => {
      defaultTemplateAPI(
        {
          templateId: row.id,
        },
        (data, error) => {
          if (error === null) {
            showSuccessToast("Template has been moved to default successfully!");
            loadTemplateApi();
          }
        }
      );
    });
  }

  return (
    <>
      {loadingDetail ? (
        <div className="spinner spinner-align-center  d-flex justify-content-center">
          <Spinner size="sm" color="primary" />
        </div>
      ) : (
        <ReactDataTable
          className="px-4"
          data={templateList}
          columns={templateDataTable(type, onEdit, onRemove, onDefault)}
          // onPageChanged={(page, count) => {
          // }}
          // onRowClicked={(row) => {
          //   // onRowData(row);
          // }}
        />
      )}

      <AddTemplate
        visible={isOpen || isEditOpen}
        type={type}
        templateTypeId={templateData?.id}
        item={selectedItem}
        onClosed={() => {
          setIsEditOpen(false);
          setSelectedItem();
          onClosed();
        }}
        onSaved={() => {
          loadTemplateApi();
        }}
      />
      <LoadingComponent isLoading={loading} />
    </>
  );
};

export default TemplateContent;
