import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  ActionButton,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  FormInput,
  FormInputType,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { resetPasssword } from "./AuthData";
import { showErrorToast } from "../../utils";

export const ConfirmationType = {
  forgotPassword: "forgot password",
  createPassword: "create password",
};

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isDisble, setIsDisble] = useState(true);
  const [errorVal, setErrorVal] = useState("");
  const [authData, setAuthData] = useState({});

  useEffect(() => {
    if ((authData?.email?.length ?? 0) === 0 || authData?.isEmailError === true) {
      setIsDisble(true);
    } else {
      setIsDisble(false);
    }
  }, [authData]);

  const onFormSubmit = (ev) => {
    ev.preventDefault();
    if (!authData.isEmailError) {
      let params = {
        email: authData.email,
      };
      resetPasssword(params, (data, error) => {
        if (error === null) {
          history.push({
            pathname: `${process.env.PUBLIC_URL}/confirmation`,
            state: {
              type: ConfirmationType.forgotPassword,
              email: authData.email,
            },
          });
        } else {
          showErrorToast(error.message);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg min-h-120" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg min-h-120" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockContent className={"mb-2"}>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <form>
              <FormInput
                label={"Email"}
                type={FormInputType.email}
                value={authData?.email}
                onError={(isError) => {
                  setAuthData((prev) => ({
                    ...prev,
                    isEmailError: isError,
                  }));
                }}
                onTextChange={(text) => {
                  setAuthData((prev) => ({
                    ...prev,
                    email: text,
                  }));
                }}
              />
              <div className="form-group">
                <ActionButton
                  title="Continue"
                  size="lg"
                  loading={loading}
                  disabled={isDisble}
                  onClick={(ev) => onFormSubmit(ev)}
                />
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Sign-in</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
