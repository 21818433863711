import moment from "moment";
import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { DATE_FORMAT, ServerStatusType } from "../../../../utils";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { toCapitalize } from "../../../../utils/Functions";

export const holidayDataTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row.title,
      width: "27rem",
      sortable: true,
    },
    {
      name: <TableTitle title={"Client Type"} />,
      selector: (row) => {
        if (row.holidayFor === "HOMECARE") {
          return "Care Home";
        }
        return toCapitalize(row.holidayFor);
      },
      sortable: false,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    {
      name: <TableTitle title={"Date"} />,
      selector: (row) => moment(row.date).utc().format(DATE_FORMAT),
      sortable: true,
      sortFunction: (a, b) => {
        return new Date(b.date) - new Date(a.date);
      },
    },
    isAllowToAccess(UserPermissionType.holiday, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addHolidayAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.title,
    date: moment(data.date).format("YYYY-MM-DD"),
    holidayFor: data?.isHomeCare?.value,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addHoliday, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listHolidayAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listHoliday, params)
    .then((data) => {
      let list = toHolidayList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toHolidayList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toHoliday(element));
  });
  return list;
}

function toHoliday(data) {
  return {
    id: data.id,
    title: data?.name ?? "",
    date: data?.date ?? "",
    isActive: data?.status === ServerStatusType.active ? true : false,
    holidayFor: data?.holidayFor,
  };
}
