import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { listTemplateAPI } from "../../pages/tabs/others/template/TemplateData";
import { convertDropMultiValue } from "../../utils/Functions";

export const TemplateDropdown = ({ value, isMulti = false, isShowAll = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    loadTemplateApi();
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadTemplateApi() {
    listTemplateAPI(
      {
        templateFor: "EMAIL",
      },
      (data, error) => {
        if (error === null) {

          setList(convertDropMultiValue(data.list));
        }
      }
    );
  }
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      //   selectedId={selectedId}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};