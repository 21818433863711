import React from "react";

const DataItem = ({ label, value, className }) => {
  return (
    <li className={`data-item ${className ?? ""}`}>
      <div className="data-col">
        {label && <div className="data-label">{label}</div>}
        <div className="data-value">{value}</div>
      </div>
    </li>
  );
};

export default DataItem;

export const TimesheetDataItem = ({ label, value, className, labelClassName, valueClassName }) => {
  return (
    <li className={`data-item ${className ?? ""}`}>
      <div className="data-col">
        {label && <div className={`data-label ${labelClassName ?? "w-100px"}`}>{label}</div>}
        <div className={`data-value  ${valueClassName}`}>{value}</div>
      </div>
    </li>
  );
};

export const TimesheetDetailItem = ({ label, value, className, labelClassName, valueClassName }) => {
  return (
    <li className={`data-item ${className ?? ""}`}>
      <div className={"data-col align-items-start"}>
        {label && <div className={`data-label ${labelClassName ?? "w-100px"}`}>{label}</div>}
        <div className={`data-value  ${valueClassName}`}>{value}</div>
      </div>
    </li>
  );
};

export const TimesheetDetailItemForComment = ({ label, value, className, labelClassName, valueClassName }) => {
  return (
    <li className={`data-item ${className ?? ""}`}>
      <div className={"data-col align-items-start"}>
        {label && <div className={`timesheetDetailModalLabelForComment ${labelClassName ?? "w-100px"}`}>{label}</div>}
        <div className={`timesheetDetailModalValueForComment  ${valueClassName}`}>{value}</div>
      </div>
    </li>
  );
};
