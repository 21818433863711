import React, { useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  FormInput,
  Icon,
  MoreOptions,
} from "../../../components/Component";
import { TimesheetStatusType } from "../../../utils";
import { openLinkInNewTab, toCapitalize } from "../../../utils/Functions";
import { ButtonGroup } from "reactstrap";
import { ImportExportButton } from "../../../components/button/ActionButton";
import DateRangePicker from "../../../components/input/date-time/DateRangePicker";
import { exportTimesheet } from "./TimesheetData";
import Timesheets from "./Timesheets";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SendNotificationModal from "./SendNotificationModal";
import { SendNotificationType } from "../../../utils/Utils";

const TimesheetNew = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sm, updateSm] = useState(false);
  const [status, setStatus] = useState({
    value: TimesheetStatusType.pending,
  });
  const [carer, setCarer] = useState();
  const [client, setClient] = useState();
  const [activeButton, setActiveButton] = useState(0); // 0 corresponds to the index of the first button
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [totalItem, setTotalItems] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const timesheetRef = useRef(null);
  const location = useLocation();
  const weekdata = location?.state?.data;

  useEffect(() => {
    if (location?.state?.isMoveTimesheet) {
      setStartDate(weekdata?.startDate);
      setEndDate(weekdata?.endDate);
    }
  }, [location?.state?.isMoveTimesheet]);

  function exportTimesheetList(ev) {
    if (!startDate || !endDate) return;
    setLoadingExport(true);
    let params = {
      // rotaId: 0,
      startDate: startDate,
      endDate: endDate,
      clientId: client?.id,
      carerId: carer?.id,
      status: status?.value,
      search: searchText,
    };

    exportTimesheet(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl, ev);
      }
      setLoadingExport(false);
    });
  }

  const val = {
    ...TimesheetStatusType,
  };

  const timesheetStatus = Object.values(val);

  const handleButtonClick = (item) => {
    const newValue = item === "ALL" ? "" : item;
    setActiveButton(timesheetStatus.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  const handleSwitchToggle = () => {
    setSwitchOn(!isSwitchOn);
  };

  const getTotalItem = (totalItem) => {
    // the callback. Use a better name
    setTotalItems(totalItem);
  };

  const handleDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <React.Fragment>
      <Head title="Timesheet" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <BlockTitle page>Timesheet</BlockTitle>
                  <BlockDes>{totalItem} Timesheets</BlockDes>
                </span>
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                    <li>
                      <FormInput
                        isShowLabel={false}
                        value={searchText ?? ""}
                        placeholder="Request ID, Client/Carer, Ward"
                        onTextChange={(text) => setSearchText(text)}
                      />
                    </li>
                    <li>
                      <ButtonGroup>
                        {timesheetStatus
                          ?.filter((item) => item !== TimesheetStatusType.finalised)
                          .map((item, index) => (
                            <Button
                              key={index}
                              className={`btn-dim btn-white border-right border
                             ${
                               activeButton === timesheetStatus?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                              onClick={() => handleButtonClick(item)}
                            >
                              {toCapitalize(item)}
                            </Button>
                          ))}
                      </ButtonGroup>
                    </li>
                    <li className="d-flex align-items-center">
                      Week
                      <label className="switch ms-1 me-1">
                        <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchToggle} />
                        <span className="slider round"></span>
                      </label>
                      ALL
                    </li>
                    {!isSwitchOn && (
                      <li>
                        <DateRangePicker
                          onDateChange={handleDatePickerChange}
                          moveDate={location?.state?.isMoveTimesheet ? { startDate, endDate } : undefined}
                        />
                      </li>
                    )}
                    {isAllowToAccess(UserPermissionType.timesheet, UserActionType.export) && (
                      <li>
                        <ImportExportButton
                          title={"Export"}
                          onClick={exportTimesheetList}
                          iconName={"arrow-up"}
                          loading={loadingExport}
                        />
                      </li>
                    )}
                    <li>
                      <span>
                        <MoreOptions
                          className="text-bg-light"
                          options={[
                            {
                              icon: "bell",
                              text: "Send Notification",
                              action: () => {
                                setNotificationModal(true);
                              },
                            },
                            ...(selectedRows?.length > 0
                              ? [
                                  {
                                    icon: "file-remove-fill",
                                    text: "Cancel",
                                    action: () => {
                                      timesheetRef.current?.cancelTimeSheet();
                                    },
                                    hide: !isAllowToAccess(UserPermissionType.timesheet, UserActionType.cancel),
                                  },
                                  {
                                    icon: "file-text",
                                    text: "Move To Wages",
                                    action: () => {
                                      timesheetRef.current?.moveWages();
                                    },
                                    hide:
                                      activeButton !== 3 ||
                                      !isAllowToAccess(UserPermissionType.timesheet, UserActionType.moveToWages),
                                  },
                                  {
                                    icon: "file-remove-fill",
                                    text: "In Query",
                                    action: () => {
                                      timesheetRef.current?.rejectTimeSheet();
                                    },
                                    hide:
                                      activeButton !== 2 ||
                                      !isAllowToAccess(UserPermissionType.timesheet, UserActionType.inquery),
                                  },
                                  {
                                    icon: "file-check-fill",
                                    text: "Verify",
                                    action: () => {
                                      timesheetRef.current?.verifyTimeSheet();
                                    },
                                    hide:
                                      activeButton !== 1 ||
                                      !isAllowToAccess(UserPermissionType.timesheet, UserActionType.verify),
                                  },
                                  {
                                    icon: "list-check",
                                    text: "Approve",
                                    action: () => {
                                      timesheetRef.current?.approveTimeSheet();
                                    },
                                    hide:
                                      activeButton !== 2 ||
                                      !isAllowToAccess(UserPermissionType.timesheet, UserActionType.approve),
                                  },
                                ]
                              : []),
                          ]}
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <div className="m-n4">
          <Timesheets
            ref={timesheetRef}
            startDate={startDate}
            endDate={endDate}
            clientId={client?.id}
            carerId={carer?.id}
            searchText={searchText}
            status={status}
            showAll={isSwitchOn}
            getTotalItem={getTotalItem}
            isShowMoreOption={(rows) => setSelectedRows(rows)}
          />
        </div>
      </Content>
      <SendNotificationModal
        visible={notificationModal}
        onClosed={() => setNotificationModal(false)}
        type={SendNotificationType.timesheet}
      />
    </React.Fragment>
  );
};

export default TimesheetNew;
