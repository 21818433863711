import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";
import { toPageInfo } from "../../../_common/DataConverter";

export const carerTypeTableData = () => [
  {
    title: "Jack Adams",
    isActive: false,
  },
  {
    title: "George Burton",
    isActive: true,
  },
  {
    title: "James Bradley",
    isActive: false,
  },
];

export const carerTypeColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.carerType, UserActionType.add) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addCarerTypeAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.title,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
    documents: data.documentField.map((doc) => {
      return {
        id: doc.id,
        documentId: doc?.documentTypeId?.id,
        isMandatory: doc?.isMandatory === 0,
      };
    }),
  };
  apiService
    .postCall(APIServicePath.saveCarerType, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listCarerTypeAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listCarerType, params)
    .then((data) => {
      let list = toCarerTypeList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

// //***************************Converters*********************************/

function toCarerTypeList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toCarerType(element));
  });
  return list;
}

function toCarerType(data) {
  return {
    id: data.id,
    title: data.name,
    documentField: data.documents.map((doc) => {
      return {
        id: doc?.id,
        documentTypeId: doc?.documentId,
        documentTitle: doc?.title,
        isMandatory: doc?.isMandatory ? 0 : 1,
      };
    }),
    isActive: data.status === ServerStatusType.active ? true : false,
  };
}

// function toCarerList(list) {
//   let items = [];
//   for (let index = 0; index < (list?.length ?? 0); index++) {
//     const element = list[index];
//     items.push(toCarer(element));
//   }
//   return items;
// }

// function toCarer(doc) {

//   return {
//     docId: doc.id,
//     documentType: doc,
//     isYesOrNo: doc?.isYesOrNo ? 0 :1,
//   };
// }
