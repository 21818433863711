import { useEffect } from "react";
import { Icon, MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { TemplateType } from "./TemplateList";
import { InvoiceFileType } from "../../../../utils/Utils";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const templateDataTable = (type, onEdit, onRemove, onDefault) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row?.title ?? "",
      cell: (row) => (
        <>
          <div className="d-flex">
            {row?.title}

            {row.status === true && (
              <div className="ms-2">
                <Icon name="check-thick" className="text-success" />
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      name: <TableTitle title={"Description"} />,
      omit: type === TemplateType.email ? true : false,
      selector: (row) => row?.description ?? "",
    },
    {
      name: <TableTitle title={"Subject"} />,
      omit: type === TemplateType.sms ? true : false,
      selector: (row) => row?.subject ?? "",
    },

    {
      name: <TableTitle title={"Action"} align={"end"} />,
      right: true,
      width: "90px",
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
              hide: !isAllowToAccess(UserPermissionType.template, UserActionType.edit),
            },
            {
              icon: "trash",
              text: "Remove",
              hide: row.status === true || !isAllowToAccess(UserPermissionType.template, UserActionType.remove),

              action: () => {
                onRemove(row);
              },
            },
            {
              icon: "navigate-up",
              text: "Set Default",
              hide: row.status === true || !isAllowToAccess(UserPermissionType.template, UserActionType.setdefault),
              action: () => {
                onDefault(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

export function addTemplateAPI(data, uploadedFiles, removeFileIds, type, templateTypeId, callback) {
  let params = {
    id: data.id,
    name: data.title,
    subject: data.subject,
    body: data.description,
    templateTypeId: templateTypeId,
    templateFor: type === TemplateType.email ? "EMAIL" : "SMS",
    status: "ACTIVE",
    fileIds:
      uploadedFiles?.length > 0 && uploadedFiles?.filter((item) => item.isOldFile === false)?.map((item) => item.id),
    removeFileIds: removeFileIds.length > 0 ? removeFileIds : null,
  };
  apiService
    .postCall(APIServicePath.saveTemplate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listTemplateAPI(params, callback) {
  apiService
    .postCall(APIServicePath.templateList, params)
    .then((data) => {
      let list = toTemplateList(data?.list);
      callback({ list }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTemplateDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.templateDetail, params)
    .then((data) => {
      let detail = toTemplateDetail(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export const removeTemplate = (data, callback) => {
  apiService
    .postCall(APIServicePath.removeTemplate, data)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export function defaultTemplateAPI(params, callback) {
  apiService
    .postCall(APIServicePath.defaultTemplate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

function toTemplateList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toTemplate(element));
  });
  return list;
}

function toTemplate(data) {
  let item = {
    id: data?.id,
    title: data?.name,
    subject: data?.data,
    description: data?.data,
    status: data?.isDefault,
  };

  return item;
}

function toTemplateDetail(data) {
  let item = {
    id: data?.id,
    title: data?.name,
    subject: data?.subject,
    description: data?.body,
    templateFiles: data?.templateFile?.map((file) => {
      let fileData = file;
      fileData.isOldFile = true;
      fileData.fileType = InvoiceFileType.template;
      return fileData;
    }),
    variables: data?.templateType?.variables,
  };

  return item;
}
