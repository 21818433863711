import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ActionButton, CancelButton } from "../Component";
import { DeleteButton } from "../button/ActionButton";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../services/permission/permission";

export const AnnsModal = ({
  isOpen,
  toggle,
  isFooterHide,
  isShowHeaderRemove,
  title,
  loading,
  size,
  disabled,
  onClick,
  onDelete,
  onCancelRota,
  ModalBodyClassName,
  headerClassName,
  actionButtonTitle = "Save",
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered" size={size}>
      <ModalHeader toggle={toggle} className={headerClassName}>
        <div className="d-flex justify-content-between w-100">
          <span>{title} </span>
          {isShowHeaderRemove && (
            <span>
              {" "}
              <DeleteButton onDelete={onDelete} />
            </span>
          )}
          {onCancelRota && isAllowToAccess(UserPermissionType.rota, UserActionType.remove) && (
            <span>
              {" "}
              <DeleteButton onCancelRota={onCancelRota} />
            </span>
          )}
        </div>
      </ModalHeader>
      <ModalBody className={ModalBodyClassName}>{props.children}</ModalBody>
      {!isFooterHide && (
        <ModalFooter>
          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
            <li>
              {/* <button className="btn btn-primary" onClick={onSave}>
              Save
            </button> */}
              <CancelButton onCancel={toggle} />
            </li>
            <li>
              {/* <button className="btn btn-primary" onClick={onSave}>
              Save
            </button> */}
              <ActionButton title={actionButtonTitle} loading={loading} disabled={disabled} onClick={onClick} />
            </li>
          </ul>
        </ModalFooter>
      )}
    </Modal>
  );
};
