import React from "react";
import {
  AnnsModal,
  Col,
  ColorPickerDropdown,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  FormCheckbox,
  FormInput,
  FormInputType,
  FormTimePicker,
  Icon,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form, Popover, PopoverBody } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { addGradeAPI, agencyGradeListAPI, gradeDetailAPI } from "./GradeTableData";
import { AppText, RadioOptionTypes, ServerStatusType, showErrorToast, showSuccessToast } from "../../../../utils";
import { useForm } from "react-hook-form";
import { convertToUpperCase } from "../../../../utils/Functions";
import moment from "moment";

const AddGrade = ({ visible, item, onSaved, onClosed }) => {
  const [gradeData, setGradeData] = useState({ agency: [] });
  const [gradeDetail, setGradeDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [agencyList, setAgencyList] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (!item) return;
    loadGradeDetail();
  }, [visible]);

  const isAllDataFilled = () => {
    const isDataFilled = agencyList?.every((item) => {
      const agencyData = gradeData.agency?.find((gradeTime) => gradeTime.agencyId === item.id);
      return agencyData && agencyData.dayStart && agencyData.nightStart && agencyData.invoiceDescFormat;
    });
    return isDataFilled;
  };

  useEffect(() => {
    loadGradeAgency();
  }, [visible]);

  useEffect(() => {
    if (
      gradeData?.name?.length === 0 ||
      gradeData?.abbrCode?.length === 0 ||
      gradeData?.colourHex === null ||
      gradeData?.mileRate?.length === 0 ||
      !isAllDataFilled()
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [gradeData, isAllDataFilled]);

  useEffect(() => {
    if (visible) {
      if (item && !gradeDetail) {
        // Load gradeDetail if editing
        loadGradeDetail();
      } else if (gradeDetail) {
        const preFilledData = gradeDetail.agencies.map((agency) => {
          return {
            agencyId: agency?.agency?.id,
            agencyName: agency?.agency?.name,
            dayStart: agency?.dayStartTime ? moment(agency?.dayStartTime, "HH:mm:ss").utc().toDate() : null,
            nightStart: agency?.nightStartTime ? moment(agency?.nightStartTime, "HH:mm:ss").utc().toDate() : null,
            invoiceDescFormat: agency?.invoiceDescFormat,
          };
        });

        setGradeData({
          ...gradeData,
          id: gradeDetail?.id,
          name: gradeDetail?.name,
          abbrCode: gradeDetail?.abbrCode,
          mileRate: gradeDetail?.mileRate,
          // colourHex: convertGradeColor({ title: gradeDetail?.colourHex }),
          colourHex: gradeDetail?.colourHex,
          isActive: gradeDetail?.isActive,
          agency: preFilledData,
        });
      } else {
        // setGradeData({
        //   ...gradeData,
        //   id: "",
        //   name: "",
        //   abbrCode: "",
        //   mileRate: null,
        //   colourHex: "",
        //   isActive: true,
        //   agency: [],
        // });
        setGradeData((prev) => {
          const updatedData = { ...prev };
          gradeDetail?.agencies?.forEach((agency) => {
            updatedData[agency.agency.id] = {
              dayStart: null, // Set default values for new items
              nightStart: null, // Set default values for new items
              invoiceDescFormat: null,
            };
          });
          return {
            ...updatedData,
            name: "",
            abbrCode: "",
            mileRate: null,
            colourHex: "",
            isActive: true,
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, gradeDetail, item]);

  // useEffect(() => {
  //   if (visible) {
  //     if (item) {
  //       // Load gradeDetail if editing
  //       loadGradeDetail();
  //     } else {
  //       // Reset gradeData if adding a new grade
  //       setGradeData({
  //         agency: [],
  //         name: "",
  //         abbrCode: "",
  //         mileRate: null,
  //         colourHex: "",
  //         isActive: true,
  //       });
  //       setGradeDetail(null);
  //     }
  //   }
  // }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setGradeData({});
    // setGradeDetail(null);
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addGradeAPI(gradeData, (_, error) => {
      if (error === null) {
        let text = "Grade" + " " + gradeData?.name + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  function loadGradeDetail() {
    gradeDetailAPI(
      {
        gradeId: item?.id,
      },
      (data, error) => {
        if (error === null) {
          setGradeDetail(data);
        }
      }
    );
  }

  function loadGradeAgency() {
    agencyGradeListAPI({ status: ServerStatusType.active }, (list, error) => {
      if (error === null) {
        setAgencyList(list?.list);
      }
    });
  }

  const { handleSubmit } = useForm();

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <>
      <AnnsModal
        size={"lg"}
        isOpen={visible}
        toggle={() => close()}
        title={item ? "Edit Grade Name" : "Add Grade Name"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Grade Name"}
                  type={FormInputType.default}
                  value={gradeData?.name}
                  onTextChange={(text) => {
                    setGradeData((prev) => ({
                      ...prev,
                      name: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Color </label>
              <span className="text-danger fs-6"> *</span>
              <div className="form-control-wrap">
                <ColorPickerDropdown
                  selectedColor={gradeData?.colourHex}
                  onSelect={(colourHex) => {
                    setGradeData((prev) => ({ ...prev, colourHex: colourHex }));
                  }}
                  placeholder="Select a color"
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Code"}
                  required={true}
                  type={FormInputType.default}
                  value={gradeData?.abbrCode ?? ""}
                  placeholder={"Code"}
                  maxLength={5}
                  onTextChange={(abbrCode) => {
                    const uppercaseAbbrCode = convertToUpperCase(abbrCode);
                    setGradeData((prev) => ({
                      ...prev,
                      abbrCode: uppercaseAbbrCode,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Mile Rate"}
                  required
                  type={FormInputType.amount}
                  value={gradeData?.mileRate}
                  placeholder={"Mile Rate"}
                  onTextChange={(mileRate) => {
                    setGradeData((prev) => ({
                      ...prev,
                      mileRate: mileRate,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          {agencyList?.length > 0 && (
            <Col size="12">
              <DataTable className="card-stretch">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text ">Agency</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Day Start</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text">Night Start</span>
                    </DataTableRow>
                    <DataTableRow className={"align-center"}>
                      <span className="sub-text">Invoice Format</span>
                      <Icon
                        id="info-icon"
                        className={"fs-6 me-n1 cursor-pointer"}
                        name="info"
                        onMouseEnter={togglePopover}
                        onMouseLeave={togglePopover}
                      />
                    </DataTableRow>
                    <Popover target="info-icon" isOpen={popoverOpen} toggle={togglePopover} placement="right">
                      <PopoverBody>
                        Format should be based on below variables:
                        <br />
                        {"{{gradeName}}"}
                        <br />
                        {"{{gradeCode}}"}
                        <br />
                        {"{{weekStartDate}}"}
                        <br />
                        {"{{weekEndDate}}"}
                        <br />
                        {"{{timesheetDate}}"}
                        <br />
                        {"{{dayType}}"}
                      </PopoverBody>
                    </Popover>
                  </DataTableHead>
                  {agencyList?.length > 0 &&
                    agencyList?.map((item, index) => (
                      <React.Fragment key={index}>
                        <DataTableItem className={"cursor-pointer"} onClick={() => ""}>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item?.title}</span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <FormTimePicker
                                placeholderText={"Select day start time"}
                                selected={gradeData.agency?.find((agency) => agency.agencyId === item.id)?.dayStart}
                                onChange={(dayStart) => {
                                  let agencyListData = [...gradeData?.agency];
                                  let agencyObject = {
                                    ...agencyListData?.find((grade) => grade.agencyId === item.id),
                                    dayStart: dayStart,
                                    agencyId: item?.id,
                                  };
                                  let index = agencyListData.findIndex((grade) => grade.agencyId === item.id);
                                  if (index !== -1) {
                                    agencyListData[index] = agencyObject;
                                  } else {
                                    agencyListData.push(agencyObject);
                                  }
                                  setGradeData((prevData) => ({
                                    ...prevData,
                                    agency: agencyListData,
                                  }));
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <FormTimePicker
                                placeholderText={"Select day start time"}
                                selected={gradeData.agency?.find((agency) => agency.agencyId === item.id)?.nightStart}
                                onChange={(nightStart) => {
                                  let agencyListData = [...gradeData?.agency];
                                  let agencyObject = {
                                    ...agencyListData?.find((grade) => grade.agencyId === item.id),
                                    nightStart: nightStart,
                                    agencyId: item?.id,
                                  };
                                  let index = agencyListData.findIndex((grade) => grade.agencyId === item.id);
                                  if (index !== -1) {
                                    agencyListData[index] = agencyObject;
                                  } else {
                                    agencyListData.push(agencyObject);
                                  }
                                  setGradeData((prevData) => ({
                                    ...prevData,
                                    agency: agencyListData,
                                  }));
                                }}
                              />
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info">
                              <FormInput
                                placeholder={"Invoice Format"}
                                type={FormInputType.default}
                                value={
                                  gradeData.agency?.find((agency) => agency.agencyId === item.id)?.invoiceDescFormat ??
                                  ""
                                } // Ensure to get the correct field
                                onTextChange={(text) => {
                                  const agencyListData = [...gradeData.agency]; // Copy the existing list
                                  const agencyObject = {
                                    ...agencyListData?.find((grade) => grade.agencyId === item.id),
                                    invoiceDescFormat: text, // Update the description field
                                    agencyId: item?.id, // Ensure the ID is retained
                                  };

                                  const index = agencyListData.findIndex((grade) => grade.agencyId === item.id);
                                  if (index !== -1) {
                                    agencyListData[index] = agencyObject; // Update existing entry
                                  } else {
                                    agencyListData.push(agencyObject); // Add a new entry if it doesn't exist
                                  }

                                  setGradeData((prevData) => ({
                                    ...prevData,
                                    agency: agencyListData, // Update the agency list
                                  }));
                                }}
                              />
                            </div>
                          </DataTableRow>
                        </DataTableItem>
                      </React.Fragment>
                    ))}
                </DataTableBody>
              </DataTable>
            </Col>
          )}
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setGradeData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={gradeData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddGrade;
