/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AnnsModal, Col, FormInput, FormInputType, TimesheetDropdown } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { showErrorToast, toCapitalize } from "../../../utils/Functions";
import { TimesheetStatusType } from "../../../utils";
import { SendNotificationType } from "../../../utils/Utils";
import { sendGradeNotification, sendTimesheetNotification } from "./TimesheetData";
import { GradeTypeDropDownWithoutCarer } from "../../../components/dropdowns/GradeTypeDropDown";

const SendNotificationModal = ({ visible, onSaved, onClosed, type }) => {
  const [loading, setLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [formData, setFormData] = useState({});
  const [timeSheetStatus, setTimeSheetStatus] = useState({
    label: toCapitalize(TimesheetStatusType.all),
    value: TimesheetStatusType.all,
  });
  const [grade, setGrade] = useState();

  const onFormSubmit = () => {
    setLoading(true);
    let params = {
      title: formData?.title,
      description: formData?.message,
      type: type === SendNotificationType.timesheet ? timeSheetStatus.value ?? undefined : undefined,
      gradeIds: type !== SendNotificationType.timesheet ? grade?.map((g) => g.id) ?? [] : undefined,
    };
    if (type === SendNotificationType.timesheet) {
      sendTimesheetNotification(params, (_, error) => {
        if (error === null) {
          close();
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    } else {
      sendGradeNotification(params, (_, error) => {
        if (error === null) {
          close();
          onSaved();
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    }
  };

  const close = () => {
    onClosed();
    setLoading(false);
    setFormData({});
    setTimeSheetStatus({
      label: toCapitalize(TimesheetStatusType.all),
      value: TimesheetStatusType.all,
    });
    setGrade();
  };

  const { handleSubmit } = useForm();

  return (
    <AnnsModal
      size={"md"}
      isOpen={visible}
      toggle={() => close()}
      title={"Send Notification"}
      actionButtonTitle="Send Notification"
      onClick={() => onFormSubmit()}
      disabled={
        !formData?.title ||
        !formData?.message ||
        (type === SendNotificationType.timesheet ? !timeSheetStatus : type === SendNotificationType.grade && !grade)
      }
      loading={loading}
    >
      <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
        <Col md="12">
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="mb-2">
                <FormInput
                  label={"Title"}
                  required={true}
                  maxLength={50}
                  type={FormInputType.default}
                  value={formData?.title}
                  onTextChange={(text) => {
                    setFormData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
              <div>
                <label className="form-label">
                  Message <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control h-70px"
                  placeholder="Message"
                  maxLength={144}
                  value={formData?.message || ""}
                  onChange={(e) => {
                    setCharCount(e.target.value.length);
                    setFormData((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                />
                <span className="d-flex justify-content-end pt-1">{charCount}/144</span>
              </div>
              {type === SendNotificationType.timesheet ? (
                <div>
                  <label className="form-label">
                    Type <span className="text-danger">*</span>
                  </label>
                  <TimesheetDropdown
                    isNotificationScreen={true}
                    value={timeSheetStatus}
                    onSelect={(selectedStatus) => {
                      setTimeSheetStatus(selectedStatus);
                    }}
                  />
                </div>
              ) : (
                <>
                  <GradeTypeDropDownWithoutCarer
                    value={grade}
                    isClearable
                    isMulti
                    placeholder={"Grade Type"}
                    onSelect={(selectedGrade) => {
                      setGrade(selectedGrade);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Col>
      </Form>
    </AnnsModal>
  );
};

export default SendNotificationModal;
