import { toCapitalize } from "../../utils/Functions";
import React from "react";
import { ColorsArray } from "../../utils/Utils";
import { Icon, PreviewCard, UserAvatar } from "../Component";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../services/permission/permission";

export const ProjectCard = ({ ...props }) => {
  return (
    <React.Fragment>
      <PreviewCard className="card-bordered h-100">
        <div className="project">{props.children}</div>
      </PreviewCard>
    </React.Fragment>
  );
};

export const ProjectHead = ({ text, phone, name, subName, email, address, onEdit, statusBadge, customHex }) => {
  return (
    <>
      <div className="project-head">
        <UserAvatar
          className="sq"
          // theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
          customHex={customHex}
          text={(text ? text.slice(0, 2) : "  ").toUpperCase()}
          subName={subName}
          name={toCapitalize(name ?? " ")}
          statusBadge={statusBadge}
        ></UserAvatar>
        {isAllowToAccess(UserPermissionType.trust, UserActionType.edit) && (
          <div className="job-edit-icon cursor-pointer" onClick={() => onEdit()}></div>
        )}
        {/* <Icon name="edit" className="cursor-pointer " onClick={() => onEdit()} /> */}
      </div>
      <hr />
      <div className="d-flex justify-content-start mb-1">
        <span className="w-trust-icon ">
          <Icon name="emails" />
        </span>
        <span className="align-center">{email}</span>
      </div>
      <div className="d-flex justify-content-start mb-1">
        <span className="w-trust-icon">
          <Icon name="call" />
        </span>
        <span className="align-center">{phone}</span>
      </div>
      <div className="d-flex justify-content-start mb-1">
        <span className="w-trust-icon">
          <Icon name="map-pin" />
        </span>
        <span className="align-center">{address}</span>
      </div>
    </>
  );
};
