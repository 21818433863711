import moment from "moment";
import { TableTitle } from "../../../components/Component";
import { TimeSheetType, TimesheetStatusType, dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { convertHexToColor, convertPoNumberDropValue } from "../../../utils/Functions";
import { GradeBadge } from "../../../components/badge/GradeBadge";

export const timesheetTableData = (type, onCancel, onVerify, onApprove, onView) => {
  return [
    {
      name: <TableTitle title={"Request Id"} />,
      selector: (row) => (row?.refId ? "#" + row?.refId : "N/A"),
      sortable: false,
      style: {
        cursor: "pointer",
      },
      // width: "150px",
    },

    {
      name: <TableTitle title={"Client"} />,
      selector: (row) => row?.client?.name ?? "",
      omit: type !== TimeSheetType.carer ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Carer"} />,
      selector: (row) => {
        return (
          <>
            <div onClick={() => onView(row)}>
              {row?.carerTitle ?? ""}
              <br />
              <span className="listingsubtext">{row?.carerEmail ?? ""}</span>
            </div>
          </>
        );
      },
      omit: type === TimeSheetType.carer ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Grade"} />,
      selector: (row) => {
        return (
          <>
            <div onClick={() => onView(row)}>
              {row?.jobGrades.slice(0, 2).map((grade, index) => (
                <span key={index}>{grade.name}</span>
              ))}
              {row?.jobGrades.length > 2 && <span>{`+ ${row?.jobGrades.length - 2}`}</span>}
              <br />
              <span className="listingsubtext">{row?.ward?.name ?? "N/A"}</span>
            </div>
          </>
        );
      },
      omit: !type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Client"} />,
      selector: (row) => row?.client?.name ?? "",
      omit: type === TimeSheetType.hospital || type === TimeSheetType.carer ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },

    {
      name: <TableTitle title={"Grade"} />,
      selector: (row) => (
        <>
          {row?.jobGrades?.map((grade, index) => (
            <GradeBadge
              key={index}
              label={grade?.name}
              color={`outline-${convertHexToColor(grade?.colourHex)}`}
              onClick={() => onView(row)}
            />
          ))}
        </>
      ),

      sortable: false,
      width: "225px",
      omit: type ? true : false,
      style: {
        cursor: "pointer",
      },
    },
    // <CustomPopOver data={row}>
    //   <div>
    //     {row?.jobGrades.slice(0, 2).map((grade, index) => (
    //       <GradeBadge key={index} label={grade.name} className={"me-1 my-1"} color={`outline-${grade.colourHex}`} />
    //     ))}
    //     {row?.jobGrades.length > 2 && (
    //       <AnnsBadge className={"my-1"} color={"primary"} label={`+ ${row?.jobGrades.length - 2}`} />
    //     )}
    //   </div>
    // </CustomPopOver>
    //   ),
    //   style: {
    //     cursor: "pointer",
    //   },
    // },

    {
      name: <TableTitle title={"Shift Date"} />,
      selector: (row) => {
        return `${dateToShow(row?.startDate)}`;
      },
      sortable: false,
      style: {
        cursor: "pointer",
      },
      omit: type ? true : false,
      width: "175px",
    },
    {
      name: <TableTitle title={"Shift"} />,
      selector: (row) => {
        const startDate = row?.startDate ? dateToShow(row?.startDate) : "";
        const shiftType = row?.shiftType?.value;
        const startTime = row?.startTime ? moment(row?.startTime).format("hh:mm A") : "";
        const endTime = row?.endTime ? moment(row?.endTime).format("hh:mm A") : "";
        const icon = (
          <img
            src={
              shiftType === "DAY"
                ? require("../../../assets/images/jsTree/shiftIcon/Sun.png")
                : require("../../../assets/images/jsTree/shiftIcon/Moon.png")
            }
          />
        );

        return (
          <div onClick={() => onView(row)}>
            <span>{startDate}</span>
            <br />
            <span className="">
              {icon}
              <span className="ms-2">
                {startTime} - {endTime}
              </span>
            </span>
          </div>
        );
      },
      omit: !type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Shift Time"} />,
      selector: (row) => {
        const shiftType = row?.shiftType;
        const startTime = row?.startTime ? moment(row?.startTime).format("hh:mm A") : "";
        const endTime = row?.endTime ? moment(row?.endTime).format("hh:mm A") : "";
        const icon = (
          <img
            src={
              shiftType === "DAY"
                ? require("../../../assets/images/jsTree/shiftIcon/Sun.png")
                : require("../../../assets/images/jsTree/shiftIcon/Moon.png")
            }
          />
        );

        return (
          <span onClick={() => onView(row)}>
            {icon}
            <span className="ms-2">
              {startTime} - {endTime}
            </span>
          </span>
        );
      },
      omit: type ? true : false,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },

    {
      name: <TableTitle title={"Ratings"} />,
      width: "100px",
      cell: (row) => (
        <>
          <span onClick={() => onView(row)}>
            <img className="me-1" src={require("../../../assets/images/jsTree/rating-start/ratingstar.png")} />
            {row?.rating}
          </span>
        </>
      ),
      omit: type ? true : false,
      style: {
        cursor: "pointer",
      },
      width: "130px",
    },
  ];
};
export function addTimesheetAPI(data, dataRowData, callback) {
  apiService
    .postCall(APIServicePath.addTimesheet, data)
    .then((response) => {
      callback(response, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function timesheetListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetList, params)
    .then((data) => {
      let list = toTimesheetData(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTimesheetDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetDetail, params)
    .then((data) => {
      let detail = toTimesheetDetail(data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function getTimesheetListingAPI(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetListing, params)
    .then((data) => {
      let detail = toTimesheetList(data?.list || []);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ detail, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTimesheetFullDataAPI(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetFullData, params)
    .then((data) => {
      let timesheetData = toTimesheetFullData(data?.data);
      callback(timesheetData, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function movetoWagesAPI(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetMovetoWages, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function moderateStatus(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetModerateStatus, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function timesheetCancel(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetCancel, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function timesheetReject(data, params, callback) {
  apiService
    .postCall(APIServicePath.timesheetReject, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportTimesheet(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetExport, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function sendTimesheetNotification(params, callback) {
  apiService
    .postCall(APIServicePath.sendTimesheetNotification, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function sendGradeNotification(params, callback) {
  apiService
    .postCall(APIServicePath.sendGradeNotification, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//API
function toTimesheetFullData(data) {
  return {
    carerComment: data?.carerComment,
    carerSignature: data?.carerSignature,
    supervisorName: data?.supervisorData?.clientFullName,
    ratings: data?.supervisorData?.ratings,
    supervisorPosition: data?.supervisorData?.clientPosition,
    supervisorComment: data?.supervisorData?.comments,
    supervisorSignature: data?.supervisorData?.clientSignature,
  };
}

function toTimesheetData(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toTimesheet(element));
  });
  return list;
}

function toTimesheet(data) {
  return {
    rotaId: data?.rotaId,
    poNumber: data?.poNumber,
    client: data?.client,
    carer: data?.carer,
    carerTitle: data?.carer?.firstName + " " + data?.carer?.lastName,
    carerEmail: data?.carer?.email,
    startDate: data?.startDate ? moment(data?.startDate).format("YYYY-MM-DD") : null,
    endDate: data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : null,
    startTime: data?.startTime ? moment(data?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: data?.endTime ? moment(data?.endTime, "HH:mm:ss").utc().toDate() : null,
    totalWeekHours: data?.timesheet?.totalWeekHours,
    totalApprovedHours: data?.timesheet?.totalApprovedHours,
    approvedBy: data?.timesheet?.approvedBy,
    timesheetId: data?.timesheet?.id,
    timesheetStatus: data?.timesheet?.status ? data?.timesheet?.status : TimesheetStatusType.pending,
    weeklyData: toWeekList(data?.timesheet?.timesheetWeeklyData),
    status: TimesheetStatusType.pending,
    jobGrades: data?.jobGrades,
    shiftType: data?.shiftType,
    rating: data?.rating,
    refId: data?.refId,
    ward: data?.ward,
  };
}

const fileConvertor = (fileList) => {
  let cfileList =
    fileList?.length > 0 &&
    fileList?.map((fItem) => ({
      id: fItem?.id,
      name: fItem?.name,
      fileUrl: fItem?.fileUrl,
      mimeType: fItem?.mimeType,
      thumbUrl: fItem?.thumbUrl,
      thumbMimeType: fItem?.thumbMimeType,
      isOldFile: true,
    }));

  return cfileList;
};

function toTimesheetList(data) {
  let cData =
    data?.length > 0 &&
    data?.map((item) => {
      return {
        id: item?.rotaCarerDateId,
        timesheetId: item?.timesheetId,
        refId: item?.refId,
        timesheetDate: item?.date,
        startTime: item?.startTime ? moment(item?.startTime, "HH:mm:ss").utc().toDate() : null,
        endTime: item?.endTime ? moment(item?.endTime, "HH:mm:ss").utc().toDate() : null,
        breakTime: item?.breakTime,
        totalHours: item?.totalHours,
        totalMiles: item?.totalMiles,
        sleepoverStart: item?.sleepoverStart ? moment(item?.sleepoverStart, "HH:mm:ss").utc().toDate() : null,
        sleepoverEnd: item?.sleepoverEnd ? moment(item?.sleepoverEnd, "HH:mm:ss").utc().toDate() : null,
        supervisorSignature: item?.supervisorSignature,
        carerSignature: item?.carerSignature,
        isMauallyAdded: item?.isMauallyAdded,
        isIncharge: item?.isIncharge,
        status: item?.status,
        poNumber: item?.poNumber,
        statusReason: item?.statusReason,
        client: {
          id: item?.client?.id,
          name: item?.client?.name,
        },
        carer: {
          id: item?.carer?.id,
          firstName: item?.carer?.firstName,
          lastName: item?.carer?.lastName,
          email: item?.carer?.email,
        },
        grade: {
          id: item?.grade?.id,
          name: item?.grade?.name,
          colourHex: item?.grade?.colourHex,
          abbrCode: item?.grade?.abbrCode,
          mileRate: 0,
          status: item?.grade?.status,
        },
        ward: {
          id: item?.ward?.id,
          name: item?.ward?.name,
        },
        rota: {
          id: item?.rota?.id,
          fromDate: item?.rota?.fromDate,
          toDate: item?.rota?.toDate,
          startTime: item?.rota?.startTime ? moment(item?.rota?.startTime, "HH:mm:ss").utc().toDate() : null,
          endTime: item?.rota?.endTime ? moment(item?.rota?.endTime, "HH:mm:ss").utc().toDate() : null,
        },
        files: item?.files && fileConvertor(item?.files),
      };
    });
  return cData;
}

function toTimesheetDetail(data) {
  const weeklyData = toWeekList(data?.timesheetList) || [];
  const rotaDates = data?.data?.rotaDates || [];
  let combined = [];
  for (let index = 0; index < rotaDates.length; index++) {
    const element = rotaDates[index];
    const timesheet = weeklyData?.find((week) => week.rotaCarerId === element.id);
    combined?.push(toTimesheetDay(element, timesheet));
  }

  return {
    rotaId: data?.data?.rotaId,
    jobId: data?.data?.id,
    poNumber: data?.data?.poNumber,
    client: data?.data?.client,
    carer: data?.data?.carer,
    carerTitle: data?.data?.carer?.firstName + " " + data?.data?.carer?.lastName,
    startDate: data?.data?.fromDate ? moment(data?.data?.fromDate).format("YYYY-MM-DD") : null,
    endDate: data?.data.toDate ? moment(data?.data.toDate).format("YYYY-MM-DD") : null,
    startTime: data?.data?.startTime ? moment(data?.data?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: data?.data?.endTime ? moment(data?.data?.endTime, "HH:mm:ss").utc().toDate() : null,
    status: data?.data?.status,
    rotasData: combined,
    refId: data?.data?.refId,
    ward: data?.data?.ward,
    grade: data?.data?.grade,
    viewListDays: (data?.data?.rotaDates ?? [])
      .map((day) => {
        return day?.dayName[0];
      })
      .join(" - "),
    files: data?.files?.map((file) => {
      let fileData = file;
      fileData.isOldFile = true;
      return fileData;
    }), // Include the 'files' array in the returned object
  };
}

function toWeekList(list) {
  let items = [];
  for (let index = 0; index < (list?.length ?? 0); index++) {
    const element = list[index];
    items.push(toWeeklyData(element));
  }
  return items;
}

function toWeeklyData(week) {
  return {
    weekId: week.id,
    rotaCarerId: week?.rotaCarerDateId ?? "",
    timesheetDate: week?.timesheetDate ? moment(week?.timesheetDate).format("YYYY-MM-DD") : null,
    startTime: week?.startTime ? moment(week?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: week?.endTime ? moment(week?.endTime, "HH:mm:ss").utc().toDate() : null,
    sleepoverStart: week?.sleepoverStart ? moment(week?.sleepoverStart, "HH:mm:ss").utc().toDate() : null,
    sleepoverEnd: week?.sleepoverEnd ? moment(week?.sleepoverEnd, "HH:mm:ss").utc().toDate() : null,
    totalMiles: week?.totalMiles?.toString(),
    breakTime: week?.breakTime,
    totalHours: week?.totalHours,
    supervisorSignature: week?.supervisorSignature ?? "",
    carerSignature: week?.carerSignature ?? "",
    isMauallyAdded: week?.isMauallyAdded,
    status: week?.status,
    poNumber: week?.poNumber,
  };
}

function toTimesheetDay(rota, timesheet) {
  let data = {
    dayName: rota?.dayName,
    id: rota?.id,
    rotaDate: rota.rotaDate,
    status: timesheet?.status ?? TimesheetStatusType.pending,
    weekId: timesheet?.id,
    startTime: timesheet?.startTime ? moment(timesheet?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: timesheet?.endTime ? moment(timesheet?.endTime, "HH:mm:ss").utc().toDate() : null,
    breakTime: timesheet?.breakTime,
    totalHours: timesheet?.totalHours,
    poNumber: convertPoNumberDropValue(timesheet?.poNumber),
    sleepoverData:
      timesheet?.sleepoverStart && timesheet?.sleepoverEnd
        ? {
            sleepoverStart: timesheet?.sleepoverStart,
            sleepoverEnd: timesheet?.sleepoverEnd,
          }
        : null,
    totalMiles: timesheet?.totalMiles,

    // sleepoverStart: data?.timesheetList[0]?.sleepoverStart
    //   ? moment(data?.timesheetList[0]?.sleepoverStart, "HH:mm:ss").utc().toDate()
    //   : null,
    // sleepoverEnd: data?.timesheetList[0]?.sleepoverEnd
    //   ? moment(data?.timesheetList[0]?.sleepoverEnd, "HH:mm:ss").utc().toDate()
    //   : null,
    supervisorSignature: timesheet?.supervisorSignature ?? "",
    carerSignature: timesheet?.carerSignature ?? "",
    isMauallyAdded: timesheet?.isMauallyAdded,
  };
  return data;
}
