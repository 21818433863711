import React from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  FormCheckbox,
  FormInput,
  FormInputType,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { addNoteTypeAPI } from "./NoteTypeTableData";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";

const AddNoteType = ({ visible, item, onSaved, onClosed }) => {
  const [noteTypeData, setNoteTypeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if ((noteTypeData?.title?.length ?? 0) === 0 || noteTypeData?.description === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [noteTypeData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setNoteTypeData({
          ...noteTypeData,
          id: item?.id,
          title: item?.title,
          description: item?.description,
          isActive: item?.isActive || false,
        });
      } else {
        setNoteTypeData({
          ...noteTypeData,
          title: "",
          description: "",
          isActive: true,
        });
      }
    }
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setNoteTypeData({});
  };

  const onFormSubmit = () => {
    if (isDisabled) return;
    setLoading(true);
    addNoteTypeAPI(noteTypeData, (_, error) => {
      if (error === null) {
        let text =
          "Note Type" + " " + noteTypeData?.title + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Note Type" : "Add Note Type"}
        onClick={() => onFormSubmit()}
        disabled={isDisabled}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Note Type"}
                  type={FormInputType.default}
                  value={noteTypeData.title}
                  onTextChange={(text) => {
                    setNoteTypeData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Description</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={noteTypeData.description}
                  onChange={(e) => {
                    setNoteTypeData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setNoteTypeData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={noteTypeData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddNoteType;
