/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { useEffect } from "react";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import { agencyDataTableColumns, agencyListAPI } from "./AgencyData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { scrollToTop } from "../../../../utils/Functions";

const AgencyList = () => {
  const [agencyListData, setAgencyListData] = useState([]);
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();

  useEffect(() => {
    loadAgency();
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadAgency();
    } else {
      setCurrentPage(1);
    }
  }, [status, searchText, sortId, sortOrder]);

  const onEdit = (row) => {
    setSelectedItem(row); // Pass the id of the selected item
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-agency`,
      state: { data: row },
    });
  };

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-agency`);
  };

  function loadAgency() {
    scrollToTop();

    if (isCheckToList(UserPermissionType.agency)) {
      agencyListAPI(
        {
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          sorting: {
            field: sortId ?? null,
            order: sortOrder ?? null,
          },
          status: status?.value ?? ServerStatusType.active,
          search: searchText,
        },
        (data, error) => {
          if (error === null) {
            setAgencyListData(data.list);
            if (currentPage === 1) {
              setTotalItems(data?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    } else {
      setInitScreen(false);
    }
  }

  return (
    <React.Fragment>
      <Head title="Users" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Agency</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    value={searchText}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </span>
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.agency, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={onAddClick} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={agencyListData}
              columns={agencyDataTableColumns(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.agency)}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
              onSort={(id) => {
                setSortId(id);
                const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
                setSortOrder(newSortOrder);
              }}
            />
          </PreviewCard>
        </Block>
        <LoadingComponent isLoading={initScreen} />
      </Content>
    </React.Fragment>
  );
};

export default AgencyList;
