import React, { useEffect, useImperativeHandle } from "react";

import { Block, ConfirmationAlert, ReactDataTable } from "../../../components/Component";
import { useState } from "react";
import { PER_PAGE_ITEMS } from "../../../utils";
import { listRateAPI, rateRemoveAPI, ratesTableData } from "./RatesData";
import AddRate from "./AddRates";
import { UserPermissionType, isCheckToList } from "../../../services/permission/permission";

const RateList = React.forwardRef(({ hospitalId, isOpenAddModel, onCloseModel, carerType }, ref) => {
  const [rate, setRate] = useState([]);

  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadRate();
  }, [currentPage]);

  useImperativeHandle(ref, () => ({
    reloadRates: () => {
      reloadRate();
    },
  }));

  function reloadRate() {
    if (currentPage === 1) {
      loadRate();
    } else {
      setCurrentPage(1);
    }
  }

  useEffect(() => {
    setModal(isOpenAddModel);
  }, [isOpenAddModel]);

  useEffect(() => {
    if (status) {
      reloadRate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  function loadRate() {
    if (isCheckToList(UserPermissionType.rate)) {
      listRateAPI(
        {
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          clientId: hospitalId,
        },
        (list, error) => {
          if (error === null) {
            setRate(list?.list);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
        }
      );
    }
  }

  function onRemove(row) {
    // ConfirmationAlert("Removing Rate!", "Do you want to remove Rate " + row?.name + "?", () => {
    //   setRate((prevData) => {
    //     const newData = prevData.filter((item) => item.id !== row.id);
    //     return newData;
    //   });
    // });
  }

  return (
    <React.Fragment>
      <Block>
        <ReactDataTable
          data={rate}
          columns={ratesTableData(onEdit, onRemove)}
          isAllowed={isCheckToList(UserPermissionType.rate)}
          totalItemCount={totalItems}
          pagination
          onPageChanged={(page, count) => {
            setCurrentPage(page);
          }}
        />
      </Block>
      <AddRate
        carerType={carerType}
        visible={modal}
        hospitalId={hospitalId}
        item={selectedItem}
        onClosed={() => {
          setModal(false);
          onCloseModel();
          setSelectedItem();
        }}
        onSaved={() => {
          reloadRate();
        }}
      />
    </React.Fragment>
  );
});

export default RateList;
