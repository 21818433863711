export {
  RedirectAs404,
  setDateForPicker,
  windowReload,
  setDeadlineDays,
  findUpper,
  showSuccessToast,
  showErrorToast,
  formatPhoneNumber,
  dateToShow,
  dateStringToShowFormat,
  convertDropValue,
  covertToNumberOnly,
  convertNameToDropdownList,
  convertToNotNull,
  formatUKPhoneNumber,
  timeToMinutes,
  minutesToTime,
  convertGradeColor,
} from "./Functions";
export {
  AuthPaths,
  NewPasswordType,
  PASSWORD_TOOL_TIP,
  ServerStatusType,
  PersonTypes,
  InvoiceTypes,
  RadioOptionTypes,
  ShiftTypes,
  ShiftCheck,
  JobStatusType,
  TimesheetStatusType,
  RotaOptions,
  TimeSheetType,
} from "./Utils";
export { AppText } from "./AppText";

export const PHONE_REGX = /^[0-9]{3} [0-9]{3} [0-9]{4}$/i;
export const PASSWORD_REGX = /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const POSTALCODE_REGX = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i;
export const PER_PAGE_ITEMS = 20;
export const COUNTRY_CODE = "+44";
export const CURRENCY_CODE = "£";
export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY hh:mm a";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT_GLOBAL = "HH:mm";
