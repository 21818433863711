import { RSelect } from "../Component";
import { useState } from "react";
import { convertYearsDropValue } from "../../utils/Functions";
import { MONTHS } from "../../utils/Utils";
import { useEffect } from "react";

const MonthDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  carerTypeId,
  numberOfPastYears,
  selectedValue,
  ...props
}) => {
  const [list, setList] = useState(MONTHS);
  const [searchString, setSearchString] = useState("");

  const handleSearch = (text) => {
    setSearchString(text);
  };
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? selectedValue}
      onInputChange={handleSearch}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default MonthDropDown;
