import React from "react";
import {
  AnnsModal,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

const TemplateInfo = ({ visible, item, templateData, onSaved, onClosed, type, templateTypeId }) => {
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const close = () => {
    onClosed();
  };

  const onFormSubmit = () => {};
  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        loading={loading}
        toggle={() => close()}
        title={templateData && (templateData?.name ?? templateData?.title) + " Variables"}
        onClick={() => onFormSubmit()}
        isFooterHide={true}
        disabled={isDisabled}
      >
        <div className="">
          <div className="mb-3">
            <p className="m-0 fw-bold">How to use variable?</p>
            <p className="m-0">
              we can use like this <span className="fw-medium text-primary">{"{{variable_name}}"}</span> for example ,{" "}
              <span className="fw-medium text-primary">{`{{${templateData?.variables[0]?.name}}}`}</span>
            </p>
            <p className="m-0"></p>
          </div>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow size="mb">
                  <span className="sub-text">Discription</span>
                </DataTableRow>
              </DataTableHead>
              {templateData?.variables?.length > 0 &&
                templateData?.variables?.map((vItem, vIndex) => (
                  <React.Fragment key={vIndex}>
                    <DataTableItem className={"cursor-pointer"} onClick={() => ""}>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="tb-lead">{vItem?.name}</span>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        <div className="user-info">
                          <span className="">{vItem?.desc}</span>
                        </div>
                      </DataTableRow>
                    </DataTableItem>
                  </React.Fragment>
                ))}
            </DataTableBody>
          </DataTable>
        </div>
      </AnnsModal>
    </>
  );
};

export default TemplateInfo;
