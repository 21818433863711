import { placeholder } from "@babel/types";
import { end } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { enGB } from "date-fns/locale";
import { Icon } from "../../Component";
import YearsDropDown from "../../dropdowns/YearsDropDown";
import MonthDropDown from "../../dropdowns/MonthDropDown";
import moment from "moment";
import { MONTHS } from "../../../utils/Utils";
registerLocale(enGB);
const YearMonthPicker = ({ selected, onChange, maxDate, minDate, error, placeholder, showMonthYearPicker }) => {
  const [year, setYear] = useState();
  const [month, setMonth] = useState();

  const CustomInput = ({ value, onClick }) => (
    <input
      className="form-control rounded-0 rounded-end rounded-start cursor-pointer"
      onClick={onClick}
      placeholder={placeholder}
      readOnly
    />
  );

  useEffect(() => {
    if (year === undefined) {
      setYear({
        id: "",
        value: moment(minDate).year(), // Use the current year as a default value
        label: moment(minDate).year(),
      });
    }
  }, [year, minDate]);

  return (
    <div className="form-control-wrap placeholder-style">
      <DatePicker
        locale={enGB}
        selected={selected}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat="dd-MM-yyyy"
        customInput={<CustomInput />}
        showMonthYearPicker={showMonthYearPicker}
        maxDate={maxDate}
        minDate={minDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="">
              <div className="input-group">
                <span
                  className="input-group-text px-1"
                  onClick={() => {
                    decreaseMonth();
                    setMonth();
                    setYear();
                  }}
                  disabled={prevMonthButtonDisabled}
                >
                  <Icon className="" name="chevron-left" />
                </span>
                <YearsDropDown
                  value={year}
                  placeholder={"years"}
                  className="w-100px"
                  minDate={minDate}
                  maxDate={maxDate}
                  onSelect={(e) => {
                    setYear(e);
                    changeYear(e?.value);
                  }}
                />
                <MonthDropDown
                  value={month}
                  placeholder={"months"}
                  className="w-100px"
                  selectedValue={MONTHS.find((item) => item.value === moment(date).format("MMMM"))}
                  onSelect={(e) => {
                    setMonth(e);
                    changeMonth(e?.id - 1);
                  }}
                />
                <span
                  className="input-group-text px-1"
                  onClick={() => {
                    increaseMonth();
                    setMonth();
                    setYear();
                  }}
                  disabled={nextMonthButtonDisabled}
                >
                  <Icon className="" name="chevron-right" />
                </span>
              </div>
            </span>
          </div>
        )}
      />
      {error && <span className="invalid">{error}</span>}
    </div>
  );
};

export default YearMonthPicker;
