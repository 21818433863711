import React from "react";
import { Form, Row } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AnnsModal, Col, FormCheckbox, FormDatePicker } from "../../../components/Component";
import { ShiftCheck, Weekdays } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { addAvailbilityDateApi } from "./AvailbilityData";
import { AppText, showErrorToast, showSuccessToast } from "../../../utils";
import moment from "moment/moment";

const AddAvailbility = ({ visible, carerId, item, onSaved, onClosed, start_Date }) => {
  const { errors, register, handleSubmit } = useForm();
  const [formData, setFormData] = useState({
    selectedShift: null, // Initialize selectedShift in formData
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const shiftCheck = ShiftCheck;

  const [weekDays, setWeekdays] = useState(() =>
    Weekdays.map((weekday) => ({
      ...weekday,
      checked: false, // Set all checkboxes as unchecked by default
      disabled: false,
    }))
  );

  useEffect(() => {
    start_Date && handleDatesChange(start_Date, start_Date);
  }, [start_Date]);

  useEffect(() => {
    if (visible && item) {
      let selectedShifts = {};
      item?.forEach((item) => {
        selectedShifts = item;
      });

      setFormData((prev) => ({
        ...prev,
        selectedShift: selectedShifts,
      }));
    }
  }, [visible, item]);
  const handleDatesChange = (startDate, endDate) => {
    if (startDate > endDate) {
      setFormData((prev) => ({
        ...prev,
        startDate: new Date(startDate),
        endDate: new Date(startDate),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        startDate: new Date(startDate),
        endDate: new Date(endDate), // Use the calculated end date
      }));
    }
    updateWeekdays(startDate, endDate);
  };

  useEffect(() => {
    start_Date && updateWeekdays(start_Date, start_Date);
  }, [start_Date]);

  const updateWeekdays = (startDate, endDate) => {
    const selectedWeekdays = [];
    let currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate)) {
      const dayLabel = currentDate.format("dddd");
      selectedWeekdays.push(dayLabel);
      currentDate.add(1, "days");
    }

    const updatedWeekdays = weekDays.map((weekday) => ({
      ...weekday,
      checked: selectedWeekdays.includes(weekday.label),
      disabled: !selectedWeekdays.includes(weekday.label),
    }));
    setWeekdays(updatedWeekdays);
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    let params = {
      startDate: formData.startDate ? moment(formData.startDate).format("YYYY-MM-DD") : "",
      endDate: formData.endDate ? moment(formData.endDate).format("YYYY-MM-DD") : "",
      carerId: carerId,
      // availableFor: formData.selectedShift?.value ? [formData?.selectedShift?.value] : null, // Include only the selected shift in the availableFor array
      days: weekDays?.filter((item) => item.checked === true)?.map((item) => item.value),
    };

    if (formData.selectedShift?.value) {
      params.availableFor = [formData?.selectedShift?.value];
    } else {
      params.isNotAvailable = true;
    }
    setIsLoading(true);
    addAvailbilityDateApi(params, (data, error) => {
      if (error === null) {
        let text = "Availability" + (data ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const isAnyWeekdayChecked = weekDays.some((item) => item.checked === true);
    if (!formData?.startDate || !formData?.endDate || !formData?.selectedShift || !isAnyWeekdayChecked) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [formData, weekDays]);

  const close = () => {
    setFormData({});
    onClosed();
    setIsLoading(false);
  };

  return (
    <AnnsModal
      size={"md"}
      isOpen={visible}
      toggle={() => close()}
      title={item ? "Edit availability" : "Add availability"}
      onClick={() => onFormSubmit()}
      disabled={isDisable}
      loading={isLoading}
    >
      <Form className="row form-validation">
        <Row>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Start Date</label>
              <div className="form-control-wrap">
                <FormDatePicker
                  selected={formData.startDate}
                  onChange={(start) => {
                    setFormData((prev) => ({
                      ...prev,
                      startDate: start,
                    }));
                    handleDatesChange(start, formData.endDate);
                  }}
                  minDate={new Date()}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">End Date</label>
              <div className="form-control-wrap">
                <FormDatePicker
                  selected={formData?.endDate}
                  onChange={(endDate) => {
                    setFormData((prev) => ({
                      ...prev,
                      endDate: endDate,
                    }));
                    handleDatesChange(formData.startDate, endDate);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12" className=" mb-2">
            <label className="form-label mt-3">Shift</label>
            <div className="form-group">
              <div className="form-control-wrap">
                <ul className="align-center flex-wrap gx-4 gy-2">
                  {shiftCheck?.map((item, index) => (
                    <li key={index}>
                      <FormCheckbox
                        id={"Availability" + index}
                        label={item.label}
                        checked={formData?.selectedShift?.value === item.value}
                        onChange={(e) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            selectedShift: item,
                          }));
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Weekdays</label>
              <ul className="align-center flex-wrap gx-4 gy-2">
                {weekDays.map((weekday, index) => (
                  <li key={index}>
                    <FormCheckbox
                      label={weekday.label}
                      id={weekday.value}
                      name={weekday.value}
                      checked={weekday.checked}
                      disabled={weekday.disabled}
                      onChange={(e) => {
                        const updatedWeekdays = weekDays.map((day) =>
                          day.value === weekday.value ? { ...day, checked: e.target.checked } : day
                        );
                        setWeekdays(updatedWeekdays);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Form>
    </AnnsModal>
  );
};

export default AddAvailbility;
