export const AppText = {
  copyright: `© ${new Date().getFullYear()} ${process.env.REACT_APP_APP_NAME}. All rights reserved.`,
  invalidEmail: "Invalid email address",
  invalidPhone: "Invalid phone number",
  resetYourPassword: "Reset your password",
  newpassword: "New password",
  connectError: "Please check your network connect and try again",
  maxFileSizeError: "You can upload max 100 MB file",
  SUPPORT_EMAIL: "testbeta1701@gmail.com",

  // Success Message
  GradeAddedSuccessfully: "Grade have been added successfully",
  EditedSuccessfully: " has been edited successfully",
  AddedSuccessfully: " has been added successfully",
  SubmittedSuccessfully: " has been submitted successfully",

  // Error Message
};
