import React from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import AddSpeciality from "./AddSpeciality";
import { listSpecialityAPI, specialityTableColumns } from "./SpecialityTableData";
import { useState } from "react";
import { ServerStatusType } from "../../../../utils";
import { useEffect } from "react";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const SpecialityList = () => {
  const [specialityListData, setSpecialityListData] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSpeciality();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status) {
      loadSpeciality();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  function loadSpeciality() {
    if (isCheckToList(UserPermissionType.speciality)) {
      setLoading(true);
      listSpecialityAPI({ status: status?.value ?? ServerStatusType.active }, (list, error) => {
        if (error === null) {
          setSpecialityListData(list);
          setLoading(false);
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Head title="Speciality" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Speciality</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.speciality, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={specialityListData}
              columns={specialityTableColumns(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.speciality)}
            />
          </PreviewCard>
        </Block>
        <AddSpeciality
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadSpeciality();
          }}
        />
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default SpecialityList;
