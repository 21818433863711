import React, { useEffect } from "react";
import { AnnsModal, Col, FormInput, FormInputType, Icon, PhoneInput, Row } from "../../../components/Component";
import { Form, Spinner } from "reactstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import RichTextEditor, { EditorValue } from "react-rte";
// import { bytesToMegaBytes, toolbarConfig } from "../../../../utils/Utils";
import { toolbarConfig } from "../../../utils/Utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const Sms = ({ visible, item, onSaved, onClosed }) => {
  const location = useLocation();
  const selected = location?.state?.data;

  const { errors, register, handleSubmit } = useForm();
  const [smsData, setSmsData] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    console.log("smsData----------->", smsData);
  }, [smsData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        if (Array.isArray(item)) {
          setSmsData({
            ...smsData,
            phoneNumberDetails: { dialCode: item[0]?.countryCode, phoneNumber: item[0]?.phone },
          });
          // setEmailData({
          //   ...emailData,
          //   id: item[0]?.id,
          //   fromEmail: item[0]?.name,
          //   toEmail: item.map((row) => row.email).join(" , "),
          // });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  const close = () => {
    setSmsData({});
    setUploadedFiles([]);
    setRemoveFileIds([]);
    onClosed();
  };

  return (
    <AnnsModal
      size={"md"}
      isOpen={visible}
      toggle={() => close()}
      title={"Sms"}
      // onClick={() => onFormSubmit()}
      // disabled={isDisable}
    >
      <Form className="row gy-4 form-validation" onSubmit={handleSubmit()}>
        <Col md="12">
          <PhoneInput
            required
            phone={smsData?.phoneNumberDetails?.phoneNumber}
            countryCode={smsData?.phoneNumberDetails?.dialCode}
            onChange={(phone) => {
              setSmsData((prev) => ({
                ...prev,
                phoneNumberDetails: phone,
              }));
              // updateData("phoneNumber", phone);
            }}
            onError={(isError) => {
              // updateData("isPhoneError", isError);
            }}
          />
        </Col>

        <Col className="col-12 mb-2">
          <div className="form-group">
            <label className="form-label">
              Sms message
              {/* <span className="text-danger fs-6">*</span> */}
            </label>
            <div className="form-control-wrap">
              <textarea
                className="form-control"
                placeholder="Address"
                value={smsData?.smsMessage}
                onChange={(e) => {
                  setSmsData((prev) => ({
                    ...prev,
                    smsMessage: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </Col>
      </Form>
    </AnnsModal>
  );
};

export default Sms;
