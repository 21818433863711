import React, { useEffect, useImperativeHandle } from "react";

import { Block, ConfirmationAlert, ReactDataTable } from "../../../../../components/Component";
import { useState } from "react";
import { ServerStatusType, showErrorToast, showSuccessToast } from "../../../../../utils";
import AddWard from "./AddWard";
import { WardData, clientWardRemove, listWardAPI, wardTableColumns } from "./WardData";
import { UserPermissionType, isCheckToList } from "../../../../../services/permission/permission";

// const WardList = ({ hospitalId, isOpenAddModel, onCloseModel }, ref) => {
const WardList = React.forwardRef(({ hospitalId, isOpenAddModel, onCloseModel }, ref) => {
  const [ward, setWard] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    loadWard();
    return () => {
      setWard();
      setStatus();
      setModal();
      setSelectedItem();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModal(isOpenAddModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddModel]);

  useEffect(() => {
    if (status) {
      loadWard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  useImperativeHandle(ref, () => ({
    reloadWard: () => {
      loadWard();
    },
  }));

  function loadWard() {
    if (isCheckToList(UserPermissionType.ward)) {
      listWardAPI({ clientId: hospitalId }, (list, error) => {
        if (error === null) {
          setWard(list);
        }
      });
    }
  }

  function onRemove(row) {
    ConfirmationAlert("Removing Ward!", "Do you want to remove ward " + row?.name + "?", () => {
      // This code block will be executed when the user confirms the removal

      clientWardRemove(
        {
          wardId: row?.id,
        },
        (data, error) => {
          if (error === null) {
            showSuccessToast("Ward is removed successfully");
            // history.goBack();
            loadWard();
          } else {
            showErrorToast(error.message);
          }
        }
      );
    });
  }

  return (
    <React.Fragment>
      <Block>
        <ReactDataTable
          data={ward}
          columns={wardTableColumns(onEdit, onRemove)}
          isAllowed={isCheckToList(UserPermissionType.ward)}
        />
      </Block>
      <AddWard
        visible={modal}
        hospitalId={hospitalId}
        item={selectedItem}
        onClosed={() => {
          setModal(false);
          onCloseModel();
          setSelectedItem();
        }}
        onSaved={() => {
          loadWard();
        }}
      />
    </React.Fragment>
  );
});

export default WardList;
