import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  UserAvatar,
} from "../../../components/Component";
import { pendingTimesheetAPI } from "./DashboardData";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PendingTimeSheets = () => {
  const history = useHistory();
  const itemsPerPage = 5;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPendingTimesheets();
  }, [currentPage]);

  function loadPendingTimesheets() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemsPerPage,
      },
    };
    setLoading(true);
    pendingTimesheetAPI(params, (data, error) => {
      if (error === null) {
        setData(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setLoading(false);
    });
  }

  const onMove = (item) => {
    const date = moment(item?.date);
    const startOfWeek = date.clone().startOf("isoWeek"); // Monday as start of the week
    const endOfWeek = date.clone().endOf("isoWeek"); // Sunday as end of the week

    history.push({
      pathname: `${process.env.PUBLIC_URL}/timesheet`,
      state: {
        data: {
          startDate: startOfWeek.format("YYYY-MM-DD"),
          endDate: endOfWeek.format("YYYY-MM-DD"),
        },
        isMoveTimesheet: true,
      },
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="me-2">Pending Timesheets</span>
            </h6>
          </CardTitle>
        </div>
      </div>

      {loading ? (
        <div className="spinner-container">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : data && data.length > 0 ? (
        <DataTableBody className="border-top" bodyclass="nk-tb-orders">
          <DataTableHead>
            <DataTableRow size="sm">
              <span>Carer</span>
            </DataTableRow>
            <DataTableRow>
              <span>Client</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Date</span>
            </DataTableRow>
          </DataTableHead>
          {data.map((item, idx) => (
            <DataTableItem key={idx} className={"cursor-pointer"} onClick={() => onMove(item)}>
              <DataTableRow size="sm">
                <div className="user-card">
                  <UserAvatar
                    size="sm"
                    theme={item?.theme}
                    customHex={item?.carer?.colourHex}
                    text={`${item?.carer?.firstName?.charAt(0) || ""}${
                      item?.carer?.lastName?.charAt(0) || ""
                    }`.toUpperCase()}
                    image={item?.carer?.profileImg?.imageUrl ?? ""}
                    name={item?.carer?.firstName + " " + item?.carer?.lastName}
                    // subName={item?.carer?.email}
                  />
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{item?.client?.name}</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="tb-sub">{moment(item?.date).format("DD-MM-YYYY")}</span>
              </DataTableRow>
            </DataTableItem>
          ))}
        </DataTableBody>
      ) : (
        <div className="border-top">
          <PreviewAltCard className="m-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </div>
      )}

      {totalItems > 5 && (
        <div className="mx-4">
          <PaginationComponent
            itemPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default PendingTimeSheets;
