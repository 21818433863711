import { RSelect } from "../Component";
import { useState, useEffect, useRef } from "react";
import { convertYearsDropValue } from "../../utils/Functions";

const YearsDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  carerTypeId,
  numberOfPastYears,
  numberOfFutureYears,
  minDate,
  maxDate,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const selectRef = useRef(null);

  useEffect(() => {
    generateArrayOfYears();
  }, []);

  function generateArrayOfYears() {
    const currentYear = new Date().getFullYear();

    let minYear = currentYear - (numberOfPastYears || 25);
    let maxYear = currentYear + (numberOfFutureYears || 25);

    if (minDate || maxDate) {
      const minDateYear = minDate ? new Date(minDate).getFullYear() : currentYear - 25;
      const maxDateYear = maxDate ? new Date(maxDate).getFullYear() : currentYear + 25;

      minYear = Math.max(minYear, minDateYear);
      maxYear = Math.min(maxYear, maxDateYear);
    }

    const years = [];

    for (let i = minYear; i <= maxYear; i++) {
      let object = {
        name: i,
      };
      years.push(convertYearsDropValue(object));
    }
    setList(years);
  }

  const handleSearch = (text) => {
    setSearchString(text);
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("react-select__option--is-selected")[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 15);
  };

  return (
    <RSelect
      ref={selectRef}
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onInputChange={handleSearch}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      onMenuOpen={onMenuOpen}
      // className={"MyDropdown"}
      classNamePrefix={"react-select"}
      {...props}
    />
  );
};

export default YearsDropDown;
