import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DATE_FORMAT, showErrorToast, showSuccessToast } from "../../../utils";
import {
  AgencyDropdown,
  AnnsModal,
  Col,
  ConfirmationAlert,
  ErrorText,
  FormCheckbox,
  FormInput,
  FormInputType,
  Row,
  UserAvatar,
} from "../../../components/Component";
import { ColorsArray } from "../../../utils/Utils";
import { toCapitalize } from "../../../utils/Functions";
import { listRateAPI } from "../rates/RatesData";
import { checkCarerAvailabilityAPI, jobCarerAssignAPI } from "./JobTableData";
import moment from "moment";

const AssignJobModal = ({
  visible,
  onClosed,
  selectedCarer,
  jobDetailData,
  jobDetailId,
  loadJobDetail,
  selectedAppliedCarer,
}) => {
  const [selectedAgency, setSelectedAgency] = useState();
  const [rateData, setRateData] = useState({ dayTypeRates: [] });
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [checkCarerAvailability, setCheckCarerAvailability] = useState();
  const [showEnhancedRate, setShowEnhancedRate] = useState(false); // State to track checkbox status
  const [enhanceRate, setEnhanceRate] = useState();

  useEffect(() => {
    if (visible) {
      if (jobDetailData?.client) {
        loadRate();
      } else {
        setRateData();
      }
      (selectedCarer || selectedAppliedCarer) && checkCarerAvailabity();
    }
  }, [visible, jobDetailData, selectedCarer, selectedAppliedCarer]);

  useEffect(() => {
    const hasInvalidRate = rateData?.dayTypeRates?.some((dayType, dayTypeIndex) => {
      return hasInvalidChargeRate(rateData?.dayTypeRates?.[dayTypeIndex]);
    });
    if (
      jobDetailData?.client === null ||
      // jobDetailData?.carerType === null ||
      // jobDetailData?.grade === null ||
      selectedCarer?.id === null ||
      rateData?.dayTypeRates?.length === 0 ||
      hasInvalidRate ||
      !selectedAgency ||
      !areDayTypeRatesFilled() ||
      (showEnhancedRate && (!enhanceRate || enhanceRate === ""))
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [jobDetailData, rateData, selectedAgency, selectedCarer, showEnhancedRate, enhanceRate]);

  const hasInvalidChargeRate = (dayTypeRate) => {
    const chargeRate = parseFloat(String(dayTypeRate?.chargeRate).replace(/,/g, "")) || 0;
    const payRate = parseFloat(String(dayTypeRate?.payRate).replace(/,/g, "")) || 0;
    return chargeRate - payRate < 0;
  };

  const areDayTypeRatesFilled = () => {
    const filledRates = rateData?.dayTypeRates?.filter(
      (dayTypeRate) =>
        dayTypeRate?.chargeRate !== null &&
        dayTypeRate?.chargeRate !== undefined &&
        dayTypeRate?.chargeRate !== "" &&
        dayTypeRate?.payRate !== null &&
        dayTypeRate?.payRate !== undefined &&
        dayTypeRate?.payRate !== ""
    );

    return filledRates?.length === rateData?.dayTypeRates?.length;
  };

  // ****************** API *************** //
  function loadRate() {
    listRateAPI(
      {
        clientId: jobDetailData?.client?.id,
        carerTypeId: jobDetailData?.carerType?.id,
        gradeId: jobDetailData?.grade?.id,
        // clientId: hospitalId,
        carerId: selectedCarer ? selectedCarer?.id : selectedAppliedCarer ? selectedAppliedCarer?.id : null,
      },
      (list, error) => {
        if (error === null) {
          setRateData((prevJobData) => ({
            ...prevJobData,
            dayTypeRates: list?.list.map((dayTypeRate) => ({
              ...dayTypeRate,
              chargeRate: dayTypeRate.chargeRate, // Convert to string
              payRate: dayTypeRate.payRate, // Convert to string
            })),
          }));
        }
      }
    );
  }

  const handleSelectCarer = (carer, agency, rate) => {
    ConfirmationAlert("Assign Carer!", "Do you want to assign " + carer.label + "?", () => {
      // const updatedJobData = {
      //   ...jobDetailData,
      //   assignedJobCarerData: [...jobDetailData.assignedJobCarerData, carer],
      // };
      setLoading(true);
      jobCarerAssignAPI(carer, jobDetailId, agency, rate, enhanceRate, (_, error) => {
        setLoading(false);
        if (error === null) {
          let text = "Job Assigned successfully";
          // setJobDetailData(updatedJobData);
          // loadJobCarerList(jobDetailId);
          loadJobDetail(jobDetailId);
          onClosed();
          showSuccessToast(text);
        } else {
          showErrorToast(error.message);
        }
      });
    });
  };

  const checkCarerAvailabity = () => {
    let params = {
      carerId: selectedCarer ? selectedCarer?.id : selectedAppliedCarer ? selectedAppliedCarer?.id : null,
      fromDate: jobDetailData?.fromDate,
      toDate: jobDetailData?.toDate,
    };
    checkCarerAvailabilityAPI(params, (data, error) => {
      if (error === null) {
        setCheckCarerAvailability(data?.list);
      } else {
        showErrorToast(error.message);
      }
    });
  };

  const close = () => {
    onClosed();
    setLoading(false);
    setSelectedAgency();
    setRateData({});
  };

  const enhanceRateSave = (rate) => {
    // rate = rate.replace(/\D/g, ""); // Remove any non-numeric characters
    // rate = rate.slice(0, 5); // Limit the rate to a maximum of 5 digits
    setEnhanceRate(rate);
  };

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => close()}
        title={"Assign"}
        onClick={() =>
          handleSelectCarer(
            selectedCarer ? selectedCarer : selectedAppliedCarer ? selectedAppliedCarer : "",
            selectedAgency,
            rateData
          )
        }
        disabled={isDisable}
        loading={loading}
      >
        <div>
          {checkCarerAvailability?.some((item) => item.rotaDate) && (
            <ErrorText className="text-center mt-n1">
              Carer is already assigned to another job on {moment(jobDetailData?.fromDate).format(DATE_FORMAT)}
            </ErrorText>
          )}
          {selectedCarer ? (
            <>
              <div className="">
                <UserAvatar
                  theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                  text={(selectedCarer?.label ? selectedCarer?.label.slice(0, 2) : "  ").toUpperCase()}
                  name={toCapitalize(selectedCarer?.label ?? " ")}
                  email={selectedCarer?.email}
                  phoneNumber={selectedCarer?.phone}
                ></UserAvatar>
              </div>
            </>
          ) : (
            <>
              {selectedAppliedCarer ? (
                <>
                  <div className="d-flex">
                    <UserAvatar
                      theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                      text={(selectedAppliedCarer.name ? selectedAppliedCarer.name.slice(0, 2) : "  ").toUpperCase()}
                      name={toCapitalize(selectedAppliedCarer?.name ?? " ")}
                      email={selectedAppliedCarer?.email ?? "N/A"}
                      phoneNumber={selectedAppliedCarer?.phone}
                    ></UserAvatar>
                  </div>
                </>
              ) : (
                <div className="text-center">Carer is not applied yet.</div>
              )}
            </>
          )}
          <div className="mt-4">
            <AgencyDropdown
              // disable={!selectedCarer}
              value={selectedAgency ?? ""}
              onSelect={(e) => {
                // handleSelectCarer(e);
                setSelectedAgency(e);
              }}
            />
          </div>
          {rateData?.dayTypeRates?.length > 0 ? (
            <div className="mt-4">
              <Row>
                <Col size="4">
                  <label className="form-label">Day Type</label>
                </Col>
                <Col size="4">
                  <label className="form-label">Pay Rate</label>
                </Col>
                <Col size="4">
                  <label className="form-label">Charge Rate</label>
                </Col>
              </Row>

              {rateData?.dayTypeRates?.map((dayType, dayTypeIndex) => {
                // let rate = rateData?.dayTypeRates?.find((item) => item?.dayTypeId === dayType?.id);
                // let index = rateData?.dayTypeRates?.findIndex((item) => item?.dayTypeId === dayType?.id) ?? -1;
                return (
                  <Row className="align-items-baseline" key={dayTypeIndex}>
                    <Col size="4">
                      <div className="my-3">
                        <label>{dayType?.dayType?.name}</label>
                      </div>
                    </Col>

                    <Col size="4">
                      <FormInput
                        baseClass={"m-0"}
                        placeholder={"Pay Rate"}
                        type={FormInputType.amount}
                        value={dayType?.payRate ?? null}
                        onTextChange={(payRate) => {
                          // payRate = parseFloat(payRate) || null;
                          let list = rateData?.dayTypeRates ? [...rateData.dayTypeRates] : []; // Initialize list as an empty array
                          let dayTypeData = dayTypeIndex !== -1 ? list[dayTypeIndex] : {};
                          dayTypeData.payRate = payRate;
                          dayTypeData.dayTypeId = dayType.id;
                          dayTypeIndex !== -1 ? (list[dayTypeIndex] = dayTypeData) : list.push(dayTypeData);
                          setRateData((prev) => ({
                            ...prev,
                            dayTypeRates: list,
                          }));
                        }}
                      />
                    </Col>
                    <Col size="4">
                      <FormInput
                        baseClass={"m-0"}
                        placeholder={"Charge Rate"}
                        type={FormInputType.amount}
                        value={dayType?.chargeRate ?? null}
                        onTextChange={(chargeRate) => {
                          // chargeRate = parseFloat(chargeRate) || null;
                          let list = rateData?.dayTypeRates ? [...rateData.dayTypeRates] : []; // Initialize list as an empty array
                          let dayTypeData = dayTypeIndex !== -1 ? list[dayTypeIndex] : {};
                          dayTypeData.chargeRate = chargeRate;
                          dayTypeData.dayTypeId = dayType.id;
                          dayTypeIndex !== -1 ? (list[dayTypeIndex] = dayTypeData) : list.push(dayTypeData);
                          setRateData((prev) => ({
                            ...prev,
                            dayTypeRates: list,
                          }));
                        }}
                      />
                      <span>
                        {hasInvalidChargeRate(rateData?.dayTypeRates?.[dayTypeIndex]) && (
                          <span className="invalid">Charge rate should not be less than pay rate</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <ErrorText>{"Client does not has rate list for selected info."}</ErrorText>
          )}
        </div>
        {rateData?.dayTypeRates?.length > 0 && (
          <div>
            <Row>
              <Col size="8" className={"align-center"}>
                <div className="mt-3 d-flex">
                  <label>Do you want to enhance the rate?</label>
                  <span className="ms-3">
                    <FormCheckbox
                      checked={showEnhancedRate} // Pass checked state to the checkbox
                      onChange={(e) => setShowEnhancedRate(e.target.checked)} // Update state when checkbox changes
                    />
                  </span>
                </div>
              </Col>
              <Col size="4" className={"height-44"}>
                {showEnhancedRate && (
                  <div className="mt-2">
                    <FormInput
                      baseClass={"m-0"}
                      placeholder={"Enhance Rate"}
                      maxLength={5}
                      type={FormInputType.amount}
                      value={enhanceRate ?? ""}
                      onTextChange={(rate) => {
                        enhanceRateSave(rate);
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </AnnsModal>
    </>
  );
};

export default AssignJobModal;
