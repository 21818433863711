import React from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  ConfirmationAlert,
  DocumentDropDown,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  IconButton,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";
import { addCarerTypeAPI } from "./CarerTypeData";

const AddCarerType = ({ visible, item, onSaved, onClosed }) => {
  const [carerTypeData, setCarerTypeData] = useState({});
  const [documentField, setDocumentField] = useState([{ documentTypeId: null, isMandatory: undefined }]);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  // useEffect(() => {
  //   if ((carerTypeData?.title?.length ?? 0) === 0 ) {
  //     setIsDisable(true);
  //   } else {
  //     setIsDisable(false);
  //   }
  // }, [carerTypeData]);
  useEffect(() => {
    if ((carerTypeData?.title?.length ?? 0) === 0 || documentField.some((doc) => doc.isMandatory === undefined)) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [carerTypeData, documentField]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setCarerTypeData({
          ...carerTypeData,
          id: item?.id,
          title: item?.title,
          isActive: item?.isActive || false,
        });
        setDocumentField(
          item?.documentField?.map((doc) => ({
            docId: doc?.id,
            documentTypeId: doc?.documentTypeId,
            isMandatory: doc?.isMandatory,
          })) || []
        );
      } else {
        setCarerTypeData({
          ...carerTypeData,
          title: "",
          isActive: true,
        });
        setDocumentField([{ documentTypeId: null, isMandatory: undefined }]);
      }
    }
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setCarerTypeData({});
  };

  const addDocumentFields = () => {
    setDocumentField([...documentField, { documentTypeId: null, isMandatory: undefined }]);
  };

  const handleRemoveDocumentField = (index) => {
    ConfirmationAlert("Are you sure!", "Do you want to cancel document" + "?", () => {
      setDocumentField((prevState) => {
        const updatedFields = [...prevState];
        updatedFields.splice(index, 1);
        return updatedFields;
      });
    });
  };

  useEffect(() => {
    setCarerTypeData((prevData) => ({
      ...prevData,
      documentField: documentField,
    }));
  }, [documentField]);

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addCarerTypeAPI(carerTypeData, (_, error) => {
      if (error === null) {
        let text =
          "Carer Type" + " " + carerTypeData?.title + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => onClosed()}
        loading={loading}
        title={item ? "Edit Carer Type" : "Add Carer Type"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Carer"}
                  type={FormInputType.default}
                  value={carerTypeData.title}
                  onTextChange={(text) => {
                    setCarerTypeData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="5">
            <label className="form-label">Document</label>
          </Col>
          <Col md="5">
            <label className="form-label">Mandatory</label>
          </Col>
          <Col md="2" className="text-end">
            <span className="form-control-wrap  top-n5 right-14 text-end mx-auto me-n4">
              <IconButton type="button" color="success" icon="plus" size={"sm"} onClick={addDocumentFields} />
            </span>
          </Col>
          {documentField.map((person, index) => (
            <React.Fragment key={index}>
              <Col md="5" className="my-2">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <DocumentDropDown
                      placeholder={"Select document"}
                      value={person.documentTypeId}
                      onSelect={(document) => {
                        const updatedFields = [...documentField];
                        updatedFields[index].documentTypeId = document;
                        setDocumentField(updatedFields);
                      }}
                      selectedId={person.documentTypeId}
                    />
                  </div>
                </div>
              </Col>
              <Col md="5" className="my-3 ">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <RadioButtonOptions
                      optionType={RadioOptionTypes.yesorno}
                      name={"expiry-date" + index}
                      onChange={(selectedIndex) => {
                        const updatedFields = [...documentField];
                        updatedFields[index].isMandatory = selectedIndex;
                        setDocumentField(updatedFields);
                      }}
                      activeIndex={person.isMandatory}
                    />
                  </div>
                </div>
              </Col>
              <Col md="2" className="text-end  my-3 ">
                {index > 0 && (
                  <span className="form-control-wrap top-n5 right-14 text-end mx-auto me-n4">
                    <IconButton
                      icon="minus"
                      size={"sm"}
                      color="danger"
                      onClick={() => handleRemoveDocumentField(index)}
                    ></IconButton>
                  </span>
                )}
              </Col>
            </React.Fragment>
          ))}

          <Col md="12">
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setCarerTypeData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={carerTypeData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddCarerType;
