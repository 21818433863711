import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  BackButton,
  LoadingComponent,
  AvailableCarersDropDown,
  ActionButton,
  UserAvatar,
} from "../../../components/Component";
import { useLocation } from "react-router";
import { getJobDetailAPI, jobCarerAPI } from "./JobTableData";
import { TIME_FORMAT_GLOBAL, dateToShow } from "../../../utils";
import moment from "moment";
import Head from "../../../layout/head/Head";
import { useHistory } from "react-router";
import { ColorsArray, JobStatusType } from "../../../utils/Utils";
import { convertHexToColor, covertFullDayName, toCapitalize } from "../../../utils/Functions";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import AssignJobModal from "./AssignJobModal";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

const JobDetail = ({}) => {
  const location = useLocation();
  const jobDetailId = location.state?.data;
  const [jobDetailData, setJobDetailData] = useState();
  const [initScreen, setInitScreen] = useState(true);
  const history = useHistory();
  const [selectedCarer, setSelectedCarer] = useState();
  const [selectedAppliedCarer, setSelectedAppliedCarer] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [rateData, setRateData] = useState({ dayTypeRates: [] });
  const [isDisable, setIsDisable] = useState(true);
  const [appliedDisable, setAppliedDisable] = useState(false);
  const [assignJobModal, setAssignJobModal] = useState(false);

  useEffect(() => {
    if (jobDetailId) {
      loadJobDetail(jobDetailId);
      loadJobCarerList(jobDetailId);
    }
  }, [jobDetailId]);

  useEffect(() => {
    if (
      // jobDetailData?.client === null
      // jobDetailData?.carerType === null
      // jobDetailData?.grade === null ||
      selectedCarer === undefined ||
      selectedCarer === null ||
      selectedCarer === ""
      // rateData?.dayTypeRates?.length === 0 ||
      // hasInvalidRate ||
      // !selectedAgency ||
      // !areDayTypeRatesFilled()
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [selectedCarer]);

  useEffect(() => {
    if (jobDetailData?.assignedJobCarerData?.length > 0) {
      setAppliedDisable(true);
    } else {
      setAppliedDisable(false);
    }
  }, [jobDetailData]);

  function loadJobDetail(jobDetailId) {
    getJobDetailAPI({ jobId: jobDetailId }, (data, error) => {
      if (error === null) {
        setJobDetailData(data);
        loadJobCarerList(jobDetailId);
      }
    });
  }

  function loadJobCarerList(jobDetailId) {
    jobCarerAPI({ jobId: jobDetailId }, (data, error) => {
      if (error === null) {
        setJobDetailData((prev) => ({
          ...prev,
          assignedJobCarerData: data?.assigned,
          appliedJobCarer: data?.applied,
        }));
      }
      setInitScreen(false);
    });
  }

  const onEditClick = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-job`,
      state: { data: jobDetailData }, // Pass the selected item as state
    });
  };

  const onMoveAuditTrail = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/job-audit-trail`,
      state: { data: jobDetailData }, // Pass the selected item as state
    });
  };

  const handleAcceptButton = (item) => {
    setSelectedAppliedCarer(item);
    setAssignJobModal(true);
  };

  let keys = Object.keys(jobDetailData?.extraData ?? {});

  return (
    <React.Fragment>
      <Head title={"Job Detail"} />
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle page>
                {/* KYCs / <strong className="text-primary small">{user.name}</strong> */}Job Detail
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <ActionButton title="Audit Trail" size="md" onClick={() => onMoveAuditTrail()} />
                </span>

                {(jobDetailData?.assignedJobCarerData && jobDetailData?.assignedJobCarerData.length === 0) ||
                jobDetailData?.status === JobStatusType.open ? (
                  <span className="me-2">
                    <ActionButton title="Edit" size="md" onClick={() => onEditClick()} />
                  </span>
                ) : null}

                <span>
                  <BackButton />
                </span>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="gy-5">
            <Col className="col-12 col-lg-7 col-xl-8">
              <Card className="card-bordered mt-2 gy-2">
                <div className="px-4 d-flex align-items-center justify-content-between bg-greenBright">
                  <span className=" job-detail-title text-white">Request Id -#{jobDetailData?.refId}</span>
                </div>
                <Row className="d-flex px-4 py-4 ">
                  <Col size="6" className="vertical-line">
                    <UserAvatar
                      className="user-avatar-size"
                      avatarText="useravatar-text"
                      text={(jobDetailData?.client?.label
                        ? jobDetailData?.client?.label.slice(0, 2)
                        : "  "
                      ).toUpperCase()}
                      name={jobDetailData?.client?.label}
                      subName={jobDetailData?.ward?.name}
                    />
                  </Col>
                  <Col size="6">
                    <Row className="text-center">
                      <div></div>
                      <Col size="6" className="mb-2 vertical-line">
                        <div className="">
                          <div className="job-detail-fw-bold">{"Start Date"}</div>
                          <div className="text-dark">{dateToShow(jobDetailData?.fromDate)}</div>
                        </div>
                      </Col>
                      <Col size="6" className="mb-2 ">
                        <div className="">
                          <div className="job-detail-fw-bold">{"End Date"}</div>
                          <div className="text-dark">{dateToShow(jobDetailData?.toDate)}</div>
                        </div>
                      </Col>
                      <Col size="6" className="vertical-line">
                        <div className="job-detail-fw-bold">{"Start Time"}</div>
                        <div className="text-dark">
                          {jobDetailData?.startTime ? moment(jobDetailData?.startTime).format(TIME_FORMAT_GLOBAL) : ""}
                        </div>
                      </Col>
                      <Col size="6">
                        <div className="job-detail-fw-bold">{"End Time"}</div>
                        <div className="text-dark">
                          {jobDetailData?.endTime ? moment(jobDetailData?.endTime).format(TIME_FORMAT_GLOBAL) : ""}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className=" bg-light text-center mx-3 p-0 g-0">
                  <span className={"job-detail-title job-detail-height text-black"}>Other Details</span>
                </div>
                <div className="">
                  <Row className="gy-2 px-4 py-4 text-center ">
                    <Col size="3" className={"vertical-line"}>
                      <div className="job-detail-fw-bold">{"Client"}</div>
                      <div className="text-dark">{jobDetailData?.client?.label}</div>
                    </Col>
                    <Col size="3" className="vertical-line">
                      <div className="job-detail-fw-bold">{"Ward"}</div>
                      <div className="text-dark">{jobDetailData?.ward?.name}</div>
                    </Col>
                    <Col size="3" className="vertical-line">
                      <div className="job-detail-fw-bold">{"Carer Type"}</div>
                      <div className="text-dark">{jobDetailData?.carerType?.label}</div>
                    </Col>
                    <Col size="3">
                      <div className="job-detail-fw-bold">{"Day"}</div>
                      <div className="text-dark">{covertFullDayName(jobDetailData?.viewListDays)}</div>
                    </Col>
                  </Row>
                  <hr className="mx-3" />
                  <Row className="gy-2 px-4 py-1 text-center ">
                    <Col size="3" className="vertical-line">
                      <div className="job-detail-fw-bold">{"Grade"}</div>
                      <div className="text-dark">
                        {
                          <GradeBadge
                            label={jobDetailData?.grade?.label}
                            color={`outline-${convertHexToColor(jobDetailData?.grade?.colourHex)}`}
                          />
                        }
                      </div>
                    </Col>
                    <Col size="3" className="vertical-line">
                      <div className="job-detail-fw-bold">{"Speciality"}</div>
                      <div className="text-dark">
                        {jobDetailData?.specialities?.map((speciality, id) => speciality.label).join(" | ")}
                      </div>
                    </Col>
                    <Col size="3" className={"vertical-line"}>
                      <div className="job-detail-fw-bold">{"Shift Type"}</div>
                      <div className="text-dark">{jobDetailData?.shiftType?.label}</div>
                    </Col>
                    {jobDetailData?.agency && (
                      <Col size="3">
                        <div className="job-detail-fw-bold">{"Agency"}</div>
                        <div className="text-dark">{jobDetailData?.agency?.name}</div>
                      </Col>
                    )}
                  </Row>
                  {jobDetailData?.enhanceRate && (
                    <>
                      <hr className="mx-3" />
                      <Row className="gy-2 px-4 py-1 text-center ">
                        <Col size="3">
                          <div className="job-detail-fw-bold">{"Agency"}</div>
                          <div className="text-dark">{jobDetailData?.enhanceRate}</div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <hr className="mx-3" />
                  <Row className="gy-2 px-4 text-center">
                    {keys
                      .filter(
                        (key) =>
                          key !== "Grade:" &&
                          key !== "Start:" &&
                          key !== "End:" &&
                          key !== "Date:" &&
                          key !== "Ward:" &&
                          key !== "vacancyReference" &&
                          key !== "startTime" &&
                          key !== "startDate" &&
                          key !== "endTime" &&
                          key !== "endDate" &&
                          key !== "bookingLimit" &&
                          key !== "client" &&
                          key !== "businessUnit" &&
                          jobDetailData?.extraData[key] !== undefined &&
                          jobDetailData?.extraData[key] !== ""
                      )
                      .map((key, index) => {
                        let keyName = key.replace(":", "");
                        return (
                          <>
                            <Col size="3" className={(index + 1) % 4 !== 0 ? "vertical-line" : ""}>
                              <div className="job-detail-fw-bold">{toCapitalize(keyName)}</div>
                              <div className="text-dark">{jobDetailData?.extraData[key]}</div>
                            </Col>
                            {(index + 1) % 4 === 0 && <hr className="mx-3" />}
                          </>
                        );
                      })}
                  </Row>

                  <Row className="px-4 pt-4">
                    <div>
                      <span className="text-danger fs-6 me-1">*</span>
                      <span>
                        Last updated on {dateToShow(jobDetailData?.createdDate)} by{" "}
                        {`${jobDetailData?.jobCreateType === "MANUAL" ? jobDetailData?.createdBy : "Email Parsing"}`}.
                      </span>
                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col className="col-12 col-lg-5 col-xl-4">
              <Card className="card-bordered mt-2 gy-2 mb-28 ">
                <div className="px-4 d-flex align-items-center justify-content-between  bg-green">
                  <span className=" job-detail-title text-white">Assigned</span>
                </div>
                <div className="border-card-end">
                  <div className="gy-2 px-4 py-3 ">
                    {jobDetailData?.assignedJobCarerData && jobDetailData?.status !== JobStatusType.booked ? (
                      <ul>
                        <li className="mb-2">
                          <AvailableCarersDropDown
                            isClearable
                            disabled={
                              !jobDetailData?.client || !isAllowToAccess(UserPermissionType.job, UserActionType.add)
                            }
                            jobId={jobDetailData?.jobId}
                            startDate={jobDetailData?.fromDate}
                            endDate={jobDetailData?.toDate}
                            gradeId={jobDetailData?.grade?.id}
                            clientId={jobDetailData?.client?.id}
                            daysFor={jobDetailData?.serverDays}
                            value={jobDetailData?.carers}
                            onSelect={(e) => {
                              setSelectedCarer(e);
                              setSelectedAgency();
                            }}
                          />
                        </li>
                        <li className="mb-2">
                          <ActionButton disabled={isDisable} title={"Submit"} onClick={() => setAssignJobModal(true)} />
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                    <div>
                      {jobDetailData?.assignedJobCarerData &&
                        jobDetailData?.assignedJobCarerData.length > 0 &&
                        jobDetailData.assignedJobCarerData.map((carer, index) => (
                          <>
                            <div className="" key={index}>
                              <UserAvatar
                                theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                                text={(carer.name ? carer.name.slice(0, 2) : "  ").toUpperCase()}
                                name={toCapitalize(carer.name ?? " ")}
                                email={carer?.email}
                                phoneNumber={carer?.phone}
                              ></UserAvatar>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="card-bordered mt-2 gy-2">
                <div className="px-4 d-flex align-items-center justify-content-between bg-purple ">
                  <span className=" job-detail-title text-white">Applied</span>
                </div>
                <div className="gy-2 px-4 py-3 ">
                  <div>
                    {jobDetailData?.appliedJobCarer && jobDetailData?.appliedJobCarer.length > 0 ? (
                      jobDetailData?.appliedJobCarer.map((appliedCarer, index) => (
                        <>
                          {index !== 0 && <hr />}
                          <div className="d-flex" key={index}>
                            <UserAvatar
                              theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
                              text={(appliedCarer.name ? appliedCarer.name.slice(0, 2) : "  ").toUpperCase()}
                              name={toCapitalize(appliedCarer?.name ?? " ")}
                              email={appliedCarer?.email}
                              phoneNumber={appliedCarer?.phone}
                            ></UserAvatar>
                            <span className="">
                              <ActionButton
                                title="Accept"
                                size="sm"
                                onClick={() => handleAcceptButton(appliedCarer)}
                                disabled={
                                  appliedDisable || !isAllowToAccess(UserPermissionType.job, UserActionType.add)
                                }
                              />
                            </span>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="text-center">Carer is not applied yet.</div>
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
      <AssignJobModal
        loadJobDetail={loadJobDetail}
        selectedCarer={selectedCarer}
        jobDetailData={jobDetailData}
        jobDetailId={jobDetailId}
        visible={assignJobModal}
        selectedAppliedCarer={selectedAppliedCarer}
        onClosed={() => {
          setSelectedCarer();
          setSelectedAppliedCarer();
          setAssignJobModal(false);
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};
export default JobDetail;
