import { Badge } from "reactstrap";
import { TimesheetStatusType } from "../../utils";

export const StatusBadge = ({ className, status, ...props }) => {
  let color = status ? "success" : "gray";
  let text = status ? "Active" : "Inactive";
  return (
    <Badge color={color} className="fs-14px fw-light">
      {text}
    </Badge>
  );
};
export const TrustStatusBadge = ({ className, status, ...props }) => {
  let color = status ? "success" : "danger";
  let text = status ? "Active" : "Inactive";
  return (
    <Badge color={color} className="fs-14px fw-light">
      {text}
    </Badge>
  );
};
export const CarerDocumentStatusBadge = ({ className, status, expiryDate, ...props }) => {
  let color;
  let text;

  const today = new Date();
  const expiry = new Date(expiryDate);

  const diffInDays = Math.ceil((expiry - today) / (1000 * 60 * 60 * 24));
  if (!expiryDate) {
    text = "N/A";
    color = "36454F";
  } else if (expiry < today) {
    color = "ED4D4D";
    text = "Expired";
  } else if (diffInDays <= 30) {
    color = "F4BD0E";
    text = "Expiring Soon";
  } else {
    color = "6AAB4C";
    text = "Active";
  }

  return (
    <Badge
      color={`#${color}`}
      style={{ backgroundColor: `#${color}` }}
      className={`fs-14px fw-light carerdetail-document-statusbadge ${className}`}
    >
      <span className="text-status-timesheet-badge text-white px-1">{text}</span>
    </Badge>
  );
};

export const TimesheetStatusBadge = ({ className, status, ...props }) => {
  let color;
  let text;

  switch (status) {
    case TimesheetStatusType.pending:
      color = "warning";
      text = "Pending";
      break;
    case TimesheetStatusType.approved:
      color = "success";
      text = "Approved";
      break;
    case TimesheetStatusType.verified:
      color = "primary";
      text = "Verified";
      break;
    case TimesheetStatusType.submitted:
      color = "info";
      text = "Submitted";
      break;
    case TimesheetStatusType.cancelled:
      color = "light";
      text = "Cancelled";
      break;
    case TimesheetStatusType.reject:
      color = "gray";
      text = "Rejected";
      break;
    default:
      color = "gray";
      text = "Unknown";
      break;
  }

  return (
    <Badge color={color} className={`fs-14px fw-light p-1 ${className}`}>
      <span className="text-status-timesheet-badge">{text}</span>
    </Badge>
  );
};
