import React, { useState } from "react";

import { ConfirmationAlert, ReactDataTable } from "../../../components/Component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { listCarerNoteAPI, listClientNoteAPI, noteData, noteTableData } from "./NoteData";
import { PER_PAGE_ITEMS } from "../../../utils";
import { useEffect } from "react";
import AddNotes from "./AddNote";
import { UserPermissionType, isCheckToList } from "../../../services/permission/permission";
export const NoteUserType = {
  hospital: "hospital",
  carer: "carer",
};

const NoteList = ({ parent, type, isOpenAddModel, onCloseModel }) => {
  const [noteList, setNoteList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItem, setSelectedItem] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(isOpenAddModel);
  }, [isOpenAddModel]);

  useEffect(() => {
    if (type === NoteUserType.hospital) {
      loadClientNote();
    } else if (type === NoteUserType.carer) {
      loadCarerNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, type]);

  const onEdit = (row) => {
    setSelectedItem(row);
    setModal(true);
  };

  function loadClientNote() {
    if (type === NoteUserType.carer) return;
    if (isCheckToList(UserPermissionType.note)) {
      listClientNoteAPI(
        {
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          clientId: parent?.id,
        },
        (list, error) => {
          if (error === null) {
            setNoteList(list?.list);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    }
  }

  function loadCarerNote() {
    if (type === NoteUserType.hospital) return;
    if (isCheckToList(UserPermissionType.note)) {
      listCarerNoteAPI(
        {
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          carerId: parent.id,
        },
        (list, error) => {
          if (error === null) {
            setNoteList(list?.list);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    }
  }

  return (
    <React.Fragment>
      <ReactDataTable
        data={noteList}
        columns={noteData(onEdit)}
        isAllowed={isCheckToList(UserPermissionType.note)}
        pagination
        totalItemCount={totalItems}
        onPageChanged={(page, count) => {
          setCurrentPage(page);
        }}
      />
      <AddNotes
        visible={modal}
        item={selectedItem}
        parents={[parent]}
        type={type}
        onClosed={() => {
          setModal(false);
          onCloseModel();
          setSelectedItem();
        }}
        onSaved={() => {
          type === NoteUserType.carer && loadCarerNote();
          type === NoteUserType.hospital && loadClientNote();
        }}
      />
    </React.Fragment>
  );
};

export default NoteList;
