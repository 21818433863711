import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { hospitalDropDownData } from "./DropDownData";
import { RSelect } from "../Component";

export const HospitalDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  isClearable,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  disabled,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    return () => {
      setList();
      setSearchString();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadHospitalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const loadHospitalList = () => {
    hospitalDropDownData(searchString, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        }
      }
    });
  };

  const handleSearch = (text) => {
    setSearchString(text);
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={"Search Client"}
      disable={disabled}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onInputChange={handleSearch}
      isClearable={isClearable}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
