import React from "react";
import {
  ActionButton,
  AnnsModal,
  BranchDropDown,
  Col,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  GenderDropDown,
  PhoneInput,
  RadioButtonOptions,
  RoleDropDown,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { AppText, RadioOptionTypes, formatPhoneNumber } from "../../../../utils";
import { showErrorToast, showSuccessToast } from "../../../../utils/Functions";
import { addUserAPI } from "./UserData";

const AddUser = ({ visible, item, onSaved, onClosed }) => {
  const [userData, setUserData] = useState({
    // role: [item?.roleIds[0].id],
  });
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (visible && item) {
      setUserData({
        ...userData,
        id: item?.id,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        role: item?.role,
        // branch: item?.branchIds,
        gender: item?.gender,
        city: item?.city,
        address: item?.address,
        postalCode: item?.postalCode,
        phone: item?.phone ? { phoneNumber: item?.phone, dialCode: item?.countryCode ?? "44" } : {},
        birthDate: item?.birthDate ? moment(item?.birthDate, "YYYY-MM-DD").utc().toDate() : null,
        isActive: item?.isActive,
      });
    } else {
      setUserData({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        // branch: "",
        gender: "",
        city: "",
        address: "",
        postalCode: "",
        phone: {},
        birthDate: null,
        isActive: true,
      });
    }
  }, [visible, item]);

  useEffect(() => {
    const hasEmptyFields =
      userData.firstName === "" ||
      userData.lastName === "" ||
      userData.email === "" ||
      userData.role === "" ||
      userData.gender === "" ||
      userData.gender === undefined ||
      // userData.branch === undefined ||
      // userData.branch === null ||
      // userData.branch === "" ||
      userData.role === undefined ||
      userData.address === "" ||
      userData.city === "" ||
      userData.postalCode === "" ||
      !userData.phone ||
      userData.phone?.phoneNumber === undefined ||
      userData.phone?.phoneNumber === "" ||
      userData.phone?.phoneNumber === null ||
      userData.birthDate === null;

    const hasErrors =
      userData.isFirstNameError ||
      userData.isLastNameError ||
      userData.isCityNameError ||
      userData.isEmailError ||
      userData.isPostalCodeError ||
      userData.isPhoneError;
    setIsDisable(hasEmptyFields || hasErrors);
  }, [userData]);

  const close = () => {
    onClosed();
    setLoading(false);
    setUserData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addUserAPI(userData, (_, error) => {
      if (error === null) {
        let text =
          "User" +
          " " +
          userData?.firstName +
          " " +
          userData?.lastName +
          (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit User" : "Add User"}
        loading={loading}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  required={true}
                  label={"First Name"}
                  placeholder={"First Name"}
                  type={FormInputType.name}
                  value={userData?.firstName ?? ""}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isFirstNameError: isError,
                    }));
                  }}
                  onTextChange={(firstName) => {
                    setUserData((prev) => ({
                      ...prev,
                      firstName: firstName,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  required={true}
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  type={FormInputType.name}
                  value={userData?.lastName ?? ""}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isLastNameError: isError,
                    }));
                  }}
                  onTextChange={(lastName) => {
                    setUserData((prev) => ({
                      ...prev,
                      lastName: lastName,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Email"}
                  required={true}
                  placeholder={"Email"}
                  type={FormInputType.email}
                  value={userData?.email}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isEmailError: isError,
                    }));
                  }}
                  onTextChange={(email) => {
                    setUserData((prev) => ({
                      ...prev,
                      email: email,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">
                Role <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <RoleDropDown
                  value={userData?.role}
                  onSelect={(role) => {
                    setUserData((prev) => ({
                      ...prev,
                      role: role,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">
                Date Of Birth <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <FormDatePicker
                  selected={userData?.birthDate}
                  onChange={(birthDate) => {
                    setUserData((prev) => ({
                      ...prev,
                      birthDate: birthDate,
                    }));
                  }}
                  numberOfPastYears={100}
                  maxDate={new Date()}
                  minDate={new Date("1924-1-1")}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">
                Gender <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <GenderDropDown
                  value={userData?.gender ?? ""}
                  onSelect={(gender) => {
                    setUserData((prev) => ({
                      ...prev,
                      gender: gender,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  required={true}
                  label={"Address"}
                  type={FormInputType.default}
                  value={userData?.address ?? ""}
                  placeholder={"Address"}
                  onTextChange={(address) => {
                    setUserData((prev) => ({
                      ...prev,
                      address: address,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  required={true}
                  label={"City"}
                  type={FormInputType.default}
                  value={userData?.city ?? ""}
                  placeholder={"City"}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isCityNameError: isError,
                    }));
                  }}
                  onTextChange={(city) => {
                    setUserData((prev) => ({
                      ...prev,
                      city: city,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  required={true}
                  label={"Postal Code"}
                  type={FormInputType.postalCode}
                  value={userData?.postalCode ?? ""}
                  placeholder={"Postal Code"}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isPostalCodeError: isError,
                    }));
                  }}
                  onTextChange={(postalCode) => {
                    setUserData((prev) => ({
                      ...prev,
                      postalCode: postalCode,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <div className="form-control-wrap">
                <PhoneInput
                  required={true}
                  phone={userData?.phone?.phoneNumber}
                  countryCode={userData?.phone?.dialCode}
                  onChange={(phone) => {
                    setUserData((prev) => ({
                      ...prev,
                      phone: phone,
                    }));
                  }}
                  onError={(isError) => {
                    setUserData((prev) => ({
                      ...prev,
                      isPhoneError: isError,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>

          {/* AREA MANAGER  */}
          {/* <Col md="6">
            <div className="form-group">
              <label className="form-label">
                Area Manager <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <BranchDropDown
                  value={userData?.branch ?? ""}
                  onSelect={(branch) => {
                    setUserData((prev) => ({
                      ...prev,
                      branch: branch,
                    }));
                  }}
                />
              </div>
            </div>
          </Col> */}

          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setUserData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={userData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddUser;
