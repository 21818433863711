/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import {
  AgencyDropdown,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  FormInput,
  LoadingComponent,
  MoreOptions,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { HospitalTableData, exportClientAPI, listHospitalAPI } from "./HospitalTableData";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import AddNotes from "../../notes/AddNote";
import { NoteUserType } from "../../notes/NoteList";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import ImportFileModal from "../../../../components/ImportFileModal/ImportFileModal";
import SendEmailModal from "../../invoices/invoiceEmail/SendEmailModal";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { scrollToTop } from "../../../../utils/Functions";

const HospitalList = () => {
  const location = useLocation();
  const history = useHistory();

  const [hospitalList, setHospitalList] = useState([]);
  const [status, setStatus] = useState();
  const [clientType, setClientType] = useState();
  const [agency, setAgency] = useState({ id: 0, label: "All", value: "" });
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [noteModal, setNoteModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");

  const emailList = selectedRows?.map((person) => person?.personFields?.map((item) => item?.personEmail));
  const isRowSelected = selectedRows?.length > 0;
  const selectedRowIds = selectedRows.map((row) => row);

  useEffect(() => {
    loadHospital();
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadHospital();
    } else {
      setCurrentPage(1);
    }
  }, [status, searchText, clientType, agency, sortId, sortOrder]);

  useEffect(() => {
    if (location?.state?.isMoveClientList && location?.state?.status) {
      setStatus(location?.state?.status);
    }
  }, [location?.state?.isMoveClientList, location?.state?.status]);

  const onEdit = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-hospital`,
      state: { data: row.id },
    });
  };

  const onView = (row) => {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/hospital-detail`,
      state: { data: row.id },
    });
  };

  const loadHospital = () => {
    scrollToTop();
    if (isCheckToList(UserPermissionType.client)) {
      listHospitalAPI(
        {
          status: status?.value ?? location.state?.status?.value ?? ServerStatusType.active,
          search: searchText,
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: PER_PAGE_ITEMS,
          },
          clientType: clientType?.value ?? null,
          agencyId: agency?.id ?? null,
          sorting: {
            field: sortId ?? null,
            order: sortOrder ?? null,
          },
        },
        (list, error) => {
          if (!error) {
            setHospitalList(list?.list);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    } else {
      setInitScreen(false);
    }
  };

  const onExport = () => {
    setLoading(true);
    let params = {
      search: searchText,
      status: status?.value ?? location.state?.status?.value ?? ServerStatusType.active,
      clientType: clientType?.value ?? null,
    };

    exportClientAPI(params, (data, error) => {
      if (!error) {
        window.open(data.fileUrl, "default");
      }
      setLoading(false);
    });
  };

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-hospital`);
  };
  const onImport = () => {
    setImportModalOpen(true);
  };

  return (
    <React.Fragment>
      <Head title="Clients" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Clients</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totalItems} clients</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end">
                <span className="me-2 mb-2 mb-lg-0">
                  <FormInput
                    labelGroupClass="m-0"
                    value={searchText ?? ""}
                    placeholder="Search Client"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>

                <span className="me-2 mb-2 mb-lg-0">
                  <AgencyDropdown isShowAll={true} className="w-125px" value={agency} onSelect={(e) => setAgency(e)} />
                </span>

                <span className="me-2 mb-2 mb-lg-0">
                  <StatusActiveDropDown value={status} className="w-125px" onSelect={(e) => setStatus(e)} />
                </span>

                {isAllowToAccess(UserPermissionType.client, UserActionType.import) && (
                  <span className="me-2 mb-2 mb-lg-0">
                    <ImportExportButton title={"Import"} iconName={"arrow-down"} onClick={onImport} />
                  </span>
                )}

                {isAllowToAccess(UserPermissionType.client, UserActionType.export) && (
                  <span className="me-2 mb-2 mb-lg-0">
                    <ImportExportButton title={"Export"} onClick={onExport} iconName={"arrow-up"} loading={loading} />
                  </span>
                )}

                {isAllowToAccess(UserPermissionType.client, UserActionType.add) && (
                  <span className="me-2 mb-2 mb-lg-0">
                    <ImportExportButton addButton title={"Add"} onClick={onAddClick} iconName={"plus"} />
                  </span>
                )}

                {isRowSelected && (
                  <span>
                    <MoreOptions
                      className="text-bg-light"
                      options={[
                        {
                          icon: "emails",
                          text: "Email",
                          action: () => setEmailModal(true),
                        },
                        {
                          icon: "chat",
                          text: "Sms",
                          action: () => {},
                        },
                        {
                          icon: "notes-alt",
                          text: "Note",
                          action: () => setNoteModal(true),
                        },
                      ]}
                    />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"hospital-list-table"}>
            <ReactDataTable
              data={hospitalList}
              columns={HospitalTableData(onEdit, onView)}
              isAllowed={isCheckToList(UserPermissionType.client)}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page) => setCurrentPage(page)}
              onSort={(id) => {
                setSortId(id);
                setSortOrder(sortOrder === "desc" ? "asc" : "desc");
              }}
              onRowClicked={(row) => isAllowToAccess(UserPermissionType.client, UserActionType.detail) && onView(row)}
            />
          </PreviewCard>
        </Block>

        <SendEmailModal visible={emailModal} item={emailList} onClosed={() => setEmailModal(false)} />
        <AddNotes
          visible={noteModal}
          parents={selectedRowIds}
          type={NoteUserType.hospital}
          onClosed={() => setNoteModal(false)}
        />
      </Content>
      <LoadingComponent isLoading={initScreen} />
      <ImportFileModal
        isClientList={true}
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen(!importModalOpen)}
        title="Clients"
        loadHospital={loadHospital}
      />
    </React.Fragment>
  );
};

export default HospitalList;
