import moment from "moment";
import { Icon, StatusBadge, TableTitle, UserAvatar } from "../../../components/Component";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { toCapitalize } from "../../../utils/Functions";
import { CarerDocumentStatusBadge } from "../../../components/badge/StatusBadge";

export const dashboardDocDataTable = (onMove) => {
  return [
    {
      name: <TableTitle title={"Carer Name"} filterable={false} />,
      id: "name",
      compact: false,
      sortable: false,
      width: "450px",
      ignoreRowClick: false,
      cell: (row) => (
        <div onClick={() => onMove(row)}>
          <UserAvatar
            className="my-2"
            image={row?.carer?.profileImg?.imageUrl ?? ""}
            customHex={row?.carer?.colourHex}
            text={`${row?.carer?.firstName?.charAt(0) || ""}${row?.carer?.lastName?.charAt(0) || ""}`.toUpperCase()}
            name={row?.carer?.firstName + " " + row?.carer?.lastName}
            subName={row?.abbrCode}
          ></UserAvatar>
        </div>
      ),
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Document Name"} />,
      id: "documentName",
      selector: (row) => row?.documentName,
      sortable: false,
      width: "300px",
      style: {
        cursor: "pointer",
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Expiry Date"} />,
      id: "expiryDate",
      cell: (row) => {
        return (
          <div className="my-2" onClick={() => onMove(row)}>
            {moment(row?.date).format("DD-MM-YYYY")}
          </div>
        );
      },
      // sortable: true,
      width: "300px",
      style: {
        cursor: "pointer",
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => {
        return (
          <div onClick={() => onMove(row)} className="cursor-pointer">
            <CarerDocumentStatusBadge status={row?.status} expiryDate={row?.date} />
          </div>
        );
      },
    },
  ];
};

export function dashboardRotaAPI(params, callback) {
  apiService
    .postCall(APIServicePath.dashboardRota, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function notBookCarerApi(params, callback) {
  apiService
    .postCall(APIServicePath.lastBooking, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function dashboardDocListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.dashboardDocument, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function pendingTimesheetAPI(params, callback) {
  apiService
    .postCall(APIServicePath.pendingTimesheets, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function invoiceStatisticAPI(callback) {
  apiService
    .postCall(APIServicePath.invoiceStatistic)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function wagesStatisticAPI(callback) {
  apiService
    .postCall(APIServicePath.wagesStatistic)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function statisticAPI(callback) {
  apiService
    .postCall(APIServicePath.statistics)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function topClientsApi(callback) {
  apiService
    .postCall(APIServicePath.topClients)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function topCarersApi(callback) {
  apiService
    .postCall(APIServicePath.topCarers)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
