import { TableTitle } from "../../../components/Component";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";

export const availbilityList = [
  {
    Sunday: "A,D",
    Monday: "D",
    Tuesday: "E",
    Wedenesday: "A",
    Thursday: "-",
    Friday: "NG",
    Saturday: "-",
  },
  {
    Sunday: "-",
    Monday: "D",
    Tuesday: "-",
    Wedenesday: "A",
    Thursday: "-",
    Friday: "NG",
    Saturday: "-",
  },
  {
    Sunday: "A,D",
    Monday: "-",
    Tuesday: "E",
    Wedenesday: "-",
    Thursday: "-",
    Friday: "NG",
    Saturday: "-",
  },
  {
    Sunday: "A,D",
    Monday: "D",
    Tuesday: "E",
    Wedenesday: "-",
    Thursday: "-",
    Friday: "NG",
    Saturday: "D",
  },
  {
    Sunday: "NG",
    Monday: "-",
    Tuesday: "E",
    Wedenesday: "A",
    Thursday: "-",
    Friday: "NG",
    Saturday: "A",
  },
  {
    Sunday: "A,D",
    Monday: "D",
    Tuesday: "-",
    Wedenesday: "A",
    Thursday: "-",
    Friday: "NG",
    Saturday: "-",
  },
  {
    Sunday: "A,D",
    Monday: "D",
    Tuesday: "E",
    Wedenesday: "A",
    Thursday: "-",
    Friday: "N-",
    Saturday: "-",
  },
];

export const availbilityData = (onEdit, onRemove, onRota, onHistory) => {
  return [
    {
      name: <TableTitle title={"Sunday"} />,
      selector: (row) => row?.Sunday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Monday"} />,
      selector: (row) => row?.Monday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Tuesday"} />,
      selector: (row) => row?.Tuesday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Wedenesday"} />,
      selector: (row) => row?.Wedenesday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Thursday"} />,
      selector: (row) => row?.Thursday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Friday"} />,
      selector: (row) => row?.Friday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Saturday"} />,
      selector: (row) => row?.Saturday,
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
  ];
};

export function addAvailbilityDateApi(params, callback) {
  apiService
    .postCall(APIServicePath.addAvailbilityDate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function availbilityDateListApi(params, callback) {
  apiService
    .postCall(APIServicePath.availableDateList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
