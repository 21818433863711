import React from "react";
import classNames from "classnames";
import ChatDropdown from "./dropdown/chat/Chat";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import User from "./dropdown/user/User";
import MobileMenu from "../menu/MobileMenu";
import menu from "../menu/MenuData";
import { menuItems } from "../../services/permission/permission";
import { Link } from "react-router-dom";

const Header = ({ fixed, theme, visibility, toggleSidebar, mobileView, className, ...props }) => {
  const headerClass = classNames({
    "nk-header is-regular": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  // let items = menuItems();

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger me-sm-2 d-lg-none">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={toggleSidebar} />
          </div>
          <div className="nk-header-brand fs-4 d-flex align-items-center ">
            <Logo />
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link ms-2 text-dark">
              <span> SAUNDERS CRM </span>
            </Link>
          </div>
          <div
            className={`nk-header-menu ms-auto ${mobileView ? "mobile-menu" : ""}  ${
              visibility ? "nk-header-active" : ""
            }`}
          >
            <div className="nk-header-mobile">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger me-n2">
                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="arrow-left" click={toggleSidebar} />
              </div>
            </div>
            {mobileView ? (
              <MobileMenu data={menuItems()} sidebarToggle={toggleSidebar} mobileView={mobileView} />
            ) : (
              <Menu />
            )}
          </div>
          {visibility && <div className="nk-header-overlay" onClick={toggleSidebar}></div>}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
