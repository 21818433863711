import moment from "moment";
import { MoreOptions, TableTitle } from "../../../components/Component";
import { dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

export const noteData = (onEdit, onRemove, onRota, onHistory) => {
  return [
    {
      name: <TableTitle title={"Subject"} />,
      selector: (row) => row?.subject,
      sortable: false,
    },
    {
      name: <TableTitle title={"Type"} />,
      selector: (row) => row?.noteType?.name,
      sortable: false,
    },
    isAllowToAccess(UserPermissionType.note, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "100px",
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            // {
            //   icon: "trash",
            //   text: "Remove",
            //   action: () => {
            //     onRemove(row);
            //   },
            // },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addClientNoteAPI(data, clientIds, callback) {
  let params = {
    id: data?.noteData?.id,
    clientIds: clientIds,
    noteDate: moment(data?.noteData?.noteDate).format("YYYY-MM-DD"),
    noteTypeId: data?.noteData?.noteType?.id,
    subject: data?.noteData?.subject,
    description: data?.noteData?.description,
    outcome: data?.noteData?.outcome,
    lessionLearn: data?.noteData?.lessionLearn,
    fileIds: data.uploadedFiles.map((file) => {
      return {
        id: file.id,
      };
    }),
  };
  apiService
    .postCall(APIServicePath.addClientNote, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function addCarerNoteAPI(data, carerIds, callback) {
  let params = {
    id: data?.noteData?.id,
    carerIds: carerIds,
    noteDate: moment(data?.noteData?.noteDate).format("YYYY-MM-DD"),
    noteTypeId: data?.noteData?.noteType?.id,
    subject: data?.noteData?.subject,
    description: data?.noteData?.description,
    outcome: data?.noteData?.outcome,
    lessionLearn: data?.noteData?.lessionLearn,
    fileIds: data.uploadedFiles.map((file) => {
      return {
        id: file.id,
      };
    }),
  };
  apiService
    .postCall(APIServicePath.saveCarerNote, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listClientNoteAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listClientNote, params)
    .then((data) => {
      let list = toClientNoteList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function listCarerNoteAPI(params, callback) {
  apiService
    .postCall(APIServicePath.carerNoteList, params)
    .then((data) => {
      let list = toCarerNoteList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toClientNoteList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toClientNote(element));
  });
  return list;
}

function toCarerNoteList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toCarerNote(element));
  });
  return list;
}

function toCarerNote(data) {
  return {
    id: data?.carerNoteId,
    noteDate: dateToShow(data?.carerNoteDate),
    noteType: data?.noteType,
    noteDate: data?.carerNoteDate,
    subject: data?.subject,
    description: data?.description,
    lessionLearn: data?.lessionLearn,
    outcome: data?.outcome,
  };
}

function toClientNote(data) {
  return {
    id: data?.clientNoteId,
    noteDate: data?.clientNoteDate,
    noteType: data?.noteType,
    subject: data?.subject,
    description: data?.description,
    lessionLearn: data?.lessionLearn,
    outcome: data?.outcome,
  };
}
