import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

export const StatusActiveDropDown = ({ value, isMulti = false, isShowAll = true, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusValues = [
      { id: 1, label: "Active", value: "ACTIVE" },
      { id: 2, label: "Inactive", value: "DISABLED" },
    ];
    if (isShowAll) {
      const allOption = { id: 0, label: "All", value: "" };
      setList([allOption, ...statusValues]);
    } else {
      setList(statusValues);
    }
  }, [isShowAll]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[1] : null) : null)}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
