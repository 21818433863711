import React from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  FormInput,
  FormInputType,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { addSpecialityAPI } from "./SpecialityTableData";
import { AppText, RadioOptionTypes, convertDropValue, showErrorToast, showSuccessToast } from "../../../../utils";
import { convertToUpperCase } from "../../../../utils/Functions";

const AddSpeciality = ({ visible, item, onSaved, onClosed }) => {
  const [specialityData, setSpecialityData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if ((specialityData?.title?.length ?? 0) === 0 || (specialityData?.abbrCode?.length ?? 0) === 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [specialityData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setSpecialityData({
          ...specialityData,
          id: item?.id,
          title: item?.title,
          abbrCode: item?.abbrCode,
          isActive: item?.isActive || false,
        });
      } else {
        setSpecialityData({ ...specialityData, title: "", abbrCode: "", isActive: true });
      }
    }
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setSpecialityData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addSpecialityAPI(specialityData, (_, error) => {
      if (error === null) {
        let text =
          "Speciality" + " " + specialityData?.title + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit speciality" : "Add speciality"}
        onClick={() => onFormSubmit()}
        loading={loading}
        disabled={isDisable}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Speciality"}
                  required={true}
                  type={FormInputType.default}
                  value={specialityData.title}
                  onTextChange={(text) => {
                    setSpecialityData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Code"}
                  required={true}
                  type={FormInputType.default}
                  value={specialityData?.abbrCode ?? ""}
                  placeholder={"Code"}
                  maxLength={5}
                  onTextChange={(abbrCode) => {
                    const uppercaseAbbrCode = convertToUpperCase(abbrCode);
                    setSpecialityData((prev) => ({
                      ...prev,
                      abbrCode: uppercaseAbbrCode,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setSpecialityData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={specialityData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddSpeciality;
