import React from "react";
import {
  AnnsModal,
  Col,
  FormDatePicker,
  FormInput,
  FormInputType,
  RadioButtonOptions,
} from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";
import { addHolidayAPI } from "./holidayTableData";
import ClientType from "../../../../components/dropdowns/ClientType";

const AddHoliday = ({ visible, item, onSaved, onClosed }) => {
  const [holidayData, setHolidayData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisabled] = useState(true);
  useEffect(() => {
    if ((holidayData?.title?.length ?? 0) === 0 || holidayData?.date === null || holidayData?.isHomeCare === null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [holidayData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setHolidayData({
          ...holidayData,
          id: item?.id,
          title: item?.title || "",
          date: new Date(item?.date),
          isActive: item?.isActive || false,
          isHomeCare:
            item?.holidayFor === "HOMECARE"
              ? { label: "Care Home", value: item?.holidayFor }
              : item?.holidayFor === "ALL"
              ? { label: "All", value: item?.holidayFor }
              : { label: "Hospital", value: item?.holidayFor },
        });
      } else {
        setHolidayData({
          ...holidayData,
          title: "",
          date: null,
          isActive: true,
          isHomeCare: null,
        });
      }
    }
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setHolidayData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addHolidayAPI(holidayData, (_, error) => {
      if (error === null) {
        let text =
          "Holiday" + " " + holidayData?.title + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Holiday" : "Add Holiday"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Holiday"}
                  type={FormInputType.default}
                  value={holidayData.title}
                  onTextChange={(text) => {
                    setHolidayData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Date</label>
              <div className="form-control-wrap">
                <FormDatePicker
                  selected={holidayData.date}
                  onChange={(date) => {
                    setHolidayData((prev) => ({
                      ...prev,
                      date: date,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Client Type</label>
              <div className="form-control-wrap">
                <ClientType
                  value={holidayData?.isHomeCare}
                  isShowAll={true}
                  onSelect={(cType) => {
                    setHolidayData((prev) => ({ ...prev, isHomeCare: cType }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setHolidayData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={holidayData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddHoliday;
