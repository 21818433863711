import React, { useEffect, useState } from "react";
import { ActionButton, Icon, ReactDataTable, Row } from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import { NavigationTabs } from "../../../../components/navigationTabs/NavigationTabs";
import { useLocation } from "react-router";
import TemplateContent from "./TemplateContent";
import AddTemplate from "./AddTemplate";
import TemplateInfo from "./templateInfo";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";

export const TemplateType = {
  email: "email",
  sms: "sms",
};

const TemplateList = ({ templateData }) => {
  const location = useLocation();
  const passedData = location.state;
  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState({ addTemplate: false, templateInfo: false });
  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState([]);

  const tabs = [
    { text: "Email", icon: "emails" },
    { text: "SMS", icon: "chat" },
  ];

  return (
    <React.Fragment>
      <Head title="Template List" />
      <div className="position-relative template-detail ">
        <div className="d-flex align-items-center position-absolute move-right pe-4">
          <span
            className="me-2 d-flex align-items-center cursor-pointer"
            onClick={() => setModal({ ...modal, templateInfo: true })}
          >
            {/* <ActionButton title="info" size="md" onClick={() => setModal({ ...modal, templateInfo: true })} /> */}
            <Icon className={"fs-4"} name="info" />
          </span>
          {isAllowToAccess(UserPermissionType.template, UserActionType.add) && (
            <span>
              <ImportExportButton
                addButton
                title={"Add"}
                onClick={() => setModal({ ...modal, addTemplate: true })}
                iconName={"plus"}
              />
            </span>
          )}
        </div>

        <NavigationTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <TemplateContent
          type={activeTab === 0 ? TemplateType?.email : TemplateType?.sms}
          templateData={templateData}
          onRowData={(rows) => setSelectedRow(rows)}
          isOpen={modal?.addTemplate}
          onClosed={() => {
            setModal({ ...modal, addTemplate: false });
          }}
        />
        <TemplateInfo
          templateData={templateData}
          visible={modal?.templateInfo}
          onClosed={() => {
            setModal({ ...modal, templateInfo: false });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default TemplateList;
