/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { LoginActivityAPI, LoginActivityTableData } from "./LoginActivityData";
import { PER_PAGE_ITEMS, showErrorToast } from "../../utils";

const LoginActivity = () => {
  const [activityList, setActivityList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadActivityList();
  }, [currentPage]);

  const loadActivityList = () => {
    setInitScreen(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
    };
    LoginActivityAPI(params, (data, error) => {
      if (error === null) {
        setActivityList(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      } else {
        showErrorToast(error.message);
      }
      setInitScreen(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Login Activity" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Login Activity</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"hospital-list-table"}>
            <ReactDataTable
              data={activityList}
              columns={LoginActivityTableData()}
              // isAllowed={isCheckToList(UserPermissionType.client)}
              totalItemCount={totalItems}
              pagination
              onPageChanged={(page) => setCurrentPage(page)}
              // onRowClicked={(row) => isAllowToAccess(UserPermissionType.client, UserActionType.detail) && onView(row)}
            />
          </PreviewCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default LoginActivity;
