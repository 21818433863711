import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import AddNoteType from "./AddNoteType";
import { listNoteTypeAPI, noteTypeColumns } from "./NoteTypeTableData";
import { ServerStatusType } from "../../../../utils";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const NoteTypeList = () => {
  const [noteTypeListData, setNoteTypeListData] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadNoteType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status) {
      loadNoteType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  function loadNoteType() {
    if (isCheckToList(UserPermissionType.noteType)) {
      setLoading(true);
      listNoteTypeAPI({ status: status?.value ?? ServerStatusType.active }, (list, error) => {
        if (error === null) {
          setNoteTypeListData(list);
        }
        setLoading(false);
      });
    }
  }

  return (
    <React.Fragment>
      <Head title="Note Type" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Note Type</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.noteType, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={noteTypeListData}
              columns={noteTypeColumns(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.noteType)}
            />
          </PreviewCard>
        </Block>
        <AddNoteType
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadNoteType();
          }}
        />
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default NoteTypeList;
