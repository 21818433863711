import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { agencyDropDownData } from "./DropDownData";

export const AgencyDropdown = ({
  value,
  selectedId,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  carerTypeId,
  disable,
  ...props
}) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAgencyList();
  }, []);

  const loadAgencyList = () => {
    agencyDropDownData((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: undefined,
            label: "All",
            value: "",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId) {
      let selectedOptions = [];
      if (Array.isArray(selectedId)) {
        selectedOptions = selectedId.map((id) => list.find((option) => option.id === id));
      } else {
        const option = list.find((option) => option.id === selectedId);
        if (option) {
          selectedOptions.push(option);
        }
      }
      if (isMulti) {
        onSelect(selectedOptions);
      } else {
        const selectedOption = selectedOptions.find((option) => option !== undefined);
        if (selectedOption) {
          onSelect(selectedOption);
        }
      }
    }
  }, [list]);

  return (
    <RSelect
      disable={disable}
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={onSelect}
      {...props}
    />
  );
};
