import React from "react";
import { useState } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

const CustomPopOver = ({ data, ...props }) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  return (
    <React.Fragment>
      <div
        className=""
        id={`data-${data.id}`}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        {props.children}
      </div>{" "}
      <Popover placement="right" isOpen={mouseEnter} target={`data-${data.id}`}>
        <PopoverHeader></PopoverHeader>
        <PopoverBody>
          <div className="d-flex flex-column justify-content-center">
            {data?.grades?.map((item, index) => (
              <div className="d-flex align-items-center" key={index}>
                <span className="">{item?.name}</span>
                <span className="mx-1">({item?.abbrCode})</span>
              </div>
            ))}
          </div>
        </PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

export default CustomPopOver;
