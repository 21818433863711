import React from "react";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import {
  ActionButton,
  AnnsModal,
  Col,
  FormDatePicker,
  FormInput,
  FormInputType,
  Icon,
  NoteTypeDropDown,
} from "../../../components/Component";
import { useHistory, useLocation } from "react-router";
import { AppText, showErrorToast, showSuccessToast } from "../../../utils";
import { addCarerNoteAPI, addClientNoteAPI } from "./NoteData";
import { NoteUserType } from "./NoteList";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { fileUploadApi } from "../carers/CarerData";
import { iconsType } from "../../../layout/fileIcon/FileIcon";
// import { addTrustAPI } from "./TrustTableData";

const AddNotes = ({ visible, item, onSaved, onClosed, parents, type }) => {
  const location = useLocation();
  const selected = item?.id;
  const history = useHistory();
  const [noteData, setNoteData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (visible) {
      if (item) {
        setNoteData((prevState) => ({
          ...prevState,
          id: item?.id,
          subject: item?.subject,
          outcome: item?.outcome,
          noteDate: new Date(item?.noteDate),
          lessionLearn: item?.lessionLearn,
          description: item?.description,
          noteType: item?.noteType,
        }));
      } else {
        setNoteData({
          subject: "",
          outcome: "",
          noteDate: null,
          lessionLearn: "",
          description: "",
          noteType: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  useEffect(() => {
    return () => {
      // Cleanup function to cancel any ongoing tasks/subscriptions
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const hasEmptyFields = noteData.subject === "" || noteData.noteType === "" || noteData.noteDate === null;
    const hasErrors = noteData.isSubjectError;
    setIsDisable(hasEmptyFields || hasErrors);
  }, [noteData]);

  const close = () => {
    onClosed();
    setLoading(false);
    setNoteData({});
  };

  const onFormSubmit = () => {
    if (!parents) return;
    if (isDisable) return;
    setLoading(true);
    if (type === NoteUserType.hospital) {
      const formData = {
        noteData: { ...noteData },
        uploadedFiles: [...uploadedFiles],
      };
      addClientNoteAPI(
        formData,
        parents?.map((item) => item.id),
        (_, error) => {
          if (error === null) {
            let text = "Client note" + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
            showSuccessToast(text);
            setLoading(false);
            // history.goBack();
            onSaved();
            close(); // Go back to the previous tab
          } else {
            showErrorToast(error.message);
            setLoading(false);
          }
        }
      );
    } else if (type === NoteUserType.carer) {
      const formData = {
        noteData: { ...noteData },
        uploadedFiles: [...uploadedFiles],
      };
      addCarerNoteAPI(
        formData,
        parents?.map((item) => item.id),
        (_, error) => {
          if (error === null) {
            let text = "Carer note" + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
            showSuccessToast(text);
            setLoading(false);
            onSaved();
            close(); // Go back to the previous tab
          } else {
            showErrorToast(error.message);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      // Set the uploaded file in the importFile state
      setUploadedFiles(fileDetails);

      // for API
      uploadFile(fileDetails, rootIndex);
    }
  };

  const handleFileRemove = (ind) => {
    // const updatedfiles = [...dayData?.attachedFiles];
    // const removedFileId = updatedfiles[ind]?.id;
    // // Add the removed file's ID to the removedFileIds state
    // setRemovedFileIds((prevRemovedFileIds) => [...prevRemovedFileIds, removedFileId]);
    // dayData?.removeFileIds?.push(removedFileId);
    // updatedfiles.splice(ind, 1);
    // setDayData({ ...dayData, attachedFiles: updatedfiles });
  };

  const uploadFile = (file, index) => {
    fileUploadApi(file, "NOTE", (data, error) => {
      if (error === null) {
        // Set the uploaded file directly in the importFile state
        setUploadedFiles((prevImportFile) => {
          const updatedImportFile = [...prevImportFile];
          file.id = data.id;
          file.isLoading = false;
          updatedImportFile[index] = file;
          return updatedImportFile;
        });
      } else {
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"lg"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Note" : "Add Note"}
        onClick={() => onFormSubmit()}
        loading={loading}
        disabled={isDisable}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Subject"}
                  type={FormInputType.default}
                  value={noteData?.subject ?? ""}
                  placeholder={"Enter subject"}
                  onError={(isError) => {
                    setNoteData((prev) => ({
                      ...prev,
                      isSubjectError: isError,
                    }));
                  }}
                  onTextChange={(subject) => {
                    setNoteData((prev) => ({
                      ...prev,
                      subject: subject,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label"> Note Type</label>
              <div className="form-control-wrap">
                <NoteTypeDropDown
                  placeholder={"Select noteType"}
                  value={noteData?.noteType}
                  onSelect={(noteType) => {
                    setNoteData((prev) => ({ ...prev, noteType: noteType }));
                  }}
                  selectedId={noteData?.noteType?.id}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Note Date</label>
              <div className="form-control-wrap">
                <FormDatePicker
                  selected={noteData?.noteDate ?? null}
                  onChange={(noteDate) => {
                    setNoteData((prev) => ({
                      ...prev,
                      noteDate: noteDate,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Description</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={noteData?.description ?? ""}
                  onChange={(e) => {
                    setNoteData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Outcome</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Outcome"
                  value={noteData?.outcome ?? ""}
                  onChange={(e) => {
                    setNoteData((prev) => ({
                      ...prev,
                      outcome: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Lesson Learn</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Lesson Learn"
                  value={noteData?.lessionLearn ?? ""}
                  onChange={(e) => {
                    setNoteData((prev) => ({
                      ...prev,
                      lessionLearn: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddNotes;
