import React from "react";
import { ActionButton, AnnsModal, Col, FormInput, FormInputType } from "../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { AppText, showErrorToast, showSuccessToast } from "../../../utils";
import { addWardAPI } from "../clients/hospital/ward/WardData";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const QueryReasonModal = ({ visible, onClosed, statusData }) => {
  const [loading, setLoading] = useState(false);

  const close = () => {
    onClosed();
    setLoading(false);
  };

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => close()}
        title={statusData?.status === "CANCELLED" ? `Reason for Cancel` : `Reason for Query`}
        // onClick={() => onFormSubmit()}
        // disabled={isDisable}
        // isShowHeaderRemove={item ? true : null}
        // loading={loading}
        isFooterHide={true}
      >
        <span className="fs-16">{statusData?.statusReason ?? "N/A"}</span>
      </AnnsModal>
    </>
  );
};

export default QueryReasonModal;
