import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

export const CarerDocStatusDropDown = ({ value, isMulti = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const statusValues = [
      { id: 1, label: "Current", value: "ACTIVE" },
      { id: 2, label: "Archive", value: "DISABLED" },
    ];

    setList(statusValues);
  }, []);

  useEffect(() => {
    // Set default value to "Current" if no value is provided
    if (!value) {
      onSelect({ id: 1, label: "Current", value: "ACTIVE" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
