import React, { useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  FormInput,
  HospitalDropDown,
  MoreOptions,
  PreviewCard,
} from "../../../components/Component";
import { exportInvoiceList, exportInvoiceListXLSX } from "./InvoiceTableData";
import InvoiceList from "./InvoiceList";
import { useHistory } from "react-router";
import { openLinkInNewTab, toCapitalize } from "../../../utils/Functions";
import { EXPORT_TYPE, File_TYPE, InvoiceStatusOption } from "../../../utils/Utils";
import ActionButton, { ImportExportButton } from "../../../components/button/ActionButton";
import DateRangePicker from "../../../components/input/date-time/DateRangePicker";
import { ButtonGroup } from "reactstrap";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ExportButton from "../../../components/button/ExportButton";

const Invoice = () => {
  const history = useHistory();
  const location = useLocation();
  const data = location?.state?.data;
  const [status, setStatus] = useState({ value: InvoiceStatusOption.pending });
  const [activeButton, setActiveButton] = useState(0);
  const [searchText, setSearchText] = useState();
  const [carer, setCarer] = useState();
  const [client, setClient] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const invoiceRef = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const val = {
    ...InvoiceStatusOption,
  };

  const invoiceStatus = Object.values(val);

  useEffect(() => {
    if (location?.state?.isInvoiceListMove) {
      setStartDate(data?.startDate);
      setEndDate(data?.endDate);
      setActiveButton(invoiceStatus.findIndex((valueItem) => valueItem === InvoiceStatusOption.prepared));
      setStatus({ value: InvoiceStatusOption.prepared });
    }
  }, [location?.state?.isInvoiceListMove]);

  // useEffect(() => {
  //   // Calculate the start and end dates for the current week
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  //   const daysToAdd = 1 - currentDay; // Adjust the start day to Sunday

  //   const startOfWeek = new Date(currentDate);
  //   startOfWeek.setDate(currentDate.getDate() + daysToAdd);

  //   const endOfWeek = new Date(startOfWeek);
  //   endOfWeek.setDate(startOfWeek.getDate() + 6); // One week duration

  //   // Update the start and end dates in the state
  //   setStartDate(startOfWeek.toISOString().slice(0, 10));
  //   setEndDate(endOfWeek.toISOString().slice(0, 10));
  // }, []);

  const handleNextPrev = (ev, isNext) => {
    ev.preventDefault();
    const currentDate = new Date(startDate);

    // Calculate the new start date and end date
    const daysToAdd = isNext ? 7 : -7;
    const newStartDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);

    // Find the nearest Monday before or on the new start date
    const startDayOfWeek = newStartDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysToSubtract = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
    newStartDate.setDate(newStartDate.getDate() - daysToSubtract);

    // Calculate the end date (Sunday of the same week)
    const newEndDate = new Date(newStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);

    // Update the state with new start and end dates
    setStartDate(newStartDate.toISOString().slice(0, 10));
    setEndDate(newEndDate.toISOString().slice(0, 10));
  };

  const handleDateChange = (selectedDate) => {
    const currentDate = new Date(selectedDate);
    const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysToSubtract = currentDay === 0 ? 6 : currentDay - 2; // Adjust to previous Monday

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - daysToSubtract);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)

    setStartDate(startOfWeek.toISOString().slice(0, 10));
    setEndDate(endOfWeek.toISOString().slice(0, 10));
  };
  const exportFile = (fileType) => {
    if (File_TYPE?.pdf === fileType) {
      exportPDF();
    } else if (File_TYPE?.xlxs === fileType) {
      exportXLSX();
    }
  };

  function exportPDF() {
    if (!startDate || !endDate) return;
    setLoadingExport(true);
    let params = {
      startDate: startDate,
      endDate: endDate,
      clientId: client?.id,
      carerId: carer?.id,
      status: status?.value ?? InvoiceStatusOption.pending,
    };
    exportInvoiceList(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl);
      }
      setLoadingExport(false);
    });
  }

  function exportXLSX() {
    if (!startDate || !endDate) return;
    setLoadingExport(true);
    let params = {
      startDate: startDate,
      endDate: endDate,
      clientId: client?.id,
      carerId: carer?.id,
      status: status?.value ?? InvoiceStatusOption.pending,
    };
    exportInvoiceListXLSX(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl);
      }
      setLoadingExport(false);
    });
  }

  const handleDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleButtonClick = (item) => {
    const newValue = item === "ALL" ? "" : item;
    setActiveButton(invoiceStatus.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  const handleClearRows = () => {
    setSelectedRows([]);
  };
  return (
    <React.Fragment>
      <Head title="Invoices" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Invoices</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                {/* <span className="me-2">
                  <CarersDropDown
                    isClearable
                    value={carer}
                    className="w-225px"
                    onSelect={(e) => {
                      setCarer(e);
                    }}
                  />
                </span> */}
                <span className="me-2">
                  {/* <HospitalDropDown
                    isClearable
                    value={client}
                    className="w-225px"
                    onSelect={(e) => {
                      setClient(e);
                    }}
                  /> */}
                  <FormInput
                    inputClass={"padding-important"}
                    isShowLabel={false}
                    value={searchText ?? ""}
                    placeholder="Invoice No, Client"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>
                <span className="me-2">
                  <ButtonGroup>
                    {invoiceStatus?.map((item, index) => (
                      <Button
                        key={index}
                        className={`btn-dim btn-white border-right border
                             ${
                               activeButton === invoiceStatus?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                        onClick={() => handleButtonClick(item)}
                      >
                        {toCapitalize(item)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </span>
                <span className="me-2">
                  <DateRangePicker
                    onDateChange={handleDatePickerChange}
                    moveDate={location?.state?.isInvoiceListMove ? { startDate, endDate } : undefined}
                  />
                </span>
                <span className="">
                  <ActionButton
                    title="Add Invoice"
                    size="md"
                    onClick={() =>
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/add-invoice`,
                      })
                    }
                  />
                </span>
                {isAllowToAccess(UserPermissionType.invoice, UserActionType.export) && (
                  <span className="mx-2">
                    {/* <MoreOptions
                      options={[
                        {
                          icon: "file-pdf",
                          text: "PDF",
                          action: () => {
                            exportInvoice("pdf");
                          },
                        },
                        {
                          icon: "file-xls",
                          text: "XLSX",
                          action: () => {
                            exportInvoice("xlsx");
                          },
                        },
                      ]}
                    /> */}
                    <ExportButton
                      title={"Export"}
                      options={EXPORT_TYPE}
                      onChange={(fileType) => exportFile(fileType)}
                    />
                  </span>
                )}
                {selectedRows?.length > 0 &&
                  (isAllowToAccess(UserPermissionType.invoice, UserActionType.prepare) ||
                    isAllowToAccess(UserPermissionType.invoice, UserActionType.sent)) && (
                    <span className="">
                      <MoreOptions
                        className="text-bg-light"
                        options={[
                          {
                            icon: "file",
                            text: "Prepare Invoice",
                            action: () => {
                              invoiceRef.current?.sendPrepared();
                            },
                            hide:
                              activeButton !== 0 ||
                              !isAllowToAccess(UserPermissionType.invoice, UserActionType.prepare),
                          },
                          {
                            icon: "emails",
                            text: "Send Email",
                            action: () => {
                              invoiceRef.current?.sendEmail();
                            },
                            hide:
                              activeButton !== 1 || !isAllowToAccess(UserPermissionType.invoice, UserActionType.sent),
                          },
                        ]}
                      />
                    </span>
                  )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"bg-transparent invoice-list-table"}>
            <div className="ms-n4 me-n2 my-n2">
              <InvoiceList
                ref={invoiceRef}
                startDate={startDate}
                endDate={endDate}
                clientId={client?.id}
                carerId={carer?.id}
                status={status}
                searchText={searchText}
                selectedRows={selectedRows}
                onRowSelectionChange={(rows) => setSelectedRows(rows)}
                handleClearRows={handleClearRows}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Invoice;
