import React from "react";

export const TableTitle = ({ title, align, filterable, className, onTextChange = () => {} }) => {
  return (
    <ul>
      <li>
        <div
          className={`d-flex text-white fw-medium container p-0 ${align ? `px-1 justify-content-${align}` : ""} ${
            className ? className : ""
          }`}
        >
          {title}
        </div>
      </li>
      {filterable && (
        <li>
          <div className="d-flex">
            <input
              type="text"
              className="my-1"
              onChange={(e) => {
                e.preventDefault();
                onTextChange(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ width: "90%" }}
            />
          </div>
        </li>
      )}
    </ul>
  );
};
