import React from "react";
import { Dropdown, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import {
  ActionButton,
  AddressDropdown,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  ConfirmationAlert,
  FormInput,
  FormInputType,
  Icon,
  IconButton,
  InvoiceTypeDropDown,
  PersonDropDown,
  PhoneInput,
  PreviewAltCard,
  RSelect,
  RadioButtonOptions,
  Row,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { useHistory, useLocation } from "react-router";
import { AppText, RadioOptionTypes, formatPhoneNumber, showErrorToast, showSuccessToast } from "../../../../utils";
import { addTrustAPI } from "./TrustTableData";
import moment from "moment";

const AddTrust = () => {
  const location = useLocation();
  const selected = location.state?.data;
  const history = useHistory();
  const [personFields, setPersonFields] = useState([{ personName: "", personEmail: "", phone: {}, personType: "" }]);
  const [trustData, setTrustData] = useState({});
  const [pincode, setPincode] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  useEffect(() => {
    const hasEmptyFields =
      personFields.some(
        (person) =>
          person.personName === "" ||
          person.personName === null ||
          person.personName === undefined ||
          person.personEmail === "" ||
          person.personEmail === null ||
          person.personEmail === undefined ||
          person.phone?.phoneNumber === null ||
          person.phone?.phoneNumber === undefined ||
          person.phone?.phoneNumber === "" ||
          !person.phone
      ) ||
      trustData.name === "" ||
      trustData.email === "" ||
      trustData.address === "" ||
      // trustData.city === "" ||
      // trustData.postalCode === "" ||
      trustData.invoiceTypes === "";
    // personFields.some(
    //   (person) =>
    //     person.personName === "" || person.personEmail === "" || !person.phone || person.phone?.phoneNumber === 0
    // );

    const hasErrors =
      trustData.isNameError ||
      trustData.isEmailError ||
      trustData.isPhoneError ||
      trustData.isPostalCodeError ||
      personFields.some((person) => person.isEmailError || person.isNameError || person.isPhoneError);
    setIsDisable(hasEmptyFields || hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustData]);

  useEffect(() => {
    if (selected) {
      setTrustData((prevState) => ({
        ...prevState,
        id: selected?.id,
        name: selected?.name ?? "",
        address: selected?.address ?? "",
        city: selected?.city ?? "",
        postalCode: selected?.postalCode ?? "",
        email: selected?.email ?? "",
        invoiceTypes: selected?.invoiceTypes ?? "",
        isActive: selected?.isActive || false,
        lastModifiedBy: selected?.lastModifiedBy,
        lastModifiedTime: selected?.lastModifiedTime,
      }));
      setPersonFields(
        selected?.personFields?.map((person) => ({
          personId: person?.personId,
          personName: person?.personName,
          personEmail: person?.personEmail,
          phone: person?.phoneNumber ? { phoneNumber: person?.phoneNumber, dialCode: person?.countryCode ?? "44" } : {},
          countryCode: person?.countryCode,
          personType: person?.personType,
        })) || []
      );
    } else {
      setTrustData({
        name: "",
        address: "",
        city: "",
        postalCode: "",
        email: "",
        invoiceTypes: null,
        isActive: true,
      });
      setPersonFields([{ personName: "", personEmail: "", phone: {}, personType: null }]);
    }
  }, [selected]);

  const addPersonFields = () => {
    setPersonFields([...personFields, { personName: "", personEmail: "", phone: {}, personType: "" }]);
  };

  const handleRemovePersonField = (index) => {
    ConfirmationAlert("Remove Person?", "Are you sure, you want to remove this person?", () => {
      setPersonFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields.splice(index, 1);
        return updatedFields;
      });
    });
  };
  useEffect(() => {
    setTrustData((prevData) => ({
      ...prevData,
      personFields: personFields,
    }));
  }, [personFields]);

  const handleSearch = () => {
    setPincode(trustData?.postalCode);
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addTrustAPI(trustData, (_, error) => {
      if (error === null) {
        let text =
          "Trust" + " " + trustData?.name + (selected ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        history.push(`${process.env.PUBLIC_URL}/trust`);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    return () => {
      // Cleanup function to cancel any ongoing tasks/subscriptions
      setLoading(false);
    };
  }, []);

  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Add-trust" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {/* {selected ? "Edit Trust" : "Add Trust"} */}
                  {selected ? `Edit Trust - ${selected?.name}` : `Add Trust`}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
            <PreviewAltCard className="mt-2">
              <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
                <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        required={true}
                        label={"Trust name"}
                        type={FormInputType.name}
                        value={trustData?.name ?? ""}
                        placeholder={"Enter trust name"}
                        onError={(isError) => {
                          setTrustData((prev) => ({
                            ...prev,
                            isNameError: isError,
                          }));
                        }}
                        onTextChange={(name) => {
                          setTrustData((prev) => ({
                            ...prev,
                            name: name,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        label={"Email"}
                        required={true}
                        type={FormInputType.email}
                        value={trustData?.email ?? ""}
                        placeholder={"Enter e-mail"}
                        onError={(isError) => {
                          setTrustData((prev) => ({
                            ...prev,
                            isEmailError: isError,
                          }));
                        }}
                        onTextChange={(email) => {
                          setTrustData((prev) => ({
                            ...prev,
                            email: email,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        maxLength={255}
                        label={"Address"}
                        required={true}
                        type={FormInputType.default}
                        value={trustData?.address ?? ""}
                        placeholder={"Enter address"}
                        onError={(isError) => {
                          setTrustData((prev) => ({
                            ...prev,
                            isNameError: isError,
                          }));
                        }}
                        onTextChange={(address) => {
                          setTrustData((prev) => ({
                            ...prev,
                            address: address,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col> */}
                {/* <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        label={"City"}
                        required={true}
                        type={FormInputType.default}
                        value={trustData?.city ?? ""}
                        placeholder={"Enter city"}
                        onError={(isError) => {
                          setTrustData((prev) => ({
                            ...prev,
                            isNameError: isError,
                          }));
                        }}
                        onTextChange={(city) => {
                          setTrustData((prev) => ({
                            ...prev,
                            city: city,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col> */}
                <Col md="6">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <FormInput
                        label={"Postal Code"}
                        // required={true}
                        type={FormInputType.postalCode}
                        value={trustData?.postalCode ?? ""}
                        placeholder={"Enter postalcode"}
                        onError={(isError) => {
                          setTrustData((prev) => ({
                            ...prev,
                            isPostalCodeError: isError,
                          }));
                        }}
                        onTextChange={(postalCode) => {
                          setTrustData((prev) => ({
                            ...prev,
                            postalCode: postalCode,
                          }));
                        }}
                        showSearchButton={true} // Show the search button
                        onSearch={handleSearch} // Provide the search callback
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">
                      Address <span className="text-danger">*</span>
                    </label>
                    <div className="form-control-wrap">
                      <AddressDropdown
                        // isClearable
                        search={pincode}
                        value={{ label: trustData?.address, value: trustData?.address }}
                        onSelect={(e) => {
                          setTrustData((prev) => ({
                            ...prev,
                            address: e.label,
                            city: e.value.city,
                            postalCode: e.value.zipCode,
                            coordinates: e.value.coordinates,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">
                      Invoice Types <span className="text-danger">*</span>
                    </label>
                    <div className="form-control-wrap">
                      <InvoiceTypeDropDown
                        isMulti={true}
                        isClearable={false}
                        placeholder={"Select invoice"}
                        value={trustData?.invoiceTypes}
                        onSelect={(invoiceTypes) => {
                          setTrustData((prev) => ({ ...prev, invoiceTypes: invoiceTypes }));
                        }}
                      />
                    </div>
                  </div>
                </Col>
                {personFields.map((person, index) => (
                  <React.Fragment key={index}>
                    <Row className="align-items-center align-items-baseline mt-3 position-relative ">
                      <Col md="3" className={"mb-2"}>
                        <div className="form-group">
                          <label className="form-label">
                            Contact type <span className="text-danger fs-6">*</span>
                          </label>
                          <div className="form-control-wrap">
                            <PersonDropDown
                              value={person?.personType}
                              placeholder={"Select type"}
                              onSelect={(person) => {
                                // setSelectedPerson(person);
                                const updatedFields = [...personFields];
                                updatedFields[index].personType = person;
                                setPersonFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3" className={"mb-2"}>
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <FormInput
                              label={"Name"}
                              required={true}
                              type={FormInputType.name}
                              value={person?.personName ?? ""}
                              placeholder={"ex. Luis"}
                              onError={(isError) => {
                                setTrustData((prev) => ({
                                  ...prev,
                                  isNameError: isError,
                                }));
                              }}
                              onTextChange={(value) => {
                                const updatedFields = [...personFields];
                                updatedFields[index].personName = value;
                                setPersonFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3" className={"mb-2"}>
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <FormInput
                              label={"Email"}
                              required={true}
                              type={FormInputType.email}
                              value={person?.personEmail ?? ""}
                              placeholder={"ex. xyz@mail.com"}
                              onError={(isError) => {
                                const updatedFields = [...personFields];
                                updatedFields[index].isEmailError = isError;
                                setPersonFields(updatedFields);
                              }}
                              onTextChange={(value) => {
                                const updatedFields = [...personFields];
                                updatedFields[index].personEmail = value;
                                setPersonFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3" className={"mb-2"}>
                        <div className="form-group">
                          <div className="form-control-wrap me-n4">
                            <PhoneInput
                              required={true}
                              phone={person?.phone?.phoneNumber}
                              countryCode={person?.phone?.dialCode}
                              onChange={(data) => {
                                const updatedFields = [...personFields];
                                updatedFields[index].phone = data;
                                setPersonFields(updatedFields);
                              }}
                              onError={(isError) => {
                                const updatedFields = [...personFields];
                                updatedFields[index].isPhoneError = isError;
                                setPersonFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      {index === 0 && (
                        <span className="form-control-wrap position-absolute top-n5 right-14 text-end mx-auto me-n4">
                          <IconButton type="button" color="success" icon="plus" size={"sm"} onClick={addPersonFields} />
                        </span>
                      )}
                      {index > 0 && (
                        <span className="form-control-wrap position-absolute top-n5 right-14 text-end me-n4">
                          <IconButton
                            icon="minus"
                            size={"sm"}
                            color="danger"
                            onClick={() => handleRemovePersonField(index)}
                          ></IconButton>
                        </span>
                      )}
                    </Row>
                  </React.Fragment>
                ))}

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="form-control-wrap">
                      <RadioButtonOptions
                        name="status"
                        size={2}
                        optionType={RadioOptionTypes.status}
                        onChange={(index) => {
                          setTrustData((prev) => ({ ...prev, isActive: index === 0 }));
                        }}
                        activeIndex={trustData.isActive ? 0 : 1}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <ul className="align-center justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <div>
                      {trustData?.lastModifiedTime && trustData?.lastModifiedBy?.firstName && (
                        <>
                          <span className="text-danger fs-6 me-1">*</span>
                          <span>
                            Last edited on {moment(trustData?.lastModifiedTime).format("DD-MM-YYYY")} at {""}
                            {moment(trustData?.lastModifiedTime).format("HH:mm")} by{" "}
                            {trustData?.lastModifiedBy?.firstName + " " + trustData?.lastModifiedBy?.lastName}.
                          </span>
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <BackButton cancel />
                      </li>
                      <li>
                        <ActionButton
                          title="Save"
                          type="submit"
                          loading={loading}
                          disabled={isDisable}
                          // onClick={() => onFormSubmit()}
                        />
                      </li>
                    </div>
                  </ul>
                </Col>
              </Form>
            </PreviewAltCard>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddTrust;
