import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
  PreviewAltCard,
  UserAvatar,
} from "../../../components/Component";
import { notBookCarerApi } from "./DashboardData";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NotBookedCarer = () => {
  const history = useHistory();
  const [carerData, setCarerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadNotBookCarer();
  }, [currentPage]);

  function loadNotBookCarer() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 5,
      },
    };
    setLoading(true);
    notBookCarerApi(params, (data, error) => {
      if (error === null) {
        setCarerData(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      }
      setLoading(false);
    });
  }

  const onMove = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/carer-detail`,
      state: { data: item, id: item?.id },
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">{`Candidates last booked`}</h6>
          </CardTitle>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : carerData && carerData.length > 0 ? (
        <DataTableBody bodyclass="nk-tb-orders">
          <DataTableHead>
            <DataTableRow size="sm">
              <span>Carer</span>
            </DataTableRow>
            <DataTableRow>
              <span>Email</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Date</span>
            </DataTableRow>
          </DataTableHead>
          {carerData.map((item, idx) => (
            <DataTableItem key={idx} className={"cursor-pointer"} onClick={() => onMove(item)}>
              <DataTableRow size="sm">
                <div className="user-card">
                  <UserAvatar
                    className="nk-activity-media"
                    theme={item?.theme}
                    image={item?.profileImg?.imageUrl ?? ""}
                    customHex={item?.colourHex}
                    text={`${item?.firstName?.charAt(0) || ""}${item?.lastName?.charAt(0) || ""}`.toUpperCase()}
                    name={item?.firstName + " " + item.lastName}
                    // subName={item?.email}
                  ></UserAvatar>
                </div>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{item?.email}</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="tb-sub">{moment(item?.lastBookingDate).format("DD-MM-YYYY")}</span>
              </DataTableRow>
            </DataTableItem>
          ))}
        </DataTableBody>
      ) : (
        <div className="no-data-text align-middle">
          <PreviewAltCard className="m-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </div>
      )}
      {carerData?.length > 0 && (
        <div className="mx-4">
          <PaginationComponent itemPerPage={5} totalItems={totalItems} paginate={paginate} currentPage={currentPage} />{" "}
        </div>
      )}
    </React.Fragment>
  );
};
export default NotBookedCarer;
