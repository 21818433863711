import moment from "moment";
import { MoreOptions, TableTitle } from "../../../components/Component";
import { CURRENCY_CODE } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { InvoiceTypes, WageStatusOption } from "../../../utils/Utils";
import { convertToHourMinute, toCapitalize } from "../../../utils/Functions";
import React, { useState } from "react";
import { NoteUserType } from "../notes/NoteList";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

const WageRow = ({ item }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className={item?.wageItem?.length > 1 ? "mb-2" : null}>
      <span>
        <span className="wageClient-text">{item?.wageClient?.name}</span>
        {item?.wageItem?.length > 0 ? (
          <span className="ms-4">
            <img
              className="cursor-pointer"
              onClick={() => toggleCollapse(item.id)}
              aria-expanded={!isCollapsed}
              aria-controls={`collapseExample-${item.id}`}
              src={
                isCollapsed
                  ? require("../../../assets/images/jsTree/arrow/Group 227.png")
                  : require("../../../assets/images/jsTree/arrow/rightarrow.png")
              }
            />
          </span>
        ) : null}
      </span>
      <br />

      <span className={`collapse${!isCollapsed ? "" : "show"}`} id={`collapseExample-${item.id}`}>
        {item?.wageItem?.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <span className="wage-detail-text-color">
                <span>{moment(data?.itemDate).format("DD-MM-YYYY") ?? "N/A"}</span>;{" "}
                <span>
                  {data?.isMileage === true ? "Mlg:" : "Hrs:"}
                  {convertToHourMinute(data?.totalHours)}
                </span>{" "}
                |{" "}
                <span>
                  Rate: {CURRENCY_CODE}
                  {data?.payRate ?? "0"}
                </span>{" "}
                |{" "}
                <span>
                  Amount: {CURRENCY_CODE}
                  {data?.totalPayAmount ?? "0"}
                </span>
              </span>
              <br />
            </React.Fragment>
          );
        })}
      </span>
    </div>
  );
};

export const wageTableData = (isCarer, status, onStatus, moveTimesheet) => {
  return [
    {
      name: <TableTitle title={"Carer"} />,
      id: "carer name",
      width: "250px",
      selector: (row) => {
        return (
          <>
            <div>
              {row?.carerName ?? ""}
              <br />
              <span className="listingsubtext">{row?.carerEmail ?? ""}</span>
            </div>
          </>
        );
      },
      sortable: true,
      omit: isCarer === NoteUserType.carer,
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Client"} />,
      id: "client name",
      selector: (row) => (
        <>
          {row?.wageData?.map((item, index) => (
            <WageRow key={index} item={item} />
          ))}
        </>
      ),
      sortable: true,
      grow: 1,
      width: "500px",
      sortFunction: (rowA, rowB) => 0,
    },
    // {
    //   name: <TableTitle title={"From Date - To Date"} />,
    //   selector: (row) => {
    //     const startDate = row?.startDate ? dateToShow(row?.startDate) : "";
    //     const endDate = row?.endDate ? dateToShow(row?.endDate) : "";
    //     return `${startDate} - ${endDate}`;
    //   },
    //   sortable: true,
    // },

    // {
    //   name: <TableTitle title={"Hours"} />,
    //   id: "totalHours",
    //   selector: (row) => row?.totalHours,
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => 0,
    // },
    {
      name: <TableTitle title={"Total Amount"} />,
      id: "total amount",
      selector: (row) => (row?.totalAmount ? CURRENCY_CODE + row?.totalAmount : "-"),
      sortable: true,
      width: "200px",
      sortFunction: (rowA, rowB) => 0,
      style: {
        cursor: "pointer",
      },
    },
    // {
    //   name: <TableTitle title={"Invoice Type"} />,
    //   selector: (row) => row?.invoiceType?.label ?? "",
    //   sortable: false,
    // },
    // {
    //   name: <TableTitle title={"Status"} />,
    //   selector: (row) => (row.status ? "Active" : "Inactive"),
    //   width: "100px",
    //   sortable: true,
    // },
    {
      name: <TableTitle title={""} />,
      selector: (row) => "",
      // sortable: true,
      // omit: status !== WageStatusOption.finalised,
    },
    {
      name: <TableTitle title={"Finalised By"} />,
      selector: (row) => row?.finalisedBy?.name,
      sortable: true,
      omit: status !== WageStatusOption.finalised,
      sortable: false,
    },
    // {
    //   name: <TableTitle title={"Created By"} />,
    //   selector: (row) => row?.createdby?.name,
    //   sortable: true,
    //   omit: status !== WageStatusOption.pending,
    // },
    // {
    //   name: <TableTitle title={"Invoiced By"} />,
    //   selector: (row) => row?.invoicedBy?.name,
    //   sortable: true,
    //   omit: status !== WageStatusOption.invoiced,
    // },

    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "100px",
      // omit: row.status === INVOICE_CREATED ? true : false,
      cell: (row) =>
        row.status === "PENDING" ? (
          <MoreOptions
            options={[
              {
                icon: "emails",
                text: "Move to timesheet",
                action: () => {
                  moveTimesheet([row?.wageId]);
                },
                hide:
                  row.status !== WageStatusOption.pending ||
                  !isAllowToAccess(UserPermissionType.wage, UserActionType.moveToTimesheet),
              },
              {
                icon: "calendar-booking",
                text:
                  row.status === WageStatusOption.pending
                    ? toCapitalize(WageStatusOption.finalised)
                    : // : row.status === WageStatusOption.finalised
                      // ? "Invoiced"
                      "",
                action: () => {
                  onStatus([row?.wageId], row?.status);
                },
                hide: !isAllowToAccess(UserPermissionType.wage, UserActionType.finalised),
              },
            ]}
          />
        ) : (
          ""
        ),
    },
  ];
};

export function listWageAPI(params, callback) {
  apiService
    .postCall(APIServicePath.wageList, params)
    .then((data) => {
      let list = toWageList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function wageModerateStatus(params, callback) {
  apiService
    .postCall(APIServicePath.wageStatus, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function wageMoveToTimesheet(params, callback) {
  apiService
    .postCall(APIServicePath.timesheetMovetoTimesheet, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportWageList(params, callback) {
  apiService
    .postCall(APIServicePath.exportWage, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//converter

function toWageList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toWage(element));
  });
  return list;
}

function toWage(data) {
  return {
    wageId: data?.id,
    client: data?.client,
    carerName: data?.carer?.firstName + " " + data?.carer?.lastName,
    carerEmail: data?.carer?.email,
    totalAmount: data?.totalAmount,
    totalHours: data?.totalHours,
    startDate: data?.startDate ? moment(data.startDate).format("YYYY-MM-DD") : null,
    endDate: data?.endDate ? moment(data.endDate).format("YYYY-MM-DD") : null,
    status: data?.status,
    wageData: toWageDataList(data?.wageData),
    finalisedBy: data?.finalisedBy
      ? {
          id: data?.finalisedBy?.id,
          name: data?.finalisedBy?.firstName + " " + data?.finalisedBy?.lastName,
        }
      : null,
    invoicedBy: data?.invoicedBy
      ? {
          id: data?.invoicedBy?.id,
          name: data?.invoicedBy?.firstName + " " + data?.invoicedBy?.lastName,
        }
      : null,
  };
}

function toWageDataList(list) {
  let items = [];
  for (let index = 0; index < (list?.length ?? 0); index++) {
    const element = list[index];
    items.push(toList(element));
  }
  return items;
}

function toList(person) {
  let types = InvoiceTypes.filter((item) => item.value === person?.invoiceType);

  return {
    id: person?.id,
    wageClient: person?.client,
    wageInvoiceType: person?.invoiceType,
    wageStartDate: person?.startDate,
    wageEndDate: person?.endDate,
    wageTotalAmount: person?.totalAmount,
    wageTotalHour: person?.totalHours,
    wageItem: toWageItem(person?.items),
    wageStatus: person?.status,
    wageInvoiceType: types?.length === 1 ? types[0] : null,
  };
}

function toWageItem(list) {
  let items = [];
  for (let index = 0; index < (list?.length ?? 0); index++) {
    const element = list[index];
    items.push(toItemWage(element));
  }
  return items;
}

function toItemWage(person) {
  return {
    itemId: person?.id,
    breakHour: person?.breakHours,
    chargeRate: person?.chargeRate,
    itemDate: person?.itemDate ? moment(person?.itemDate).format("YYYY-MM-DD") : null,
    payRate: person?.payRate,
    isMileage: person?.isMileage,
    totalPayAmount: person?.totalPayAmount,
    totalHours: person?.totalHours,
    status: person?.status,
    endTime: person?.endTime ? moment(person?.endTime, "HH:mm:ss").utc().toDate() : null,
    startTime: person?.startTime ? moment(person?.startTime, "HH:mm:ss").utc().toDate() : null,
  };
}
