import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { ClientTypeOption } from "../../utils/Utils";

const ClientType = ({ value, isMulti = false, isShowAll = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    if (isShowAll === false) {
      setList(ClientTypeOption);
    } else {
      let all = {
        id: undefined,
        label: "All",
        value: "ALL",
      };
      setList([all, ...ClientTypeOption]);
    }

    return () => {
      setList();
    };
  }, []);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? null}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};

export default ClientType;
