import React from "react";
import classNames from "classnames";
import { ActionButton, StatusBadge } from "../Component";

const CarerProfile = ({
  className,
  name,
  subName,
  email,
  size,
  theme,
  icon,
  text,
  image,
  relations,
  imageAlt,
  phoneNumber,
  avatarText,
  grade,
  onButtonClick,
  carerFilter,
  statusbadge,
  ...props
}) => {
  const classes = classNames({
    [`${className}`]: className,
    [`user-avatar-${size} `]: size,
  });

  return (
    <div className={`d-flex align-items-center user-card  nk-tb-list ${avatarText}`}>
      <div className={classes}>
        {image && (
          <img className={carerFilter ? `carer-image-for-rota` : `carer-image-css`} src={image} alt={imageAlt} />
        )}
        {props.children}
      </div>

      {name ? (
        <div className="user-info">
          <div className="tb-lead align-center">
            <span className="pe-1">{name}</span> {statusbadge && <StatusBadge status={statusbadge === "ACTIVE"} />}
          </div>

          {subName && <div className="">{subName}</div>}
          {email && <div className="">{email}</div>}
          {grade && <div className="">{grade}</div>}
          {relations && <div className="">{relations}</div>}
          {phoneNumber && <div className="">{phoneNumber}</div>}
        </div>
      ) : null}
    </div>
  );
};

export default CarerProfile;
