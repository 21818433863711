import React, { useState } from "react";
import { ActionButton, AnnsModal, Col, ConfirmationAlert, DataItem, Row } from "../../../components/Component";
import moment from "moment";
import { timesheetCancel } from "../timesheets/TimesheetData";
import { RotaOptions, dateToShow, showErrorToast, showSuccessToast } from "../../../utils";
import QueryModal from "../timesheets/QueryModal";

export const ModalStatus = {
  success: "success",
  pending: "pending",
};

const RotaDetailModal = ({ visible, dayLabel, item, onSaved, onClosed, rotaStatus }) => {
  const [queryModal, setQueryModal] = useState(false);

  const close = (result) => {
    onClosed(result);
  };

  const onCancelRota = () => {
    setQueryModal(true);
  };

  const handleQuerySave = (data, cancelledBy, params) => {
    let parameter = {
      rotaCarerDateIds: params?.id ? [params?.id] : [],
      // timesheetDates: [params?.rotaDate],
      statusReason: data,
      cancelledBy: cancelledBy,
    };
    ConfirmationAlert("Are you sure!", "Do you want to cancel Rota" + "?", () => {
      timesheetCancel(parameter, (_, error) => {
        if (error === null) {
          showSuccessToast("Rota has been cancelled successfully");
        } else {
          showErrorToast(error.message);
        }
        setQueryModal(false);
        close(ModalStatus.success);
      });
    });
  };

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => close(ModalStatus.pending)}
        title={"Rota Detail"}
        onCancelRota={
          item?.status === RotaOptions.cancelled || item?.status === RotaOptions.completed ? "" : onCancelRota
        }
        isFooterHide={true}
      >
        <Row className=" gy-2">
          <Col size="6">
            <span className="fw-bold">Client</span>
            <span className="sub-text">{item?.client?.name}</span>
          </Col>
          <Col size="6">
            <span className="fw-bold">Carer</span>
            <span className="sub-text">{item?.carer}</span>
          </Col>
          <Col size="6">
            <span className="fw-bold">Start Time - End Time</span>
            <span className="sub-text">
              {" "}
              {moment(item?.startTime).format("HH:mm")} - {moment(item?.endTime).format("HH:mm")}
            </span>
          </Col>
          <Col size="6">
            <span className="fw-bold">Rota Date</span>
            <span className="sub-text">{dateToShow(item?.rotaDate)}</span>
          </Col>
          <Col size="6">
            <span className="fw-bold">Day</span>
            <span className="sub-text">{item?.dayName}</span>
          </Col>
          {rotaStatus === RotaOptions.cancelled && (
            <>
              <Col size="6">
                <span className="fw-bold">Cancelled By</span>
                <span className="sub-text">{item?.lastModifiedBy}</span>
              </Col>
              <Col size="6">
                <span className="fw-bold">Cancelled Date</span>
                <span className="sub-text">{item?.lastModifiedDate}</span>
              </Col>
            </>
          )}
        </Row>
      </AnnsModal>
      <QueryModal
        isCancelReason
        visible={queryModal}
        // selectedDate={selectedParamsReject}
        // selectedDate={item}
        onClosed={() => {
          setQueryModal(false);
        }}
        onSaved={(data, cancelledBy) => handleQuerySave(data, cancelledBy, item)}
      />
    </>
  );
};

export default RotaDetailModal;
