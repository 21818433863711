import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { TimesheetStatusType } from "../../utils";
import { toCapitalize } from "../../utils/Functions";

export const TimesheetDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  isNotificationScreen = false,
  onSelect,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNotificationScreen) {
      setList([
        { id: 0, label: "All", value: TimesheetStatusType.all },
        { id: 1, label: "Pending", value: TimesheetStatusType.pending },
        { id: 2, label: "Submitted", value: TimesheetStatusType.submitted },
      ]);
    } else {
      const statusValues = [];
      Object.values(TimesheetStatusType).forEach((item) => {
        statusValues.push({ id: 1, label: toCapitalize(item), value: item });
      });

      if (isShowAll) {
        const allOption = { id: 0, label: "All", value: "All" };
        setList([allOption, ...statusValues]);
      } else {
        setList(statusValues);
      }
    }
  }, [isShowAll, isNotificationScreen]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={
        value ??
        (isShowAll || isNotificationScreen ? (list ? list[0] : null) : { id: 1, label: "Pending", value: "PENDING" })
      }
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
