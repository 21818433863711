import React, { useCallback, useEffect, useMemo } from "react";
import Content from "../../../layout/content/Content";
import {
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ConfirmationAlert,
  PayTypeDropDown,
  PreviewAltCard,
} from "../../../components/Component";
import { Step, Steps, useSteps } from "react-step-builder";
import { createContext } from "react";
import { useState } from "react";
import CarerDetials from "./CarerDetailsForm";
import CarerOthers from "./CarerOthers";
import CarereContact from "./CarereContact";
import CarerDocuments from "./CarerDocuments";
import CarerDetailsForm from "./CarerDetailsForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  carerClientBanFormAPI,
  carerDetailApi,
  carerDetailFormAPI,
  carerKinContactFormAPI,
  carerOtherFormAPI,
  carerSaveApi,
} from "./CarerData";
import { AppText, PER_PAGE_ITEMS, ServerStatusType, convertDropValue } from "../../../utils";
import moment from "moment/moment";
import {
  convertDropMultiValue,
  removeTimeFromServerDateString,
  showErrorToast,
  showSuccessToast,
  toCapitalize,
} from "../../../utils/Functions";
import CarerHospital from "./CarerHospital";
import CarerHomeCare from "./CarerHomeCare";
import { listHospitalAPI } from "../clients/hospital/HospitalTableData";
import Head from "../../../layout/head/Head";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import { PayType } from "../../../utils/Utils";

export const FormBackType = {
  directBack: "DirectBack",
  prevBack: "PrevBack",
  jump: "JumpBack",
};

export const CarerWizardContext = createContext();

const Header = ({ current, jump, valid }) => {
  function getTabClassName(current, tabIndex) {
    if (current === tabIndex) {
      return "active-tab";
    } else if (current > tabIndex) {
      return "previous-tab";
    } else {
      return "pending-tab";
    }
  }

  return (
    <div className="steps clearfix">
      <ul className="">
        <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 1)}`}
          onClick={(ev) => {
            jump(1);
            // if (!valid) {
            //   jump(1);
            // } else {
            //   ev.preventDefault();
            // }
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 1)}`}>Detail</span>
          </a>
        </li>
        <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 2)}`}
          onClick={(ev) => {
            jump(2);
            // if (!valid) {
            //   jump(2);
            // } else {
            //   ev.preventDefault();
            // }
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 2)}`}>Other</span>
          </a>
        </li>
        <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 3)}`}
          onClick={(ev) => {
            jump(3);
            // if (!valid) {
            //   jump(3);
            // } else {
            //   ev.preventDefault();
            // }
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 3)}`}>Next Of Kin</span>
          </a>
        </li>
        <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 4)}`}
          onClick={(ev) => {
            jump(4);
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 4)}`}>Hospital</span>
          </a>
        </li>
        <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 5)}`}
          onClick={(ev) => {
            jump(5);
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 5)}`}>Care Home</span>
          </a>
        </li>
        {/* <li
          className={`wizard-margin cursor-pointer py-1 " ${getTabClassName(current, 6)}`}
          onClick={(ev) => {
            jump(6);
          }}
        >
          <a>
            <span className={`number" ${getTabClassName(current, 6)}`}>Document</span>
          </a>
        </li> */}
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const AddCarer = () => {
  const { current, next, prev } = useSteps();
  const location = useLocation();

  const [carerDetails, setCarerDetails] = useState();
  const [hospitalList, setHospitalList] = useState([]);
  const [homeCareList, sethomeCareList] = useState([]);
  const [hospitalObject, setHospitalObject] = useState();
  const [homeCareObject, setHomeCareObject] = useState();
  const [initScreen, setInitScreen] = useState(false);
  const history = useHistory();

  const [data, setData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    carerType: "",
    payType: "",
    gradeType: "",
    SpecialityType: "",
    address: "",
    city: "",
    postalCode: "",
    isActive: true,
    isOnHold: false,
    startDate: "",
    branch: "",
    refNo: "",
    NI_No: "",
    NISCC_NO: "",
    homeTel1: "",
    homeTel2: "",
    endDate: "",
    contacts: [{ title: "", firstName: "", lastName: "", email: "", phoneNumber: "", Address: "", relationship: "" }],

    documents: [{ expiredDate: null, issuedDate: null, file: null, refNo: "" }],
    romoveFiles: [],
    hospital: [],
  });
  const [initialData, setInitialData] = useState();
  const [currentStep, setCurrentStep] = useState(current);
  const [valid, setValid] = useState(true);
  const [hasChange, setHasChange] = useState(false);

  useEffect(() => {
    loadHospital();
    loadHomeCare();
  }, []);

  useEffect(() => {
    if (location?.state?.data) {
      callCarerDetailApi();
    }
  }, [location]);

  useEffect(() => {
    if (carerDetails !== undefined) {
      setData(copyData(carerDetails));
      setInitialData(copyData(carerDetails));
    }
  }, [carerDetails]);

  function copyData(fromObject) {
    let data = { ...fromObject };
    let newData = {
      id: location?.state?.data?.id,
      title: { label: toCapitalize(data?.title), value: data?.title },
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      // phoneNumber: carerDetails?.phone,
      phoneNumber: data?.phone ? { phoneNumber: data?.phone, dialCode: data?.countryCode ?? "44" } : {},
      gender: convertDropValue(data?.gender, true),
      dob: data?.dob ? moment(removeTimeFromServerDateString(data?.dob)).utc().toDate() : null,
      carerType: convertDropValue(data?.carerType),
      payType: data?.payType ? PayType.find((item) => item.value === data?.payType) : undefined, //{ label:  toCapitalize(data?.payType), value: data?.payType }
      latitude: data?.latitude,
      longitude: data?.longitude,
      gradeType: convertDropMultiValue(data?.grades),
      SpecialityType: convertDropMultiValue(data?.specialities),
      address: data?.address ? data?.address : "",
      city: data?.city ? data?.city : "",
      // postalCode: data?.postalCode ? data?.postalCode : "",
      isActive: data?.status === "ACTIVE" ? true : false,
      isOnHold: false,
      startDate: data?.contractStartDate
        ? moment(removeTimeFromServerDateString(data?.contractStartDate)).utc().toDate()
        : null,
      refNo: data?.profile?.refNo ? data?.profile?.refNo : "",
      NI_No: data?.profile?.niNo ? data?.profile?.niNo : "",
      NISCC_NO: data?.profile?.nisccNmcPin ? data?.profile?.nisccNmcPin : "",
      homeTel1: data?.profile?.homeTel1
        ? {
            phoneNumber: data?.profile?.homeTel1,
            dialCode: data?.profile?.homeTel1CountryCode ?? "44",
          }
        : {},
      homeTel2: data?.profile?.homeTel2
        ? {
            phoneNumber: data?.profile?.homeTel2,
            dialCode: data?.profile?.homeTel2CountryCode ?? "44",
          }
        : {},
      endDate: "",
      contacts:
        data?.kinContacts?.length > 0
          ? data?.kinContacts?.map((item, index) => ({
              id: item?.id,
              title: { label: toCapitalize(item?.title), value: data?.title } ?? "",
              firstName: item?.firstName ?? "",
              lastName: item?.lastName ?? "",
              email: item?.email ?? "",
              phoneNumber: item?.phone ? { phoneNumber: item?.phone, dialCode: item?.countryCode ?? "44" } : {},
              Address: item?.address ?? "",
              relationship: item?.relationship ?? "",
            }))
          : [
              {
                title: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                Address: "",
                relationship: "",
              },
            ],

      documents:
        data?.documents.length > 0
          ? data?.documents.map((item) => ({
              expiredDate: item?.expiryDate
                ? moment(removeTimeFromServerDateString(item?.expiryDate)).utc().toDate()
                : null,
              issuedDate: item?.issueDate
                ? moment(removeTimeFromServerDateString(item?.issueDate)).utc().toDate()
                : null,
              refNo: item?.refNo ? item?.refNo : "",
              file: { ...item, isOldFile: true, isLoading: false },
              carerDocumentId: item?.carerDocumentId,
              documentId: item?.document?.length > 0 ? item?.document[0]?.id : null,
            }))
          : [],
      //  [{ expiredDate: "", issuedDate: "", file: "" }]
      romoveFiles: [],
      hospital: data?.banClients,
      cover: data?.carerImage,
    };
    return newData;
  }

  const carerWizardContext = useMemo(
    () => ({
      data: data,
      updateData: (key, value) => {
        setData((prev) => ({
          ...prev,
          [key]: value,
        }));
      },
    }),
    [data]
  );

  const jump = (step) => {
    // if(step === 1)
    if (!valid) {
      if (hasChange) {
        if (currentStep === 1) {
          onCarerDetailsForm(step, FormBackType.jump);
        } else if (currentStep === 2) {
          onCarerOtherForm(step, FormBackType.jump);
        } else if (currentStep === 3) {
          onCarerKinContactForm(step, FormBackType.jump);
        } else if (currentStep === 4) {
          onCarerClientBanForm(step, FormBackType.jump);
        } else if (currentStep === 5) {
          onCarerClientBanForm(step, FormBackType.jump);
        }
        //  else if (currentStep === 6) {
        // }
      } else {
        setCurrentStep(step);
      }
    }
  };

  const prevStep = () => {
    if (!valid) {
      if (hasChange) {
        if (currentStep === 1) {
          onCarerDetailsForm(0, FormBackType.prevBack);
        } else if (currentStep === 2) {
          onCarerOtherForm(1, FormBackType.prevBack);
        } else if (currentStep === 3) {
          onCarerKinContactForm(2, FormBackType.prevBack);
        } else if (currentStep === 4) {
          onCarerClientBanForm(3, FormBackType.prevBack);
        } else if (currentStep === 5) {
          onCarerClientBanForm(4, FormBackType.prevBack);
        }
      } else {
        if (currentStep === 1) {
          history.goBack();
        } else {
          setCurrentStep(currentStep - 1);
        }
      }
    } else {
      setData({ ...initialData });
      setInitialData({ ...initialData });
      if (currentStep === 1) {
        history.goBack();
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const backButtonClick = () => {
    if (!valid) {
      if (hasChange) {
        if (currentStep === 1) {
          onCarerDetailsForm(0, FormBackType.directBack);
        } else if (currentStep === 2) {
          onCarerOtherForm(0, FormBackType.directBack);
        } else if (currentStep === 3) {
          onCarerKinContactForm(0, FormBackType.directBack);
        } else if (currentStep === 4) {
          onCarerClientBanForm(0, FormBackType.directBack);
        } else if (currentStep === 5) {
          onCarerClientBanForm(0, FormBackType.directBack);
        }
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  function loadHospital() {
    listHospitalAPI(
      {
        pageInfo: {
          pageIndex: 1,
          itemsPerPage: 200,
        },
        clientType: "HOSPITAL",
      },
      (list, error) => {
        if (error === null) {
          setHospitalList(list?.list);
        }
      }
    );
  }

  function loadHomeCare() {
    listHospitalAPI(
      {
        pageInfo: {
          pageIndex: 1,
          itemsPerPage: 200,
        },
        clientType: "HOMECARE",
      },
      (list, error) => {
        if (error === null) {
          sethomeCareList(list?.list);
        }
      }
    );
  }

  const callCarerDetailApi = () => {
    setInitScreen(true);
    carerDetailApi(location?.state?.data?.id, (data, error) => {
      if (error === null) {
        setCarerDetails(data?.data);
      }
      setInitScreen(false);
    });
  };

  // const handleCancel = (isBackType, jump) => {

  // };

  const onCarerDetailsForm = (jump, isBackType, isNext) => {
    if (hasChange && !isNext) {
      ConfirmationAlert(
        "Are you sure!",
        "Do you want to save carer with changes" + " " + "?",
        () => {
          saveCarerDetails(jump, isBackType);
        },
        () => {
          // handleCancel(isBackType, jump);
          setData({ ...initialData });
          setInitialData({ ...initialData });
          if (isBackType === FormBackType.directBack) {
            history.goBack();
          } else if (isBackType === FormBackType.prevBack) {
            history.goBack();
          } else if (isBackType === FormBackType.jump) {
            setCurrentStep(jump);
          }
        }
      );
    } else {
      if (isNext && hasChange) {
        // Call save API directly without confirmation alert
        saveCarerDetails(jump, isBackType);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const onCarerOtherForm = (jump, isBackType, isNext) => {
    if (hasChange && !isNext) {
      ConfirmationAlert(
        "Are you sure!",
        "Do you want to save the data of others" + " " + "?",
        () => {
          saveCarerOthers(jump, isBackType);
        },
        () => {
          setData({ ...initialData });
          setInitialData({ ...initialData });

          if (isBackType === FormBackType.directBack) {
            history.goBack();
          } else if (isBackType === FormBackType.prevBack) {
            setCurrentStep(currentStep - 1);
          } else if (isBackType === FormBackType.jump) {
            setCurrentStep(jump);
          }
          // handleCancel(isBackType, jump);
        }
      );
    } else {
      if (isNext && hasChange) {
        // Call save API directly without confirmation alert
        saveCarerOthers(jump, isBackType);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const onCarerKinContactForm = (jump, isBackType, isNext) => {
    if (hasChange && !isNext) {
      ConfirmationAlert(
        "Are you sure!",
        "Do you want to save the data of the Kin contact" + " " + "?",
        () => {
          saveCarerKinContact(jump, isBackType);
        },
        () => {
          setData((prev) => ({
            ...prev,
            contacts: _.cloneDeep(initialData.contacts),
          }));
          if (isBackType === FormBackType.directBack) {
            history.goBack();
          } else if (isBackType === FormBackType.prevBack) {
            setCurrentStep(currentStep - 1);
          } else if (isBackType === FormBackType.jump) {
            setCurrentStep(jump);
          }
          // handleCancel(isBackType, jump);
        }
      );
    } else {
      if (isNext && hasChange) {
        // Call save API directly without confirmation alert
        saveCarerKinContact(jump, isBackType);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const onCarerClientBanForm = (jump, isBackType, isNext) => {
    if (hasChange && !isNext) {
      ConfirmationAlert(
        "Are you sure!",
        "Do you want to save the data of the Client" + " " + "?",

        () => {
          saveCarerClientBanForm(jump, isBackType);
        },
        () => {
          setData((prev) => ({
            ...prev,
            hospital: _.cloneDeep(initialData.hospital),
          }));
          if (isBackType === FormBackType.directBack) {
            history.goBack();
          } else if (isBackType === FormBackType.prevBack) {
            setCurrentStep(currentStep - 1);
          } else if (isBackType === FormBackType.jump) {
            setCurrentStep(jump);
          }
          // handleCancel(isBackType, jump);
        }
      );
    } else {
      if (isNext && hasChange) {
        // Call save API directly without confirmation alert
        saveCarerClientBanForm(jump, isBackType);
      } else {
        if (jump === 0) {
          history.goBack();
        } else {
          setCurrentStep(jump);
        }
      }
    }
  };

  const saveCarerDetails = (jump, isBackType) => {
    carerDetailFormAPI(data, (result, error) => {
      if (error === null) {
        let text =
          "Carer" +
          " " +
          data?.firstName +
          " " +
          data?.lastName +
          (data?.id ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        if (jump === undefined || jump === null) {
          setCurrentStep(currentStep + 1);
        } else {
          if (jump === 0) {
            history.goBack();
          } else {
            setCurrentStep(jump);
          }
        }
        showSuccessToast(text);
        if (!location?.state?.data?.id) {
          setData({
            ...data,
            id: result?.id,
          });
          setInitialData({
            ...data,
            id: result?.id,
          });
        } else {
          setData({ ...data });
          setInitialData({ ...data });
        }
      } else {
        showErrorToast(error.message);
        setCurrentStep(currentStep);
      }
    });
  };

  const saveCarerOthers = (jump, isBackType) => {
    carerOtherFormAPI(data, (result, error) => {
      if (error === null) {
        let text =
          "Carer" +
          " " +
          data?.firstName +
          " " +
          data?.lastName +
          (data?.id ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);

        if (jump === undefined || jump === null) {
          setCurrentStep(currentStep + 1);
        } else {
          if (jump === 0) {
            history.goBack();
          } else {
            setCurrentStep(jump);
          }
        }
        setInitialData({ ...data });
        setData({ ...data });
      } else {
        showErrorToast(error.message);
        setCurrentStep(currentStep);
      }
    });
  };

  const saveCarerKinContact = (jump, isBackType) => {
    carerKinContactFormAPI(data, (result, error) => {
      if (error === null) {
        let text =
          "Carer" +
          " " +
          data?.firstName +
          " " +
          data?.lastName +
          (data?.id ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        setInitialData(_.cloneDeep(data));

        if (jump === undefined || jump === null) {
          setCurrentStep(currentStep + 1);
        } else {
          if (jump === 0) {
            history.goBack();
          } else {
            setCurrentStep(jump);
          }
        }
      } else {
        showErrorToast(error.message);
        setCurrentStep(currentStep);
      }
    });
  };

  const saveCarerClientBanForm = (jump, isBackType) => {
    carerClientBanFormAPI(data, (result, error) => {
      if (error === null) {
        let text =
          "Carer" +
          " " +
          data?.firstName +
          " " +
          data?.lastName +
          (data?.id ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        setInitialData(_.cloneDeep(data));

        if (jump === undefined || jump === null) {
          history.goBack(); // Go back after saving data
        } else {
          if (jump === 0) {
            history.goBack();
          } else {
            setCurrentStep(jump);
          }
        }
      } else {
        showErrorToast(error.message);
        setCurrentStep(currentStep);
      }
    });
  };

  let stepComponent;
  switch (currentStep) {
    case 1:
      stepComponent = (
        <div className="step">
          <CarerDetailsForm
            initScreen={initScreen}
            isValid={(status, hasChanges) => {
              if (status === true) {
                setValid(true);
              } else {
                setValid(false);
              }
              if (hasChanges === true) {
                setHasChange(true);
              } else {
                setHasChange(false);
              }
            }}
            // onNext={() => onCarerDetailsForm(2)}
            onNext={() => onCarerDetailsForm(2, undefined, true)}
            onPrev={() => prevStep()}
          ></CarerDetailsForm>
        </div>
      );
      break;
    case 2:
      stepComponent = (
        <div className="step">
          <CarerOthers
            isValid={(status, hasChanges) => {
              if (status === true) {
                setValid(true);
              } else {
                setValid(false);
              }
              if (hasChanges === true) {
                setHasChange(true);
              } else {
                setHasChange(false);
              }
            }}
            onNext={() => onCarerOtherForm(3, undefined, true)}
            onPrev={() => prevStep()}
          ></CarerOthers>
        </div>
      );
      break;
    case 3:
      stepComponent = (
        <div className="step">
          <CarereContact
            isValid={(status, hasChanges) => {
              if (status === true) {
                setValid(true);
              } else {
                setValid(false);
              }
              if (hasChanges === true) {
                setHasChange(true);
              } else {
                setHasChange(false);
              }
            }}
            onNext={() => onCarerKinContactForm(4, undefined, true)}
            onPrev={() => prevStep()}
          ></CarereContact>
        </div>
      );
      break;
    case 4:
      stepComponent = (
        <div className="step">
          <CarerHospital
            isValid={(status, hasChanges) => {
              if (status === true) {
                setValid(true);
              } else {
                setValid(false);
              }
              if (hasChanges === true) {
                setHasChange(true);
              } else {
                setHasChange(false);
              }
            }}
            onNext={() => onCarerClientBanForm(5, undefined, true)}
            onPrev={() => prevStep()}
          ></CarerHospital>
        </div>
      );
      break;
    case 5:
      stepComponent = (
        <div className="step">
          <CarerHomeCare
            isValid={(status, hasChanges) => {
              if (status === true) {
                setValid(true);
              } else {
                setValid(false);
              }
              if (hasChanges === true) {
                setHasChange(true);
              } else {
                setHasChange(false);
              }
            }}
            onNext={() => {
              onCarerClientBanForm(0, undefined, true);
            }}
            onPrev={() => prevStep()}
          ></CarerHomeCare>
        </div>
      );
      break;
    // case 6:
    //   stepComponent = (
    //     <div className="step">
    //       <CarerDocuments onNext={() => nextStep()} onPrev={() => prevStep()}></CarerDocuments>
    //     </div>
    //   );
    //   break;
    default:
      stepComponent = null;
  }

  return (
    <React.Fragment>
      <Head title="Carer" />
      <Content>
        <Block className="wide-md mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {carerDetails
                    ? `Edit Carer - ${carerDetails?.firstName + " " + carerDetails?.lastName}`
                    : `Add Carer`}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton onClick={() => backButtonClick()} />
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewAltCard>
            <div className="nk-wizard nk-wizard-simple wizard clearfix">
              <CarerWizardContext.Provider value={carerWizardContext}>
                <Header current={currentStep} jump={jump} valid={valid} />
                {stepComponent}
              </CarerWizardContext.Provider>
            </div>
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddCarer;
