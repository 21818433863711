import React, { useEffect, useRef } from "react";
import { AnnsModal, Col, FormInput, FormInputType, Icon, TemplateDropdown } from "../../../../components/Component";
import { Form, Spinner } from "reactstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import RichTextEditor, { EditorValue } from "react-rte";
import { InvoiceFileType, bytesToMegaBytes, toolbarConfig } from "../../../../utils/Utils";
import { AppText, EMAIL_REGX, showErrorToast, showSuccessToast } from "../../../../utils";
import { iconsType } from "../../../../layout/fileIcon/FileIcon";
import { fileUploadApi } from "../../carers/CarerData";
import { getTemplateDetailAPI } from "../../others/template/TemplateData";
import TemplateInfo from "../../others/template/templateInfo";
import { invoiceContactListApi, sentEmailAPI, sentInvoiceEmailAPI } from "../InvoiceTableData";
import TagInput from "../../../../components/input/TagInput/TagInput";
import { Editor } from "@tinymce/tinymce-react";

const InvoiceEmail = ({ visible, item, onSaved, onClosed }) => {
  const editorRef = useRef(null);
  const { errors, register, handleSubmit } = useForm();
  const [emailData, setEmailData] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (visible) {
      if (item) {
        if (Array.isArray(item)) {
          const clientIds = item.every((it, index, array) => it.client?.id === array[0]?.client?.id);
          clientIds && loadToEmail(item[0]?.client?.id);
          let invoiceFile = item.map((item) => item?.invoiceFile);
          setUploadedFiles(invoiceFile);
        } else {
          loadToEmail(item?.client?.id);
          setUploadedFiles([item?.invoiceFile]);
        }
      } else {
        setEmailData({ ...emailData, fromEmail: "", toEmail: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  useEffect(() => {
    if (
      emailData?.toEmail === undefined ||
      !emailData?.tamplate ||
      emailData?.subject === "" ||
      emailData?.html === "<p><br></p>"
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [emailData]);

  useEffect(() => {
    loadTemplateDetails();
  }, [emailData?.tamplate]);

  useEffect(() => {
    const descriptionValue = RichTextEditor.createValueFromString(emailData?.tamplateDetails?.description, "html");
    setEmailData((prev) => ({
      ...prev,
      subject: emailData?.tamplateDetails?.subject,
      description: emailData?.tamplateDetails?.description,
      html: descriptionValue,
    }));

    if (emailData?.tamplateDetails?.templateFiles) {
      setUploadedFiles((prevFiles) => {
        const templateFiles = emailData?.tamplateDetails?.templateFiles || [];
        return [...prevFiles, ...templateFiles];
      });
    }
  }, [emailData?.tamplateDetails]);

  const close = () => {
    setEmailData({});
    setUploadedFiles([]);
    setRemoveFileIds([]);
    onClosed();
  };
  // file fuctions ------------------------>

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;
      fileDetails.fileType = InvoiceFileType.new;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile

    setUploadedFiles(updatedfiles);
  };

  // API CALLING----------------------------------------
  const uploadFile = (file, index) => {
    fileUploadApi(file, "TEMPLATE", (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const loadTemplateDetails = () => {
    emailData?.tamplate?.id &&
      getTemplateDetailAPI({ templateId: emailData?.tamplate?.id }, (data, error) => {
        if (error === null) {
          setEmailData({ ...emailData, tamplateDetails: data });
        }
      });
  };

  const onFormSubmit = () => {
    setLoading(true);
    let templateFileIds = uploadedFiles
      .filter((item) => item.fileType === InvoiceFileType.template)
      ?.map((item) => item.id);
    let invoiceFileIds = uploadedFiles
      .filter((item) => item.fileType === InvoiceFileType.invoice)
      ?.map((item) => item.id);
    let newFileIds = uploadedFiles.filter((item) => item.fileType === InvoiceFileType.new)?.map((item) => item.id);

    sentInvoiceEmailAPI(emailData, templateFileIds, invoiceFileIds, newFileIds, (_, error) => {
      if (error === null) {
        let text = "Email sent successfully";
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const loadToEmail = (clientId) => {
    invoiceContactListApi(clientId, (data, error) => {
      if (error === null) {
        const invoiceContact = data?.list?.find((contact) => contact.type === "INVOICE");
        const contactToDisplay = invoiceContact || data?.list?.find((contact) => contact.type === "CONTACT");
        const contactEmail = contactToDisplay ? contactToDisplay.email : "";
        setEmailData({
          ...emailData,
          invoiceIds: Array.isArray(item) ? item.map((item) => item.id) : [item?.id],
          toEmail: [contactEmail],
        });
      } else {
        showErrorToast(error.message);
      }
    });
  };

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  return (
    <>
      <AnnsModal
        actionButtonTitle="Send"
        size={"md"}
        isOpen={visible}
        toggle={() => close()}
        title={"Send Invoice"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit()}>
          {/* <ul className="data-list is-compact"> */}

          <Col className="col-md-12 ">
            <div className="form-group">
              <div className="d-flex align-items-center mb-1">
                <label className="form-label m-0">Select template</label>

                {emailData?.tamplate && (
                  <span className="ms-1 d-flex align-items-center cursor-pointer" onClick={() => setModal(true)}>
                    <Icon className={"fs-4"} name="info" />
                  </span>
                )}
              </div>

              <div className="form-control-wrap">
                <TemplateDropdown
                  value={emailData?.tamplate}
                  onSelect={(tamplate) => {
                    setUploadedFiles(uploadedFiles.filter((item) => item.fileType !== InvoiceFileType.template));
                    setEmailData({ ...emailData, tamplate: tamplate });
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md="12">
            <div className="form-group">
              <label className="form-label">To Email:</label>
              <div className="form-control-wrap">
                <TagInput
                  value={Array.isArray(emailData?.toEmail) ? emailData?.toEmail.join(", ") : ""}
                  settings={{
                    pattern: EMAIL_REGX,
                    keepInvalidTags: true,
                    validate: (data) => {
                      return EMAIL_REGX.test(data.value);
                    },
                  }}
                  onChange={(emails) => {
                    setEmailData((prev) => ({
                      ...prev,
                      toEmail: emails.map((emailObj) => emailObj.value),
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  type={FormInputType.default}
                  label={"Subject :"}
                  // value={trustData?.email ?? ""}
                  placeholder={"Enter e-mail"}
                  value={emailData?.subject ?? ""}
                  onTextChange={(text) => {
                    setEmailData((prev) => ({
                      ...prev,
                      subject: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            {/* <div className="form-group">
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={emailData?.description ?? ""}
                  onChange={(e) => {
                    setEmailData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div> */}
            {/* <RichTextEditor
              toolbarConfig={toolbarConfig}
              editorClassName="custom-editor-container"
              value={
                emailData?.html?._cache?.html === undefined || emailData?.html?._cache?.html === null
                  ? EditorValue?.createEmpty()
                  : emailData?.html
              }
              onChange={(value) => {
                setEmailData((prev) => ({
                  ...prev,
                  html: value, // Update HTML content directly
                  description: value.toString("html"), // Convert value to HTML string
                }));
              }}
            /> */}
            <Editor 
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={emailData?.description ?? EditorValue?.createEmpty()} // Set the HTML content as the editor value
              apiKey="ckmat7h6bcf5ocsg0s9bvbusma7f9pdvq8nx14i8uc3oaf73"
              onEditorChange={(value) => {
                setEmailData((prev) => ({
                  ...prev,
                  html: value,
                  description: value.toString("html"), // Update the description property with the new text
                }));
              }}
              init={{
                menubar: false,
                statusbar: false,
                height: 450,
                automatic_uploads: false,
                file_picker_callback: function (callback, value, meta) {
                  // Provide file and text for the link dialog
                  if (meta.filetype === "file") {
                    callback("mypage.html", { text: "My text" });
                  }

                  // Provide image and alt text for the image dialog
                  if (meta.filetype === "image") {
                    callback("myimage.jpg", { alt: "My alt text" });
                  }

                  // Provide alternative source and posted for the media dialog
                  if (meta.filetype === "media") {
                    callback("movie.mp4", {
                      source2: "alt.ogg",
                      poster: "image.jpg",
                    });
                  }
                },
                fontsize_formats:
                  "8px 9px 10px 11px 12px 14px 15px 16px 17px 18px 19px 20px 24px 30px 36px 48px 60px 72px 96px",
                paste_data_images: true,
                // images_upload_url: "postAcceptor.php",

                /* we override default upload handler to simulate successful upload*/
                images_upload_handler: function (blobInfo, success, failure) {
                  setTimeout(function () {
                    success();
                  }, 2000);
                },
                content_style: "body { font-size: 14pt; }",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code",
                ],
                toolbar1:
                  "preview | undo redo | formatselect | " +
                  "bold italic underline | code | numlist bullist | " +
                  "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 35px 36px",
              }}
            />
          </Col>

          <Col className="col-12  mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 text-primary" />
                <span className="text-primary"> Attach document</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.doc,.docx,.xlsx,.xls,.jpg,.jpeg"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                  e.target.value = '';
                }}
              />
            </div>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file, ind) => {
                return (
                  <React.Fragment key={`file-${ind}`}>
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {file?.mimeType ? (
                          <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span
                            className="title"
                            style={{ cursor: "pointer" }} // Add cursor pointer style
                            onClick={() => openFileInNewTab(file)} // Handle click event
                          >
                            {file?.name ? file?.name : file?.fileName ? file?.fileName : "Invoice"}
                          </span>
                          {/* <span className="title">{file?.name}</span> */}
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {file?.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span onClick={() => handleFileRemove(ind)}>
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            {}
          </Col>
          {/* </ul> */}
          <TemplateInfo
            templateData={emailData?.tamplateDetails}
            visible={modal}
            onClosed={() => {
              setModal(false);
            }}
          />
        </Form>
      </AnnsModal>
    </>
  );
};

export default InvoiceEmail;
