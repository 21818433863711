import React, { useEffect, useRef, useState } from "react";
import { WageStatusDropdown } from "../../../components/dropdowns/WageStatusDropdown";
import {
  ActionButton,
  AgencyDropdown,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  CarersDropDown,
  HospitalDropDown,
  MoreOptions,
  PayTypeDropDown,
  PreviewCard,
} from "../../../components/Component";
import DateRangePicker from "../../../components/input/date-time/DateRangePicker";
import Head from "../../../layout/head/Head";
import { exportWageList } from "./WageTableData";
import { openLinkInNewTab, toCapitalize } from "../../../utils/Functions";
import { WageStatusOption } from "../../../utils/Utils";
import WageList from "./WageList";
import Content from "../../../layout/content/Content";
import { ButtonGroup } from "reactstrap";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Wage = () => {
  const location = useLocation();
  const data = location?.state?.data;
  const [status, setStatus] = useState({ value: WageStatusOption.pending });
  const [activeButton, setActiveButton] = useState(0); // 0 corresponds to the index of the first button
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [carer, setCarer] = useState();
  const [client, setClient] = useState();
  const [agency, setAgency] = useState({ id: 0, label: "All", value: "" });
  const [payType, setPayType] = useState({ id: 0, label: "All", value: "" });
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowIds = selectedRows.map((row) => row?.wageId);
  const wagesRef = useRef(null);

  const val = {
    ...WageStatusOption,
  };

  const wageStatus = Object.values(val);

  useEffect(() => {
    if (location?.state?.isWageListMove) {
      setStartDate(data?.startDate);
      setEndDate(data?.endDate);
    }
  }, [location?.state?.isWageListMove]);

  function exportWages(ev) {
    if (!startDate || !endDate) return;
    setLoadingExport(true);
    let params = {
      startDate: startDate,
      endDate: endDate,
      carerId: carer?.id,
      clientId: client?.id,
      agencyId: agency?.id ?? null,
      payType: payType?.value ?? null,
      status: status?.value ?? WageStatusOption.pending,
    };

    exportWageList(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl, ev);
      }
      setLoadingExport(false);
    });
  }

  const handleDatePickerChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleButtonClick = (item) => {
    const newValue = item === "ALL" ? "" : item;
    setActiveButton(wageStatus.findIndex((valueItem) => valueItem === item));
    setStatus({ value: newValue });
  };

  return (
    <React.Fragment>
      <Head title="Wages" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Wages</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <HospitalDropDown
                    isClearable
                    value={client}
                    className="w-225px"
                    onSelect={(selectedClient) => {
                      setClient(selectedClient);
                    }}
                  />
                </li>
                <li>
                  <CarersDropDown
                    isClearable
                    value={carer}
                    className="w-225px"
                    onSelect={(e) => {
                      setCarer(e);
                    }}
                  />
                </li>
                <li>
                  <span className="">
                    {/* <AgencyDropdown
                      isShowAll={true}
                      className="w-125px"
                      value={agency}
                      onSelect={(e) => {
                        setAgency(e);
                      }}
                    /> */}

                    <PayTypeDropDown
                      className="w-150px"
                      value={payType}
                      isShowAll={true}
                      selectedValue={payType?.value}
                      onSelect={(e) => {
                        setPayType(e);
                      }}
                    />
                  </span>
                </li>
                <li>
                  <ButtonGroup>
                    {wageStatus?.map((item, index) => (
                      <Button
                        key={index}
                        className={`btn-dim btn-white border-right border
                             ${
                               activeButton === wageStatus?.findIndex((valueItem) => valueItem === item)
                                 ? "bg-purple btn-lighter text-white"
                                 : "bg-white text-gray"
                             }`}
                        onClick={() => handleButtonClick(item)}
                      >
                        {toCapitalize(item)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </li>
                <li>
                  <DateRangePicker
                    onDateChange={handleDatePickerChange}
                    moveDate={location?.state?.isWageListMove ? { startDate, endDate } : undefined}
                  />
                </li>
                {isAllowToAccess(UserPermissionType.wage, UserActionType.export) && (
                  <li>
                    <ActionButton title="Export" loading={loadingExport} size="md" onClick={(ev) => exportWages(ev)} />
                  </li>
                )}
                <li>
                  {selectedRows?.length > 0 && (
                    <span className="">
                      <MoreOptions
                        className="text-bg-light"
                        options={[
                          {
                            icon: "emails",
                            text: "Move to timesheet",
                            action: () => {
                              wagesRef.current?.moveTimesheet(selectedRowIds);
                            },
                            hide: !isAllowToAccess(UserPermissionType.wage, UserActionType.moveToTimesheet),
                          },
                          {
                            icon: "calendar-booking",
                            text: "Finalised",
                            action: () => {
                              wagesRef.current?.moveFinalised(selectedRowIds);
                            },
                            hide: !isAllowToAccess(UserPermissionType.wage, UserActionType.finalised),
                          },
                        ]}
                      />
                    </span>
                  )}
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"bg-transparent wage-list-table"}>
            <div className="tableWagelistingText m-n4">
              <WageList
                startDate={startDate}
                ref={wagesRef}
                endDate={endDate}
                carerId={carer?.id}
                clientId={client?.id}
                agencyId={agency?.id}
                payType={payType?.value}
                status={status?.value ?? WageStatusOption.pending}
                onRowSelectionChange={(rows) => setSelectedRows(rows)}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Wage;
