import React, { useEffect, useRef, useState } from "react";
import { Badge } from "reactstrap";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  FormInput,
  Icon,
  LoadingComponent,
  MoreOptions,
  PreviewAltCard,
  PreviewCard,
  RotaStatusDropDown,
  Row,
  ShiftDropDown,
  StatusBadge,
  TimesheetDropdown,
  UserAvatar,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { NavigationTabs } from "../../../components/navigationTabs/NavigationTabs";
import Content from "../../../layout/content/Content";
import NoteList, { NoteUserType } from "../notes/NoteList";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { CarerTooltipButton, carerDetailApi } from "./CarerData";
import { convertHexToColor, formatUKPhoneNumber, openLinkInNewTab, toCapitalize } from "../../../utils/Functions";
import Availbility from "../availbility/Availbility";
import { RotaType } from "../rotas/RotaList";
import CarerDocumentList from "./CarerDocumentList";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import moment from "moment";
import RotaListing from "../rotas/RotaCalendar";
import DateRangePicker from "../../../components/input/date-time/DateRangePicker";
import { GradeTypeDropDownWithoutCarer } from "../../../components/dropdowns/GradeTypeDropDown";
import WageList from "../invoices/WageList";
import { WageStatusDropdown } from "../../../components/dropdowns/WageStatusDropdown";
import { exportWageList } from "../invoices/WageTableData";
import { PayType, TimeSheetType, TimesheetStatusType, WageStatusOption } from "../../../utils/Utils";
import Timesheets from "../timesheets/Timesheets";
import SendEmailModal from "../invoices/invoiceEmail/SendEmailModal";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";
import AuditTrailDatatable from "../clients/hospital/AuditTrailDataTable";

const CarerDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const carerId = location.state?.id;

  const [showModal, setShowModal] = useState({
    note: false,
    rate: false,
    ward: false,
    email: false,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [carerDetails, setCarerDetails] = useState();
  const [totalPendingTimesheet, setTotalPendingTimesheet] = useState();
  const [sideBar, setSidebar] = useState(false);
  const [current, setCurrent] = useState(1);
  const [timesheetStartDate, setTimesheetStartDate] = useState(""); // Initial start date
  const [timesheetEndDate, setTimesheetEndDate] = useState(""); // Initial end date
  const [searchText, setSearchText] = useState();
  const [totalItem, setTotalItems] = useState();
  const [timeSheetStatus, setTimeSheetStatus] = useState({
    label: toCapitalize(TimesheetStatusType.pending),
    value: TimesheetStatusType.pending,
  });
  const [grade, setGrade] = useState();
  const [initScreen, setInitScreen] = useState(true);
  const [shiftValue, setShiftValue] = useState();
  const [rotaStatus, setRotaStatus] = useState({ id: 1, label: "Open", value: "OPEN" });
  const [status, setStatus] = useState();
  const [loadingExport, setLoadingExport] = useState(false);
  const [wagesCall, setWagesCall] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedWageRows, setSelectedWageRows] = useState([]);
  const selectedWageRowIds = selectedWageRows?.map((row) => row?.id);
  const timesheetRef = useRef(null);

  const getTotalItem = (totalItem) => {
    // the callback. Use a better name
    setTotalItems(totalItem);
  };

  useEffect(() => {
    if (location.state?.isMoveCarerDocuments) {
      setActiveTab(3);
    }
  }, [location.state?.isMoveCarerDocuments]);

  useEffect(() => {
    setTimesheetEndDate();
    setTimesheetStartDate();
  }, [activeTab]);

  useEffect(() => {
    callCarerDetailApi();
  }, [location, carerId]);

  const handleDatePickerChange = (startDate, endDate) => {
    setTimesheetStartDate(startDate);
    setTimesheetEndDate(endDate);
  };
  function exportWages(ev) {
    if (!timesheetStartDate || !timesheetEndDate) return;
    setLoadingExport(true);
    let params = {
      startDate: timesheetStartDate,
      endDate: timesheetEndDate,
      carerId: carerId,
      status: status?.value ?? WageStatusOption.pending,
    };

    exportWageList(params, (data, error) => {
      if (error === null) {
        openLinkInNewTab(data.fileUrl, ev);
      }
      setLoadingExport(false);
    });
  }

  function exportToTimesheet(wageIds) {
    let params = {
      // startDate: startDate,
      // endDate: endDate,
      // clientId: client?.id,
      // carerId: carer?.id,
      // status: status?.value ?? WageStatusOption.pending,
    };

    // exportWageList(params, (data, error) => {
    //   if (error === null) {
    //     openLinkInNewTab(data.fileUrl, ev);
    //   }
    //   setLoadingExport(false);
    // });
  }

  const tabs = [
    {
      text: "Rota",
      icon: "flickr-round",
      content: (
        <>
          <div className="d-flex justify-content-between mt-4 align-center px-4 px-xxl-0">
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <GradeTypeDropDownWithoutCarer
                    value={grade}
                    isClearable
                    className="w-225px"
                    placeholder={"Grade Type"}
                    onSelect={(selectedGrade) => {
                      setGrade(selectedGrade);
                    }}
                  />
                </li>
                <li>
                  <RotaStatusDropDown
                    value={rotaStatus}
                    className="w-125px "
                    onSelect={(e) => {
                      setRotaStatus(e);
                    }}
                  />
                </li>
                <li>
                  <ShiftDropDown
                    className={"w-120px"}
                    isAny={true}
                    value={shiftValue}
                    onSelect={(selectedShift) => {
                      setShiftValue(selectedShift);
                    }}
                  />
                </li>
                <li>
                  <DateRangePicker onDateChange={handleDatePickerChange} />
                </li>
              </ul>
            </div>
          </div>
          {carerDetails?.id ? (
            <div className="py-4 px-4 px-xxl-0">
              <RotaListing
                carerId={carerDetails?.id}
                type={RotaType.carer}
                startDate={timesheetStartDate}
                endDate={timesheetEndDate}
                gradeId={grade?.id}
                status={rotaStatus?.value}
                shiftType={shiftValue}
              />
            </div>
          ) : null}
        </>
      ),
      addButton: false,
    },
    {
      text: "Timesheet",
      icon: "table-view",
      content: (
        <>
          <div className="d-flex justify-content-between mt-2 align-center px-4 px-xxl-0">
            <div className="mb-2">
              <span className="text-dark fs-3 fw-bold">Timesheet</span>
              <br />
              {/* <AnnsBadge
                label={totalItem ? totalItem + "Pending Timesheet" : "0 Pending Timesheet"}
                className={"bg-success text-white carehomebadge"}
              /> */}
            </div>
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <FormInput
                    inputClass={"padding-important"}
                    isShowLabel={false}
                    value={searchText ?? ""}
                    placeholder="Request ID, Hospital, Ward"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </li>
                <li>
                  <TimesheetDropdown
                    value={timeSheetStatus}
                    className="w-225px"
                    onSelect={(selectedStatus) => {
                      setTimeSheetStatus(selectedStatus);
                    }}
                  />
                </li>
                <li>
                  <DateRangePicker onDateChange={handleDatePickerChange} />
                </li>
                <li>
                  {selectedRows?.length > 0 ? (
                    <li>
                      <span>
                        <MoreOptions
                          className="text-bg-light"
                          options={[
                            {
                              icon: "file-text",
                              text: "Move To Wages",
                              action: () => {
                                // onEdit(row);
                                timesheetRef.current?.moveToWages();
                                setWagesCall(true);
                              },
                            },
                          ]}
                        />
                      </span>
                    </li>
                  ) : null}
                </li>
              </ul>
            </div>
          </div>
          <div className="m-n4">
            <Timesheets
              ref={timesheetRef}
              carerId={carerDetails?.id}
              wagesCall={wagesCall}
              searchText={searchText}
              status={timeSheetStatus}
              type={TimeSheetType.carer}
              startDate={timesheetStartDate}
              endDate={timesheetEndDate}
              getTotalItem={getTotalItem}
              isShowMoreOption={(rows) => setSelectedRows(rows)}
            />
          </div>
        </>
      ),
      addButton: false,
    },
    {
      text: "Availability",
      icon: "activity",
      content: isCheckToList(UserPermissionType.availability) ? (
        <Availbility carerId={carerId} />
      ) : (
        <Block className={"pt-5"}>
          <PreviewAltCard>
            <span className="d-flex justify-content-center">You do not have permission</span>
          </PreviewAltCard>
        </Block>
      ),
      dateIndicator: true,
    },
    {
      text: "Documents",
      icon: "emails",
      content: (
        <>
          <div className="bg-transparent carer-detail-document px-4 px-xxl-0">
            <CarerDocumentList
              documentData={carerDetails?.documents}
              carerId={carerId}
              carerTypeID={carerDetails?.carerType?.id}
            />
          </div>
        </>
      ),
      addButton: false,
    },
    {
      text: "Wages",
      icon: "file-text",
      content: (
        <>
          <div className="d-flex justify-content-between mt-2 align-center px-4 px-xxl-0">
            <div className="">
              <span className="text-dark fs-3 fw-bold">Wages</span>
            </div>
            <div>
              <ul className="nk-block-tools g-2 d-flex flex-wrap justify-content-end">
                <li>
                  <WageStatusDropdown
                    value={status}
                    className="w-125px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </li>
                <li>
                  <DateRangePicker onDateChange={handleDatePickerChange} />
                </li>
                <li>
                  <ActionButton title="Export" loading={loadingExport} size="md" onClick={(ev) => exportWages(ev)} />
                </li>
                <li>
                  {selectedWageRowIds?.length > 0 && (
                    <span className="">
                      <MoreOptions
                        className="text-bg-light"
                        options={[
                          {
                            icon: "emails",
                            text: "Move to timesheet",
                            action: () => {
                              // onEdit(row);
                              // onEmail(selectedRows);
                              exportToTimesheet(selectedWageRowIds);
                            },
                          },
                        ]}
                      />
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="px-4 px-xxl-0">
            <PreviewCard className={"bg-transparent wage-list-table wage-list-border"}>
              <div className="tableWagelistingText m-n4">
                <WageList
                  startDate={timesheetStartDate}
                  type={NoteUserType.carer}
                  endDate={timesheetEndDate}
                  carerId={carerId}
                  status={status?.value ?? WageStatusOption.pending}
                  onRowSelectionChange={(rows) => setSelectedWageRows(rows)}
                />
              </div>
            </PreviewCard>
          </div>
        </>
      ),
      addButton: false,
    },
    {
      text: "Note",
      icon: "files",
      content: (
        <>
          <div className="px-4 px-xxl-0">
            <NoteList
              parent={carerDetails}
              type={NoteUserType.carer}
              isOpenAddModel={showModal.note}
              onCloseModel={() => setShowModal({ note: false })}
            />
          </div>
        </>
      ),
      addButton: true,
    },
    {
      text: "Audit Trail",
      icon: "sort-line",
      content: (
        <div className="px-4 px-xxl-0">
          <AuditTrailDatatable
            // startDate={month?.startDate}
            // endDate={month?.endDate}
            carerId={carerId}
          />
        </div>
      ),
      addButton: true,
    },

    // { text: "History", icon: "history" },
  ]; // State variable to track active tab, default is set to 0 (Jobs)

  const toggle = () => {
    setSidebar(!sideBar);
  };

  const onAddClick = () => {
    switch (tabs[activeTab].text) {
      case "Note":
        setShowModal({ note: true });
        break;
      default:
        break;
    }
  };

  const goTo = (text) => {
    switch (text) {
      case "Email":
        setShowModal({ ...showModal, email: true });

        break;
      case "Sms":
        // history.push(`${process.env.PUBLIC_URL}/sms`);
        break;
      case "Note":
        setActiveTab(5);
        setShowModal({ ...showModal, note: true });
        break;
      default:
        break;
    }
  };

  const callCarerDetailApi = () => {
    carerDetailApi(carerId, (data, error) => {
      if (error === null) {
        setCarerDetails(data?.data);
        setTotalPendingTimesheet(data?.totalPendingTimesheet);
      }
      setInitScreen(false);
    });
  };

  function getTabClassName(current, tabIndex) {
    if (current === tabIndex) {
      return "active-tab-client-detail";
    } else {
      return "pending-tab-client-detail";
    }
  }

  const onEditClick = (row) => {
    history.push({ pathname: `${process.env.PUBLIC_URL}/add-carer`, state: { data: carerDetails } });
  };

  return (
    <React.Fragment>
      <Head title="hospital-detail" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {`${carerDetails?.firstName || ""} ${carerDetails?.lastName || ""}`}
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    Last Booking: <span className="text-base">{"N/A"}</span>
                  </li>
                  <li>
                    Last Login <span className="text-base"> {"N/A"}</span>
                  </li>
                </ul>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center ">
                <span className="me-2">
                  {tabs[activeTab].addButton &&
                    tabs[activeTab]?.text === "Note" &&
                    isAllowToAccess(UserPermissionType.note, UserActionType.add) && (
                      <ActionButton title={`Add ${tabs[activeTab].text}`} size="sm" onClick={onAddClick} />
                    )}
                </span>
                <span className="me-2">
                  <BackButton />
                </span>
                {/* <span className="d-xl-none">
                  <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                    <Icon name="user-list-fill"></Icon>
                  </Button>
                </span> */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="gy-5">
            <Col className="col-12 col-lg-3 col-xl-3">
              <div className="card-content p-0">
                <PreviewAltCard className="card-inner-group" bodyClass={"p-0"}>
                  <div className="card-inner p-3">
                    <div className="d-flex align-center justify-content-between">
                      <StatusBadge status={carerDetails?.status} />
                      {/* <Icon name="edit" className="cursor-pointer fs-5" onClick={onEditClick} /> */}
                      <div className="job-edit-icon cursor-pointer" onClick={onEditClick}></div>
                    </div>
                    <div className="user-card user-card-s2 mt-xxl-0 p-0">
                      <div>
                        <UserAvatar
                          // className="carer-profile-section"
                          className={"user-avatar-width"}
                          image={carerDetails?.carerImage?.imageUrl}
                          text={`${carerDetails?.firstName?.charAt(0) || ""}${
                            carerDetails?.lastName?.charAt(0) || ""
                          }`.toUpperCase()}
                          imageAlt={"carerimage"}
                          customHex={carerDetails?.colourHex}
                        />
                        <div className="mt-2">
                          <h5 className="d-flex align-center justify-content-center client-detail-profile-title mb-0">
                            <span>
                              {toCapitalize((carerDetails?.firstName || "") + " " + (carerDetails?.lastName || ""))}
                            </span>
                          </h5>

                          <span className="carerdetails-otherdetails-head">
                            Reference ID: {carerDetails?.profile?.refNo ?? "N/A"}
                          </span>
                        </div>
                        <div>
                          {carerDetails?.grades.map((grade, index) => (
                            <span key={index}>
                              <GradeBadge
                                label={grade?.name}
                                className={"me-1 my-1"}
                                color={`outline-${convertHexToColor(grade?.colourHex)}`}
                              />
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carer-border-divide py-2">
                    <ul className="btn-toolbar justify-center gx-1">
                      {CarerTooltipButton.map((buttonItem, index) => (
                        <li
                          className="me-1"
                          key={index}
                          onClick={() => {
                            goTo(buttonItem?.text);
                          }}
                        >
                          <Icon name={buttonItem?.icon} />
                          <span className="cursor-pointer">{buttonItem?.text}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-inner py-2">
                    <div className="">
                      <div className="steps">
                        <ul className="d-flex text-center">
                          <li>
                            <span className="carerdetails-dob-head">Gender</span>
                            <br />
                            <span className="carerdetails-dob-text">
                              {toCapitalize(carerDetails?.gender?.name ?? "N/A")}
                            </span>
                          </li>
                          <li>
                            <span className="carerdetails-dob-head">Dob</span>
                            <br />
                            <span className="carerdetails-dob-text">
                              {moment(carerDetails?.dob).format("DD/MM/YYYY")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <div className={"steps"}>
                      <ul className={""}>
                        <li
                          onClick={() => setCurrent(1)}
                          className={`wizard-margin py-1 align-center" ${getTabClassName(current, 1)}`}
                        >
                          <div className="align-center justify-content-center">
                            <svg
                              className="me-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill={current === 1 ? "#ffffff" : "#333"}
                            >
                              <g clipPath="url(#clip0_524_310)">
                                <path d="M3 18V14.25H0V0H17.25V14.25H10.275L3 18ZM1.5 12.75H4.5V15.525L9.975 12.75H15.75V1.5H1.5V12.75Z" />
                                <path d="M6 6H3.75V8.25H6V6Z" />
                                <path d="M9.75 6H7.5V8.25H9.75V6Z" />
                                <path d="M13.5 6H11.25V8.25H13.5V6Z" />
                              </g>
                              <defs>
                                <clipPath id="clip0_524_310">
                                  <rect width="18" height="18" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span className={`cursor-pointer number" ${getTabClassName(current, 1)}`}>Contact</span>
                          </div>
                        </li>
                        <li
                          onClick={() => setCurrent(2)}
                          className={`wizard-margin py-1 align-center" ${getTabClassName(current, 2)}`}
                        >
                          <div className="align-center justify-content-center">
                            <svg
                              className="me-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="19"
                              viewBox="0 0 24 19"
                              fill={current === 2 ? "#FFFFFF" : "#666666"}
                            >
                              <path
                                d="M4.34607 5.83958C4.34607 3.2856 6.4271 1.20923 9.00153 1.20923C11.576 1.20923 13.657 3.2856 13.657 5.83958C13.657 8.39359 11.576 10.4699 9.00153 10.4699C6.4271 10.4699 4.34607 8.39359 4.34607 5.83958Z"
                                stroke={current === 2 ? "#FFFFFF" : "#666666"}
                                strokeWidth="1.5"
                              />
                              <path
                                d="M15.7172 8.83438C16.1292 7.91943 16.3582 6.9053 16.3582 5.83951C16.3582 4.77372 16.1292 3.75959 15.7172 2.84464C16.2171 2.56457 16.794 2.40479 17.41 2.40479C19.321 2.40479 20.8643 3.94585 20.8643 5.83951C20.8643 7.73317 19.321 9.27423 17.41 9.27423C16.794 9.27423 16.2171 9.11444 15.7172 8.83438Z"
                                stroke={current === 2 ? "#FFFFFF" : "#666666"}
                                strokeWidth="1.5"
                              />
                              <path
                                d="M14.544 13.9417L14.544 13.9417C15.936 14.5442 16.7761 15.5493 17.2322 17.0393C17.323 17.3359 17.1008 17.6437 16.7779 17.6437H1.22724C0.903201 17.6437 0.680155 17.3347 0.771411 17.0369L0.771412 17.0369C1.22796 15.5469 2.06929 14.542 3.46241 13.9401C4.79318 13.3652 6.60374 13.1655 9.00142 13.1655C11.4012 13.1655 13.2129 13.3656 14.544 13.9417Z"
                                stroke={current === 2 ? "#  FFFFFF" : "#666666"}
                                strokeWidth="1.5"
                              />
                              <path
                                d="M18.8167 14.1002L18.8167 14.1002C19.29 14.8131 19.9483 15.2524 20.6146 15.2524H22.7535C23.1006 15.2524 23.3337 14.9126 23.2192 14.598L23.2191 14.5978C23.2047 14.5581 23.1894 14.5182 23.1735 14.4781L18.8167 14.1002ZM18.8167 14.1002C18.2476 13.2432 17.5107 12.5284 16.5963 11.9725C16.9431 11.9682 17.2911 11.9689 17.6402 11.9695C17.7635 11.9697 17.8869 11.9699 18.0104 11.9699H18.019L18.0277 11.9702L18.0284 11.9703M18.8167 14.1002L18.0284 11.9703M21.3942 12.6012L21.7102 11.921L21.3942 12.6012ZM21.3942 12.6012C22.2627 13.0048 22.8324 13.6207 23.1735 14.478L18.0284 11.9703M21.3942 12.6012C20.5494 12.2086 19.441 12.0258 18.0284 11.9703"
                                stroke={current === 2 ? "#FFFFFF" : "#666666"}
                                strokeWidth="1.5"
                              />
                            </svg>
                            <span className={`cursor-pointer number" ${getTabClassName(current, 2)}`}>NOK</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-inner">
                    {current === 1 && (
                      <>
                        <Row className="g-3 active-tab-detail-text text-dark">
                          <Col>
                            <div className="d-flex align-center">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="21"
                                  viewBox="0 0 17 21"
                                  fill="none"
                                  className="me-2"
                                >
                                  <path
                                    d="M7.82141 19.9001C7.82141 19.9001 7.82183 19.9004 8.33341 19.2456L8.845 19.9004C8.54408 20.1333 8.12233 20.1331 7.82141 19.9001Z"
                                    fill="#666666"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.33336 5.14478C6.49239 5.14478 5 6.63024 5 8.46265C5 10.295 6.49239 11.7805 8.33336 11.7805C10.1743 11.7805 11.6667 10.295 11.6667 8.46265C11.6667 6.63024 10.1743 5.14478 8.33336 5.14478ZM6.66668 8.46265C6.66668 7.54644 7.41285 6.80371 8.33336 6.80371C9.25386 6.80371 10 7.54644 10 8.46265C10 9.37888 9.25386 10.1216 8.33336 10.1216C7.41285 10.1216 6.66668 9.37888 6.66668 8.46265Z"
                                    fill="#666666"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.82139 19.9001L8.33339 19.2456C8.84498 19.9004 8.84589 19.8997 8.84589 19.8997L8.84748 19.8985L8.85264 19.8944L8.87089 19.8802C8.88656 19.8678 8.90914 19.85 8.93823 19.8269C8.99623 19.7807 9.08014 19.7132 9.18614 19.6261C9.39806 19.452 9.69881 19.1992 10.0589 18.8806C10.7779 18.2444 11.739 17.3412 12.7028 16.2753C13.6643 15.212 14.6443 13.9694 15.3879 12.6537C16.1271 11.3456 16.6668 9.90522 16.6668 8.46253C16.6668 6.26265 15.7888 4.15286 14.226 2.5973C12.6632 1.04175 10.5436 0.167847 8.33339 0.167847C6.12324 0.167847 4.00361 1.04175 2.44079 2.5973C0.877981 4.15286 0 6.26265 0 8.46253C0 9.90522 0.539687 11.3456 1.2789 12.6537C2.02246 13.9694 3.00255 15.212 3.96395 16.2753C4.92777 17.3412 5.88889 18.2444 6.60787 18.8806C6.96796 19.1992 7.26872 19.452 7.48063 19.6261C7.58663 19.7132 7.67055 19.7807 7.72855 19.8269C7.75764 19.85 7.78022 19.8678 7.79589 19.8802L7.81414 19.8944L7.8193 19.8985L7.82139 19.9001ZM3.61932 3.77035C4.86957 2.5259 6.56527 1.82678 8.33339 1.82678C10.1015 1.82678 11.7972 2.5259 13.0475 3.77035C14.2977 5.01479 15.0001 6.70262 15.0001 8.46253C15.0001 9.50824 14.6023 10.66 13.9353 11.8403C13.2725 13.013 12.3776 14.1551 11.464 15.1655C10.5528 16.1732 9.6389 17.0326 8.95164 17.6407C8.71473 17.8503 8.50547 18.0294 8.33339 18.1738C8.1613 18.0294 7.95205 17.8503 7.71513 17.6407C7.02788 17.0326 6.11397 16.1732 5.20278 15.1655C4.28917 14.1551 3.39425 13.013 2.73154 11.8403C2.0645 10.66 1.66668 9.50824 1.66668 8.46253C1.66668 6.70262 2.36906 5.01479 3.61932 3.77035Z"
                                    fill="#666666"
                                  />
                                </svg>
                              </span>
                              {carerDetails?.address ? <span className="ms-1">{carerDetails?.address}</span> : "N/A"}
                            </div>
                          </Col>
                          {carerDetails?.phone && (
                            <Col>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  className="me-1"
                                >
                                  <path
                                    d="M14.2538 13.1226L13.7729 13.6264C13.7729 13.6264 12.6302 14.8239 9.51104 11.5553C6.3919 8.28661 7.53462 7.0891 7.53462 7.0891L7.83737 6.77185C8.58321 5.9903 8.65351 4.73554 8.0028 3.8195L6.67177 1.94567C5.8664 0.811905 4.31017 0.662134 3.38708 1.62946L1.73028 3.36566C1.27257 3.84531 0.965851 4.46707 1.00305 5.15682C1.09821 6.92141 1.85575 10.718 6.08287 15.1477C10.5655 19.8452 14.7716 20.0319 16.4916 19.863C17.0357 19.8095 17.5088 19.5175 17.89 19.118L19.3896 17.5466C20.4017 16.486 20.1163 14.6676 18.8212 13.9256L16.8046 12.7702C15.9542 12.283 14.9183 12.4261 14.2538 13.1226Z"
                                    stroke="#666666"
                                    strokeWidth="1.6"
                                  />
                                </svg>
                                <span className="ms-1">
                                  {formatUKPhoneNumber(carerDetails?.countryCode, carerDetails?.phone)}
                                </span>
                              </div>
                            </Col>
                          )}
                          {carerDetails?.email && (
                            <Col>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="16"
                                  viewBox="0 0 20 16"
                                  fill="none"
                                  className="me-1"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.833333 0.804199L0 1.63366V14.9051L0.833333 15.7345H19.1667L20 14.9051V1.63366L19.1667 0.804199H0.833333ZM1.66667 3.5088V14.0756H18.3333V3.50852L9.99989 11.0493L1.66667 3.5088ZM17.011 2.46312H2.98873L9.99989 8.80729L17.011 2.46312Z"
                                    fill="#666666"
                                  />
                                </svg>
                                <span className="ms-1">{carerDetails?.email}</span>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </>
                    )}
                    {current === 2 && carerDetails?.kinContacts.length > 0 && (
                      <>
                        {carerDetails?.kinContacts.map((contactItem, index) => (
                          <Row className="g-3 fs-poppins" key={index}>
                            <Col>
                              <h6 className="carerdetails-tab-head">
                                {toCapitalize(contactItem?.firstName ?? "") +
                                  " " +
                                  toCapitalize(contactItem?.lastName ?? "")}
                              </h6>
                            </Col>

                            <Col>
                              <div className="d-flex">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="21"
                                    viewBox="0 0 17 21"
                                    fill="none"
                                    className="me-2"
                                  >
                                    <path
                                      d="M7.82141 19.9001C7.82141 19.9001 7.82183 19.9004 8.33341 19.2456L8.845 19.9004C8.54408 20.1333 8.12233 20.1331 7.82141 19.9001Z"
                                      fill="#666666"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.33336 5.14478C6.49239 5.14478 5 6.63024 5 8.46265C5 10.295 6.49239 11.7805 8.33336 11.7805C10.1743 11.7805 11.6667 10.295 11.6667 8.46265C11.6667 6.63024 10.1743 5.14478 8.33336 5.14478ZM6.66668 8.46265C6.66668 7.54644 7.41285 6.80371 8.33336 6.80371C9.25386 6.80371 10 7.54644 10 8.46265C10 9.37888 9.25386 10.1216 8.33336 10.1216C7.41285 10.1216 6.66668 9.37888 6.66668 8.46265Z"
                                      fill="#666666"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.82139 19.9001L8.33339 19.2456C8.84498 19.9004 8.84589 19.8997 8.84589 19.8997L8.84748 19.8985L8.85264 19.8944L8.87089 19.8802C8.88656 19.8678 8.90914 19.85 8.93823 19.8269C8.99623 19.7807 9.08014 19.7132 9.18614 19.6261C9.39806 19.452 9.69881 19.1992 10.0589 18.8806C10.7779 18.2444 11.739 17.3412 12.7028 16.2753C13.6643 15.212 14.6443 13.9694 15.3879 12.6537C16.1271 11.3456 16.6668 9.90522 16.6668 8.46253C16.6668 6.26265 15.7888 4.15286 14.226 2.5973C12.6632 1.04175 10.5436 0.167847 8.33339 0.167847C6.12324 0.167847 4.00361 1.04175 2.44079 2.5973C0.877981 4.15286 0 6.26265 0 8.46253C0 9.90522 0.539687 11.3456 1.2789 12.6537C2.02246 13.9694 3.00255 15.212 3.96395 16.2753C4.92777 17.3412 5.88889 18.2444 6.60787 18.8806C6.96796 19.1992 7.26872 19.452 7.48063 19.6261C7.58663 19.7132 7.67055 19.7807 7.72855 19.8269C7.75764 19.85 7.78022 19.8678 7.79589 19.8802L7.81414 19.8944L7.8193 19.8985L7.82139 19.9001ZM3.61932 3.77035C4.86957 2.5259 6.56527 1.82678 8.33339 1.82678C10.1015 1.82678 11.7972 2.5259 13.0475 3.77035C14.2977 5.01479 15.0001 6.70262 15.0001 8.46253C15.0001 9.50824 14.6023 10.66 13.9353 11.8403C13.2725 13.013 12.3776 14.1551 11.464 15.1655C10.5528 16.1732 9.6389 17.0326 8.95164 17.6407C8.71473 17.8503 8.50547 18.0294 8.33339 18.1738C8.1613 18.0294 7.95205 17.8503 7.71513 17.6407C7.02788 17.0326 6.11397 16.1732 5.20278 15.1655C4.28917 14.1551 3.39425 13.013 2.73154 11.8403C2.0645 10.66 1.66668 9.50824 1.66668 8.46253C1.66668 6.70262 2.36906 5.01479 3.61932 3.77035Z"
                                      fill="#666666"
                                    />
                                  </svg>
                                </span>
                                {contactItem?.address ? <span className="">{contactItem?.address} </span> : "N/A"}
                              </div>
                            </Col>

                            {contactItem?.phone && (
                              <Col>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    className="me-2"
                                  >
                                    <path
                                      d="M14.2538 13.1193L13.7729 13.6231C13.7729 13.6231 12.6302 14.8206 9.51104 11.552C6.3919 8.28331 7.53462 7.08581 7.53462 7.08581L7.83737 6.76855C8.58321 5.987 8.65351 4.73224 8.0028 3.81621L6.67177 1.94238C5.8664 0.808609 4.31017 0.658838 3.38708 1.62616L1.73028 3.36237C1.27257 3.84201 0.965851 4.46378 1.00305 5.15352C1.09821 6.91811 1.85575 10.7147 6.08287 15.1445C10.5655 19.8419 14.7716 20.0286 16.4916 19.8597C17.0357 19.8062 17.5088 19.5142 17.89 19.1147L19.3896 17.5433C20.4017 16.4827 20.1163 14.6643 18.8212 13.9223L16.8046 12.7669C15.9542 12.2797 14.9183 12.4228 14.2538 13.1193Z"
                                      stroke="#666666"
                                      strokeWidth="1.6"
                                    />
                                  </svg>
                                </span>
                                <span>{formatUKPhoneNumber(contactItem?.countryCode, contactItem?.phone)}</span>
                              </Col>
                            )}
                            {contactItem?.email && (
                              <Col>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="16"
                                    viewBox="0 0 20 16"
                                    fill="none"
                                    className="me-2"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M0.833333 0.805542L0 1.635V14.9064L0.833333 15.7359H19.1667L20 14.9064V1.635L19.1667 0.805542H0.833333ZM1.66667 3.51014V14.0769H18.3333V3.50987L9.99989 11.0506L1.66667 3.51014ZM17.011 2.46447H2.98873L9.99989 8.80864L17.011 2.46447Z"
                                      fill="#666666"
                                    />
                                  </svg>
                                </span>
                                <span>{contactItem?.email}</span>
                              </Col>
                            )}

                            {index !== carerDetails?.kinContacts.length - 1 && ( // Add divider if not the last item
                              <hr className="my-2" /> // Adjust class and styles as needed
                            )}
                          </Row>
                        ))}
                      </>
                    )}
                  </div>
                  <div className="card-inner">
                    <>
                      <h6 className="carerdetails-tab-head mb-2">Other Details</h6>
                      <Row className={"g-2"}>
                        <Col size="6">
                          <span className="me-2 carerdetails-otherdetails-head">NI Number:</span>
                          <br />
                          <span className="carerdetails-otherdetails-text">
                            {carerDetails?.profile?.niNo === null || carerDetails?.profile?.niNo === ""
                              ? "N/A"
                              : carerDetails?.profile?.niNo}
                          </span>
                        </Col>
                        <Col size="6">
                          <span className="me-2 carerdetails-otherdetails-head">Pay Type</span>
                          <br />
                          <span className="carerdetails-otherdetails-text">
                            {carerDetails?.payType
                              ? PayType.find((item) => item.value === carerDetails?.payType).label
                              : ""}
                          </span>
                        </Col>
                        <Col size="6">
                          <span className="me-2 carerdetails-otherdetails-head">NMC PIN:</span>
                          <br />
                          <span className="carerdetails-otherdetails-text">
                            {carerDetails?.profile?.nisccNmcPin === null || carerDetails?.profile?.nisccNmcPin === ""
                              ? "N/A"
                              : carerDetails?.profile?.nisccNmcPin}
                          </span>
                        </Col>
                      </Row>
                    </>
                  </div>
                  {carerDetails?.specialities.length > 0 && (
                    <div className="card-inner">
                      <h6 className="carerdetails-tab-head mb-2">Speciality</h6>
                      <ul className="g-1">
                        {carerDetails?.specialities.map((sItem, sIndex) => (
                          <li className="btn-group mt-1" key={sIndex}>
                            <Button color="light" size="xs" className="btn-dim">
                              {sItem?.name}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </PreviewAltCard>
              </div>
            </Col>
            <Col className="col-12 col-lg-9 col-xl-9">
              <PreviewAltCard className="card-bordered overflow-x-auto  details-css min-height-100">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <NavigationTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                  </div>
                </div>
              </PreviewAltCard>
            </Col>
          </Row>
        </Block>
      </Content>
      <SendEmailModal
        visible={showModal.email}
        item={[carerDetails?.email]}
        onClosed={() => {
          setShowModal(false);
          //  setSelectedItem();
        }}
        onSaved={() => {
          //  loadBranch();
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default CarerDetails;
