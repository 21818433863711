import { Storage } from "../../services/storage/storage";
import { PersonTypes } from "../../utils";

function toAvatar(response) {
  let avatar = {};
  avatar.thumb = response.thumbnailUrl;
  avatar.url = response.imageUrl ?? "";
  avatar.mimetype = response.mimetype ?? "";
  return avatar;
}

function toSessionUser(sessionUser) {
  let user = this.toUser(sessionUser);
  updateSessionUserInfo(user);
  return user;
}

function updateSessionUserInfo(user) {
  let info = Storage.getLoggedInUser() ?? {};
  info.user = user;
  Storage.setLoggedInUser(info);
}

function toPageInfo(pageInfo) {
  let isNextPageExist = pageInfo.currentPage < pageInfo.totalPages ? true : false;
  return {
    isNextPageExist: isNextPageExist,
    currentPage: pageInfo.currentPage,
    totalPages: pageInfo.totalPages,
    totalItems: pageInfo.totalItems,
  };
}

function toContactList(list) {
  let items = [];
  for (let index = 0; index < (list?.length ?? 0); index++) {
    const element = list[index];
    items.push(toContact(element));
  }
  return items;
}

function toContact(person) {
  let types = PersonTypes.filter((item) => item.value === person?.type);

  return {
    personId: person.id,
    personType: types.length === 1 ? types[0] : null,
    personName: person?.name ?? "",
    phoneNumber: person?.phone ?? "",
    personEmail: person?.email ?? "",
    countryCode: person?.countryCode ?? "",
  };
}

export { toAvatar, toSessionUser, toPageInfo, toContactList };
