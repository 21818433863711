import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Modal, ModalBody, Row, Button } from "reactstrap";
import { DataTablePagination } from "../Component";
import { PER_PAGE_ITEMS } from "../../utils";

const Export = ({ data }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  const fileName = "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const copyToClipboard = () => {
    setModal(true);
  };

  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center">
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <CopyToClipboard text={JSON.stringify(data)}>
            <Button className="buttons-copy buttons-html5" onClick={() => copyToClipboard()}>
              <span>Copy</span>
            </Button>
          </CopyToClipboard>{" "}
          <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={() => exportCSV()}>
            <span>CSV</span>
          </button>{" "}
          <button className="btn btn-secondary buttons-excel buttons-html5" type="button" onClick={() => exportExcel()}>
            <span>Excel</span>
          </button>{" "}
        </div>
      </div>
      <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">Copied {data.length} rows to clipboard</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const ExpandableRowComponent = ({ data }) => {
  return (
    <ul className="dtr-details p-2 border-bottom ms-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span> <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span> <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span> <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span> <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

// const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//   <div className="custom-control custom-control-sm custom-checkbox notext">
//     <input
//       id={rest.name}
//       type="checkbox"
//       className="custom-control-input"
//       ref={ref}
//       onClick={onClick}
//       {...rest}
//     />
//     <label className="custom-control-label" htmlFor={rest.name} />
//   </div>
// ));

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="custom-control custom-control-sm custom-checkbox notext" style={{ backgroundColor: "" }}>
        <input type="checkbox" className="form-check-input" ref={ref} onClick={onClick} {...rest} />
        <label className="form-check-label" id="booty-check" />
      </div>
    </>
  );
});

// Ref: https://react-data-table-component.netlify.app/?path=/story/getting-started-intro--page

const ReactDataTable = ({
  data,
  columns,
  pagination,
  className,
  selectableRows,
  totalItemCount,
  onPageChanged,
  sortFunction,
  selectableRowsNoSelectAll,
  isAllowed = true,
  noTableHead,
  onRowSelected = () => {},
  onRowClicked = () => {},
  onSort = () => {},
}) => {
  const [itemPerPage, setItemPerPage] = useState(PER_PAGE_ITEMS);

  const customStyles = {
    // rows: {
    //   style: {
    //     gap: "8px", // override the row height
    //   },
    // },
    // table: {
    //   style: {
    //     overflow: "visible",
    //   },
    // },
    // tableWrapper: {
    //   style: {
    //     display: "table",
    //     overflow: "visible",
    //   },
    // },
    // responsiveWrapper: {
    //   style: {},
    // },
  };
  return (
    <div className={`${className ? className : ""}`}>
      <DataTable
        customStyles={customStyles}
        data={data}
        columns={columns}
        // className={"overflow-visiable" + className}
        sortFunction={sortFunction}
        selectableRows={selectableRows}
        noTableHead={noTableHead}
        selectableRowsNoSelectAll={selectableRowsNoSelectAll}
        selectableRowsComponent={CustomCheckbox}
        noHeader={false}
        onSelectedRowsChange={(data) => onRowSelected(data)}
        noDataComponent={
          <div className="p-2">{isAllowed ? "There are no records found" : "You do not have permission"}</div>
        }
        sortIcon={
          <div className="text-white">
            <span>&darr;</span>
            <span>&uarr;</span>
          </div>
        }
        onSort={(data) => {
          onSort(data.id);
        }}
        dense
        pagination={pagination}
        paginationServer={pagination}
        paginationTotalRows={totalItemCount}
        paginationPerPage={itemPerPage}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={onPageChanged}
        onRowClicked={onRowClicked}
        paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
          <DataTablePagination
            customItemPerPage={itemPerPage}
            itemPerPage={rowsPerPage}
            totalItems={rowCount}
            paginate={onChangePage}
            currentPage={currentPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            setRowsPerPage={setItemPerPage}
          />
        )}
      ></DataTable>
    </div>
  );
};

export default ReactDataTable;
