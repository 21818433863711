import moment from "moment";
import { MoreOptions, StatusBadge, TableTitle } from "../../../components/Component";
import { DATE_FORMAT, dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { removeTimeFromServerDateString, toCapitalize } from "../../../utils/Functions";
import { toPageInfo } from "../../_common/DataConverter";
import { Weekdays } from "../../../utils/Utils";
import { RotaType } from "./RotaList";

export const rotaTableData = (type, onCancel) => {
  return [
    {
      name: <TableTitle title={"Client"} />,
      selector: (row) => row?.client?.name ?? "",
      omit: type === RotaType.hospital ? true : false,
      sortable: true,
    },

    {
      name: <TableTitle title={"Carer Type"} />,

      selector: (row) => row?.carerType?.name ?? "",
      sortable: false,
      width: "150px",
    },
    {
      name: <TableTitle filterable={false} title={"Carer"} />,
      selector: (row) => row?.carer ?? "",
      omit: type === RotaType.carer ? true : false,
      sortable: true,
    },

    {
      name: <TableTitle title={"Start Date - End Date"} />,
      selector: (row) => {
        const startDate = row?.startDate ? dateToShow(row?.startDate) : "";
        const toDate = row?.toDate ? dateToShow(row?.toDate) : "";
        return `${startDate} - ${toDate}`;
      },
      width: "215px",
      sortable: true,
    },
    {
      name: <TableTitle title={"Start Time - End Time"} />,
      selector: (row) => {
        const startTime = row?.startTime ? moment(row?.startTime).format("hh:mm A") : "";
        const endTime = row?.endTime ? moment(row?.endTime).format("hh:mm A") : "";
        return `${startTime}  -  ${endTime}`;
      },
      width: "215px",

      sortable: true,
    },
    {
      name: <TableTitle filterable={false} title={"Duration"} />,
      sortable: true,
      selector: (row) => {
        let startTime = moment(row?.startTime);
        let endTime = moment(row?.endTime);

        if (startTime.isValid() && endTime.isValid()) {
          // Calculate the duration in minutes
          let durationMinutes = endTime.diff(startTime, "minutes");

          if (durationMinutes < 0) {
            durationMinutes += 24 * 60;
          }

          let hours = Math.floor(durationMinutes / 60);
          let minutes = durationMinutes % 60;
          return `${hours} : ${minutes}`;
        }

        return "N/A";
      },
    },

    // {
    //   name: <TableTitle filterable={false} title={"Duration"} />,
    //   selector: (row) => {
    //     const startTime = moment(row?.startTime);
    //     const endTime = moment(row?.endTime);

    //     let timerDuration = moment.duration(endTime.diff(startTime));

    //     // let timerDuration2 = moment.duration(cleanTime.diff(startTime));
    //     // if (startTime.isValid() && endTime.isValid()) {
    //     //   const duration = moment.duration(endTime.diff(startTime));
    //     //   const hours = Math.floor(Math.floor(duration.asHours()));
    //     //   const minutes = Math.abs(duration.minutes());

    //     //   return `${hours} : ${minutes}`;
    //     // }

    //     return "N/A"; // Handle cases where either startTime or endTime is missing or invalid
    //   },
    //   omit: type === RotaType.carer ? true : false,
    //   sortable: true,
    // },

    {
      name: <TableTitle title={"Day"} />,
      selector: (row) => row?.viewListDays ?? "",
      sortable: false,
    },
    // {
    //   name: <TableTitle title={"Status"} />,
    //   cell: (row) => (
    //     <StatusBadge status={row?.status}/>
    //   ),
    //   width: "100px",
    //   sortable: true,
    // },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "cross",
              text: "Cancel",
              action: () => {
                onCancel(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/

//***************************Converters*********************************/

export function rotaListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.rotaList, params)
    .then((data) => {
      let list = toRotaList(data?.list);
      callback({ list }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function rotaDayListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.rotaDayList, params)
    .then((data) => {
      let list = toRotaList(data?.list);
      callback({ list }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function toRotaList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toRota(element));
  });
  return list;
}

export function exportRotaAPI(params, callback) {
  apiService
    .postCall(APIServicePath.exportRota, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

function toRota(data) {
  return {
    id: data?.id,
    rotaId: data?.rota?.id,
    client: data?.rota?.client,
    rotaDate: data.rotaDate ? moment(data.rotaDate).format("YYYY-MM-DD") : null,
    grade: data?.rota?.grade,
    dayName: data?.dayName,
    carer: toCapitalize(data?.carer?.firstName) + " " + toCapitalize(data?.carer?.lastName),
    days: (data?.days?.daysFor ?? []).map((day) => {
      const foundWeekday = Weekdays.find((weekday) => weekday.value === day);
      return foundWeekday ? foundWeekday.label : "";
    }),
    viewListDays: (data?.days?.daysFor ?? [])
      .map((day) => {
        return day[0];
      })
      .join(" - "),
    carerType: data?.carerType,
    startDate: data.fromDate ? moment(data.fromDate).format("YYYY-MM-DD") : null,
    toDate: data.toDate ? moment(data.toDate).format("YYYY-MM-DD") : null,
    startTime: data?.rota?.startTime ? moment(data?.rota?.startTime, "HH:mm:ss").utc().toDate() : null,
    endTime: data?.rota?.endTime ? moment(data?.rota?.endTime, "HH:mm:ss").utc().toDate() : null,
    validTill: data?.validTill ? moment(removeTimeFromServerDateString(data?.validTill)).format(DATE_FORMAT) : null,
    status: data?.status,
    lastModifiedBy: data?.lastModifiedBy ? data?.lastModifiedBy?.firstName + " " + data?.lastModifiedBy?.lastName : "-",
    lastModifiedDate: data?.lastModifiedTime ? moment(data?.lastModifiedTime).format("YYYY-MM-DD") : "-",
    statusReason: data?.statusReason ? data?.statusReason : "-",
  };
}

//client carer api for filter

export function showClientListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listHospital, params)
    .then((data) => {
      let list = toHospitalList(data?.list);
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function showCarerListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.carerList, params)
    .then((data) => {
      let list = toHospitalList(data.list);
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

function toHospitalList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toHospital(element));
  });
  return list;
}

function toHospital(data) {
  let item = {
    id: data?.id,
    name: data?.name ?? data?.firstName + " " + data?.lastName,
    carerImage: data?.carerImage,
    // phoneNumber: data?.contacts
    //   ? data?.contacts[0]?.countryCode + " " + data?.contacts[0]?.phone
    //   : data?.countryCode + " " + data?.phone,
    phoneNumber: data?.contacts
  ? data?.contacts[0]?.countryCode
    ? data?.contacts[0]?.countryCode + " " + data?.contacts[0]?.phone
    : data?.contacts[0]?.phone
  : data?.countryCode
    ? data?.countryCode + " " + data?.phone
    : data?.phone || '-',

  };

  return item;
}
