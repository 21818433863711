/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Block, LoadingComponent, PreviewAltCard } from "../../../components/Component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ShiftCheck } from "../../../utils";
import { useEffect } from "react";
import { availbilityDateListApi } from "./AvailbilityData";
import CalenderApp from "../../../components/partials/calender/Calender";
import AddAvailbility from "./AddAvailbility";
import moment from "moment";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";

const Availbility = ({ carerId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [availbilityDateList, setAvailbilityDateList] = useState([]);
  const [availbilityEvent, satAvailbilityEvent] = useState([]);

  useEffect(() => {
    if (startDate && endDate) callAvailbilityDateListApi();
  }, [endDate]);

  useEffect(() => {
    let eventList = [];
    availbilityDateList.forEach((item, index) => {
      let objectAvail = [];
      let dayAvail = [];
      item?.availableFor.forEach((object, index) => {
        let findItem = ShiftCheck?.find((shiftTimeItem) => object === shiftTimeItem.value);
        if (findItem) {
          objectAvail.push(findItem);
          dayAvail.push(findItem?.label);
        }
      });
      const determineClassName = (objectAvail) => {
        if (objectAvail.some((item) => item.value === "is_any")) {
          return "fc-event-success";
        } else if (objectAvail.some((item) => item.value === "is_night")) {
          return "fc-event-primary";
        } else {
          return "fc-event-orange";
        }
      };
      eventList.push({
        id: "default-" + `${index + 1}`,
        title: dayAvail.join(", "),
        objects: objectAvail,
        start: moment(item?.availableDate).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(item?.availableDate).add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
        className: determineClassName(objectAvail),
      });
      satAvailbilityEvent(eventList);
    });

    satAvailbilityEvent(eventList);
  }, [availbilityDateList]);

  useEffect(() => {}, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      // loadClientNote();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const addEvent = (info) => {
    setSelectedDate(info.date);
    setModal(true);
  };

  const onEventClick = (info) => {
    setSelectedDate(moment(info._instance?.range?.start).format("YYYY-MM-DD"));
    setSelectedItem(info._def.extendedProps?.objects);

    setModal(true);
  };

  const callAvailbilityDateListApi = () => {
    if (!startDate || !endDate) return;
    if (isCheckToList(UserPermissionType.availability)) {
      let params = {
        carerId: carerId,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      };
      availbilityDateListApi(params, (data, error) => {
        if (error === null) {
          setAvailbilityDateList(data.list);
        }
        setInitScreen(false);
      });
    } else {
      setInitScreen(false);
    }
  };

  return (
    <React.Fragment>
      <div className="py-4 px-4 px-xxl-0">
        {isCheckToList(UserPermissionType.availability) ? (
          <CalenderApp
            events={availbilityEvent}
            onDelete={"deleteEvent"}
            onEdit={"onEdit"}
            onAdd={isAllowToAccess(UserPermissionType.availability, UserActionType.setavailability) ? addEvent : null}
            onEvent={
              isAllowToAccess(UserPermissionType.availability, UserActionType.setavailability) ? onEventClick : null
            }
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        ) : (
          <Block className={"pt-1"}>
            <PreviewAltCard>
              <span className="d-flex justify-content-center">You do not have permission</span>
            </PreviewAltCard>
          </Block>
        )}
      </div>

      <AddAvailbility
        visible={modal}
        carerId={carerId}
        start_Date={selectedDate}
        item={selectedItem}
        onClosed={() => {
          setModal(false);
          setSelectedItem();
          setSelectedDate();
        }}
        onSaved={() => {
          callAvailbilityDateListApi();
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default Availbility;
