import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { ActionButton, CancelButton, Icon } from "../Component";
import {
  clientImportTemplateAPI,
  fileImportClientApi,
  fileImportRateApi,
} from "../../pages/tabs/clients/hospital/HospitalTableData";
import { AppText, showErrorToast } from "../../utils";
import { bytesToMegaBytes } from "../../utils/Utils";
import { iconsType } from "../../layout/fileIcon/FileIcon";
import { carerImportTemplateAPI, fileImportCarerApi, fileUploadApi } from "../../pages/tabs/carers/CarerData";
import { loadFetchMasterList } from "../../store/masterListSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openLinkInNewTab } from "../../utils/Functions";
import { fileImportWardApi } from "../../pages/tabs/clients/hospital/ward/WardData";
import { exportRateAPI } from "../../pages/tabs/rates/RatesData";

const ImportFileModal = ({
  isOpen,
  toggle,
  title,
  disabled,
  isClientList,
  loadHospital,
  callCarerListApi,
  clientId,
  importType,
  onSuccess,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [importRatesLoading, setImportRatesLoading] = useState(false);
  const [carerImportRatesLoading, setCarerImportRatesLoading] = useState(false);
  const [wardImportRatesLoading, setWardImportRatesLoading] = useState(false);
  const [rateImportRatesLoading, setRateImportRatesLoading] = useState(false);
  const [numberofClientImport, setNumberofClientImport] = useState();
  const [numberofCarerImport, setNumberofCarerImport] = useState();
  const [numberofWardImport, setNumberofWardImport] = useState();
  const [numberofRateImport, setNumberofRateImport] = useState();

  const fileInputRef = React.createRef(); // Create a reference for the file input

  const dispatch = useDispatch();
  // const carerTemplate = useSelector((state) => state?.masterList?.masterList?.carerImportTemplate);
  const clientTemplate = useSelector((state) => state?.masterList?.masterList?.clientImportTemplate);
  const wardTemplate = useSelector((state) => state?.masterList?.masterList?.wardImportTemplate);

  useEffect(() => {
    dispatch(loadFetchMasterList());
  }, []);

  useEffect(() => {
    setUploadedFiles([]);
    setRemoveFileIds([]);
    setImportRatesLoading(false);
    setCarerImportRatesLoading(false);
    setWardImportRatesLoading(false);
    setRateImportRatesLoading(false);
    setNumberofClientImport(null);
    setNumberofCarerImport(null);
    setNumberofWardImport(null);
    setNumberofRateImport(null);
  }, [isOpen]);

  const handleFileSelect = (file, rootIndex) => {
    if (uploadedFiles.length > 0) {
      showErrorToast("Only one file can be attached at a time.");
      return;
    }
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles];
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile

    setUploadedFiles(updatedfiles);
  };

  const openFileInNewTab = (file) => {
    if (file) {
      // window.open(file?.fileUrl, "_blank");
    }
  };

  //   ****************** API ********************* //
  const uploadFile = (file, index) => {
    fileUploadApi(
      file,
      isClientList
        ? "CLIENT_XLSX"
        : importType === "Ward"
        ? "WARD_XLSX"
        : importType === "Rate"
        ? "RATE_XLSX"
        : "CARER_XLSX",
      (data, error) => {
        const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
        if (error === null) {
          file.id = data.id;
          file.isLoading = false;
          updatedfiles[index] = file;
          setUploadedFiles(updatedfiles);
        } else {
          updatedfiles.splice(index, 1);
          setUploadedFiles(updatedfiles);
          showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
        }
      }
    );
  };

  // ************ IMPORT CLIENT ************ //
  const importClientFile = (fileId) => {
    setImportRatesLoading(true);
    fileImportClientApi(fileId, (data, error) => {
      if (error === null) {
        setNumberofClientImport(data);
        loadHospital();
      } else {
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
      setImportRatesLoading(false);
    });
  };

  // ************ IMPORT CARER ************ //
  const importCarerFile = (fileId) => {
    setCarerImportRatesLoading(true);
    fileImportCarerApi(fileId, (data, error) => {
      if (error === null) {
        setNumberofCarerImport(data);
        callCarerListApi();
      } else {
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
      setCarerImportRatesLoading(false);
    });
  };

  // ************ IMPORT WARD ************ //
  const importWardFile = (fileId, clientId) => {
    setWardImportRatesLoading(true);
    fileImportWardApi(fileId, clientId, (data, error) => {
      if (error === null) {
        setNumberofWardImport(data);
        onSuccess && onSuccess(importType);
      } else {
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
      setWardImportRatesLoading(false);
    });
  };

  // ************ IMPORT RATE ************ //
  const importRateFile = (fileId, clientId) => {
    setRateImportRatesLoading(true);
    fileImportRateApi(fileId, clientId, (data, error) => {
      if (error === null) {
        setNumberofRateImport(data);
        onSuccess && onSuccess(importType);
      } else {
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
      setRateImportRatesLoading(false);
    });
  };

  // ***************** DOWNLOAD TEMPLATE ************ //
  const TemplateDownload = () => {
    if (isClientList) {
      clientTemplateDownload();
    } else if (importType === "Ward") {
      openLinkInNewTab(wardTemplate);
    } else if (importType === "Rate") {
      onRateDownload();
    } else {
      carerTemplateDownload();
    }
  };

  function onRateDownload() {
    // setLoading(true);
    let params = {
      clientId: clientId,
    };
    exportRateAPI(params, (data, error) => {
      if (error === null) {
        window.open(data.fileUrl, "default");
        toggle();
      }
      // setLoading(false);
    });
  }

  function carerTemplateDownload() {
    carerImportTemplateAPI((data, error) => {
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
        toggle();
      } else {
        showErrorToast(error.message);
      }
    });
  }

  function clientTemplateDownload() {
    clientImportTemplateAPI((data, error) => {
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
        toggle();
      } else {
        showErrorToast(error.message);
      }
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <ModalHeader toggle={toggle}>
        <span>{"Import " + title}</span>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between align-center">
          <span className="fs-6 fw-bold">Download Template</span>
          <span onClick={() => TemplateDownload()} className="cursor-pointer text-primary">
            Download
          </span>
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <span className="fs-6 fw-bold">Select File</span>
          <div>
            <div className="form-group mb-0 ms-3">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 text-primary" />
                <span className="text-primary"> Attach file</span>
              </label>
              <div className="text-end">
                <input
                  id={"files"}
                  type="file"
                  accept=".xlsx,.xls"
                  className="form-control"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={(e) => {
                    const selectedFiles = e.target.files;
                    if (selectedFiles.length > 0) {
                      handleFileSelect(selectedFiles[0]);
                    }
                    e.target.value = '';
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {uploadedFiles?.length > 0 &&
          uploadedFiles?.map((file, ind) => {
            return (
              <React.Fragment key={`file-${ind}`}>
                <div className="nk-upload-item">
                  <div className="nk-upload-icon">
                    {file?.isOldFile ? (
                      <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                    ) : (
                      <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                    )}
                  </div>
                  <div className="nk-upload-info">
                    <div className="nk-upload-title">
                      <span className="title" style={{ cursor: "pointer" }} onClick={() => openFileInNewTab(file)}>
                        {file?.name}
                      </span>
                    </div>
                  </div>
                  <div className="nk-upload-action">
                    <a
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                    >
                      {file?.isLoading ? (
                        <Spinner size="sm" color="dark" />
                      ) : (
                        <span onClick={() => handleFileRemove(ind)}>
                          <Icon name="trash"></Icon>
                        </span>
                      )}
                    </a>
                  </div>
                </div>
                {(numberofClientImport || numberofCarerImport || numberofWardImport || numberofRateImport) && (
                  <>
                    <span className="text-black mt-2">
                      import success ={" "}
                      {isClientList
                        ? numberofClientImport?.totalSuccessCounter
                        : importType === "Ward"
                        ? numberofWardImport?.totalSuccessCounter
                        : importType === "Rate"
                        ? numberofRateImport?.totalSuccessCounter
                        : numberofCarerImport?.totalSuccessCounter}
                    </span>
                    <br />
                    <span className="text-black mt-2">
                      import fail ={" "}
                      {isClientList
                        ? numberofClientImport?.totalFailCounter
                        : importType === "Ward"
                        ? numberofWardImport?.totalFailCounter
                        : importType === "Rate"
                        ? numberofRateImport?.totalFailCounter
                        : numberofCarerImport?.totalFailCounter}
                    </span>
                  </>
                )}
              </React.Fragment>
            );
          })}
      </ModalBody>
      <ModalFooter>
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
          <li>
            <CancelButton onCancel={toggle} />
          </li>
          <li>
            <ActionButton
              title="Save"
              loading={
                isClientList
                  ? importRatesLoading
                  : importType === "Ward"
                  ? wardImportRatesLoading
                  : importType === "Rate"
                  ? rateImportRatesLoading
                  : carerImportRatesLoading
              }
              disabled={disabled}
              onClick={() => {
                const fileId = uploadedFiles.length > 0 ? uploadedFiles[0].id : null;
                if (isClientList) {
                  importClientFile(fileId);
                } else if (importType === "Ward") {
                  importWardFile(fileId, clientId);
                } else if (importType === "Rate") {
                  importRateFile(fileId, clientId);
                } else {
                  importCarerFile(fileId);
                }
                setTimeout(() => {
                  toggle();
                }, 2000);
              }}
            />
          </li>
        </ul>
      </ModalFooter>
    </Modal>
  );
};

export default ImportFileModal;
