import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { RotaOptions } from "../../utils";
import { toCapitalize } from "../../utils/Functions";

export const RotaStatusDropDown = ({ value, isMulti = false, isShowAll = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusValues = [];
    Object.values(RotaOptions).forEach((item) => {
      statusValues.push({ id: 1, label: toCapitalize(item), value: item });
    });

    if (isShowAll) {
      const allOption = { id: 0, label: "All", value: "All" };
      setList([allOption, ...statusValues]);
    } else {
      setList(statusValues);
    }
  }, [isShowAll]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : { id: 1, label: "Open", value: "OPEN" })}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
