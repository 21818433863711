import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatAmount } from "../input/FormInput/FormInput";
import { CURRENCY_CODE } from "../../utils";

export const BarChart = ({ data }) => {
  const history = useHistory();
  const dates = data?.map((item) => item.date);
  const totalAmount = data.map((item) => item.totalAmount);
  const labels = data.map((item) => '');

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Amount",
        data: totalAmount,
        backgroundColor: "rgba(0,110,255,1)",
        borderColor: "rgba(0,110,255,1)",
        borderWidth: 0.6,
        barPercentage: 0.8,
        categoryPercentage: 0.6,
      },
    ],
  };

  const handleLineClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const clickedIndex = chartElements[0]._index;
      const clickedDate = data[clickedIndex].date;
      const date = moment(clickedDate);
      const startOfWeek = date.clone().startOf("isoWeek");
      const endOfWeek = date.clone().endOf("isoWeek");
      if (data[clickedIndex].isWageStatistics) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/wages`,
          state: {
            data: {
              startDate: startOfWeek.format("YYYY-MM-DD"),
              endDate: endOfWeek.format("YYYY-MM-DD"),
            },
            isWageListMove: true,
          },
        });
      } else if (data[clickedIndex].isInvoiceStatistics) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/invoices`,
          state: {
            data: {
              startDate: startOfWeek.format("YYYY-MM-DD"),
              endDate: endOfWeek.format("YYYY-MM-DD"),
            },
            isInvoiceListMove: true,
          },
        });
      }
    }
  };

  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      width={180}
      // height={400}
      data={chartData}
      options={{
        legend: {
          display: false,
        },
        layout: {
          padding: {
              bottom: 0
          }
      },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true, // Start the scale at 0
                min: 0, // Set a minimum value for the scale (adjust as needed)
              },
            },
          ],
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
            },
          ],
        },

        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItem, data) {
              const clickedIndex = tooltipItem[0].index;
              const clickedDate = dates[clickedIndex];
              const currentDate = moment(clickedDate);
              const startOfWeek = currentDate.clone().startOf("isoWeek");
              const endOfWeek = currentDate.clone().endOf("isoWeek");
              return `${startOfWeek.format("MMM DD")} - ${endOfWeek.format("MMM DD")}`;
            },
            label: function (tooltipItem, data) {
              return `Amount: ${data.datasets[0].data[tooltipItem.index]}`;
            },
          },
          position: "nearest",
          // xAlign: "left | right",
          // yAlign: "bottom | top",
          backgroundColor: "#eff6ff",
          titleFontSize: 11,
          titleFontColor: "#000000",
          titleMarginBottom: 4,
          bodyFontColor: "#000000",
          bodyFontSize: 10,
          bodySpacing: 3,
          yPadding: 8,
          xPadding: 8,
          footerMarginTop: 0,
          displayColors: false,
        },
        onClick: handleLineClick,
      }}
    />
  );
};
