import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { trustDropDownData } from "./DropDownData";

export const TrustDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  selectedId,
  placeholder,
  isClearShow,
  ...props
}) => {
  const [list, setList] = useState();
  useEffect(() => {
    loadTrustList();
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTrustList = () => {
    trustDropDownData((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId) {
      const selectedOption = list.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [list]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      selectedId={selectedId}
      isClearable
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
