import moment from "moment";
import { AnnsBadge, Icon, MoreOptions, StatusBadge, TableTitle, UserAvatar } from "../../../components/Component";
import { ServerStatusType, dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { convertHexToColor, findUpper, toCapitalize } from "../../../utils/Functions";
import { toPageInfo } from "../../_common/DataConverter";
import CustomPopOver from "./CustomPopover";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

// export const carerData = () => [
//   {
//     name: "Jack Adams",
//     email: "jk@gmail.com",
//     phone: "87345430000",
//     city: "London",
//     lastBooking: new Date(),
//     speciality: ["ENT"],
//     compliance: true,
//     status: true,
//   },
//   {
//     name: "George Burton",
//     email: "gb@gmail.com",
//     phone: "8734543453",
//     city: "Oxford",
//     lastBooking: new Date(),
//     speciality: ["Urology"],
//     compliance: true,
//     status: true,
//   },
//   {
//     name: "James Bradley",
//     email: "jb@gmail.com",
//     phone: "8734543453",
//     city: "Bristol",
//     lastBooking: new Date(),
//     speciality: ["Orthopedics"],
//     compliance: true,
//     status: true,
//   },
//   {
//     name: "Olivia Booth",
//     email: "ob@gmail.com",
//     phone: "8734543453",
//     city: "London",
//     lastBooking: new Date(),
//     speciality: ["Ophthalmology"],
//     compliance: true,
//     status: true,
//   },
//   {
//     name: "Emily Anderson",
//     email: "ea@gmail.com",
//     phone: "8734543453",
//     city: "Bristol",
//     lastBooking: new Date(),
//     speciality: ["Gynecology"],
//     compliance: true,
//     status: true,
//   },
// ];

export const carerTableColumns = (
  onEdit,
  onView,
  onRemove,
  onAvailability,
  onRota,
  onTimesheet,
  onWage,
  onInvoice,
  onNotes,
  onHistory
) => {
  return [
    {
      name: <TableTitle title={"Name"} />,
      id: "name",
      cell: (row) => (
        <div onClick={() => isAllowToAccess(UserPermissionType.carer, UserActionType.detail) && onView(row)}>
          <UserAvatar
            className="my-2"
            // theme={ColorsArray[Math.floor(Math.random() * ColorsArray?.length)]}
            customHex={row?.colourHex}
            text={findUpper(toCapitalize(row?.firstName ?? " ")) + findUpper(toCapitalize(row?.lastName ?? " "))}
            image={row?.carerImage?.imageUrl}
            name={toCapitalize(row?.firstName ?? " ") + " " + toCapitalize(row?.lastName ?? " ")}
            subName={row?.carerType?.name}
          ></UserAvatar>
        </div>
      ),
      sortable: true,
      width: "250px",
      // wrap: true,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Contact"} />,
      selector: (row) => (
        <>
          <div onClick={() => isAllowToAccess(UserPermissionType.carer, UserActionType.detail) && onView(row)}>
            <span>
              <span className="me-1 mb-1">
                <Icon name={"call"} />
              </span>
              {(row?.countryCode ? "+" + row?.countryCode + "-" : "") + row?.phone}
            </span>
            <br />
            <span>
              <span className="me-1">
                <Icon name={"mail"} />
              </span>
              {row?.email ? row?.email : ""}
            </span>
          </div>
        </>
      ),
      sortable: false,
      width: "300px",
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Grade"} />,
      cell: (row) => (
        <CustomPopOver data={row}>
          <div onClick={() => isAllowToAccess(UserPermissionType.carer, UserActionType.detail) && onView(row)}>
            {row?.grades.slice(0, 2).map((grade, index) => (
              <>
                <GradeBadge
                  key={index}
                  label={grade.name}
                  className={"me-1 my-1"}
                  color={`outline-${convertHexToColor(grade?.colourHex)}`}
                />
              </>
            ))}
            {row?.grades.length > 2 && (
              <AnnsBadge className={"my-1"} color={"primary"} label={`+ ${row?.grades.length - 2}`} />
            )}
          </div>
        </CustomPopOver>
      ),
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Last Booking"} />,
      selector: (row) => (row?.lastBooking?.toDate ? dateToShow(row?.lastBooking?.toDate) : "NA"),
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Next Booking"} />,
      selector: (row) => (row?.nextBooking?.fromDate ? dateToShow(row?.nextBooking?.fromDate) : "NA"),
      sortable: false,
      style: {
        cursor: "pointer",
      },
    },
    isAllowToAccess(UserPermissionType.carer, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "100px",
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

export const CarerTooltipButton = [
  {
    id: 1,
    href: "#email",
    className: "btn-trigger btn-icon",
    icon: "mail",
    text: "Email",
  },
  {
    id: 1,
    href: "#sms",
    className: "btn-trigger btn-icon",
    icon: "chat",
    text: "Sms",
  },
  {
    id: 3,
    href: "#add note",
    className: "btn-trigger btn-icon",
    icon: "note-add",
    text: "Note",
  },
];

// API_--------------------------------------------------->

export function fileUploadApi(file, uploadFileType, callback) {
  apiService
    .fileUploader(file, uploadFileType, APIServicePath.fileUpload)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileImportCarerApi(fileId, callback) {
  let params = {
    fileId: fileId,
  };
  apiService
    .postCall(APIServicePath.importFileCarer, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
//   apiService
//     .uploader(params, APIServicePath.importFileCarer)
//     .then((data) => {
//       callback(data, null);
//     })
//     .catch((error) => {
//       callback(null, error);
//     });
// }

export function carerListApi(params, callback) {
  apiService
    .postCall(APIServicePath.carerList, params)
    .then((data) => {
      let list = data.list;
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function carerDetailApi(params, callback) {
  let param = {
    carerId: params,
  };
  apiService
    .postCall(APIServicePath.carerDetail, param)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function carerDocumentSaveApi(params, callback) {
  apiService
    .postCall(APIServicePath.carerDocumentApi, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerDocumentListApi(params, callback) {
  apiService
    .postCall(APIServicePath.carerDocumentList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerDocumentRemoveApi(params, callback) {
  apiService
    .postCall(APIServicePath.carerDocumentRemove, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportCarerAPI(params, callback) {
  apiService
    .postCall(APIServicePath.exportCarer, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerImportTemplateAPI(callback) {
  apiService
    .postCall(APIServicePath.carerImportTemplate)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerSaveApi(data, callback) {
  let kinsList = [];
  data?.contacts.map((KinItem, index) => {
    let kin = {
      id: index,
      title: KinItem?.title?.label,
      firstName: KinItem?.firstName,
      middleName: "",
      lastName: KinItem?.lastName,
      email: KinItem?.email,
      phone: KinItem?.phoneNumber?.phoneNumber ?? "",
      countryCode: KinItem?.phoneNumber?.dialCode,
      address: KinItem?.Address,
      relationship: KinItem?.relationship,
    };
    kinsList.push(kin);
  });

  let documentsData = [];

  // Check if documents exist and have a length greater than 0
  if (data?.documents && data.documents.length > 0) {
    documentsData = data.documents.map((item, index) => ({
      fileId: item?.file?.id ?? item?.file?.carerDocumentId,
      carerDocId: item?.carerDocumentId,
      documentId: item?.file?.documentId ?? parseInt(item?.file?.document.map((item) => item.id).toString()),
      expiryDate: item?.expiredDate ? dateToShow(item?.expiredDate, "YYYY-MM-DD") : null,
      issueDate: item?.issuedDate ? dateToShow(item?.issuedDate, "YYYY-MM-DD") : null,
      refNo: item?.refNo,
    }));
  }

  let params = {
    id: data.id ?? null,
    email: data?.email,
    specialityIds: data?.SpecialityType ? data?.SpecialityType.map((item) => item.id) : [],
    title: data?.title?.label,
    firstName: data?.firstName,
    middleName: "",
    lastName: data?.lastName,
    phone: data?.phoneNumber?.phoneNumber ?? "",
    countryCode: data?.phoneNumber?.dialCode,
    dob: moment(data?.dob).format("YYYY-MM-DD"),
    genderId: data?.gender.id,
    carerTypeId: data?.carerType?.id,
    gradeIds: data?.gradeType ? data?.gradeType.map((item) => item.id) : [],
    payType: data?.payType?.value,
    address: data?.Address,
    city: data?.city,
    postalCode: data?.postalCode,
    contractStartDate: data?.startDate ? moment(data?.startDate).format("YYYY-MM-DD") : null,
    contractEndDate: data?.endDate ? moment(data?.endDate).format("YYYY-MM-DD") : null,
    status: data?.isActive ? ServerStatusType.active : ServerStatusType.disabled,
    refNo: data?.refNo ? data?.refNo : "",
    niNo: data?.NI_No ? data?.NI_No : "",
    nisccNmcPin: data?.NISCC_NO ? data?.NISCC_NO : "",
    homeTel1: data?.homeTel,
    homeTel2: data?.homeTel2,
    kins: kinsList,
    documents: documentsData.length > 0 ? documentsData : [],
    fileId: data?.cover?.id,
    removeDocumentIds:
      data?.romoveFiles?.length > 0
        ? data?.romoveFiles?.filter((item) => item?.isOldFile === true)?.map((item) => item.carerDocumentId)
        : [],
    clientBanIds: data?.hospital ? data?.hospital : [],
  };
  apiService
    .postCall(APIServicePath.carerSave, params)
    .then((data) => {
      // let list = toHospitalList(data?.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function carerDetailFormAPI(data, callback) {
  let params = {
    id: data.id ?? null,
    email: data?.email,
    specialityIds: data?.SpecialityType ? data?.SpecialityType.map((item) => item.id) : [],
    title: data?.title?.label,
    firstName: data?.firstName,
    middleName: "",
    lastName: data?.lastName,
    phone: data?.phoneNumber?.phoneNumber ?? "",
    countryCode: data?.phoneNumber?.dialCode,
    dob: moment(data?.dob).format("YYYY-MM-DD"),
    genderId: data?.gender.id,
    carerTypeId: data?.carerType?.id,
    gradeIds: data?.gradeType ? data?.gradeType.map((item) => item.id) : [],
    payType: data?.payType?.value,
    address: data?.address,
    latitude: data?.coordinates?.lat,
    longitude: data?.coordinates?.lng,
    city: data?.city,
    postalCode: data?.postalCode,
    status: data?.isActive ? ServerStatusType.active : ServerStatusType.disabled,
    fileId: data?.cover?.id,
  };
  apiService
    .postCall(APIServicePath.carerDetailFormApi, params)
    .then((data) => {
      // let list = toHospitalList(data?.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerOtherFormAPI(data, callback) {
  let params = {
    carerId: data?.id,
    refNo: data?.refNo ? data?.refNo : "",
    niNo: data?.NI_No ? data?.NI_No : "",
    nisccNmcPin: data?.NISCC_NO ? data?.NISCC_NO : "",
    homeTel1: data?.homeTel1
      ? {
          countryCode: data?.homeTel1?.dialCode ? data?.homeTel1?.dialCode : null,
          phone: data?.homeTel1?.phoneNumber ? data?.homeTel1?.phoneNumber : null,
        }
      : null,
    homeTel2: data?.homeTel2
      ? {
          countryCode: data?.homeTel2?.dialCode ? data?.homeTel2?.dialCode : null,
          phone: data?.homeTel2?.phoneNumber ? data?.homeTel2?.phoneNumber : null,
        }
      : null,
    startDate: data?.startDate ? moment(data?.startDate).format("YYYY-MM-DD") : null,
    endDate: data?.endDate ? moment(data?.endDate).format("YYYY-MM-DD") : null,
  };
  apiService
    .postCall(APIServicePath.carerOtherFormApi, params)
    .then((data) => {
      // let list = toHospitalList(data?.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerKinContactFormAPI(data, callback) {
  let kinsList = [];
  data?.contacts.map((KinItem, index) => {
    let kin = {
      id: KinItem?.id,
      title: KinItem?.title?.label,
      firstName: KinItem?.firstName,
      middleName: "",
      lastName: KinItem?.lastName,
      email: KinItem?.email,
      phone: KinItem?.phoneNumber?.phoneNumber ?? "",
      countryCode: KinItem?.phoneNumber?.dialCode,
      address: KinItem?.Address,
      relationship: KinItem?.relationship,
    };
    kinsList.push(kin);
  });

  let params = {
    carerId: data?.id ?? null,
    kins: kinsList,
  };
  apiService
    .postCall(APIServicePath.carerKinFormApi, params)
    .then((data) => {
      // let list = toHospitalList(data?.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerClientBanFormAPI(data, callback) {
  let params = {
    carerId: data?.id ?? null,
    clientBanIds: data?.hospital ? data?.hospital : [],
  };
  apiService
    .postCall(APIServicePath.carerHospitalFormApi, params)
    .then((data) => {
      // let list = toHospitalList(data?.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerTypeDocumentApi(id, callback) {
  let params = {
    id: id,
  };
  apiService
    .postCall(APIServicePath.carerTypeDocument, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function carerAuditTrailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.carerAuditTrail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
