import React from "react";
import { Button, Icon } from "../Component";
import { JobStatusType } from "../../utils";

const AnnsBadge = ({ color, label, onRemove, onText, onAdd , className,status }) => {
  return (
    <li className="btn-group">
      <Button color={color || "light"} size="xs" className={`btn-dim ${className}`} onClick={onText}>
        {label}
      </Button>
      {onRemove && (status === JobStatusType.open || status === JobStatusType.applied) && (
        <Button color={color || "light"} size="xs" className="btn-icon btn-dim" onClick={onRemove}>
          <Icon name="cross"></Icon>
        </Button>
      )}
      {onAdd && (status === JobStatusType.open|| status === JobStatusType.applied) && (
        <Button color={color || "light"} size="xs" className="btn-icon btn-dim" onClick={onAdd}>
          <Icon name={"plus"}></Icon>
        </Button>
      )}
    </li>
  );
};

export default AnnsBadge;
