import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";

export const specialityTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: <TableTitle title={"Code"} />,
      selector: (row) => row?.abbrCode ?? "",
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      sortable: true,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.speciality, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addSpecialityAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.title,
    abbrCode: data.abbrCode,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addSpecialit, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listSpecialityAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listSpeciality, params)
    .then((data) => {
      let list = toSpecialityList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toSpecialityList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toSpeciality(element));
  });
  return list;
}

function toSpeciality(data) {
  return {
    id: data.id,
    title: data?.name,
    abbrCode: data?.abbrCode,
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}
