import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { documentDropDownData } from "./DropDownData";

export const DocumentDropDown = ({
  value,
  selectedId,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadDocumentList();
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDocumentList = () => {
    documentDropDownData((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  
  useEffect(() => {
    if (selectedId) {
      const selectedOption = list.find(option => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  }, [list]);

  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={onSelect}
      {...props}
    />
  );
};
