import React, { useState, useEffect } from "react";
import { AnnsBadge, Icon, IconButton, RSelect } from "../Component";
import { ColorsArray, ColorsWithHexCode } from "../../utils/Utils";
import { toCapitalize } from "../../utils/Functions";

export const ColorPickerDropdown = ({ selectedColor, onSelect, placeholder, ...props }) => {
  const [colorOptions, setColorOptions] = useState([]);

  useEffect(() => {
    // Create options from ColorsArray with equivalent labels
    const options = ColorsWithHexCode.map(({ label, value }) => ({
      value: value,
      name: label,
      label: (
        <>
          <Icon name={`square-fill text-${label}`}></Icon>
          <span>{toCapitalize(label)}</span>
        </>
      ),
    }));
    setColorOptions(options);
  }, []);

  return (
    <RSelect
      options={colorOptions}
      placeholder={placeholder}
      value={colorOptions.find((item) => item?.value === selectedColor)}
      onChange={(selectedOption) => {
        onSelect(selectedOption.value);
      }}
      {...props}
    />
  );
};
