import React from "react";
import Head from "../../../layout/head/Head";
import {
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import AuditTrailDatatable from "../clients/hospital/AuditTrailDataTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const JobAuditTrail = () => {
  const location = useLocation();
  const jobId = location.state?.data?.jobId;

  return (
    <React.Fragment>
      <Head title={"Audit Trail"} />
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle page>Audit Trail</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <BackButton />
                </span>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <AuditTrailDatatable jobId={jobId} />
        </Block>
      </Content>

      <LoadingComponent isLoading={""} />
    </React.Fragment>
  );
};

export default JobAuditTrail;
