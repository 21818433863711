import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

const NameTitleDropDown = ({ value, isMulti = false, isShowAll = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusValues = [
      { id: 1, label: "Mr", value: "Mr" },
      { id: 2, label: "Mrs", value: "Mrs" },
      { id: 2, label: "Miss", value: "Miss" },
      // Add more static options as needed
    ];

    if (isShowAll) {
      setList([...statusValues]);
    } else {
      setList(statusValues);
    }
  }, [isShowAll]);
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default NameTitleDropDown;
