import React from "react";
import { AnnsBadge, TableTitle } from "../../../components/Component";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import CustomPopOver from "../../../pages/tabs/carers/CustomPopover";
import { GradeBadge } from "../../../components/badge/GradeBadge";
import { convertHexToColor, toCapitalize } from "../../../utils/Functions";

export const BroadCastTableData = () => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      id: "ipAddress",
      selector: (row) => row?.title,
      sortable: false,
      style: {
        color: "black",
      },
      cell: (row) => <div className="flex-column">{row?.title}</div>,
    },
    {
      name: <TableTitle title={"Message"} />,
      id: "description",
      sortable: false,
      cell: (row) => <div className="flex-column my-2">{row?.description}</div>,
      style: {
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Grade"} />,
      cell: (row) => (
        <CustomPopOver data={row}>
          <div className="flex-column">
            {row?.grades?.length > 0 ? (
              <>
                {row.grades.slice(0, 2).map((grade, index) => (
                  <GradeBadge
                    key={index}
                    label={grade.name}
                    className={"me-1 my-1"}
                    color={`outline-${convertHexToColor(grade?.colourHex)}`}
                  />
                ))}
                {row.grades.length > 2 && (
                  <AnnsBadge className={"my-1"} color={"primary"} label={`+ ${row.grades.length - 2}`} />
                )}
              </>
            ) : (
              <span>-</span>
            )}
          </div>
        </CustomPopOver>
      ),
      style: {
        cursor: "pointer",
      },
    },
    {
      name: <TableTitle title={"Type"} />,
      id: "type",
      selector: (row) => toCapitalize(row?.type),
      sortable: false,
      cell: (row) => <div className="flex-column">{toCapitalize(row?.type)}</div>,
      style: {
        color: "black",
      },
      width: "150px",
    },
    {
      name: <TableTitle title={"Sent by"} />,
      id: "country",
      selector: (row) => row?.sentBy?.firstName + " " + row?.sentBy?.lastName,
      sortable: false,
      cell: (row) => (
        <div className="flex-column">
          {row?.sentBy?.firstName} {row?.sentBy?.lastName}
        </div>
      ),
      style: {
        color: "black",
      },
    },
  ];
};

export function broadCastListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.notificationList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
