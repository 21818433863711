import React, { useContext, useState } from "react";
import { CarerWizardContext } from "./AddCarer";
import { Form } from "reactstrap";
import {
  ActionButton,
  Block,
  BranchDropDown,
  Col,
  FormDatePicker,
  FormInput,
  FormInputType,
  PhoneInput,
  Row,
} from "../../../components/Component";
import { useEffect } from "react";
import moment from "moment/moment";
import { CarerPreviousButton } from "../../../components/button/ActionButton";

const CarerOthers = ({ onNext, onPrev, isValid }) => {
  const { data, updateData } = useContext(CarerWizardContext);
  const [isDisable, setIsDisable] = useState(true);
  const [itHasChanges, setItHasChanges] = useState(false);
  useEffect(() => {
    // Check if startDate is null or undefined
    if (itHasChanges && data?.startDate == null) {
      setIsDisable(true);
      isValid(true, itHasChanges);
    } else {
      setIsDisable(false);
      isValid(false, itHasChanges);
    }

    // Check if itHasChanges, homeTel1, and ishomeTel1 are present
    if (itHasChanges && data?.homeTel1 && data?.ishomeTel1) {
      setIsDisable(true);
      isValid(true, itHasChanges);
    }
  }, [data, itHasChanges]);

  const onFormSubmit = () => {
    onNext();
  };
  return (
    <>
      <Form>
        <Row>
          <Col className="col-12  col-md-10 w-100">
            <Row>
              <Col className="col-md-6 mb-2">
                <FormInput
                  label={"Ref No"}
                  type={FormInputType.default}
                  value={data?.refNo}
                  maxLength={15}
                  // onError={(isError) => {
                  //   updateData("isRefNo", isError);
                  // }}
                  onTextChange={(text) => {
                    if (data?.refNo !== text) {
                      setItHasChanges(true);
                    }
                    updateData("refNo", text);
                  }}
                />
              </Col>
              <Col className="col-md-6 mb-2">
                <FormInput
                  label={"NI No"}
                  type={FormInputType.default}
                  value={data?.NI_No}
                  maxLength={15}
                  // onError={(isError) => {
                  //   updateData("isNI_No", isError);
                  // }}
                  onTextChange={(text) => {
                    if (data?.NI_No !== text) {
                      setItHasChanges(true);
                    }
                    updateData("NI_No", text);
                  }}
                />
              </Col>
              <Col className="col-md-12 mb-2">
                <FormInput
                  label={"NISCC NO / NMC Pin"}
                  maxLength={15}
                  type={FormInputType.default}
                  value={data?.NISCC_NO}
                  // onError={(isError) => {
                  //   updateData("isNISCC_NO", isError);
                  // }}
                  onTextChange={(text) => {
                    if (data?.NISCC_NO !== text) {
                      setItHasChanges(true);
                    }
                    updateData("NISCC_NO", text);
                  }}
                />
              </Col>
              <Col className="col-md-6 mb-2">
                <PhoneInput
                  phone={data?.homeTel1?.phoneNumber}
                  countryCode={data?.homeTel1?.dialCode}
                  onChange={(text) => {
                    if (data?.homeTel !== text) {
                      setItHasChanges(true);
                    }
                    updateData("homeTel1", text);
                  }}
                  onError={(isError) => {
                    updateData("ishomeTel1", isError);
                  }}
                />
                {/* <FormInput
                  label={"Home Tel "}
                  maxLength={10}
                  type={FormInputType.number}
                  value={data?.homeTel}
                  onError={(isError) => {
                    updateData("ishomeTel", isError);
                  }}
                  onTextChange={(text) => {
                    updateData("homeTel", text);
                  }}
                /> */}
              </Col>
              <Col className="col-md-6 mb-2">
                <PhoneInput
                  phone={data?.homeTel2?.phoneNumber}
                  countryCode={data?.homeTel2?.dialCode}
                  onChange={(text) => {
                    if (data?.homeTel2 !== text) {
                      setItHasChanges(true);
                    }
                    updateData("homeTel2", text);
                  }}
                  // onError={(isError) => {
                  //   updateData("ishomeTel2", isError);
                  // }}
                />
                {/* <FormInput
                  label={"Home Tel 2"}
                  maxLength={10}
                  type={FormInputType.number}
                  value={data?.homeTel2}
                  onError={(isError) => {
                    updateData("ishomeTel2", isError);
                  }}
                  onTextChange={(text) => {
                    updateData("homeTel2", text);
                  }}
                /> */}
              </Col>
              <Col className="col-md-6 mb-2">
                <div className="form-group">
                  <label className="form-label">
                    Start Date <span className="text-danger fs-6">*</span>
                  </label>
                  <div className="form-control-wrap">
                    <FormDatePicker
                      label={"From Date"}
                      selected={data?.startDate}
                      onChange={(date) => {
                        if (data?.startDate !== date) {
                          setItHasChanges(true);
                        }
                        updateData("startDate", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col className="col-md-6 mb-2">
                <div className="form-group ">
                  <label className="form-label">End Date</label>
                  <div className="form-control-wrap ">
                    <FormDatePicker
                      label={"From Date"}
                      selected={data?.endDate}
                      onChange={(date) => {
                        if (data?.endDate !== date) {
                          setItHasChanges(true);
                        }
                        updateData("endDate", date);
                      }}
                      minDate={data?.startDate}
                    />
                  </div>
                </div>
              </Col>
              {/* <Col className="col-md-12 mb-2">
                <div className="form-group">
                  <label className="form-label">
                    Select Branch <span className="text-danger fs-6">*</span>
                  </label>
                  <div className="form-control-wrap">
                    <BranchDropDown
                      value={data?.branch ?? ""}
                      onSelect={(branch) => {
                        updateData("branch", branch);
                      }}
                    />
                  </div>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
        {/* <button
          onClick={() => {
            onNext();
          }}
        >
          CarerDetials
        </button> */}
        <Block className="text-end mt-3">
          <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
            <li>
              <CarerPreviousButton className="w-100px" title="Back" size="md" onClick={() => onPrev()} />
            </li>
            <li>
              <ActionButton
                disabled={isDisable}
                className="w-120px"
                title="Save & Next"
                size="md"
                type="button"
                onClick={() => onFormSubmit()}
              />
            </li>
          </ul>
        </Block>
      </Form>
    </>
  );
};

export default CarerOthers;
