import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { carersDropDownData } from "./DropDownData";
import { RSelect } from "../Component";

export const CarersDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  isClearable,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  carerTypeId,
  searchOnInput = false,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    return () => {
      setList();
      setSearchString();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchOnInput) {
      loadCarerList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, carerTypeId]);

  useEffect(() => {
    if (searchOnInput === true) {
      if (searchString) {
        loadCarerList();
      }
    }
  }, [searchOnInput, searchString, carerTypeId]);

  const loadCarerList = () => {
    carersDropDownData(searchString, carerTypeId, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        }
      }
    });
  };

  const handleSearch = (text) => {
    setSearchString(text);
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={"Search Carer"}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onInputChange={handleSearch}
      isClearable={isClearable}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
