import React from "react";
import { Badge } from "reactstrap";
import CarerProfile from "../user/CarerProfile";

export const GradeBadge = ({ color, label, className, onClick, ...props }) => {
  return (
    <>
      <Badge onClick={onClick} color={color} className={`badge-dim badge-sm ${className}`}>
        <span className="text-dark">{label}</span>
      </Badge>
    </>
  );
};

export const ClientCarerBadge = ({
  color,
  label,
  phoneNumber,
  className,
  onClick,
  selected,
  carerImage,
  isCarer,
  ...props
}) => {
  return (
    <span
      className={`d-flex align-items-center border ${
        selected ? "bg-white" : "bg-white"
      } text-black badge-sm cursor-pointer ${className} w-100`}
      onClick={onClick}
    >
      <span className="me-2">
        <input type="radio" name={selected} id={selected} checked={selected} readOnly />
      </span>

      <div className="d-flex align-items-center">
        {isCarer && (
          <div className="me-2">
            <CarerProfile
              carerFilter={true}
              className="sm"
              image={carerImage ? carerImage : "https://dummyimage.com/300"}
            />
          </div>
        )}
        <div className="text-start ">
          <span>{label}</span>
          <br />
          <span className="sub-text">{phoneNumber}</span>
        </div>
      </div>
    </span>
  );
};
