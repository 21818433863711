/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { documentTypeColumns, listDocumentTypeAPI } from "./DocumentTypeData";
import AddDocumentType from "./AddDocumentType";
import { ServerStatusType } from "../../../../utils";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const DocumentTypeList = () => {
  const [documentTypeListData, setDocumentTypeListData] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status) {
      loadDocumentType();
    }
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }
  function loadDocumentType() {
    if (isCheckToList(UserPermissionType.documentType)) {
      setLoading(true);
      listDocumentTypeAPI({ status: status?.value ?? ServerStatusType.active }, (list, error) => {
        if (error === null) {
          setDocumentTypeListData(list);
        }
        setLoading(false);
      });
    }
  }
  return (
    <React.Fragment>
      <Head title="Document Type" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Document Type</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.documentType, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={documentTypeListData}
              columns={documentTypeColumns(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.documentType)}
            />
          </PreviewCard>
        </Block>
        <AddDocumentType
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadDocumentType();
          }}
        />
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default DocumentTypeList;
