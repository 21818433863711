import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";

export const documentTypeColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <TableTitle title={"Expiry date"} />,
      selector: (row) => (row.isExpire === 0 ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: <TableTitle title={"Issue date"} />,
      selector: (row) => (row.isIssue === 0 ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.documentType, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,

      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addDocumentTypeAPI(data, callback) {
  let params = {
    id: data?.id,
    title: data?.title,
    desc: data?.description,
    isExpire: data?.isExpire === 0 ? true : false,
    isIssue: data?.isIssue === 0 ? true : false,
    status: data?.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.saveDocument, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listDocumentTypeAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listDocument, params)
    .then((data) => {
      let list = toDocumentTypeList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toDocumentTypeList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toDocumentType(element));
  });
  return list;
}

function toDocumentType(data) {
  return {
    id: data?.id ?? "",
    title: data.title ?? "",
    description: data?.desc ?? "",
    isExpire: data?.isExpire ? 0 : 1,
    isIssue: data?.isIssue ? 0 : 1,
    isActive: data.status === ServerStatusType.active ? true : false,
  };
}
