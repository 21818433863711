/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { useEffect, useState } from "react";
import { fetchRoleList, userRoleTableData } from "./UserRoleTableData";
import { useHistory } from "react-router";
import { ServerStatusType } from "../../../../utils";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { scrollToTop } from "../../../../utils/Functions";

const UserRoleList = () => {
  const [userRoleList, setUserRoleList] = useState([]);
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    loadRoleList();
  }, [status]);

  const onEdit = (row) => {
    setSelectedItem(row); // Pass the id of the selected item
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-userrole`,
      state: { data: row },
    });
  };

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-userrole`);
  };

  // API--------------------------------

  const loadRoleList = () => {
    if (isCheckToList(UserPermissionType.role)) {
      setLoading(true);
      fetchRoleList(
        {
          status: status?.value ?? ServerStatusType.active,
          searchText: searchText,
          sort: { field: "name" },
        },
        (data, error) => {
          if (error === null) {
            setUserRoleList(data);
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="User Roles" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>User Role</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.role, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={onAddClick} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={userRoleList}
              columns={userRoleTableData(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.role)}
            />
          </PreviewCard>
        </Block>
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default UserRoleList;
