import React, { useRef } from "react";
import { AnnsModal, Col, FormInput, FormInputType, Icon } from "../../../../components/Component";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { AppText, showErrorToast, showSuccessToast } from "../../../../utils";
import RichTextEditor, { EditorValue } from "react-rte";
import { addTemplateAPI } from "./TemplateData";
import { TemplateType } from "./TemplateList";
import { bytesToMegaBytes, toolbarConfig } from "../../../../utils/Utils";
import { iconsType } from "../../../../layout/fileIcon/FileIcon";
import { fileUploadApi } from "../../carers/CarerData";
import { Editor } from "@tinymce/tinymce-react";

const AddTemplate = ({ visible, item, onSaved, onClosed, type, templateTypeId }) => {
  const editorRef = useRef(null);
  const { errors, register, handleSubmit } = useForm();
  const [templateData, setTemplateData] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    return () => {
      setTemplateData();
      setLoading();
      setIsDisabled();
    };
  }, []);

  useEffect(() => {
    if ((templateData?.title?.length ?? 0) === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [templateData]);

  // useEffect(() => {
  //   if (visible && item) {
  //     // Convert HTML string to EditorValue
  //     const descriptionValue = RichTextEditor.createValueFromString(item?.description, "html");
  //     setTemplateData({
  //       ...templateData,
  //       id: item?.id,
  //       title: item?.title,
  //       subject: item?.subject,
  //       description: item?.description,
  //       html: descriptionValue || "",
  //     });
  //     setUploadedFiles(item?.templateFiles);
  //   } else {
  //     setTemplateData({
  //       ...templateData,
  //       title: "",
  //       description: "",
  //       subject: "",
  //     });
  //   }
  // }, [visible, item]);
  useEffect(() => {
    if (visible && item) {
      // Convert HTML string to EditorValue
      const descriptionValue = RichTextEditor.createValueFromString(item?.description, "html");
      setTemplateData((prev) => ({
        ...prev,
        id: item?.id,
        title: item?.title,
        subject: item?.subject,
        description: item?.description,
        html: descriptionValue || EditorValue.createEmpty(),
      }));
      setUploadedFiles(item?.templateFiles);
    } else {
      setTemplateData((prev) => ({
        ...prev,
        title: "",
        description: "",
        subject: "",
      }));
    }
  }, [visible, item]);

  const close = () => {
    setTemplateData({});
    setUploadedFiles([]);
    setRemoveFileIds([]);
    setLoading(false);
    onClosed();
  };

  const onFormSubmit = () => {
    if (isDisabled) return;
    setLoading(true);
    addTemplateAPI(templateData, uploadedFiles, removeFileIds, type, templateTypeId, (_, error) => {
      if (error === null) {
        let text =
          "Template" + " " + templateData?.title + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  // file fuctions ------------------------>

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile

    setUploadedFiles(updatedfiles);
  };

  // API CALLING----------------------------------------
  const uploadFile = (file, index) => {
    fileUploadApi(file, "TEMPLATE", (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  const openFileInNewTab = (file) => {
    if (file) {
      // window.open(file?.fileUrl, "_blank");
    }
  };

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        loading={loading}
        toggle={() => close()}
        title={item ? "Edit Template" : "Add Template"}
        onClick={() => onFormSubmit()}
        disabled={isDisabled}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  label={"Title"}
                  type={FormInputType.default}
                  value={templateData.title}
                  onTextChange={(text) => {
                    setTemplateData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          {type === TemplateType.email && (
            <Col md="12">
              <div className="form-group">
                <div className="form-control-wrap">
                  <FormInput
                    label={"Subject"}
                    type={FormInputType.default}
                    value={templateData.subject}
                    onTextChange={(text) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        subject: text,
                      }));
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Description</label>
              <div className="form-control-wrap">
                {type === TemplateType.sms ? (
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={templateData.description}
                    onChange={(e) => {
                      setTemplateData((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <>
                    {/* <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      editorClassName="custom-editor-container"
                      value={templateData?.html ?? EditorValue?.createEmpty()}
                      onChange={(value) => {
                        setTemplateData((prev) => ({
                          ...prev,
                          html: value, // Update HTML content directly
                          description: value.toString("html"), // Convert value to HTML string
                        }));
                      }}
                    /> */}
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={templateData?.description ?? EditorValue?.createEmpty()} // Set the HTML content as the editor value
                      apiKey="ckmat7h6bcf5ocsg0s9bvbusma7f9pdvq8nx14i8uc3oaf73"
                      onEditorChange={(value) => {
                        setTemplateData((prev) => ({
                          ...prev,
                          html: value,
                          description: value.toString("html"), // Update the description property with the new text
                        }));
                      }}
                      init={{
                        menubar: false,
                        statusbar: false,
                        height: 450,
                        automatic_uploads: false,
                        file_picker_callback: function (callback, value, meta) {
                          // Provide file and text for the link dialog
                          if (meta.filetype === "file") {
                            callback("mypage.html", { text: "My text" });
                          }

                          // Provide image and alt text for the image dialog
                          if (meta.filetype === "image") {
                            callback("myimage.jpg", { alt: "My alt text" });
                          }

                          // Provide alternative source and posted for the media dialog
                          if (meta.filetype === "media") {
                            callback("movie.mp4", {
                              source2: "alt.ogg",
                              poster: "image.jpg",
                            });
                          }
                        },
                        fontsize_formats:
                          "8px 9px 10px 11px 12px 14px 15px 16px 17px 18px 19px 20px 24px 30px 36px 48px 60px 72px 96px",
                        paste_data_images: true,
                        // images_upload_url: "postAcceptor.php",

                        /* we override default upload handler to simulate successful upload*/
                        images_upload_handler: function (blobInfo, success, failure) {
                          setTimeout(function () {
                            success();
                          }, 2000);
                        },
                        content_style: "body { font-size: 14pt; }",
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code",
                        ],
                        toolbar1:
                          "preview | undo redo | formatselect | " +
                          "bold italic underline | code | numlist bullist | " +
                          "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                        lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 35px 36px",
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col className="col-12  mb-3">
            <div className="form-group mb-0">
              <label htmlFor="files">
                <Icon name="clip" className="mb-1 text-primary" />
                <span className="text-primary"> Attach document</span>
              </label>
              <input
                type="file"
                name="files"
                accept=".pdf,.doc,.docx,.xlsx,.xls"
                className="form-control"
                id={"files"}
                style={{ display: "none" }}
                onChange={(e) => {
                  let temp = Array.prototype.slice.call(e.target.files);
                  if (temp.length > 0) {
                    handleFileSelect(temp[0]);
                  }
                  e.target.value = '';
                }}
              />
            </div>
            {uploadedFiles?.length > 0 &&
              uploadedFiles?.map((file, ind) => {
                return (
                  <React.Fragment key={`file-${ind}`}>
                    <div className="nk-upload-item">
                      <div className="nk-upload-icon">
                        {file?.isOldFile ? (
                          <>{iconsType[file?.mimeType] ? iconsType[file?.mimeType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[file?.type] ? iconsType[file?.type] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <div className="nk-upload-title">
                          <span
                            className="title"
                            style={{ cursor: "pointer" }} // Add cursor pointer style
                            onClick={() => openFileInNewTab(file)} // Handle click event
                          >
                            {file?.name}
                          </span>
                          {/* <span className="title">{file?.name}</span> */}
                        </div>
                      </div>
                      <div className="nk-upload-action">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                        >
                          {file?.isLoading ? (
                            <Spinner size="sm" color="dark" />
                          ) : (
                            <span onClick={() => handleFileRemove(ind)}>
                              <Icon name="trash"></Icon>
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            {}
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddTemplate;
