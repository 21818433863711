// AddressDropdown.js
import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { addressDropDownData } from "./DropDownData";

export const AddressDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  isClearable,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  carerTypeId,
  search,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    // Clear the list when the component unmounts
    return () => {
      setList([]);
    };
  }, []);

  useEffect(() => {
    // Load address list only when the search value is available
    if (search !== undefined && search !== null) {
      loadAddressList();
    }
  }, [search]);

  const loadAddressList = () => {
    // Check if the search string is not empty before making the API call
    if (search.trim() !== "") {
      addressDropDownData(search, (data, error) => {
        if (error === null) {
          if (isShowAll === false) {
            setList(data);
            data.length > 0 && onSelect(data[0]);
          }
        }
      });
    }
  };

  return (
    <>
      <RSelect
        placeholder={placeholder}
        options={list ?? []}
        isMulti={isMulti}
        value={value ?? (list.length > 0 ? list[0].label : null)}
        isClearable={isClearable}
        onChange={(e) => {
          onSelect(e);
        }}
        {...props}
      />
    </>
  );
};

export default AddressDropdown;
