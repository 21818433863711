import { Storage } from "../storage/storage";
import { APIDataConverter } from "./api-data-converter";
import { APIError, APIErrorCode, APIServiceBase } from "./api-service-base";

export const APIServicePath = {
  //Auth
  signin: "auth/sign-in",
  forgotPassword: "auth/reset-password",
  generateSession: "auth/login-with-token",
  checkPassword: "user/check-password",
  changePassword: "user/change-password",
  logout: "user/sign-out",
  verifyResetPasswordToken: "auth/verify-reset-password-token",
  resendEmail: "auth/resend-email-link",
  recoverPassword: "auth/recover-password",

  // Master List
  masterList: "common/master-list",

  // Location-search
  locationList: "common/location-search-by-pincode",

  // current user
  currentUser: "user/current-user",
  loginActivity: "user/login-activity-list",

  // Grade
  addUser: "user/save",
  listUser: "user/list",

  // Grade
  addGrade: "grade/save",
  listGrade: "grade/list",
  gradeDetail: "grade/detail",

  // Ward
  addWard: "client/ward-save",
  listWard: "client/ward-list",
  exportWard: "client/ward-xlsx",
  importWardClient: "client/import-ward-data-xlsx",

  // Rate
  addRate: "rate/save",
  listRate: "rate/list",
  exportRate: "rate/export-data-xlsx",
  importRate: "rate/import-data-xlsx",
  rateRemove: "rate/remove",
  // Speciality
  addSpecialit: "speciality/save",
  listSpeciality: "speciality/list",

  // Notetype
  addNoteType: "note-type/save",
  listNoteType: "note-type/list",

  // user Role
  roleMaster: "role/Master",
  roleList: "role/list",
  roleSave: "role/save",
  roleDetail: "role/detail",

  // Branch
  addBranch: "branch/save",
  listBranch: "branch/list",

  // Holiday
  addHoliday: "holiday/save",
  listHoliday: "holiday/list",

  // Trust
  addTrust: "trust/save",
  listTrust: "trust/list",
  trustDetail: "trust/detail",

  // Hospital
  addHospital: "client/save",
  listHospital: "client/list",
  detailHospital: "client/detail",
  exportClient: "client/client-xlsx",
  importFileClient: "client/import-client-data-xlsx",
  clientWardRemove: "client/ward-remove",
  clientImportTemplate: "client/import-template",
  clientAuditTrail: "client/audit-trail",

  //Carer
  carerList: "carer/list",
  carerSave: "carer/save",
  carerDetail: "carer/detail",
  carerTypeDocument: "carer-type/document-list",
  saveCarerNote: "carer/note-save",
  importFileCarer: "carer/import-data-xlsx",
  exportCarer: "carer/export-carer",
  carerDetailFormApi: "carer/detail-save",
  carerOtherFormApi: "carer/other-save",
  carerKinFormApi: "carer/kin-save",
  carerHospitalFormApi: "carer/hospital-save",
  carerDocumentApi: "carer/document-save",
  carerDocumentList: "carer/document-list",
  carerDocumentRemove: "carer/remove-document",
  carerImportTemplate: "carer/import-template",
  carerAuditTrail: "carer/audit-trail",

  // Client-Note
  addClientNote: "client/note-save",
  listClientNote: "client/client-note-save-list",

  // Carer
  carerType: "carer-type/list",
  addAvailbilityDate: "carer/set-carer-available-date",
  availableDateList: "carer/available-date-list",

  // Document Type

  saveDocument: "document/master/save",
  listDocument: "document/master/list",

  // Carer Type
  saveCarerType: "carer-type/save",
  listCarerType: "carer-type/list",
  carerNoteList: "carer/carer-note-save-list",

  //Job
  saveJob: "job/save",
  jobList: "job/list",
  jobDetail: "job/detail",
  jobCarerList: "job/carer-list",
  jobAssignedCarer: "job/carer-assign",
  moveToRota: "rota/save",
  availableCarer: "job/available-carer-list",
  checkCarerAvailability: "job/check-carer-availability",
  jobAuditTrail: "job/audit-trail",

  //Rota
  rotaList: "rota/list",
  exportRota: "rota/day-xlsx",
  rotaDayList: "rota/day-list",

  //Template
  templateList: "template/list",
  saveTemplate: "template/save",
  defaultTemplate: "template/mark-default",
  templateDetail: "template/detail",
  removeTemplate: "template/remove",

  //Timesheet
  timesheetList: "timesheet/rota-weeklies",
  addTimesheet: "timesheet/save",
  timesheetDetail: "timesheet/detail",
  timesheetModerateStatus: "timesheet/moderate-status",
  timesheetCancel: "timesheet/timesheet-cancel",
  timesheetReject: "timesheet/timesheet-reject",
  sentEmailNotification: "notification/sent-email",
  poNumberList: "timesheet/po-number-list",
  timesheetExport: "timesheet/timesheet-xlsx",
  timesheetListing: "timesheet/listing",
  timesheetMovetoWages: "timesheet/move-to-wages",
  timesheetMovetoTimesheet: "timesheet/move-to-timesheet",
  timesheetFullData: "timesheet/get-full-data",
  sendTimesheetNotification: "notification/custom-notifications",
  sendGradeNotification: "notification/custom-notify-by-grade",

  //Wage
  wageList: "wage/list",
  wageStatus: "wage/moderate-status",
  exportWage: "wage/pay-role-export",

  //Wage-Invoice
  wageInvoiceList: "wage/invoice-list",
  exportInvoice: "wage/invoice-export-pdf",
  exportInvoiceXLSX: "invoice/export",
  getContactList: "client/get-contact-list",
  invoiceSend: "invoice/send",
  moveToPrepared: "invoice/prepared",
  customInvoice: "invoice/custom-invoice",

  // Agency
  addAgency: "agency/save",
  agencyList: "agency/list",
  agencyDetail: "agency/detail",

  // Dashboard
  topClients: "dashboard/client",
  topCarers: "dashboard/carer",
  dashboardRota: "dashboard/rota",
  lastBooking: "dashboard/last-booking",
  pendingTimesheets: "dashboard/timesheet",
  statistics: "dashboard/statistic",
  dashboardDocument: "dashboard/document",
  invoiceStatistic: "dashboard/invoice-statistic",
  wagesStatistic: "dashboard/wage-statistic",

  // COMMON
  fileUpload: "common/file-upload",

  // Notification
  notificationList: "notification/audit-list",
};

export const APIServiceMethod = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export class APIServiceImpl extends APIServiceBase {
  constructor() {
    super();
    this.dataConverter = new APIDataConverter();
  }

  async handleAPIResopnse(response) {
    const json = await response.json();
    if ((response.status === 200 || response.status === 201) && json) {
      return json;
    } else {
      let error = new APIError(
        json?.errorCode ?? APIErrorCode.UnkonwnError,
        json?.message ?? null,
        json?.title ?? null,
        json?.isForceLogout ?? false
      );
      if (json?.isForceLogout === true) {
        Storage.clearAll();
        window.location.reload();
      }
      throw error;
    }
  }

  setUserToken(accessToken) {
    this.accessToken = accessToken;
  }

  handleAPIException(exception) {
    console.log("exception-------->", exception);
    if (exception instanceof APIError) {
      return exception;
    } else {
      if (exception.code === 20 || exception.message === "Aborted") {
        // Abort Api call
        return new APIError(APIErrorCode.Aborted);
      } else {
        return new APIError(APIErrorCode.UnkonwnError);
      }
    }
  }

  handleAuthResponse(authResponse) {
    if (!authResponse.accessToken) {
      throw new APIError(APIErrorCode.UnexpectedAuthResponse);
    }
    this.accessToken = authResponse.accessToken;
  }

  // ************************Common API's*******************************//
  async postCall(path, req, timeout) {
    return await this.sessionAPICall(path, APIServiceMethod.post, req, null, timeout);
  }

  async postCallWithCancelSignal(path, req, token) {
    const controller = new window.AbortController();
    const signal = controller.signal;
    token.cancel = () => {
      controller.abort();
    };
    return await this.sessionAPICall(path, APIServiceMethod.post, req, signal);
  }

  async postPublicCall(path, req) {
    return await this.publicAPICall(path, APIServiceMethod.post, req);
  }

  async getCall(path, req) {
    return await this.sessionAPICall(path, APIServiceMethod.get, req);
  }

  async fileUploader(file, type, path) {
    if (file === undefined || file === null) {
      return null;
    }

    return await this.uploader(
      {
        file: file,
        type: type,
        name: file.name,
      },
      path
    );
  }

  async uploader(params, path) {
    if (params === undefined || params === null) {
      return null;
    }
    let body = new FormData();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        body.append(key, params[key]);
      }
    });

    const result = await this.uploadImageApiCall(path, APIServiceMethod.post, body, 120000);
    return result.data;
  }
}
