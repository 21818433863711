import moment from "moment";
import { MoreOptions, TableTitle } from "../../../components/Component";
import { DATE_FORMAT, dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { convertDropValue, covertToNumberOnly, removeTimeFromServerDateString } from "../../../utils/Functions";

export const ratesTableData = (onEdit, onRemove, onRota, onHistory) => {
  return [
    // {
    //   name: <TableTitle title={"Client"} />,
    //   selector: (row) => row?.client,
    //   sortable: true,
    // },
    // {
    //   name: <TableTitle title={"Hospital"} />,
    //   selector: (row) => row?.hospital,
    //   sortable: true,
    //   grow: 1,
    //   //wrap: true,
    // },

    {
      name: <TableTitle title={"Pay Type"} />,
      selector: (row) => row?.payType,
      sortable: false,
    },
    // {
    //   name: <TableTitle title={"Shift"} />,
    //   selector: (row) =>
    //   {
    //     const startTime = row?.startTime ? moment(row?.startTime).format("hh:mm A") : "";
    //     const endTime = row?.endTime ? moment(row?.endTime).format("hh:mm A") : "";
    //     return `${startTime}  -  ${endTime}`;
    //   },
    //   sortable: true,
    // },
    {
      name: <TableTitle title={"Day type name"} />,
      selector: (row) => row?.dayType?.name,
      sortable: true,
    },
    {
      name: <TableTitle title={"Carer type"} />,
      selector: (row) => row?.carerType?.label,
      sortable: true,
    },
    {
      name: <TableTitle title={"Grade"} />,
      selector: (row) => row?.grade?.label,
      sortable: true,
    },
    {
      name: <TableTitle title={"Charge Rate"} />,
      selector: (row) => row?.chargeRate,
      sortable: true,
    },
    {
      name: <TableTitle title={"Pay Rate"} />,
      selector: (row) => row?.payRate,
      sortable: true,
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "100px",
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            // {
            //   icon: "trash",
            //   text: "Remove",
            //   action: () => {
            //     onRemove(row);
            //   },
            // },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addRateAPI(data, hospitalId, callback) {
  let params = {
    clientId: hospitalId,
    gradeId: data?.grade?.id,
    payType: data?.payType?.id,
    carerTypeId: data?.carerType?.id,
    payType: data?.payType?.value,

    dayTypes: data?.dayTypeRates?.map((rate) => {
      return {
        chargeRate: parseFloat(covertToNumberOnly(rate?.chargeRate)),
        payRate: parseFloat(covertToNumberOnly(rate.payRate)),
        dayTypeId: rate.dayTypeId,
      };
    }),
  };
  apiService
    .postCall(APIServicePath.addRate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listRateAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listRate, params)
    .then((data) => {
      let list = toRateList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo ?? "");
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportRateAPI(params, callback) {
  apiService
    .postCall(APIServicePath.exportRate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function rateRemoveAPI(params, callback) {
  apiService
    .postCall(APIServicePath.rateRemove, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toRateList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toRate(element));
  });
  return list;
}

function toRate(data) {
  return {
    id: data?.clientRateId,
    client: data?.clientId,
    dayType: data?.dayType,
    dayType: { id: data?.dayType?.id, name: data?.dayType?.name },
    speciality: convertDropValue(data?.speciality),
    carerType: convertDropValue(data?.carerType),
    grade: convertDropValue(data?.grade),
    payType: data?.payType,
    chargeRate: data?.chargeRate,
    payRate: data?.payRate,
    shiftTimeId: data?.shiftTimeId,
    startTime: moment(data?.shiftStartTime, "HH:mm:ss").utc().toDate(),
    endTime: moment(data?.shiftEndTime, "HH:mm:ss").utc().toDate(),
    validTill: data?.validTill ? moment(removeTimeFromServerDateString(data?.validTill)).format(DATE_FORMAT) : null,
  };
}
