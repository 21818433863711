import React, { useState } from "react";
import { useHistory } from "react-router";
import { Badge, Card, Spinner } from "reactstrap";
import { DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../components/Component";
import moment from "moment";
import { RotaType } from "./RotaList";
import RotaDetailModal, { ModalStatus } from "./RotaDetailModal";
import { hextoBackgrondColor } from "../../../utils/Functions";

const RotaMatrix = ({ isLoading, hList, vList, rotaType, onCancel }) => {
  const history = useHistory();
  const [detailModal, setDetailModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  function handleShowDetail(detail) {
    setDetailModal(true);
    setSelectedData(detail);
  }

  return (
    <>
      <div className="matrix-table h-100">
        <DataTable
          id="style-3"
          className="h-100 card-stretch overflow-auto min-h-calc_100vh_-_220  border border-light-gray w-100 border mx-10"
        >
          <DataTableBody>
            <DataTableHead>
              {/* Render dates for the rest of the indices */}
              {hList?.map((item, index) => (
                <DataTableRow key={index} className="border-light-gray-right text-center px-2 border-bottom">
                  <span className="matrix-font-day">{moment(item).format("ddd")}</span>
                  <span className="sub-text matrix-font-date">{moment(item).format("DD/MM")}</span>
                </DataTableRow>
              ))}
            </DataTableHead>
            <DataTableItem className="t-body matrix-card-align-top">
              {vList?.length > 0 &&
                hList.map((hItem, hIndex) => {
                  let rotas = vList.filter(
                    (item) => moment(item.rotaDate).format("YYYY-MM-DD") === moment(hItem).format("YYYY-MM-DD")
                  );
                  return (
                    <DataTableRow key={hIndex} className="border-light-gray-right text-black text-center px-2">
                      {rotas?.map((vItem, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Card
                              className={`matrix-text-card cursor-pointer`}
                              style={{ backgroundColor: `${hextoBackgrondColor(vItem?.grade?.colourHex, 0.5)}` }}
                              onClick={() => handleShowDetail(vItem)}
                            >
                              <div className={`d-flex  `}>
                                <span
                                  style={{
                                    borderLeft: `4px solid ${vItem?.grade?.colourHex}`,
                                    marginRight: "10px",
                                    borderRadius: "10px",
                                  }}
                                ></span>
                                <span className=" ">
                                  {rotaType === RotaType.carer ? vItem?.client?.name : vItem?.carer} <br />
                                  {moment(vItem?.startTime).format("HH:mm")} - {moment(vItem?.endTime).format("HH:mm")}
                                </span>
                              </div>
                            </Card>
                          </React.Fragment>
                        );
                      })}
                    </DataTableRow>
                  );
                })}
            </DataTableItem>
          </DataTableBody>
          {vList?.length === 0 && (
            <span className="d-flex justify-content-center border-bottom">There is no data found</span>
          )}
        </DataTable>
      </div>
      <RotaDetailModal
        visible={detailModal}
        item={selectedData}
        onClosed={(status) => {
          if (status === ModalStatus.success) {
            onCancel();
          }
          setDetailModal(false);
        }}
      />
    </>
  );
};

export default RotaMatrix;
