import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

export const BreakHourDropdown = ({
  value,
  intervalMinutes = 15,
  disabled,
  onSelect,
  placeholder,
  selected,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const timeValues = [];

    for (let minute = 0; minute <= 120; minute += intervalMinutes) {
      const hours = Math.floor(minute / 60); // Calculate hours
      const minutes = minute % 60; // Calculate remaining minutes
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timeValues.push({ label: timeString, value: minute });
    }

    setList(timeValues);
  }, [intervalMinutes]);

  useEffect(() => {
    if (!value && selected) {
      const selectedOption = list.find((option) => option.value === selected);
      onSelect(selectedOption);
    }
  }, [list, onSelect, selected, value]);

  return (
    <RSelect
      options={list}
      placeholder={placeholder}
      value={value}
      isDisabled={disabled}
      onChange={(selectedOption) => onSelect(selectedOption)}
      {...props}
    />
  );
};
