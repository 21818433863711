import Swal from "sweetalert2";

export const ConfirmationAlert = (title, message, onConfirmation, oncancel ) => {

  Swal.fire({
    title: title ?? "",
    text: message,
    showCancelButton: true,
    cancelButtonText: oncancel ? "No" : "Cancel",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirmation();
    } else {
      oncancel && oncancel();
    }
  });
};
