import React from "react";
import { AnnsModal, Col, FormInput, FormInputType, RadioButtonOptions } from "../../../../components/Component";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";
import { addDocumentTypeAPI } from "./DocumentTypeData";

const AddDocumentType = ({ visible, item, onSaved, onClosed }) => {
  const [documentTypeData, setDocumentTypeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (
      (documentTypeData?.title?.length ?? 0) === 0 ||
      documentTypeData?.isExpire === undefined ||
      documentTypeData?.isIssue === undefined
      // documentTypeData?.description === ""
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [documentTypeData]);

  useEffect(() => {
    if (visible) {
      if (item) {
        setDocumentTypeData({
          ...documentTypeData,
          id: item?.id,
          title: item?.title,
          description: item?.description,
          isActive: item?.isActive || false,
          isExpire: item?.isExpire,
          isIssue: item?.isIssue,
        });
      } else {
        setDocumentTypeData({
          ...documentTypeData,
          title: "",
          description: "",
          isActive: true,
          isExpire: undefined,
          isIssue: undefined,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item]);

  const close = () => {
    onClosed();
    setLoading(false);
    setDocumentTypeData({});
  };

  const onFormSubmit = () => {
    if (isDisable) return;
    setLoading(true);
    addDocumentTypeAPI(documentTypeData, (_, error) => {
      if (error === null) {
        let text =
          "Document type" +
          " " +
          documentTypeData?.title +
          (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
        showSuccessToast(text);
        onSaved();
        close();
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  const { errors, register, handleSubmit } = useForm();
  return (
    <>
      <AnnsModal
        size={"sm"}
        isOpen={visible}
        toggle={() => onClosed()}
        title={item ? "Edit Document" : "Add Document"}
        onClick={() => onFormSubmit()}
        loading={loading}
        disabled={isDisable}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">
                Document <span className="text-danger fs-5">*</span>
              </label>
              <div className="form-control-wrap">
                <FormInput
                  // label={"Document"}
                  type={FormInputType.default}
                  value={documentTypeData.title}
                  onTextChange={(text) => {
                    setDocumentTypeData((prev) => ({
                      ...prev,
                      title: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Description</label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control"
                  placeholder="Description"
                  value={documentTypeData.description}
                  onChange={(e) => {
                    setDocumentTypeData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">
                Issue Date <span className="text-danger fs-5">*</span>
              </label>
              <div className="form-control-wrap">
                <RadioButtonOptions
                  optionType={RadioOptionTypes.yesorno}
                  name="issueyesorno"
                  onChange={(index) => {
                    setDocumentTypeData((prev) => ({ ...prev, isIssue: index }));
                  }}
                  activeIndex={documentTypeData.isIssue}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">
                Expiry Date <span className="text-danger fs-5">*</span>
              </label>
              <div className="form-control-wrap">
                <RadioButtonOptions
                  optionType={RadioOptionTypes.yesorno}
                  name="expiryyesorno"
                  onChange={(index) => {
                    setDocumentTypeData((prev) => ({ ...prev, isExpire: index }));
                  }}
                  activeIndex={documentTypeData.isExpire}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <label className="form-label">Status</label>
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    setDocumentTypeData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={documentTypeData.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default AddDocumentType;
