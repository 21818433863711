import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import { Icon, PreviewAltCard } from "../../../components/Component";
import { BarChart } from "../../../components/charts/BarChart";
import { invoiceStatisticAPI } from "./DashboardData";
import { calculatePercentageChange, calculateSecondLastWeekPercentageChange } from "../../../utils/Functions";

const InvoiceStatistics = () => {
  const [statisticData, setStatisticData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadInvoiceStatistics();
  }, []);

  function loadInvoiceStatistics() {
    setLoading(true);
    invoiceStatisticAPI((responseData, error) => {
      if (error === null) {
        // Sorting the data based on date in descending order
        const sortedData = responseData.list.sort((b, a) => new Date(b.date) - new Date(a.date));
        // Calculating total amount as percentage relative to maximum total amount
        // const maxTotalAmount = Math.max(...sortedData.map((item) => item.totalAmount));
        const formattedData = sortedData.map((item) => ({
          date: item.date,
          // percentage: (item.totalAmount / maxTotalAmount) * 100, // Calculate percentage
          totalAmount: item.totalAmount,
          totalHours: item.totalHours,
          isInvoiceStatistics: true,
        }));
        setStatisticData(formattedData);
      }
      setLoading(false);
    });
  }

  const reversedData = [...statisticData].reverse();
  const { lastWeekpercentage } = calculatePercentageChange(statisticData);
  const { secondLastWeekPercentage } = calculateSecondLastWeekPercentageChange(statisticData);
  let lastWeekPercentageChangeClass = lastWeekpercentage < 0 ? "change down text-danger" : "change up text-success";
  let lastWeekPercentageChangeIcon = lastWeekpercentage < 0 ? "arrow-long-down" : "arrow-long-up";
  let secondLastWeekPercentageChangeClass =
    secondLastWeekPercentage < 0 ? "change down text-danger" : "change up text-success";
  let secondLastWeekPercentageChangeIcon = secondLastWeekPercentage < 0 ? "arrow-long-down" : "arrow-long-up";

  return (
    <React.Fragment>
      <PreviewAltCard className="card-full">
        <div className="card-title-group align-start mb-2">
          <CardTitle>
            <h6 className="title">Invoices</h6>
          </CardTitle>
        </div>
        {loading ? (
          <div className="spinner-container-statistics">
            <Spinner className="spinner-border-md" color="primary" />
          </div>
        ) : statisticData && statisticData?.length > 0 ? (
          <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
            <div className="nk-sale-data-group flex-md-nowrap g-4">
              <div className="nk-sale-data">
                <span className="amount">
                  {reversedData[0]?.totalAmount}
                  <span className={lastWeekPercentageChangeClass}>
                    <Icon name={lastWeekPercentageChangeIcon} />
                    {lastWeekpercentage + "%"}
                  </span>
                </span>
                <span className="sub-title">Last week</span>
              </div>
              <div className="nk-sale-data">
                <span className="amount">
                  {reversedData[1]?.totalAmount}
                  <span className={secondLastWeekPercentageChangeClass}>
                    <Icon name={secondLastWeekPercentageChangeIcon} />
                    {secondLastWeekPercentage + "%"}
                  </span>
                </span>
                <span className="sub-title">Second last week</span>
              </div>
            </div>
            <div className="nk-sales-ck sales-revenue h-100px">
              <BarChart data={statisticData} />
            </div>
          </div>
        ) : (
          <div className="no-data-text align-middle">
            <PreviewAltCard className="m-3">
              <div>
                <div className="text-center">
                  <p>There are no records found.</p>
                </div>
              </div>
            </PreviewAltCard>
          </div>
        )}
      </PreviewAltCard>
    </React.Fragment>
  );
};
export default InvoiceStatistics;