import React from "react";
import { AnnsModal, Col, FormDatePicker } from "../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { moveToPrepareAPI } from "./InvoiceTableData";
import { showErrorToast, showSuccessToast } from "../../../utils";
import moment from "moment";

const PrepareInvoiceModal = ({ visible, item, onClosed, onSuccess, invoiceIds }) => {
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [invoiceDate, setInvoiceDate] = useState();
  const [dueDate, setDueDate] = useState();

  useEffect(() => {
    if (!invoiceDate || !dueDate) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [invoiceDate, dueDate]);

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);

    // Only set due date automatically if it's not manually set
    if (date) {
      const dueDateAfter14Days = moment(date).add(14, "days");
      let formattedDueDate = dueDateAfter14Days.toString();
      setDueDate(dueDateAfter14Days.toDate()); // Convert moment to JavaScript Date
    }
  };

  const close = () => {
    onClosed();
    setLoading(false);
    setInvoiceDate();
    setDueDate();
  };

  function onFormSubmit() {
    setLoading(true);
    moveToPrepareAPI(invoiceIds, invoiceDate, dueDate, (data, error) => {
      if (error === null) {
        close();
        onSuccess();
        showSuccessToast("Invoice prepared successfully");
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  }

  const { errors, register, handleSubmit } = useForm();

  return (
    <>
      <AnnsModal
        size={"md"}
        actionButtonTitle="Prepare"
        invoicePrepare={true}
        isOpen={visible}
        toggle={() => close()}
        title={"Set Your Invoice Dates"}
        onClick={() => onFormSubmit()}
        disabled={isDisable}
        loading={loading}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col sm="12">
            <div className="form-group">
              <label className="form-label">
                Invoice Date<span className="text-danger fs-6">*</span>
              </label>
              <FormDatePicker selected={invoiceDate} onChange={(date) => handleInvoiceDateChange(date)} />
            </div>
          </Col>
          <Col sm="12">
            <div className="form-group">
              <label className="form-label">
                Due Date<span className="text-danger fs-6">*</span>
              </label>
              <FormDatePicker
                selected={dueDate}
                onChange={(date) => {
                  setDueDate(date);
                }}
                minDate={invoiceDate}
              />
            </div>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default PrepareInvoiceModal;
