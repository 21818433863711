/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { gradeTableColumns, listGradesAPI } from "./GradeTableData";
import { useState } from "react";
import AddGrade from "./AddGrade";
import { ServerStatusType } from "../../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { ImportExportButton } from "../../../../components/button/ActionButton";

const GradeList = (props) => {
  const [grades, setGrades] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadGrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setGrades([]);
      setStatus();
      setModal(false);
      setSelectedItem();
    };
  }, []);

  useEffect(() => {
    if (status) {
      loadGrades();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onEdit(row) {
    setSelectedItem(row);
    setModal(true);
  }

  function loadGrades() {
    if (isCheckToList(UserPermissionType.grade)) {
      setLoading(true);
      listGradesAPI({ status: status?.value ?? ServerStatusType.active }, (list, error) => {
        if (error === null) {
          setGrades(list);
        }
        setLoading(false);
      });
    }
  }

  return (
    <React.Fragment>
      <Head title="Grades" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Grades</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.grade, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={grades}
              isAllowed={isCheckToList(UserPermissionType.grade)}
              columns={gradeTableColumns(onEdit)}
            />
          </PreviewCard>
        </Block>
        {modal && (
          <AddGrade
            visible={modal}
            item={selectedItem}
            onClosed={() => {
              setModal(false);
              setSelectedItem();
            }}
            onSaved={() => {
              loadGrades();
            }}
          />
        )}

        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default GradeList;
