import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { useSelector } from "react-redux";
import { shift } from "@floating-ui/core";
import { ShiftTypes } from "../../utils";

export const ShiftDropDown = ({ value, isMulti = false, className, onSelect, placeholder, isAny, ...props }) => {
  const [list, setList] = useState();

  useEffect(() => {
    setList(isAny ? ShiftTypes : ShiftTypes.filter((option) => option.value !== "ANY"));
  }, [isAny]);

  return (
    <RSelect
      className={className}
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? { id: 3, label: "Any", value: "ANY" }}
      onChange={onSelect}
      {...props}
    />
  );
};
