import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { specialityDropDownData } from "./DropDownData";
import { useContext } from "react";
import { CarerWizardContext } from "../../pages/tabs/carers/AddCarer";

export const SpecialityDropDown = ({
  value,
  selectedId,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  carerTypeId,
  ...props
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carerTypeId) {
      loadSpecialityList();
    }
  }, [carerTypeId]);

  const loadSpecialityList = () => {
    specialityDropDownData(carerTypeId, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId) {
      let selectedOptions = [];
      if (Array.isArray(selectedId)) {
        selectedOptions = selectedId.map((id) => list.find((option) => option.id === id));
      } else {
        const option = list.find((option) => option.id === selectedId);
        if (option) {
          selectedOptions.push(option);
        }
      }
      if (isMulti) {
        onSelect(selectedOptions);
      } else {
        const selectedOption = selectedOptions.find((option) => option !== undefined);
        if (selectedOption) {
          onSelect(selectedOption);
        }
      }
    }
  }, [list]);

  return (
    <RSelect options={list} isMulti={isMulti} placeholder={placeholder} value={value} onChange={onSelect} {...props} />
  );
};
