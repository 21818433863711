import React, { useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
  StatusActiveDropDown,
} from "../../../../components/Component";
import { useState } from "react";
import { branchTableData, listBranchAPI } from "./BranchTableData";
import AddBranch from "./AddBranch";
import { ServerStatusType } from "../../../../utils";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";
import { scrollToTop } from "../../../../utils/Functions";

const BranchList = (props) => {
  const [branchList, setBranchList] = useState([]);
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    loadBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status || sortId || sortOrder) {
      loadBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, sortId, sortOrder]);

  const onEdit = (row) => {
    setSelectedItem(row);
    setModal(true);
  };

  function loadBranch() {
    scrollToTop();

    if (isCheckToList(UserPermissionType.branch)) {
      setLoading(true);
      listBranchAPI(
        {
          status: status?.value ?? ServerStatusType.active,
          sorting: {
            field: sortId ?? null,
            order: sortOrder ?? null,
          },
        },
        (list, error) => {
          if (error === null) {
            setBranchList(list);
            setLoading(false);
          }
        }
      );
    } else {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Head title="Area Manager" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Area Manager</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-100px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.branch, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={() => setModal(true)} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={branchList}
              columns={branchTableData(onEdit)}
              isAllowed={isCheckToList(UserPermissionType.branch)}
              pagination
              totalItemCount={branchList?.length}
              onPageChanged={(page, count) => {
                console.log(page);
              }}
              onSort={(id) => {
                setSortId(id);
                const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
                setSortOrder(newSortOrder);
              }}
            />
          </PreviewCard>
        </Block>
        <AddBranch
          visible={modal}
          item={selectedItem}
          onClosed={() => {
            setModal(false);
            setSelectedItem();
          }}
          onSaved={() => {
            loadBranch();
          }}
        />
        <LoadingComponent isLoading={loading} />
      </Content>
    </React.Fragment>
  );
};

export default BranchList;
