import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
} from "../../../components/Component";
import { Card } from "reactstrap";
import PendingTimeSheets from "./PendingTimeSheets";
import DashboardRota from "./DashboardRota";
import NotBookedCarer from "./NotBookedCarer";
import TopClients from "./TopClients";
import TopCarers from "./TopCarers";
import ClientStatistics from "./ClientStatistics ";
import DocumentStatistics from "./DocumentStatistics";
import { statisticAPI } from "./DashboardData";
import { showErrorToast } from "../../../utils";
import CarerStatistics from "./CarerStatistics";
import JobStatistics from "./JobStatistics";
import InvoiceStatistics from "./InvoiceStatistics";
import WageStatistics from "./WageStatistics";

const Dashboard = () => {
  const [statisticsData, setStatisticsData] = useState();

  useEffect(() => {
    loadStatistics();
  }, []);

  function loadStatistics() {
    statisticAPI((data, error) => {
      if (error === null) {
        setStatisticsData(data);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to ANNS CRM.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className={"mb-3"}>
            <Col lg={3} md={6} className={"mb-3 mb-lg-0"}>
              <ClientStatistics clientCount={statisticsData?.client} />
            </Col>
            <Col lg={3} md={6} className={"mb-3 mb-lg-0"}>
              <CarerStatistics carerCount={statisticsData?.carer} />
            </Col>
            <Col lg={3} md={6} className={"mb-3 mb-lg-0"}>
              <DocumentStatistics documentCount={statisticsData?.document} />
            </Col>
            <Col lg={3} md={6} className={"mb-3 mb-lg-0"}>
              <JobStatistics jobCount={statisticsData?.job} />
            </Col>
          </Row>
          <Row className={"mb-3"}>
            <Col xxl={6} lg={6} md={12} className={"mb-3 mb-lg-0"}>
              <WageStatistics />
            </Col>
            <Col xxl={6} lg={6} md={12} className={"mb-3 mb-lg-0"}>
              <InvoiceStatistics />
            </Col>
          </Row>
          <Row className="g-gs">
            <Col xxl={6} lg="6" md="12">
              <div className="dashboard-box-height">
                <Card className="card-full">
                  <DashboardRota />
                </Card>
              </div>
            </Col>
            <Col xxl="6" lg="6">
              <div className="dashboard-box-height">
                <Card className="card-full">
                  <PendingTimeSheets />
                </Card>
              </div>
            </Col>
            <Col xxl={6} lg="6" md="12">
              <div className="dashboard-box-height">
                <Card className="card-full">
                  <NotBookedCarer />
                </Card>
              </div>
            </Col>
            <Col xxl={3} lg="6" md="6">
              <div className="dashboard-box-height">
                <Card className="card-full">
                  <TopClients />
                </Card>
              </div>
            </Col>
            <Col xxl={3} lg="6" md="6">
              <div className="dashboard-box-height">
                <Card className="card-full">
                  <TopCarers />
                </Card>
              </div>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
