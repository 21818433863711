import moment from "moment";
import { AnnsBadge, MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";
import { Badge } from "reactstrap";
import { GradeBadge } from "../../../../components/badge/GradeBadge";
import { toAgencyList } from "../agency/AgencyData";
import { convertHexToColor } from "../../../../utils/Functions";

export const gradeTableColumns = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      // selector: (row) => row?.name ?? "",
      selector: (row) => (
        <>
          <GradeBadge label={row?.name} color={`outline-${convertHexToColor(row?.colourHex) ?? ""}`} />
        </>
      ),
      sortable: true,
    },
    {
      name: <TableTitle title={"Code"} />,
      selector: (row) => row?.abbrCode ?? "",
      sortable: true,
    },
    {
      name: <TableTitle title={"Mile Rate"} />,
      selector: (row) => row?.mileRate ?? "",
      sortable: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.grade, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "90px",
      cell: (row) => {
        return (
          <MoreOptions
            options={[
              {
                icon: "edit",
                text: "Edit",
                hide: false,
                action: () => {
                  onEdit(row);
                },
              },
            ]}
          />
        );
      },
    },
  ];
};

//***************************API's*********************************/
export function addGradeAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.name,
    mileRate: data?.mileRate ? parseFloat(data?.mileRate) : null,
    abbrCode: data.abbrCode,
    dayStart: data.dayStart ? moment(data.dayStart).format("HH:mm") : null,
    nightStart: data.nightStart ? moment(data.nightStart).format("HH:mm") : null,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
    colourHex: data?.colourHex,
    agencies: data.agency.map((agencyTime) => ({
      agencyId: agencyTime.agencyId,
      dayStartTime: moment(agencyTime.dayStart).format("HH:mm"),
      nightStartTime: moment(agencyTime.nightStart).format("HH:mm"),
      invoiceDescFormat: agencyTime.invoiceDescFormat,
    })),
  };
  apiService
    .postCall(APIServicePath.addGrade, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listGradesAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listGrade, params)
    .then((data) => {
      let list = toGradeList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function gradeDetailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.gradeDetail, params)
    .then((data) => {
      let detail = toGrade(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function agencyGradeListAPI(params, callback) {
  apiService
    .postCall(APIServicePath.agencyList, params)
    .then((data) => {
      let list = toAgencyList(data?.list);
      callback({ list }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toGradeList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toGrade(element));
  });
  return list;
}

function toGrade(data) {
  return {
    id: data.id,
    name: data?.name ?? "",
    abbrCode: data?.abbrCode ?? null,
    mileRate: parseFloat(data?.mileRate ?? 0),
    // dayStart: data?.dayStart ? moment(data?.dayStart, "HH:mm").toDate() : null,
    // nightStart: data?.nightStart ? moment(data?.nightStart, "HH:mm").toDate() : null,
    isActive: data?.status === ServerStatusType.active ? true : false,
    colourHex: data?.colourHex ?? null,
    agencies: data?.gradeAgencies,
  };
}
