import moment from "moment";
import { TableTitle } from "../../components/Component";
import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const LoginActivityTableData = () => {
  return [
    {
      name: <TableTitle title={"Ip address"} />,
      id: "ipAddress",
      selector: (row) => row?.ipAddress,
      sortable: false,
      style: {
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Date & Time"} />,
      id: "date",
      sortable: false,
      cell: (row) => {
        return (
          <div className="my-2">
            {moment(row?.loginTime).format("DD-MM-YYYY")} at {moment(row?.loginTime).format("hh:mm A")}
          </div>
        );
      },
      style: {
        color: "black",
      },
    },
    {
      name: <TableTitle title={"City"} />,
      id: "city",
      selector: (row) => row?.location?.city,
      sortable: false,
      style: {
        color: "black",
      },
    },
    {
      name: <TableTitle title={"Country"} />,
      id: "country",
      selector: (row) => countries.getName(row?.location?.country, "en"),
      sortable: false,
      style: {
        color: "black",
      },
    },
  ];
};

export function LoginActivityAPI(params, callback) {
  apiService
    .postCall(APIServicePath.loginActivity, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
