import React, { useEffect, useState } from "react";
import { Icon, PreviewAltCard, TooltipComponent } from "../../../components/Component";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ClientStatistics = ({ clientCount }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!clientCount) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [clientCount]);

  const onMove = (statusobj) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/hospitals`,
      state: { status: statusobj, isMoveClientList: true },
    });
  };

  return (
    <React.Fragment>
      <PreviewAltCard className="card-full">
        {loading ? (
          <div className="spinner-container-statistics">
            <Spinner className="spinner-border-md" color="primary" />
          </div>
        ) : (
          <>
            <div className="card-title-group align-start pb-3 g-2">
              <div className="card-title card-title-sm">
                <h6 className="title">Clients</h6>
              </div>
            </div>
            <div className="analytic-au">
              <div className="analytic-data-group analytic-au-group g-3">
                <div
                  className="analytic-data analytic-au-data cursor-pointer"
                  onClick={() => onMove({ id: 1, label: "Active", value: "ACTIVE" })}
                >
                  <div className="title">Active</div>
                  <div className="amount">{clientCount?.active}</div>
                </div>
                <div
                  className="analytic-data analytic-au-data cursor-pointer text-center"
                  onClick={() => onMove({ id: 2, label: "Inactive", value: "DISABLED" })}
                >
                  <div className="title">Inactive</div>
                  <div className="amount">{clientCount?.inActive}</div>
                </div>
                <div
                  className="analytic-data analytic-au-data cursor-pointer text-end"
                  onClick={() => onMove({ id: 0, label: "All", value: "" })}
                >
                  <div className="title">Total</div>
                  <div className="amount">{clientCount?.all}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </PreviewAltCard>
    </React.Fragment>
  );
};

export default ClientStatistics;
