import React, { useEffect, useState } from "react";
import { availableCarersDropDownData } from "./DropDownData";
import { RSelect } from "../Component";

export const AvailableCarersDropDown = ({
  value,
  onSelect,
  handleOnSearch,
  placeholder,
  startDate,
  endDate,
  gradeId,
  clientId,
  daysFor,
  disabled,
  isClearable,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    return () => {
      setList();
      setSearchString();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCarerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, gradeId, startDate, endDate, clientId, disabled, daysFor]);

  const loadCarerList = () => {
    !disabled &&
      gradeId &&
      startDate &&
      endDate &&
      clientId &&
      (daysFor?.length ?? 0) > 0 && (
        availableCarersDropDownData(searchString, startDate, endDate, gradeId, clientId, daysFor, (data, error) => {
          if (error === null) {
            setList(data);
          }
        })
      );
     
  };

  const handleSearch = (text) => {
    setSearchString(text);
  };

  return (
    <RSelect
      options={list ?? []}
      placeholder={"Select Carer"}
      value={value}
      isClearable={isClearable}
      onInputChange={handleSearch}
      isDisabled={disabled ?? false}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
