import React, { useEffect, useState } from "react";
import { CardTitle, Spinner } from "reactstrap";
import { LoadingComponent, PaginationComponent, PreviewAltCard, UserAvatar } from "../../../components/Component";
import { topClientsApi } from "./DashboardData";
import { showErrorToast } from "../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TopClients = () => {
  const history = useHistory();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTopClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function loadTopClients() {
    setLoading(true);
    topClientsApi((data, error) => {
      if (error === null) {
        setClientData(data?.list);
      }
      setLoading(false);
    });
  }

  const onMove = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/hospital-detail`,
      state: { data: row.id }, // Pass the selected item as state
    });
  };

  // Calculate total pages
  const totalPages = Math.ceil(clientData.length / itemsPerPage);

  // Get the current items for the current page
  const currentItems = clientData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Top 10 Clients</h6>
          </CardTitle>
        </div>
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner className="spinner-border-md" color="primary" />
        </div>
      ) : clientData && clientData.length > 0 ? (
        <ul className="nk-activity">
          {currentItems?.map((item, index) => (
            <li className="nk-activity-item dashboard-rota cursor-pointer" key={index} onClick={() => onMove(item)}>
              <UserAvatar
                className="nk-activity-media"
                customHex={item?.colourHex}
                text={(item?.name ? item?.name.slice(0, 2) : "  ").toUpperCase()}
                name={item?.name}
                subName={item?.type}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-data-text align-middle">
          <PreviewAltCard className="m-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </div>
      )}
      {clientData?.length > itemsPerPage && (
        <div className="mx-4 mt-4">
          <PaginationComponent
            itemPerPage={itemsPerPage}
            totalItems={clientData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
      {/* <LoadingComponent isLoading={loading} /> */}
    </React.Fragment>
  );
};

export default TopClients;
