import React, { useContext, useEffect, useRef, useState } from "react";
import { CarerWizardContext } from "./AddCarer";
import { Form, Spinner } from "reactstrap";
import {
  ActionButton,
  AddressDropdown,
  BackButton,
  Block,
  Col,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  GenderDropDown,
  LoadingComponent,
  PayTypeDropDown,
  PhoneInput,
  RadioButtonOptions,
  Row,
  SpecialityDropDown,
} from "../../../components/Component";
import moment from "moment/moment";
import { CarerTypeDropDown } from "../../../components/Component";
import NameTitleDropDown from "../../../components/dropdowns/NameTitleDropDown";
import GradeTypeDropDown from "../../../components/dropdowns/GradeTypeDropDown";
import { AppText, RadioOptionTypes, covertToNumberOnly, formatPhoneNumber, showErrorToast } from "../../../utils";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { fileUploadApi } from "./CarerData";
import { handleScriptLoad, loadScript } from "../../../services/google/autocomplete";

const CarerDetailsForm = ({ onNext, onPrev, isValid, initScreen }) => {
  const { data, updateData } = useContext(CarerWizardContext);
  const [isDisable, setIsDisable] = useState(true);
  const [itHasChanges, setItHasChanges] = useState(false);
  const [pincode, setPincode] = useState();

  useEffect(() => {
    if (data?.cover?.imageUrl) {
      updateData("cover", data?.cover?.imageUrl);
    }
  }, [data?.cover?.imageUrl]);

  useEffect(() => {
    const hasEmptyFields =
      data.title === "" ||
      data.title === undefined ||
      data.firstName === "" ||
      data.firstName === undefined ||
      data.lastName === "" ||
      data.lastName === undefined ||
      data.email === "" ||
      data.email === undefined ||
      !data.phoneNumber ||
      data.phoneNumber?.phoneNumber === 0 ||
      data.gender === "" ||
      data.gender === undefined ||
      data.dob === "" ||
      data.dob === undefined ||
      (data.carerType && data.carerType.length === 0) ||
      (data.payType && data.payType.length === 0) ||
      (data.gradeType && data.gradeType.length === 0) ||
      // (data.SpecialityType && data.SpecialityType.length === 0) ||
      data?.gradeType === null ||
      data?.gradeType === undefined ||
      data?.gradeType === "" ||
      data?.payType === undefined ||
      data?.payType === null ||
      data?.payType === "" ||
      data?.carerType === null ||
      data?.carerType === undefined ||
      data?.carerType === "" ||
      data?.address === "" ||
      data?.address === undefined ||
      // data.city === "" ||
      // data.city === undefined ||
      // data.postalCode === "" ||
      // data.postalCode === undefined ||
      data.isActive === "" ||
      data.isActive === undefined;
    //  ||
    // // data.isOnHold === "" ||
    // // data.isOnHold === undefined;

    const hasErrors =
      data?.isFirstNameError || data?.islastNameError || data?.isEmailError || data?.isPhoneError || data.isPostalCode;

    setIsDisable(hasEmptyFields || hasErrors);
    isValid(hasEmptyFields || hasErrors, itHasChanges);
  }, [data]);

  const onFormSubmit = () => {
    onNext();
  };
  const handleSearch = () => {
    setPincode(data?.postalCode);
  };

  const handleFileSelect = (file) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      // Assuming "cover" is a single file, not an array
      updateData("cover", fileDetails);

      uploadFile(fileDetails);
    }
  };

  const uploadFile = (formData) => {
    fileUploadApi(formData, "CARER_IMAGE", (fileData, error) => {
      if (error === null) {
        const fileId = fileData.id;
        const fileUrl = fileData.fileUrl;

        const updatedFile = {
          id: fileId,
          isLoading: false,
          fileUrl: fileUrl,
        };
        if (data?.cover?.fileUrl !== updatedFile?.fileUrl) {
          setItHasChanges(true);
        }
        // Update the "cover" with the new file data
        updateData("cover", updatedFile);
      } else {
        // Handle the error here
        showErrorToast(error.message.length > 0 ? error.message : AppText.connectError);
      }
    });
  };

  return (
    <>
      <Form>
        {/* <Row>
          <Col className="col-12  col-md-10"> */}
        {/* {isSpinner ? (
          <div className="spinner d-flex justify-content-center">
            <Spinner size="sm" color="primary" />
          </div>
        ) : (<></>)} */}
        <Row>
          <Col>
            <Row>
              <Col size="6">
                <Row>
                  <Col className=" mb-2">
                    <div className="form-group">
                      <label className="form-label">
                        Title <span className="text-danger fs-6">*</span>
                      </label>
                      <div className="form-control-wrap">
                        <NameTitleDropDown
                          value={data?.title ?? ""}
                          onSelect={(title) => {
                            if (data?.title !== title) {
                              setItHasChanges(true);
                            }
                            updateData("title", title);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" mb-2">
                    <FormInput
                      label={"First Name"}
                      required={true}
                      type={FormInputType.name}
                      value={data?.firstName}
                      onError={(isError) => {
                        updateData("isFirstNameError", isError);
                      }}
                      onTextChange={(text) => {
                        if (data?.firstName !== text) {
                          setItHasChanges(true);
                        }
                        updateData("firstName", text);
                      }}
                    />
                  </Col>
                  <Col className="">
                    <FormInput
                      label={"Last Name"}
                      required={true}
                      type={FormInputType.name}
                      value={data?.lastName}
                      onError={(isError) => {
                        updateData("islastNameError", isError);
                      }}
                      onTextChange={(text) => {
                        if (data?.lastName !== text) {
                          setItHasChanges(true);
                        }
                        updateData("lastName", text);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <div
                  htmlFor="files"
                  // style={{
                  //   backgroundImage: data?.cover
                  //     ? `url(${typeof data.cover === "object" ? : data?.cover})`
                  //     : require("../../../images/logo-dark2x.png"),
                  // }}

                  style={{
                    backgroundImage: data?.cover
                      ? `url(${typeof data.cover === "string" ? data.cover : data.cover.fileUrl})`
                      : require("../../../images/logo-dark2x.png"),
                  }}
                >
                  <div className="form-control-wrap">
                    <input
                      type="file"
                      name="files"
                      accept="image/*"
                      className="form-control"
                      id={"files"}
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        let temp = Array.prototype.slice.call(e.target.files);
                        if (temp.length > 0) {
                          handleFileSelect(temp[0]);
                        }
                        e.target.value = '';
                      }}
                    />
                  </div>
                </div>
                <div className="form-group text-center">
                  <div
                    className="form-control-wrap w-100 img-thumbnail mb-3 mx-auto"
                    style={{ height: "250px", overflow: "hidden" }}
                  >
                    <img
                      src={
                        data?.cover
                          ? typeof data.cover === "string"
                            ? data.cover
                            : data.cover.fileUrl
                          : require("../../../images/profile_placeholder.png")
                      }
                      alt={"Selected"}
                      style={{
                        height: "240px",
                        width: " 100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <label
                    // className="btn"
                    htmlFor="files"
                    style={{
                      color: "#2C75FF",
                      fontSize: 14,
                      marginBottom: 14,
                    }}
                  >
                    {data?.cover?.isLoading === true ? <Spinner size="sm" color="dark" /> : "Select Photo"}
                  </label>
                </div>
              </Col>
            </Row>
          </Col>

          <Col className="col-md-6 mb-2">
            <FormInput
              label={"Email"}
              required={true}
              type={FormInputType?.email}
              value={data?.email}
              onError={(isError) => {
                updateData("isEmailError", isError);
              }}
              onTextChange={(text) => {
                if (data?.email !== text) {
                  setItHasChanges(true);
                }
                updateData("email", text);
              }}
            />
          </Col>
          <Col className="col-md-6 mb-2">
            <PhoneInput
              required
              phone={data?.phoneNumber?.phoneNumber}
              countryCode={data?.phoneNumber?.dialCode}
              onChange={(phone) => {
                if (data?.phoneNumber !== phone) {
                  setItHasChanges(true);
                }
                updateData("phoneNumber", phone);
              }}
              onError={(isError) => {
                updateData("isPhoneError", isError);
              }}
            />
          </Col>
          {/* selector */}
          <Col className="col-md-6 mb-2">
            <div className="form-group">
              <label className="form-label">
                Gender<span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <GenderDropDown
                  value={data?.gender ?? ""}
                  onSelect={(gender) => {
                    if (data?.gender !== gender) {
                      setItHasChanges(true);
                    }
                    updateData("gender", gender);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="col-md-6 mb-2">
            <div className="form-group">
              <label className="form-label">
                Date Of Birth<span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <FormDatePicker
                  label={"From Date"}
                  numberOfPastYears={100}
                  maxDate={new Date()}
                  minDate={new Date("1924-1-1")}
                  // maxDate={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())} // Sets the minimum date to be less than 21 years ago
                  selected={data?.dob}
                  onChange={(date) => {
                    if (data?.dob !== date) {
                      setItHasChanges(true);
                    }
                    updateData("dob", date);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="col-md-6 mb-2">
            <div className="form-group">
              <label className="form-label">
                Carer Type <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <CarerTypeDropDown
                  value={data?.carerType ?? ""}
                  onSelect={(carerType) => {
                    if (data?.carerType !== carerType) {
                      setItHasChanges(true);
                    }
                    updateData("carerType", carerType);
                    updateData("gradeType", null);
                    updateData("SpecialityType", null);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="col-md-6 mb-2">
            <div className="form-group">
              <label className="form-label">
                Pay Type <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <PayTypeDropDown
                  value={data?.payType ?? ""}
                  // carerType={data?.carerType}
                  onSelect={(payType) => {
                    if (data?.payType !== payType) {
                      setItHasChanges(true);
                    }
                    updateData("payType", payType);
                  }}
                />
              </div>
            </div>
          </Col>

          <Col className="col-12 mb-2">
            <div className="form-group">
              <label className="form-label">
                Grade type <span className="text-danger fs-6">*</span>
              </label>
              <div className="form-control-wrap">
                <GradeTypeDropDown
                  value={data?.gradeType ?? ""}
                  isMulti={true}
                  carerTypeId={data?.carerType?.id}
                  onSelect={(gradeType) => {
                    if (data?.gradeType !== gradeType) {
                      setItHasChanges(true);
                    }
                    updateData("gradeType", gradeType);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12 mb-2">
            {/* <Form */}
            <div className="form-group">
              <label className="form-label">
                Speciality Type <span className="text-danger fs-6"></span>
              </label>
              <div className="form-control-wrap">
                <SpecialityDropDown
                  value={data?.SpecialityType ?? ""}
                  isMulti={true}
                  carerTypeId={data?.carerType?.id}
                  onSelect={(SpecialityType) => {
                    if (data?.SpecialityType !== SpecialityType) {
                      setItHasChanges(true);
                    }
                    updateData("SpecialityType", SpecialityType);
                  }}
                />
              </div>
            </div>
          </Col>

          <Col className="col-md-6 mb-2">
            <FormInput
              label={"Postal Code"}
              // required={true}
              type={FormInputType.postalCode}
              value={data?.postalCode}
              onError={(isError) => {
                updateData("isPostalCode", isError);
              }}
              onTextChange={(text) => {
                if (data?.postalCode !== text) {
                  setItHasChanges(true);
                }
                updateData("postalCode", text);
              }}
              showSearchButton={true} // Show the search button
              onSearch={handleSearch} // Provide the search callback
            />
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">
                Address <span className="text-danger">*</span>
              </label>
              <div className="form-control-wrap">
                <AddressDropdown
                  search={pincode}
                  value={{ label: data?.address, value: data?.address }}
                  onSelect={(selectedAddress) => {
                    if (data?.address !== selectedAddress.label) {
                      setItHasChanges(true);
                    }
                    updateData("address", selectedAddress.label);
                    updateData("city", selectedAddress.value.city);
                    updateData("postalCode", selectedAddress.value.zipCode);
                    updateData("coordinates", selectedAddress.value.coordinates);
                  }}
                />
              </div>
            </div>
          </Col>

          <Col className="col-md-4 mb-2">
            <div className="form-group">
              {/* <label className="form-label">Status</label> */}
              <div className="form-control-wrap">
                <RadioButtonOptions
                  name="status"
                  size={6}
                  optionType={RadioOptionTypes.status}
                  onChange={(index) => {
                    if (data?.isActive !== index) {
                      setItHasChanges(true);
                    }
                    updateData("isActive", index === 0);
                    // setTrustData((prev) => ({ ...prev, isActive: index === 0 }));
                  }}
                  activeIndex={data.isActive ? 0 : 1}
                />
              </div>
            </div>
          </Col>
          <Col className="col-md-4 mb-2">
            <div className="form-group">
              {/* <label className="form-label">On hold</label> */}
              <div className="form-control-wrap">
                <FormCheckbox
                  id="status1"
                  required={false}
                  name="status"
                  label={"On hold"}
                  checked={data?.isOnHold ?? false}
                  onChange={(e) => {
                    if (data?.isOnHold !== e.target.checked) {
                      setItHasChanges(true);
                    }
                    updateData("isOnHold", e.target.checked);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* </Col>
        </Row> */}
        {/* <button
          onClick={() => {
            onNext();
          }}
        >
          CarerDetailsForm
        </button> */}
        <Col md="12" className={"mb-2"}>
          <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
            <li>
              <BackButton cancel onClick={() => onPrev()} />
            </li>
            <li>
              <ActionButton
                size={"md"}
                title="Save & Next"
                type={"button"}
                disabled={isDisable}
                onClick={() => onFormSubmit()}
              />
            </li>
          </ul>
        </Col>
      </Form>
      <LoadingComponent isLoading={initScreen} />
    </>
  );
};

export default CarerDetailsForm;
