import React, { useState, useEffect } from "react";
import moment from "moment";
import { Icon } from "../../Component";
import YearMonthPicker from "./YearMonthPicker";

const DateRangePicker = ({ onDateChange, moveDate }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  // useEffect(() => {
  //   setSelectedDate(new Date());
  // }, []);

  useEffect(() => {
    if (moveDate) {
      setStartDate(moveDate.startDate);
      setEndDate(moveDate.endDate);
    } else {
      setSelectedDate(new Date());
    }
  }, [moveDate]);

  useEffect(() => {
    if (!moveDate && selectedDate) {
      handleDateChange(selectedDate);
    }
  }, [selectedDate, moveDate]);

  // useEffect(() => {
  //   selectedDate && handleDateChange(selectedDate);
  // }, [selectedDate]);

  const updateDateRange = (newStartDate) => {
    const newEndDate = new Date(newStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate.toISOString().slice(0, 10));
    setEndDate(newEndDate.toISOString().slice(0, 10));
    onDateChange(newStartDate.toISOString().slice(0, 10), newEndDate.toISOString().slice(0, 10));
    setSelectedDate(newStartDate);
  };

  const handleNextPrev = (isNext) => {
    const currentDate = new Date(startDate);
    const daysToAdd = isNext ? 7 : -7;
    const newStartDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
    const startDayOfWeek = newStartDate.getDay();
    const daysToSubtract = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
    newStartDate.setDate(newStartDate.getDate() - daysToSubtract);
    updateDateRange(newStartDate);
  };

  const handleDateChange = (selectedDate) => {
    const currentDate = new Date(selectedDate);
    const currentDay = currentDate.getDay();
    const daysToSubtract = currentDay === 0 ? 6 : currentDay - 1; // Adjust to the previous Monday

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - daysToSubtract);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)

    // Update the state with the selected dates
    setStartDate(startOfWeek.toISOString().slice(0, 10));
    setEndDate(endOfWeek.toISOString().slice(0, 10));

    // Pass the selected dates to the parent component
    onDateChange(startOfWeek.toISOString().slice(0, 10), endOfWeek.toISOString().slice(0, 10));
  };

  return (
    <div className="input-group ">
      <span
        className="input-group-text bg-purple text-white border-0 cursor-pointer"
        onClick={() => handleNextPrev(false)}
      >
        <Icon className="fs-4" name="chevron-left" />
      </span>
      <YearMonthPicker
        onChange={(date) => {
          handleDateChange(date);
          setSelectedDate(date);
        }}
        placeholder={`${moment(startDate).format("MMM DD")} - ${moment(endDate).format("MMM DD")}`}
        selected={selectedDate}
      />
      <span
        className="input-group-text bg-purple text-white border-0 cursor-pointer"
        onClick={() => handleNextPrev(true)}
      >
        <Icon className="fs-4" name="chevron-right" />
      </span>
    </div>
  );
};

export default DateRangePicker;
