import { MoreOptions, StatusBadge, TableTitle } from "../../../../components/Component";
import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-service-impl";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../../services/permission/permission";
import { ServerStatusType } from "../../../../utils";

export const branchTableData = (onEdit, onRemove) => {
  return [
    {
      name: <TableTitle title={"Title"} />,
      selector: (row) => row?.name ?? "",
      sortable: true,
      id: "branchName",
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <StatusBadge status={row?.isActive} />,
    },
    isAllowToAccess(UserPermissionType.branch, UserActionType.edit) && {
      name: <TableTitle title={""} align="end" />,
      right: true,
      cell: (row) => (
        <MoreOptions
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            // {
            //   icon: "trash",
            //   text: "Remove",
            //   action: () => {
            //     onRemove(row);
            //   },
            // },
          ]}
        />
      ),
    },
  ];
};

//***************************API's*********************************/
export function addBranchAPI(data, callback) {
  let params = {
    id: data.id,
    name: data.name,
    status: data.isActive ? ServerStatusType.active : ServerStatusType.disabled,
  };
  apiService
    .postCall(APIServicePath.addBranch, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listBranchAPI(params, callback) {
  apiService
    .postCall(APIServicePath.listBranch, params)
    .then((data) => {
      let list = toBranchList(data?.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//***************************Converters*********************************/

function toBranchList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toBranch(element));
  });
  return list;
}

function toBranch(data) {
  return {
    id: data.id,
    name: data?.name ?? "",
    isActive: data?.status === ServerStatusType.active ? true : false,
  };
}
