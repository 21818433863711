import React from "react";
import Button from "./Button";
import { Spinner } from "reactstrap";
import { Icon } from "../Component";

const ActionButton = ({ className, type, title, size, loading, disabled, color, ...props }) => {
  return (
    <Button
      size={size}
      className={`btn-block ${
        disabled ? "btn-light" : size ? `custom-solid-btn-${size}` : "custom-solid-btn-sm"
      } ${className}`}
      color={disabled && "light"}
      disabled={loading || disabled}
      onClick={props.onClick}
      type={type ?? "button"}
    >
      {loading ? <Spinner size="sm" color="light" /> : title}
    </Button>
  );
};
export default ActionButton;

export const CancelButton = ({ onCancel, className, type = "button", ...props }) => {
  return (
    <Button className={`btn-block btn-light ${className}`} onClick={onCancel} type={type} {...props}>
      Cancel
    </Button>
  );
};

export const DeleteButton = ({ onDelete, onCancelRota, className, type = "button", ...props }) => {
  return (
    <>
      {onDelete ? (
        <Button className={`btn-block btn-danger ${className}`} onClick={onDelete} type={type} {...props}>
          Delete
        </Button>
      ) : (
        <Button className={`btn-block btn-danger ${className}`} onClick={onCancelRota} type={type} {...props}>
          Cancel
        </Button>
      )}
    </>
  );
};

export const ImportExportButton = ({ iconName, onClick, loading, title, color, addButton, disabled }) => {
  return (
    <>
      <Button
        className={`btn-block ${addButton ? "custom-solid-btn-sm" : "bg-white"} `}
        color={disabled && `light${color}`}
        disabled={loading || disabled}
        onClick={onClick}
        type="button"
      >
        {loading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <>
            <Icon name={iconName} />
            <span>{title}</span>
          </>
        )}
      </Button>
    </>
  );
};

export const CarerPreviousButton = ({ onClick, className, type = "button", ...props }) => {
  return (
    <Button color="light" type="button" outline className="bg-white d-sm-inline-flex" onClick={onClick}>
      Previous
    </Button>
  );
};
