import React, { useEffect, useState } from "react";
import {
  ConfirmationAlert,
  Icon,
  LoadingComponent,
  PreviewAltCard,
  ReactDataTable,
  TableTitle,
} from "../../../components/Component";
import { ServerStatusType, dateToShow, showErrorToast } from "../../../utils";
import UploadDocumentModal from "../../../components/UploadDocumentmodal/UploadDocumentModal";
import { carerDocumentListApi, carerDocumentRemoveApi, carerTypeDocumentApi } from "./CarerData";
import { Popover, PopoverBody } from "reactstrap";
import { CarerDocumentStatusBadge } from "../../../components/badge/StatusBadge";
import { CarerDocStatusDropDown } from "../../../components/dropdowns/CarerDocStatusDropDown";

const CarerDocumentList = ({ ...props }) => {
  const [initScreen, setInitScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentModal, setdocumentModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [carerDocumentList, setCarerDocumentList] = useState([]);
  const [finalDocList, setFinalDocList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    status && callCarerTypeDocumentApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carerTypeID, status]);

  useEffect(() => {
    if (status?.value === ServerStatusType.active) {
      setFinalDocList(mergeDocumentData());
    } else {
      setFinalDocList(carerDocumentList);
    }
  }, [carerDocumentList, documentList]);

  const mergeDocumentData = () => {
    const combinedData = documentList.map((item) => {
      const matchingItem = carerDocumentList.find((dataItem) => dataItem?.document?.id === item.id);
      return { ...item, ...(matchingItem ?? {}) };
    });
    return combinedData;
  };

  const loadDocumentList = () => {
    let params = {
      carerId: props?.carerId,
      status: status?.value ?? ServerStatusType.active,
    };
    carerDocumentListApi(params, (data, error) => {
      if (error === null) {
        setCarerDocumentList(data?.list);
      }
      setInitScreen(false);
    });
  };

  const callCarerTypeDocumentApi = () => {
    if (!props.carerTypeID) return;
    carerTypeDocumentApi(props.carerTypeID, (data, error) => {
      if (error === null) {
        setDocumentList(data?.list);
        loadDocumentList();
      } else {
        showErrorToast(error.message);
      }
      // setInitScreen(false);
    });
  };
  const removeDocument = (row) => {
    ConfirmationAlert("Removing Document!", "Do you want to remove this Document ?", () => {
      let params = {
        carerId: props?.carerId,
        carerDocId: row?.carerDocumentId,
      };
      carerDocumentRemoveApi(params, (data, error) => {
        if (error === null) {
          setFinalDocList(finalDocList.filter((item) => item.carerDocumentId !== row?.carerDocumentId));
        }
      });
    });
  };

  // *********** Functions ************* //
  const onAdddocument = (row) => {
    setSelectedItem(row);
    setdocumentModal(true);
  };

  const openFileInNewTab = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mt-2 align-center">
        <div className="">
          <span className="text-dark fs-3 fw-bold"></span>
          <br />
        </div>
        <div>
          <CarerDocStatusDropDown
            value={status}
            isShowAll={false}
            className="w-225px"
            placeholder={"Status"}
            onSelect={(e) => {
              setStatus(e);
            }}
          />
        </div>
      </div>
      {finalDocList.length === 0 ? (
        <>
          <PreviewAltCard className="my-3">
            <div>
              <div className="text-center">
                <p>There are no records found.</p>
              </div>
            </div>
          </PreviewAltCard>
        </>
      ) : (
        <ReactDataTable
          className={"mt-n1"}
          data={finalDocList}
          columns={documentTableData(onAdddocument, removeDocument, (row) => openFileInNewTab(row?.fileUrl))}
        />
      )}

      <UploadDocumentModal
        visible={documentModal}
        carerId={props?.carerId}
        selectedItem={selectedItem}
        onClosed={() => {
          setdocumentModal(false);
        }}
        afterDocumentSave={() => {
          loadDocumentList();
          setCurrentPage(1);
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default CarerDocumentList;

const FileCell = ({ row }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const closePopover = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    if (popoverOpen) {
      document.addEventListener("click", closePopover);
    } else {
      document.removeEventListener("click", closePopover);
    }
    return () => {
      document.removeEventListener("click", closePopover);
    };
  }, [popoverOpen]);

  const openFileInNewTab = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  return (
    <>
      {row?.files?.length > 0 ? (
        <>
          <div className="cursor-pointer">
            <div id={`popover-${row.id}`} onClick={togglePopover}>
              <label htmlFor={`popover-${row.id}`}>
                <Icon name="clip" className="text-primary" id={`popover-${row.id}`} />
                <span className="cursor-pointer text-primary" id={`popover-${row.id}`}>
                  {row?.files?.length ?? "N/A"}
                </span>
              </label>
            </div>
            <Popover isOpen={popoverOpen} target={`popover-${row.id}`} toggle={togglePopover}>
              <PopoverBody>
                {Array.isArray(row?.files) && row.files.length > 0
                  ? row.files.map((file, index) => (
                      <React.Fragment key={index}>
                        <div className="my-1" onClick={togglePopover}>
                          <span
                            className="me-1 cursor-pointer text-primary"
                            onClick={() => openFileInNewTab(file?.fileUrl)}
                          >
                            {file?.fileName}
                          </span>
                        </div>
                      </React.Fragment>
                    ))
                  : null}
              </PopoverBody>
            </Popover>
          </div>
        </>
      ) : (
        <span>N/A</span>
      )}
    </>
  );
};

export const documentTableData = (onAdddocument, removeDocument) => {
  return [
    {
      name: <TableTitle title={"Name"} />,
      selector: (row) => {
        if (row?.carerDocumentStatus === "DISABLED") {
          return row?.document?.title ?? "";
        } else {
          return row?.title ?? "";
        }
      },
      sortable: true,
    },
    {
      name: <TableTitle title={"Reference no"} />,
      selector: (row) => row?.refNo ?? "N/A",
      sortable: true,
    },
    {
      name: <TableTitle title={"Issue Date"} />,
      selector: (row) => (row?.issueDate ? dateToShow(row?.issueDate) : "N/A"),
    },
    {
      name: <TableTitle title={"Expiry Date"} />,
      selector: (row) => {
        const expiryDate = row?.expiryDate ? new Date(row?.expiryDate) : null;
        const today = new Date();
        if (!expiryDate) {
          return "N/A";
        }
        if (expiryDate < today) {
          return <span className="">{dateToShow(row?.expiryDate)}</span>;
        } else if (expiryDate > today.setMonth(today.getMonth() + 3)) {
          return <span className="">{dateToShow(row?.expiryDate)}</span>;
        } else {
          return <span className="">{dateToShow(row?.expiryDate)}</span>;
        }
      },
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => <CarerDocumentStatusBadge status={row?.carerDocumentStatus} expiryDate={row?.expiryDate} />,
    },
    {
      name: <TableTitle title={"File"} />,
      width: "80px",
      style: {
        cursor: "pointer",
      },
      cell: (row) => <FileCell row={row} />,
    },
    {
      name: <TableTitle title={"Updated By"} />,
      cell: (row) => (
        <>
          <div>
            <span>{`${row?.updatedBy?.firstName || "N/A"} ${row?.updatedBy?.lastName || "N/A"}`}</span>
            <br />
            <span className="text-center">{row?.updatedBy?.userType}</span>
          </div>
        </>
      ),
    },
    {
      name: <TableTitle title={""} />,
      width: "120px",
      style: {
        cursor: "pointer",
      },
      right: true,
      cell: (row) => (
        <>
          {row?.carerDocumentStatus === "ACTIVE" ? (
            <span className="text-primary" onClick={() => onAdddocument(row)}>
              Renew Now
            </span>
          ) : row?.carerDocumentStatus === "DISABLED" ? (
            <span className="text-primary" onClick={() => removeDocument(row)}>
              Remove
            </span>
          ) : (
            <span className="text-primary" onClick={() => onAdddocument(row)}>
              Add
            </span>
          )}
        </>
      ),
    },
  ];
};
