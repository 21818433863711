import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { useSelector } from "react-redux";
import { convertDropValue } from "../../utils";

export const GenderDropDown = ({ value, isMulti = false, onSelect, placeholder, ...props }) => {
  const [list, setList] = useState();
  const genders = useSelector((state) => state?.masterList?.masterList?.genders);

  useEffect(() => {

    return () => {
      setList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let statusValues = [];
    for (let index = 0; index < genders?.length ?? 0; index++) {
      const element = genders[index];
      statusValues.push(convertDropValue(element, true))
    }
    setList(statusValues);
  }, [genders]);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
