/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ActionButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Component";
import Content from "../../content/Content";
import Head from "../../head/Head";
import SendNotificationModal from "../../../pages/tabs/timesheets/SendNotificationModal";
import { SendNotificationType } from "../../../utils/Utils";
import { broadCastListAPI, BroadCastTableData } from "./BroadCastData";
import { PER_PAGE_ITEMS, showErrorToast } from "../../../utils";

const BroadCast = () => {
  const [notificationModal, setNotificationModal] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadBroadCastList();
  }, [currentPage]);

  // useEffect(() => {
  //   if (initScreen) return;
  //   if(currentPage === 1) {
  //     loadBroadCastList();
  //   } else {
  //     setCurrentPage(1);
  //   }
  // }, [currentPage]);

  const loadBroadCastList = () => {
    setInitScreen(true);
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: PER_PAGE_ITEMS,
      },
    };
    broadCastListAPI(params, (data, error) => {
      if (error === null) {
        setList(data?.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems ?? 0);
        }
      } else {
        showErrorToast(error.message);
      }
      setInitScreen(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Broadcast" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Broadcast</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ActionButton title={"Send message"} onClick={() => setNotificationModal(true)} />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <ReactDataTable
              data={list}
              columns={BroadCastTableData()}
              pagination={true}
              //   isAllowed={isCheckToList(UserPermissionType.carerType)}
              totalItemCount={totalItems}
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
            />
          </PreviewCard>{" "}
        </Block>
      </Content>
      <SendNotificationModal
        visible={notificationModal}
        onClosed={() => setNotificationModal(false)}
        type={SendNotificationType.grade}
        onSaved={() => loadBroadCastList()}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default BroadCast;
