import React from "react";
import {
  AnnsModal,
  Col,
  ConfirmationAlert,
  FormInput,
  FormInputType,
  PayTypeDropDown,
  Row,
} from "../../../components/Component";
import { Form } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { AppText, PER_PAGE_ITEMS, showErrorToast, showSuccessToast } from "../../../utils";
import { useForm } from "react-hook-form";
import { CarerTypeDropDown } from "../../../components/Component";
import { addRateAPI, listRateAPI, rateRemoveAPI } from "./RatesData";
import { useSelector } from "react-redux";
import GradeTypeDropDown from "../../../components/dropdowns/GradeTypeDropDown";
import { formatAmount } from "../../../components/input/FormInput/FormInput";

const AddRate = ({ visible, hospitalId, item, carerType, onSaved, onClosed }) => {
  const [rateData, setRateData] = useState({
    dayTypeRates: [],
  });
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isError, setIsError] = useState(false);
  const [rateDetails, setRateDetails] = useState();
  const dayTypes = useSelector((state) => state?.masterList?.masterList?.dayTypes);

  const areDayTypeRatesFilled = () => {
    const filledRates = rateData?.dayTypeRates?.filter(
      (dayTypeRate) =>
        dayTypeRate?.chargeRate !== null &&
        dayTypeRate?.chargeRate !== undefined &&
        dayTypeRate?.chargeRate !== "" &&
        dayTypeRate?.payRate !== null &&
        dayTypeRate?.payRate !== undefined &&
        dayTypeRate?.payRate !== ""
    );

    return filledRates?.length === dayTypes?.length;
  };

  useEffect(() => {
    setIsError(false);
    const hasInvalidRate = dayTypes.some((dayType, dayTypeIndex) => {
      return hasInvalidChargeRate(rateData?.dayTypeRates?.[dayTypeIndex]);
    });

    if (
      rateData.grade === null ||
      rateData.carerType === null ||
      rateData.payType === null ||
      hasInvalidRate ||
      !areDayTypeRatesFilled()
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [rateData]);

  useEffect(() => {
    setRateData({
      ...rateData,
      dayTypeRates: rateDetails?.map((item) => ({
        chargeRate: formatAmount("", item?.chargeRate + ""),
        dayTypeId: item?.dayType?.id,
        payRate: formatAmount("", item?.payRate + ""),
      })),
    });
  }, [rateDetails]);

  useEffect(() => {
    if (item) {
      loadRate();
    }
  }, [item]);

  useEffect(() => {
    setIsError(false);
    if (visible) {
      if (item) {
        setRateData({
          id: item?.id,
          carerType: item?.carerType,
          grade: item?.grade,
          payType: item?.payType,
          // dayTypeRates,
        });
      } else {
        setRateData({
          grade: null,
          carerType: null,
          payType: null,
          // dayTypeRates: dayTypes.map((dayType) => ({
          //   chargeRate: "",
          //   payRate: "",
          //   dayTypeId: dayType.id,
          // })),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, item, dayTypes]);

  const close = () => {
    onClosed();
    setLoading(false);
    setRateData({
      dayTypeRates: [],
    });
  };

  const onFormSubmit = () => {
    if (rateData?.chargeRate < rateData?.payRate) {
      setIsError(true);
      setIsDisable(true);
    } else {
      setIsError(false);
      if (isDisable) return;
      setLoading(true);
      addRateAPI(rateData, hospitalId, (_, error) => {
        if (error === null) {
          let text = "Rate" + (item ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);
          showSuccessToast(text);
          onSaved();
          close();
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    }
  };

  const onRemove = () => {
    ConfirmationAlert("Removing Rate!", "Do you want to remove all these Rate  ?", () => {
      loadRemoveRate();
    });
  };

  const hasInvalidChargeRate = (dayTypeRate) => {
    const chargeRate = parseFloat(dayTypeRate?.chargeRate?.toString().replace(/,/g, "") || 0);
    const payRate = parseFloat(dayTypeRate?.payRate?.toString().replace(/,/g, "") || 0);
    return chargeRate - payRate < 0;
  };

  const { handleSubmit } = useForm();

  // API--------------------------------------------->

  function loadRate() {
    listRateAPI(
      {
        pageInfo: {
          pageIndex: 1,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        // clientId: hospitalId,
        clientId: item?.client,
        carerTypeId: item?.carerType?.id,
        gradeId: item?.grade?.id,
        payType: item?.payType,
      },
      (data, error) => {
        if (error === null) {
          setRateDetails(data?.list);
        }
      }
    );
  }

  const loadRemoveRate = (row) => {
    let params = {
      clientId: item?.client,
      carerTypeId: item?.carerType?.id,
      gradeId: item?.grade?.id,
      payType: item?.payType,
    };
    rateRemoveAPI(params, (data, error) => {
      if (error === null) {
        onSaved();
        close();
      }
    });
  };

  return (
    <>
      <AnnsModal
        size={"md"}
        isOpen={visible}
        toggle={() => close()}
        title={item ? "Edit Rate" : "Add Rate"}
        onClick={() => onFormSubmit()}
        onDelete={() => onRemove()}
        disabled={isDisable}
        loading={loading}
        isShowHeaderRemove={item ? true : null}
      >
        <Form className="row gy-4 form-validation" onSubmit={handleSubmit(onFormSubmit)}>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Carer Type</label>
              <div className="form-control-wrap">
                <CarerTypeDropDown
                  value={rateData?.carerType ?? ""}
                  onSelect={(item) => {
                    if (item.id !== rateData?.carerType?.id) {
                      setRateData((prev) => ({ ...prev, carerType: item, speciality: null, grade: null }));
                    }
                  }}
                  selectedId={rateData?.carerType?.id}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Grade</label>
              <div className="form-control-wrap">
                <GradeTypeDropDown
                  value={rateData?.grade ?? ""}
                  selectedId={rateData?.grade?.id}
                  carerTypeId={rateData?.carerType?.id}
                  onSelect={(items) => {
                    setRateData((prev) => ({ ...prev, grade: items }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Pay Type</label>
              <div className="form-control-wrap">
                <PayTypeDropDown
                  // carerType={rateData?.carerType}
                  value={rateData?.payType}
                  selectedValue={rateData?.payType}
                  onSelect={(payType) => {
                    setRateData((prev) => ({ ...prev, payType: payType }));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <Row>
              <Col size="4">
                <div>Day Type</div>
              </Col>
              <Col size="4">
                <div>Pay Rate</div>
              </Col>
              <Col size="4">
                <div>Charge Rate</div>
              </Col>
            </Row>
            {dayTypes?.length > 0 &&
              dayTypes.map((dayType, dayTypeIndex) => {
                let rate = rateData?.dayTypeRates?.find((item) => item?.dayTypeId === dayType?.id);
                let index = rateData?.dayTypeRates?.findIndex((item) => item?.dayTypeId === dayType?.id) ?? -1;

                return (
                  <Row className="align-items-baseline" key={dayTypeIndex}>
                    <Col size="4">
                      <div className=" my-3">
                        <label>{dayType?.name}</label>
                      </div>
                    </Col>
                    <Col size="4">
                      <FormInput
                        placeholder={"Pay Rate"}
                        type={FormInputType.amount}
                        value={rate?.payRate ?? null}
                        onTextChange={(payRate) => {
                          let list = rateData?.dayTypeRates ? [...rateData.dayTypeRates] : []; // Initialize list as an empty array
                          let dayTypeData = index !== -1 ? list[index] : {};
                          dayTypeData.payRate = payRate;
                          dayTypeData.dayTypeId = dayType.id;
                          index !== -1 ? (list[index] = dayTypeData) : list.push(dayTypeData);
                          setRateData((prev) => ({
                            ...prev,
                            dayTypeRates: list,
                          }));
                        }}
                      />
                    </Col>
                    <Col size="4">
                      <FormInput
                        baseClass={"m-0"}
                        placeholder={"Charge Rate"}
                        type={FormInputType.amount}
                        value={rate?.chargeRate ?? null}
                        onTextChange={(chargeRate) => {
                          chargeRate = chargeRate ? chargeRate.toString() : "";
                          let list = rateData?.dayTypeRates ? [...rateData.dayTypeRates] : []; // Initialize list as an empty array
                          let dayTypeData = index !== -1 ? list[index] : {};
                          dayTypeData.chargeRate = chargeRate;
                          dayTypeData.dayTypeId = dayType.id;
                          index !== -1 ? (list[index] = dayTypeData) : list.push(dayTypeData);
                          setRateData((prev) => ({
                            ...prev,
                            dayTypeRates: list,
                          }));
                        }}
                      />
                      <span>
                        {hasInvalidChargeRate(rateData?.dayTypeRates?.[index]) && (
                          <span className="invalid">Charge rate should not be less than pay rate</span>
                        )}
                      </span>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};
export default AddRate;
