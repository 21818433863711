import store from "../../store/store";
import { convertToNotNull } from "../../utils";
export const UserPermissionType = {
  agency: "agency",
  trust: "trust",
  client: "client",
  carer: "carer",
  job: "job",
  rota: "rota",
  timesheet: "timesheet",
  invoice: "invoice",
  wage: "wage",
  user: "user",
  branch: "branch",
  role: "role",
  rate: "rate",
  note: "note",
  grade: "grade",
  speciality: "speciality",
  availability: "availability",
  holiday: "holiday",
  noteType: "note type",
  documentType: "document type",
  carerType: "carer type",
  template: "template",
  ward: "ward",
};

export const UserActionType = {
  add: "add",
  edit: "edit",
  list: "list",
  remove: "remove",
  detail: "detail",
  import: "import",
  export: "export",
  save: "save",
  cancel: "cancel",
  approve: "approve",
  verify: "verify",
  inquery: "in query",
  moveToWages: "move to wages",
  moveToTimesheet: "move to timesheet",
  finalised: "finalise",
  prepare: "prepare",
  sent: "sent",
  setdefault: "set default",
  setavailability: "set availability",
};

export function isAllowToAccess(type, action) {
  let roles = store.getState()?.auth?.sessionUser?.roles;
  if (!roles) return false;
  if (!roles[type]) return false;
  if (!roles[type].find((item) => item === action)) return false;
  return true;
}

export function isCheckToList(type) {
  let roles = store.getState()?.auth?.sessionUser?.roles ?? {};
  if (!roles) return false;
  if (!roles[type]) return false;
  return true;
}

export const menuItems = () => {
  let roles = store.getState()?.auth?.sessionUser?.roles;
  if (!roles) return [];
  let menu = [];

  menu.push({
    text: "",
    link: "/",
  });

  if (roles[UserPermissionType.trust] || roles[UserPermissionType.client]) {
    let submenu = [];
    roles[UserPermissionType.trust] &&
      submenu.push({
        text: "Trust",
        link: "/trust",
      });

    roles[UserPermissionType.client] &&
      submenu.push({
        text: "Client",
        link: "/hospitals",
      });

    let item = {
      text: "Client",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }

  roles[UserPermissionType.carer] &&
    menu.push({
      text: "Carer",
      link: "/carers",
    });

  roles[UserPermissionType.job] &&
    menu.push({
      text: "Job",
      link: "/jobs",
    });

  roles[UserPermissionType.rota] &&
    menu.push({
      text: "Rota",
      link: "/rotas",
    });

  roles[UserPermissionType.timesheet] &&
    menu.push({
      text: "Timesheet",
      link: "/timesheet",
    });

    // roles[UserPermissionType.wage] &&
    // menu.push({
    //   text: "Wages",
    //   link: "/wages",
    // });

    // roles[UserPermissionType.invoice] &&
    // menu.push({
    //   text: "Invoices",
    //   link: "/invoices",
    // });

  if (roles[UserPermissionType.wage] || roles[UserPermissionType.invoice]) {
    let submenu = [];

    roles[UserPermissionType.wage] &&
      submenu.push({
        text: "Wages",
        link: "/wages",
      });

    roles[UserPermissionType.invoice] &&
      submenu.push({
        text: "Invoices",
        link: "/invoices",
      });

    let item = {
      text: "Invoice",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }

  if (
    roles[UserPermissionType.user] ||
    roles[UserPermissionType.agency] ||
    roles[UserPermissionType.branch] ||
    roles[UserPermissionType.role] ||
    roles[UserPermissionType.grade] ||
    roles[UserPermissionType.speciality] ||
    roles[UserPermissionType.holiday] ||
    roles[UserPermissionType.noteType] ||
    roles[UserPermissionType.documentType] ||
    roles[UserPermissionType.carerType] ||
    roles[UserPermissionType.template]
  ) {
    let submenu = [];

    roles[UserPermissionType.agency] &&
      submenu.push({
        text: "Agency",
        link: "/agency",
      });

    roles[UserPermissionType.user] &&
      submenu.push({
        text: "User",
        link: "/users",
      });

    roles[UserPermissionType.branch] &&
      submenu.push({
        text: "Area Manager",
        link: "/branches",
      });

    roles[UserPermissionType.role] &&
      submenu.push({
        text: "User Role",
        link: "/roles",
      });

    roles[UserPermissionType.grade] &&
      submenu.push({
        text: "Grade",
        link: "/grade",
      });

    roles[UserPermissionType.speciality] &&
      submenu.push({
        text: "Speciality",
        link: "/speciality",
      });

    roles[UserPermissionType.holiday] &&
      submenu.push({
        text: "Holiday",
        link: "/holidays",
      });

    roles[UserPermissionType.noteType] &&
      submenu.push({
        text: "Note Type",
        link: "/note-type",
      });

    roles[UserPermissionType.documentType] &&
      submenu.push({
        text: "Document Type",
        link: "/document-type",
      });

    roles[UserPermissionType.carerType] &&
      submenu.push({
        text: "Carer Type",
        link: "/carer-type",
      });

    roles[UserPermissionType.template] &&
      submenu.push({
        text: "Template",
        link: "/template",
      });

    let item = {
      text: "Other",
      subMenu: convertToNotNull(submenu),
    };
    menu.push(item);
  }
  return menu;
};
